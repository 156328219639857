<template>
	<div class="card p-2 m-0">
		<div class="d-flex flex-column flex-md-row justify-content-between align-items-end mb-1">
			<div class="d-flex flex-column flex-md-row contain-flex">
				<b-form-group label="Fecha de inicio" class="mb-0 mr-0 mr-md-1">
					<flat-pickr
						@on-change="reloadSlider"
						v-model="dateIn"
						:config="{ maxDate: dateFn }"
						class="form-control bg-white"
						placeholder="Seleccionar fecha"
					/>
				</b-form-group>
				<b-form-group label="Fecha final" class="mb-0 mt-1 mt-md-0">
					<flat-pickr
						@on-change="reloadSlider"
						v-model="dateFn"
						:config="{ minDate: dateIn }"
						class="form-control bg-white"
						placeholder="Seleccionar fecha"
					/>
				</b-form-group>
			</div>

			<div class="d-flex flex-column flex-md-row contain-flex" v-if="is_local == 0">
				<b-form-group label="" class="mb-0 mr-0 mr-md-1 mt-1 mt-md-0">
					<b-form-input
						@keyup.enter="reloadSlider"
						placeholder="Buscar por nombre"
						v-model="search"
						trim
					></b-form-input>
				</b-form-group>
				<div class="d-flex align-items-end justify-content-start mt-1 mt-md-0">
					<button class="btn btn-primary" @click="reloadSlider">Buscar</button>
				</div>
			</div>
		</div>

		<div class="container-scroll">
			<div class="container-main-list">
				<div class="list-table border-left list-users">
					<div class="header bg-header">
						<p class="mb-0">Usuarios</p>
					</div>
					<div class="card-user" v-for="(item, index) in items" :key="index">
						<p class="mb-0">{{ item.name }}</p>
						<b-avatar class="avatar" size="30px" variant="secondary" :src="item.user_avatar"></b-avatar>
					</div>
				</div>

				<div class="container-swiper">
					<SlidersAttendance
						v-if="!load"
						:sliders="sliders"
						:items="items"
						:swiper="swiper"
						@setSwiper="setSwiper"
						:load="load"
					/>
					<div v-else class="load-sliders">
						<p>Cargando...</p>
						<b-spinner variant="primary" label="Spinning"></b-spinner>
					</div>
				</div>
				<div class="list-table border-right total-hours">
					<div class="header">
						<div class="header-title">
							<p class="mb-0">Horas</p>
						</div>
						<div class="grid">
							<p class="mb-0 subtotal">SUBTOTAL</p>
							<p class="mb-0 total">TOTAL</p>
						</div>
					</div>
					<div class="card-user" v-for="(item, index) in items" :key="index">
						<p class="mb-0">
							{{ sliders[indexSwiper] && item && sumHoursSubTotal(sliders, item, indexSwiper) }}
						</p>
						<p class="mb-0 total">{{ sumHours(item.days, "total") }}</p>
					</div>
				</div>
			</div>
		</div>

		<!-- <p>{{ paginate }}</p> -->
		<!-- {{  }} -->
		<div class="paginate">
			<p @click="previousSlider">
				<FeatherIcon icon="ChevronLeftIcon" size="18" class="" />
			</p>
			<p
				@click="activeSlider(page)"
				v-for="(page, index) in paginate"
				:class="[indexSwiper == page - 1 ? 'active' : '']"
			>
				{{ page }}
			</p>
			<p @click="nextSlider">
				<FeatherIcon icon="ChevronRightIcon" size="18" class="" />
			</p>
		</div>
	</div>
</template>

<script>
import AttendanceCalendar from "./AttendanceCalendar.vue";
// Import Swiper styles
// import "swiper/css";
import Swiper from "swiper";
import { initCalendarDates } from "@/helpers/calendar";
import attendanceService from "@/services/headers/attendance";
import moment from "moment";
import SlidersAttendance from "./SlidersAttendance.vue";

export default {
	name: "AttendanceMain",
	components: { AttendanceCalendar, SlidersAttendance },
	props: {
		is_local: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			dateIn: moment().startOf("week").format("YYYY-MM-DD"),
			dateFn: moment().endOf("week").format("YYYY-MM-DD"),
			// dateFn: "2022-12-28",
			sliders: [],
			load: true,
			items: [],
			paginate: [],
			swiper: null,
			search: null,
		};
	},
	computed: {
		sumHours: () => (items, key) => {
			return items.reduce(function (previousValue, currentValue) {
				return previousValue + currentValue[key];
			}, 0);
		},
		indexSwiper() {
			return this.swiper ? this.swiper.realIndex : null;
		},
		sumHoursSubTotal: () => (sliders, item, index) => {
			const slider = sliders[index] && sliders[index].map((it) => it.date_num);
			const user = item && item.days;

			const sum = slider.reduce(function (previousValue, currentValue) {
				let hours = 0;
				user.forEach((it) => {
					if (it.date == currentValue) {
						hours = hours + it.total;
					}
				});
				return previousValue + hours;
			}, 0);

			// console.log("slider", slider);
			// console.log("user", user);

			// console.log('sum', sum);

			return sum;
		},
	},
	async mounted() {
		await this.initSlider();
	},
	methods: {
		async initSlider() {
			// this.isPreloading();
			await this.init();
			this.initCalendar();

			this.initPaginate();

			// this.isPreloading(false);
			this.load = false;
		},
		initCalendar() {
			this.sliders = [];
			const { calendar, week_num } = initCalendarDates(this.dateIn, this.dateFn);
			let semana = 1;
			let day = 0;
			for (semana; semana <= week_num; semana += 1) {
				const sem = [];
				for (let item = 1; item <= 7; item += 1) {
					if (calendar[day]) {
						sem.push(calendar[day]);
						day += 1;
					}
				}
				this.sliders.push(sem);
			}
			this.sliders = this.sliders.filter((it) => it[0]);
			// console.log('week_num', week_num);

			// this.sliders.forEach((slider, index) => {
			// 	slider.forEach((day, key) => {
			// 		this.sliders[index][key].data = 10;
			// 	});
			// 	console.log("sd", slider);
			// });
		},
		async init() {
			this.isPreloading();
			const { data, status } = await attendanceService.index({
				search: this.search,
				start_date: moment(this.dateIn).format("YYYY-MM-DD"),
				end_date: moment(this.dateFn).format("YYYY-MM-DD"),
				is_local: this.is_local,
			});
			this.isPreloading(false);
			this.items = data;
		},
		async initPaginate() {
			let array = [];
			let i = 1;
			Array.from({ length: this.sliders.length }).forEach(async () => {
				array.push(i);
				i++;
			});
			this.paginate = array;
			// console.log("array", array);
			// const [resp] = document.getElementsByClassName("swiper-wrapper");
			// console.log("resp", resp);
			// resp.style.backgroundColor = "blue";
			// resp.style.transform = "translate3d(-1317px, 0px, 0px)";
		},
		nextSlider() {
			this.swiper.slideNext();
		},
		previousSlider() {
			this.swiper.slidePrev();
		},
		activeSlider(page) {
			this.swiper.slideTo(page - 1);
		},
		async reloadSlider() {
			// this.swiper.removeAllSlides();
			this.load = true;
			await this.initSlider();
		},
		setSwiper(sw) {
			this.swiper = sw;
		},
	},
};
</script>

<style lang="scss">
.container-scroll {
	overflow-x: auto;
	padding: 1rem 0;
	margin: 0.5rem 0;
}
.container-main-list {
	display: grid;
	grid-template-columns: 1fr 10fr 2fr;
	overflow: hidden;
	// margin: 2rem 0;
	// gap: 2rem;
	min-width: 1200px;

	.list-table {
		z-index: 10;
		// padding: 2rem;
		background: #fff;
		// display: flex;
		// flex-direction: column;
		// align-items: center;
		// justify-content: center;
		border-top: 1px solid rgba(217, 217, 217, 1);
		border-bottom: 1px solid rgba(217, 217, 217, 1);
		.card-user {
			height: 80px;
			border-radius: 8px;
			padding: 1rem;
			// background: rgba(217, 217, 217, 1);
			// text-align: center;
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			.avatar{
				border: 2px solid rgba(115, 103, 240, 1);
			}
		}
		.card-times {
			display: grid;
			grid-template-columns: 1fr 1fr;
			align-items: center;
			justify-content: center;
			p {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		.header {
			height: 80px;
			display: flex;
			align-items: center;
			justify-content: center;
			// margin: 1rem;
			padding: 2rem;
			border-bottom: 1px solid rgba(217, 217, 217, 1);
		}
	}
	.border-left {
		// border: 1px solid rgba(115, 103, 240, 1);
		// border-left: 1px solid rgba(115, 103, 240, 1);
		// border-right: 1px solid rgba(115, 103, 240, 1);
	}
	.border-right {
		// border-right: 1px solid rgba(217, 217, 217, 1);
		.header {
			padding: 0;
			display: flex;
			flex-direction: column;
			border-bottom: 1px solid rgba(115, 103, 240, 1);
			.header-title {
				// flex: 1;
				height: 100%;
				background: rgba(115, 103, 240, 1);
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				p {
					color: #fff;
					font-size: 16px;
					font-weight: bolder;
				}
			}
			.grid {
				background: rgba(115, 103, 240, 0.1);
				// padding: .5rem 0;
				height: 60px;
				border-top: 1px solid #fff;
				// background: #00D5E9;
				width: 100%;
				display: grid;
				grid-template-columns: 1fr 1fr;
				justify-content: center;
				align-items: center;
				p {
					text-align: center;
					color: rgba(115, 103, 240, 1);
					font-weight: bolder;
				}
				.subtotal {
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.total {
					border-left: 1px solid rgba(115, 103, 240, 1);
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					// border-right: 1px solid rgba(115, 103, 240, 1) !important;
				}
			}
		}
		.card-user {
			display: grid;
			grid-template-columns: 1fr 1fr;
			justify-content: center;
			align-items: center;
			padding: 0;
			background: rgba(115, 103, 240, 0.1);
			border-radius: 0px !important;
			// border-right: 1px solid rgba(115, 103, 240, 1) !important;
			border-bottom: 1px solid rgba(115, 103, 240, 1);
			&:last-child {
				// background: red;
				border-bottom: none;
			}
			p {
				text-align: center;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				color: rgba(140, 140, 140, 1) !important;
				font-size: 18px;
				font-weight: 500;
			}
			.total {
				border-left: 1px solid rgba(115, 103, 240, 1);
			}
		}
	}
	.swiper {
		// width: 80%;
		// background: ;
		position: relative;
		.container-footer {
			background: red;
			width: 400px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			z-index: 200;
			position: absolute;
			bottom: -200px;
			left: 0;
			z-index: 100;
			.swiper-button-prev {
				height: 40px;
				width: 40px;
				background: green;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
			}
			.swiper-button-next {
				height: 40px;
				width: 40px;
				background: green;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
			}
		}
	}
	.container-swiper {
		overflow: hidden;
		// border-top: 1px solid rgba(217, 217, 217, 1);
		// border-bottom: 1px solid rgba(217, 217, 217, 1);
		cursor: pointer;
		.load-sliders {
			// background: red;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			border-bottom: 1px solid rgba(217, 217, 217, 1);
			border-top: 1px solid rgba(217, 217, 217, 1);
			p {
				font-weight: bolder;
			}
		}
	}
	.border-none-bottom {
		border-bottom: none !important;
	}
	.total-hours {
		border-radius: 15px;
		overflow: hidden;
		margin-left: 2rem;
		border: 1px solid rgba(115, 103, 240, 1) !important ;
	}
	.list-users {
		border: none !important;

		border-bottom-left-radius: 15px;
		overflow: hidden;
		.header {
			border: 1px solid rgba(115, 103, 240, 1) !important;
			overflow: hidden;
			border-top-left-radius: 15px;
			p{
				color: rgba(115, 103, 240, 1);
				font-weight: bolder;
			}
		}
		.card-user {
			border-bottom: 1px solid rgba(217, 217, 217, 1);
			border-right: 1px solid rgba(217, 217, 217, 1);
			border-left: 1px solid rgba(217, 217, 217, 1);
			border-radius: 0px;
			p{
				color: rgba(115, 103, 240, 1);
				font-weight: bolder;
			}
		}
	}
}

.swiper-wrapper {
	// background: red;
	display: grid;
	grid-template-columns: repeat(122, 1fr);
	// border-top: 1px solid rgba(217, 217, 217, 1);
}
.swiper-slide {
	// padding: 2rem;
	// background: gold;
	display: grid !important;
	grid-template-columns: repeat(7, 1fr) !important;
	// gap: 1rem;
	overflow: hidden;
	background: #fff;
	// border-left: 1px solid rgba(217, 217, 217, 1);
	.container-days {
		// padding: 1rem;
		.day {
			// padding: 1rem;
			// border-radius: 8px;
			// padding: 1rem;
			// background: rgba(217, 217, 217, 1);
			// text-align: center;
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			// border-top: 1px solid rgba(217, 217, 217, 1);
			border-bottom: 1px solid rgba(217, 217, 217, 1);
			border-right: 1px solid rgba(217, 217, 217, 1);
			// border-left: 1px solid rgba(217, 217, 217, 1);
			height: 80px;
			// background: red;
			&:first {
				background: red !important;
				// border: 1px solid red;
			}
			.number {
				background: #8900ee;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				font-size: 16px;
				font-weight: bolder;
				height: 40px;
				width: 40px;
				border-radius: 50%;
			}
			.init {
				display: flex;
				align-items: center;
				justify-content: center;
				span {
					font-weight: bolder;
					margin-left: 0.5rem;
				}
				margin-bottom: 0.4rem;
			}
			.finished {
				margin-bottom: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				span {
					font-weight: bolder;
					margin-left: 0.5rem;
				}
			}
			position: relative;
			.container-text {
				width: 100%;
				height: 100%;
				z-index: 10;
				background: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				
			}
			.skeletor {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					height: 30px;
					width: 30px;
				}
				p {
					margin-bottom: 0;
				}
			}
		}
		.header {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			// margin-bottom: 1rem;
			height: 80px;
			// padding: 2rem;
			// border-bottom: 1px solid rgba(217, 217, 217, 1);
			// margin: 1rem;
			// border-left: 1px solid rgba(217, 217, 217, 1);
			border-right: 1px solid rgba(217, 217, 217, 1);

			.number {
				margin-bottom: 0;
				color: #8900ee;
				height: 32px;
				width: 32px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: bolder;
				font-size: 16px;
				background: transparent;
			}
			.active {
				background: #8900ee !important;
				color: #fff !important;
			}
			.text {
				margin-bottom: 0;
				color: #8900ee;
				font-weight: bolder;
			}
		}
		.header-left {
			border-left: none;
			// background: red;
		}
		.header-right {
			// border-right: 1px solid rgba(217, 217, 217, 1);
			border: none !important;
		}
		&:last-child {
			.bg-header-select {
				// background: red !important;
			}
		}
	}
	.bg-not-select{
		// background: red;
		&:last-child{
			// border-top-right-radius: 15px !important;
			// overflow: hidden;
			// background: red !important;
			.border-right{
				// background: red;
			}
		}
	}
}
.paginate {
	display: flex;
	justify-content: center;
	margin-top: 1rem;
	p {
		margin-bottom: 0rem;
		width: 30px;
		height: 30px;
		background: rgba(217, 217, 217, 1);
		color: #8900ee;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		font-weight: bolder;
		margin: 0 0.5rem;
		font-size: 16px;
		cursor: pointer;
		transition: 0.3s all ease-in-out;
	}
	.active {
		background: #8900ee;
		color: #fff;
	}
}

.d-flex flex-column flex-md-row justify-content-b {
	display: grid;
	gap: 1rem;
	grid-template-columns: 1fr;
	@media screen and (min-width: 400px) {
		grid-template-columns: 1fr 1fr;
	}
	@media screen and (min-width: 900px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
	@media screen and (min-width: 1200px) {
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	}
	@media screen and (min-width: 1400px) {
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	}
}
.skeletor {
	img {
		height: 20px !important;
		width: 20px !important;
	}
	p {
		margin-bottom: 0;
		margin-left: 0.5rem;
		font-weight: 500;
	}
}
.grid-7 {
	display: grid !important;
	grid-template-columns: repeat(7, 1fr);
}
.bg-header {
	background: #f4f1f7;
}
.bg-header-select {
	background: #f4f1f7;
	border-bottom: 1px solid rgba(115, 103, 240, 1);
	border-top: 1px solid rgba(115, 103, 240, 1) !important;
}
.contain-flex {
	width: 100%;
	@media screen and (min-width: 770px) {
		width: auto;
	}
}
</style>
