<template>
	<b-modal
		centered
		no-close-on-backdrop
		hide-footer
		size="xl"
		:title="title"
		v-model="isActive"
		@hidden="handleHidden"
	>
		<b-overlay :show="isLoading">
			<!-- <pre>{{ info }}</pre> -->
			<b-table-simple sticky-header="80vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th class="bg-transparent border-0" colspan="4"></b-th>
						<b-th class="bg-primary text-white" colspan="2">Pollos</b-th>
					</b-tr>
					<b-tr>
						<b-th>Postura</b-th>
						<b-th>Cintillos</b-th>
						<b-th>Madrilla</b-th>
						<b-th>Padrillo</b-th>
						<b-th>Vivos</b-th>
						<b-th>A matar</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(row, idx) in rows" :key="idx">
						<b-td>
							{{ row.code }}
						</b-td>
						<b-td>
							<CombChickenlegsCintillos
								v-if="row.cintillos && row.cintillos.id"
								:postura="{
									chickenleg_wich_leg: row.cintillos.wich_leg,
									chickenleg_qty_cintillos: row.cintillos.qty_cintillos,
									cintillo_color: row.cintillos.hex_1,
									cintillo_description: row.cintillos.description_1,
									second_color_color: row.cintillos.hex_2,
									second_color_description: row.cintillos.description_2,
									total_minus_infertile_eggs: 1,
								}"
								:chickenleg_wich_leg="1"
								is_in_posturas
							/>
							<CombChickenlegsCintillos
								v-if="row.cintillos && row.cintillos.id"
								:postura="{
									chickenleg_wich_leg: row.cintillos.wich_leg,
									chickenleg_qty_cintillos: row.cintillos.qty_cintillos,
									cintillo_color: row.cintillos.hex_1,
									cintillo_description: row.cintillos.description_1,
									second_color_color: row.cintillos.hex_2,
									second_color_description: row.cintillos.description_2,
									total_minus_infertile_eggs: 1,
								}"
								is_in_posturas
								:chickenleg_wich_leg="2"
							/>
						</b-td>
						<b-td>
							<SpecimenPlate :specimen="row.madrilla" />
						</b-td>
						<b-td>
							<SpecimenPlate :specimen="row.padrillo" />
						</b-td>
						<b-td>
							<b-button
								variant="primary"
								@click="selectPostura(row)"
								:disabled="row.alreadySelected == row.qty"
							>
								{{ row.qty }}
							</b-button>
						</b-td>
						<b-td>
							<span :class="{ 'text-danger font-weight-bolder': !!row.alreadySelected }">
								{{ row.alreadySelected || "---" }}
							</span>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
	</b-modal>
</template>

<script>
import deadsService from "@/services/deads.service"

import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import CombChickenlegsCintillos from "../../encaste/lotes/components/CombChickenlegsCintillos.vue"

export default {
	components: { SpecimenPlate, CombChickenlegsCintillos },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: null,
		rows: [],
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.rows = []
			this.$emit("closing")
		},
		async getOpts() {
			this.isLoading = true
			const { data } = await deadsService.getCintillos({ lote_id: this.info.lote.value })
			this.rows = data.map((p) => {
				const cintillos = {
					...p.cintillos,
					chickenleg_wich_leg:
						p.cintillos &&
						(p.cintillos.wich_leg == "IZQUIERDA" ? 1 : p.cintillos.wich_leg == "DERECHA" ? 2 : 3),
				}

				const qty = Number(p.qty)
				let alreadySelected = null
				const selectedQty = this.info.rowsQuantity.find((row) => row.eggLote == p.egg_lote_id)
				if (selectedQty != undefined) {
					alreadySelected = selectedQty.quantity
				}

				return { ...p, cintillos, qty, alreadySelected }
			})
			this.isLoading = false
		},
		selectPostura(row) {
			this.$emit("choosed", {
				id: row.postura_id,
				code: row.code,
				max: Number(row.qty),
				cintillo: row.cintillos.id,
				lote: row.egg_lote_id,
			})
			// para cerrar sin que semita closing 2 veces, esto da error en el handler del padre
			this.isActive = false
			this.isLoading = false
			this.rows = []
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.title = `Selecciona una postura del lote ${this.info.lote.text}`
				this.getOpts()
			}
		},
	},
}
</script>
