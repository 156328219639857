<template>
    <div class="container-bookmarks">
        <div class="item-bookmarks">
            <feather-icon icon="ImageIcon" color="#ff9f43" size="18" />
            <p class="mb-0 ml-1">Ver galeria</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "ViewGallery"
};
</script>
