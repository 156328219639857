import coliseum from "./sidebar-amg/coliseums";
import snoods from "./sidebar-amg/snoods";
import participants from "./sidebar-amg/participants";
import contacts from "./sidebar-amg/contacts";
import reasons from "./sidebar-amg/reasons";
import diseases from "./sidebar-amg/diseases";
import clients from "./sidebar-amg/clients";
import encasteSettings from "./sidebar-amg/encaste-settings";
import menuPrincipal from "./sidebar-amg/menu";

// Array of sections
export default [
	...coliseum,
	...snoods,
	...participants,
	...contacts,
	...reasons,
	...encasteSettings,
	...diseases,
	...clients,
	...menuPrincipal,
];
