<template>
	<div>
		<b-row class="mb-1">
			<b-col class="d-flex align-items-center">
				<small class="text-secondary">
					Mostrando {{ pagination.from }} a {{ pagination.to }} de {{ pagination.total }} registros
				</small>
			</b-col>
			<b-col class="d-flex align-items-center justify-content-end flex-wrap gap-x-4 gap-y-1">
				<b-pagination
					v-model="pagination.curPage"
					:total-rows="pagination.total"
					:per-page="pagination.perPage"
					first-number
					last-number
					class="mb-0 mt-1 mt-sm-0"
					prev-class="prev-item"
					next-class="next-item"
				>
					<template #prev-text>
						<feather-icon icon="ChevronLeftIcon" size="18" />
					</template>
					<template #next-text>
						<feather-icon icon="ChevronRightIcon" size="18" />
					</template>
				</b-pagination>
			</b-col>
		</b-row>
		<b-row class="mb-1">
			<b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
				<label>Mostrar</label>
				<v-select
					v-model="pagination.perPage"
					:options="perPageOptions"
					:clearable="false"
					class="per-page-selector d-inline-block mx-50"
					style="width: 90px"
				/>
				<label class="mr-2">registros</label>
				<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="refresh" />
			</b-col>
		</b-row>
		<b-table-simple responsive sticky-header="60vh" no-border-collapse>
			<b-thead class="sticky-header">
				<b-th class="text-center">Torneo</b-th>
				<b-th class="text-center">Fecha</b-th>
				<b-th class="text-center">Metodo</b-th>
				<b-th class="text-center">Frentes</b-th>
				<b-th class="text-center" v-b-tooltip.hover.top="`Requeridos`">Req</b-th>
				<b-th class="text-center" v-b-tooltip.hover.top="`Recomendados`">Rec</b-th>
				<b-th class="text-center" v-b-tooltip.hover.top="`Aprobados`">Apro</b-th>
				<b-th class="text-center" v-b-tooltip.hover.top="`Cambiar`">Camb</b-th>
				<b-th class="text-center">Etapa</b-th>
				<b-th class="text-center">Acción</b-th>
			</b-thead>
			<b-tbody>
				<template v-for="(data, index) in recommendeds">
					<b-tr
						:class="
							(isPreparador && data.is_finalized === 1) || (isAdmin && data.is_update === 1)
								? 'finalized-row'
								: ''
						"
						:key="index"
					>
						<b-td class="text-center">
							<div style="display: flex; gap: 1rem; align-items: center">
								<div>
									<ViewImage
										:specimen="{
											id: 1,
											url: data.url_image,
										}"
									/>
								</div>
								<div style="display: flex; flex-direction: column; align-items: flex-start">
									<p style="text-align: start; font-weight: 500" class="m-0 text-dark">
										{{ data.tournament.name }}
									</p>
									<div class="d-flex text-left text-primary">
										<p style="font-size: 0.9rem; width: 60px; font-weight: 600" class="m-0">
											Coliseo:
										</p>
										<p class="m-0">{{ data.coliseum.name }}</p>
									</div>
									<div class="d-flex text-left text-primary">
										<p style="font-size: 0.9rem; width: 60px; font-weight: 600" class="m-0">
											Etapa:
										</p>
										<p class="m-0">{{ data.stage.name }} ({{ data.type.name }})</p>
									</div>
								</div>
							</div>
						</b-td>
						<b-td class="text-center">
							<span style="white-space: nowrap">{{ data.date | myGlobalDayShort }}</span>
						</b-td>
						<b-td class="text-center">
							<span>{{ data.method }}</span>
						</b-td>
						<b-td class="text-center">
							<span>{{ data.programming.fronts }}</span>
						</b-td>
						<b-td class="text-center">
							<span>{{ data.programming.required }}</span>
						</b-td>
						<b-td class="text-center">
							<span class="text-primary font-weight-bold">{{ data.recommended.total }}</span>
						</b-td>
						<b-td class="text-center">
							<span class="text-success font-weight-bold">{{ data.recommended.approved }}</span>
						</b-td>
						<b-td class="text-center">
							<span class="text-danger font-weight-bold">{{ data.recommended.rejected }}</span>
						</b-td>
						<b-td class="text-center">
							<div style="display: flex; align-items: center; justify-content: center; gap: 1rem">
								<div
									v-if="data.last_tracking"
									class="d-flex flex-column align-item-center justify-content-center"
								>
									<b-badge
										:variant="`light-${resolveVariant(data.last_tracking)}`"
										class="text-danger font-weight-bold mt-25 text-uppercase"
									>
										{{ getTrackingStatus(data.last_tracking.status) }}
									</b-badge>
									<span :class="`text-${resolveVariant(data.last_tracking)}`">
										{{ data.last_tracking.date | myGlobalDayShort }}
									</span>
								</div>
								<b-badge
									variant="light-dark"
									class="text-danger font-weight-bold mt-25 text-uppercase"
									v-else
								>
									PENDIENTE
								</b-badge>
								<div v-if="data.last_tracking" class="cursor-pointer" @click="openModalTracking(data)">
									<b-avatar icon="ListIcon" rounded="sm" variant="secondary" class="cursor-pointer">
										<feather-icon icon="ListIcon" size="15"></feather-icon>
									</b-avatar>
								</div>
							</div>
						</b-td>
						<b-td class="text-center">
							<div style="display: flex; align-items: center; justify-content: center; gap: 0.3rem">
								<div
									v-if="isViewButtonAddSpecimen(data)"
									@click="openSpecimens(data)"
									class="cursor-pointer"
								>
									<b-avatar
										rounded="sm"
										:variant="`${resolveVariantPrep(data)}`"
										:badge="data.counter_seen_trainer > 0 ? `${data.counter_seen_trainer}` : false"
										badge-top
										badge-offset="-0.5em"
										badge-variant="danger"
										class="cursor-pointer"
									>
										<feather-icon :icon="`${resolveIconPrep(data)}`" size="15"></feather-icon>
									</b-avatar>
								</div>
								<div
									v-if="
										(isAdmin &&
											data.last_tracking &&
											[
												'en proceso',
												'finalizado',
												'enviado',
												'retornado',
												'enviado_admin',
											].includes(data.last_tracking.status)) ||
										((isAdmin || isPreparador) && indexTab == 1) ||
										(isPreparador &&
											data.last_tracking &&
											data.last_tracking.status == 'finalizado')
									"
									class="cursor-pointer"
									@click="openModalAdmin(data)"
								>
									<b-avatar
										rounded="sm"
										:variant="`${resolveVariantButtonAdmin(data)}`"
										:badge="data.counter_seen > 0 ? `${data.counter_seen}` : false"
										badge-top
										badge-offset="-0.5em"
										badge-variant="danger"
										class="cursor-pointer"
									>
										<feather-icon :icon="`${resolveIconAdmin(data)}`" size="15"></feather-icon>
									</b-avatar>
								</div>
							</div>
						</b-td>
					</b-tr>
				</template>
				<b-tr v-if="recommendeds.length == 0">
					<b-td colspan="10" class="text-center" style="height: 80px">No hay registros para mostrar</b-td>
				</b-tr>
			</b-tbody>
		</b-table-simple>

		<SpecimensRecommendedModal
			v-if="showSpecimens"
			:show="showSpecimens"
			:info="rowInfo"
			@closing="showSpecimens = false"
			@refresh="getEvents"
		/>

		<AdminModal
			v-if="showModal"
			:show="showModal"
			:info="rowInfo"
			@closing="showModal = false"
			@refresh="getEvents"
		/>

		<TrackingStatusModal v-if="showTracking" :show="showTracking" :info="rowInfo" @closing="showTracking = false" />
	</div>
</template>

<script>
import RecommendedSpecimenService from "@/services/preparacion/events/recommendedSpecimen.service.js"
import ViewImage from "@/components/commons/ViewImage.vue"
import SpecimensRecommendedModal from "@/views/amg/preparacion/components/recommendedSpecimens/SpecimensRecommendedModal.vue"
import AdminModal from "@/views/amg/preparacion/components/recommendedSpecimens/AdminModal.vue"
import TrackingStatusModal from "@/views/amg/preparacion/components/recommendedSpecimens/TrackingStatusModal.vue"
import vSelect from "vue-select"

export default {
	components: { ViewImage, SpecimensRecommendedModal, AdminModal, TrackingStatusModal, vSelect },
	mixins: [],
	props: {
		indexTab: Number,
		isGeneral: Boolean,
		start: {
			required: false,
			type: String,
		},
		end: {
			required: false,
			type: String,
		},
		dateCheck: Boolean,
	},
	data() {
		return {
			recommendeds: [],
			showSpecimens: false,
			showModal: false,
			showTracking: false,
			rowInfo: {},
			perPageOptions: [10, 25, 50, 100],
			pagination: {
				total: 0,
				curPage: 1,
				perPage: 25,
				from: 0,
				to: 0,
			},
		}
	},
	async created() {
		await this.getEvents()
	},
	watch: {
		async indexTab(val, old) {
			await this.getEvents()
		},
		"pagination.curPage": async function () {
			await this.getEvents()
		},
		"pagination.perPage": async function () {
			await this.getEvents()
		},
		async start() {
			await this.getEvents()
		},
		async dateCheck() {
			await this.getEvents()
		},
	},
	computed: {},
	methods: {
		async refresh() {
			await this.getEvents()
		},
		isViewButtonAddSpecimen(rec) {
			if (
				(this.isPreparador && !rec.last_tracking && this.indexTab === 0) ||
				(this.isPreparador &&
					rec.last_tracking &&
					rec.last_tracking.status != "finalizado" &&
					this.indexTab === 0)
			)
				return true
			if (
				!this.isGeneral &&
				this.isPreparador &&
				this.indexTab === 0 &&
				(!rec.last_tracking || (rec.last_tracking && rec.last_tracking.status != "finalizado"))
			)
				return true
			if (
				this.indexTab === 0 &&
				this.isGeneral &&
				this.isAdmin &&
				(!rec.last_tracking || rec.last_tracking.status === "proceso_admin")
			)
				return true
			return false
		},
		getTrackingStatus(tracking) {
			if (tracking) {
				if (tracking === "enviado_admin") {
					return "enviado_dueño"
				} else if (tracking === "proceso_admin") {
					return "proceso_dueño"
				} else {
					return tracking
				}
			}
		},
		resolveVariantPrep(rec) {
			// data.last_tracking != null && data.last_tracking.status != 'en proceso' ? 'info' : 'primary'
			if (
				(!rec.last_tracking ||
					["en proceso", "retornado", "enviado_admin"].includes(rec.last_tracking.status)) &&
				this.indexTab == 0 &&
				this.isPreparador
			)
				return "warning"
			if (
				(!rec.last_tracking || rec.last_tracking.status === "proceso_admin") &&
				this.indexTab == 0 &&
				this.isAdmin
			)
				return "warning"
			if (rec.last_tracking && rec.last_tracking.status == "guardado" && this.indexTab == 0) return "primary"
			return "info"
		},
		resolveIconPrep(rec) {
			// data.last_tracking != null && data.last_tracking.status !== 'en proceso' ? 'EyeIcon' : 'PlusIcon'
			if (this.indexTab == 1) return "EyeIcon"
			if (
				!rec.last_tracking ||
				(["en proceso", "guardado", "retornado", "enviado_admin"].includes(rec.last_tracking.status) &&
					this.isPreparador) ||
				(rec.last_tracking.status === "proceso_admin" && this.isAdmin)
			)
				return "PlusIcon"
			return "EyeIcon"
		},
		resolveVariant(tracking) {
			if (tracking) {
				if (tracking.status == "en proceso") {
					return "dark"
				} else if (tracking.status == "enviado") {
					return "warning"
				} else if (tracking.status == "retornado") {
					return "danger"
				} else {
					return "primary"
				}
			} else {
				return "dark"
			}
		},
		resolveIconAdmin(rec) {
			if (rec.last_tracking && rec.last_tracking.status == "enviado" && this.isAdmin && this.indexTab == 0)
				return "CheckIcon"
			return "EyeIcon"
		},
		resolveVariantButtonAdmin(rec) {
			if (rec.last_tracking && rec.last_tracking.status == "enviado" && this.isAdmin && this.indexTab == 0)
				return "primary"
			return "info"
		},
		openSpecimens(data) {
			this.rowInfo = { ...data, indexTab: this.indexTab }
			this.showSpecimens = true
		},
		openModalAdmin(data) {
			this.rowInfo = { ...data, indexTab: this.indexTab }
			this.showModal = true
		},
		openModalTracking(data) {
			this.rowInfo = data
			this.showTracking = true
		},
		async getEvents() {
			this.isPreloading()
			try {
				const { data } = await RecommendedSpecimenService.getEventsForRecommendedSpecimen({
					origin: "preparation",
					type: this.indexTab == 0 ? "proximas" : "pasadas",
					start_date: this.indexTab == 1 ? this.start : null,
					end_date: this.indexTab == 1 ? this.end : null,
					is_admin: this.isAdmin ? "admin" : "other",
					check_filter_week: this.indexTab == 1 ? (this.dateCheck ? 1 : 0) : null,
					page: this.pagination.curPage,
					perpage: this.pagination.perPage,
				})
				this.recommendeds = data.data.data
				this.pagination.total = data.data.total
				this.pagination.from = data.data.from
				this.pagination.to = data.data.to
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
	async mounted() {
		// this.toggleModal("modal-specimen-recommended")
	},
}
</script>

<style>
.sticky-header th {
	position: sticky;
	top: 0;
	z-index: 1;
}

.finalized-row {
	background-color: rgba(185, 230, 165, 0.3);
}
</style>
