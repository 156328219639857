<template>
	<b-nav-item-dropdown class="dropdown-notification mr-25" menu-class="dropdown-menu-media" right>
		<template #button-content>
			<feather-icon
				:badge="count"
				badge-classes="bg-danger"
				class="active-icon"
				:class="reload_animation ? 'active-notification' : ''"
				icon="BellIcon"
				size="21"
				@click="loadingNotification = true"
			/>
		</template>

		<!-- Header -->
		<li class="dropdown-menu-header">
			<div class="dropdown-header d-flex">
				<h4 class="notification-title mb-0 mr-auto">Notificaciones 🎉</h4>
				<b-badge pill variant="light-primary">
					{{ notifications.filter((it) => it.seen == 0).length }}
					{{ `Nuevo${notifications.filter((it) => it.seen == 0).length == 1 ? "" : "s"}` }}
				</b-badge>
			</div>
		</li>

		<!-- Notifications -->
		<vue-perfect-scrollbar
			:settings="perfectScrollbarSettings"
			class="scrollable-container media-list scroll-area"
			tagname="li"
			id="scrollable-container-notifications"
		>
			<!-- Account Notification -->
			<b-link v-for="(notification, key) in notifications" :key="key">
				<b-media>
					<div class="d-flex grid-notification" @click="redirectPathNotification(notification)">
						<b-avatar
							size="32"
							:text="notification.alias"
							:variant="variants[Math.floor(Math.random() * variants.length)]"
						/>
						<div class="ml-50 w-100">
							<div class="d-flex justify-content-between">
								<small class="notification-text mb-0">{{ notification.madrilla.alias }}</small>
								<small class="notification-text mb-0">{{
									notification.madrilla.plate
								}}</small>
							</div>

							<!-- {{ notification.created_at | myGlobalDayShort}} -->
							<!-- <p class="media-heading">
								<span class="font-weight-bolder">
									{{ notification.message }}
								</span>
							</p> -->
						</div>
						<!-- <div
							:class="!notification.seen ? 'bg-danger' : 'bg-secondary'"
							class="icon-seen-notification"
						></div> -->
					</div>
				</b-media>
			</b-link>
			<div class="text-center mb-50 mt-50" v-if="loadingScroll">
				<b-spinner variant="primary" label="Spinning"></b-spinner>
			</div>
			<div class="text-center" v-if="this.last_page == this.page - 1">
				<p class="text-final-scroll"></p>
				<!-- <p class="mb-0">------------------------</p> -->
			</div>
		</vue-perfect-scrollbar>

		<!-- Cart Footer -->
		<!-- <li class="dropdown-menu-footer">
			<b-button @click="seenNotification" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block
				>Marcar todos</b-button
			>
		</li> -->
	</b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";

import { mapState, mapActions, mapMutations } from "vuex";
// import notificationsService from "@/services/notifications.service";

export default {
	components: {
		BNavItemDropdown,
		BBadge,
		BMedia,
		BLink,
		BAvatar,
		VuePerfectScrollbar,
		BButton,
		BFormCheckbox,
	},
	directives: {
		Ripple,
	},
	data() {
		return {
			perfectScrollbarSettings: {
				maxScrollbarLength: 60,
				wheelPropagation: false,
			},
			variants: ["primary", "secondary", "success", "warning", "danger", "info", "dark"],
			activeNotification: false,
			sampler: null,
			isLoaded: false,
			loadingNotification: false,
		};
	},
	async mounted() {
		// settimeout(() => {
		// 	this.sampler.triggerAttack("A1");
		// }, 2000);
		await this.getCount();

		const listElm = document.querySelector(`#scrollable-container-notifications`);
		listElm.addEventListener("scroll", (e) => {
			if (listElm.scrollTop + listElm.clientHeight + 1 >= listElm.scrollHeight) {
				this.loadMore();
			}
		});
	},
	computed: {
		...mapState("notifications", [
			"notifications",
			"count",
			"page",
			"last_page",
			"loadingScroll",
			"reload_animation",
		]),
	},
	methods: {
		...mapActions("notifications", ["getNotifications", "getCount", "startRingtone"]),
		...mapMutations("notifications", ["SET_PAGE", "SET_LOADING_SCROLL"]),
		redirectPathNotification(notification) {
			this.$router.push({
				name: notification.route,
				query: {
					madrilla_id: notification.parameter,
				},
			});
		},
		async loadMore() {
			if (this.last_page >= this.page && !this.loadingScroll) {
				this.SET_LOADING_SCROLL(true);
				await this.getNotifications({ page: this.page, perpage: 10 });
				this.SET_PAGE(this.page + 1);
				this.SET_LOADING_SCROLL(false);
			}
		},
		// async seenNotification(id) {
		// 	const resp = await notificationsService.updateSeen({
		// 		is_global: id ? true : false,
		// 		id,
		// 	});
		// 	await this.getNotifications({ page: 1, perpage: 10 });
		// 	await this.getCount();
		// },
		async init() {
			this.SET_LOADING_SCROLL(true);
			await this.getNotifications({ page: this.page, perpage: 10 });
			this.SET_LOADING_SCROLL(false);
			this.SET_PAGE(this.page + 1);
		},
	},
	watch: {
		async loadingNotification() {
			console.log("loadingNotification", this.loadingNotification);
			if (this.loadingNotification) {
				await this.init();
				this.SET_PAGE(1);
				this.loadingNotification = false;
			}
		},
	},
};
</script>

<style lang="scss">
.grid-notification {
	position: relative;
	// background: red;
	align-items: center;
	.icon-seen-notification {
		position: absolute;
		bottom: 0px;
		right: 0;
		// background: red;
		height: 10px;
		width: 10px;
		background: blueviolet;
		border-radius: 50%;
	}
}
.text-final-scroll {
	width: 10%;
	height: 2px;
	text-align: center;
	margin: 0.8rem auto 0.8rem auto;
	background: blueviolet;
}
.active-icon {
	transition: 1s all ease-in-out;
	// color: aqua;
}

@keyframes notification-active {
	0% {
		transform: rotate(0) scale(1);
	}
	25% {
		transform: rotate(25deg) scale(1.2);
	}
	50% {
		transform: rotate(0) scale(1);
	}
	75% {
		transform: rotate(-25deg) scale(1.2);
	}
	100% {
		transform: rotate(0) scale(1);
	}
}

.active-notification {
	animation: notification-active 1s linear;
}
</style>
