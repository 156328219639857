<template>
  <b-modal
    ref="modal-add-type-weight"
    @hidden="$emit('hidden')"
    title="Agregar Tipo de Peso"
    centered
    ok-title="Guardar"
    cancel-title="Cancelar"
    @ok.prevent="addTypeWeight()"
    size="sm"
  >
    <ValidationObserver ref="form">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <b-form-input
          v-model="typeWeight"
          @input="
            () => {
              typeWeight = typeWeight.toUpperCase();
            }
          "
          type="text"
          @keydown.enter="addTypeWeight()"
          :state="errors[0] ? false : null"
        />
      </ValidationProvider>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal.js";
import { mapActions } from "vuex";
export default {
  mixins: [modalMixin],

  data() {
    return {
      typeWeight: "",
    };
  },
  async mounted() {
    this.toggleModal("modal-add-type-weight");
  },
  methods: {
    ...mapActions("weightSpecimen", ["A_ADD_TYPE_WEIGHT"]),
    async addTypeWeight() {
      const success = await this.$refs.form.validate();
      if (success) {
        const confirm = await this.showConfirmSwal({
          text: "¡Guardarás un nuevo tipo de peso!",
        });
        if (confirm.value) {
          try {
            this.isPreloading();
            const params = {
              typeWeight: this.typeWeight,
              createdBy: this.currentUser.user_id,
            };
            await this.A_ADD_TYPE_WEIGHT(params);
            this.showSuccessToast("¡Tipo de peso guardado!");
            this.toggleModal("modal-add-type-weight");
            this.$emit("hidden");
          } catch (error) {
            this.showErrorSwal(error);
          } finally {
            this.isPreloading(false);
          }
        }
      }
    },
  },
};
</script>

<style>
</style>

