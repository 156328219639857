<template>
	<b-modal
		centered
		no-close-on-backdrop
		hide-footer
		size="sm"
		:title="title"
		v-model="isActive"
		@hidden="handleHidden"
	>
		<b-overlay class="d-flex justify-content-center py-2" :show="isLoading">
			<ShowAndUploadImg v-model="evidence" readonly />
		</b-overlay>
	</b-modal>
</template>

<script>
import deadsService from "@/services/deads.service"

import ShowAndUploadImg from "./ShowAndUploadImg.vue"

export default {
	components: { ShowAndUploadImg },
	props: {
		show: Boolean,
		info: Object,
		title: String
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		evidence: [],
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.evidence = []
			this.$emit("closing")
		},
		async getEvidence() {
			this.isLoading = true
			const { data } = await deadsService.getSpecimenEvidences({
				specimen_id: this.info.dead_specimen_id,
			})
			this.evidence = data
			this.isLoading = false
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getEvidence()
			}
		},
	},
}
</script>
