<template>
	<div class="mt-1">
		<div id="chart-pareto-empates"></div>
	</div>
</template>

<script>
import { lineGraphTournaments } from "@/services/reports.service";
import ApexCharts from "apexcharts";
import GraphTournamentTotal from "./GraphTournamentTotal.vue";
import GraphTournamentTriunfos from "./GraphTournamentTriunfos.vue";
import GraphTournamentDerrotas from "./GraphTournamentDerrotas.vue";

export default {
	name: "GraphTournamentEmpates",
	components: {
		apexchart: ApexCharts,
		GraphTournamentTotal,
		GraphTournamentTriunfos,
		GraphTournamentDerrotas,
	},
	props: {
		selectYear: {
			type: Number,
		},
	},
	data() {
		return {
			data: [],
			chartOptionsParetoEmpates: {
				chart: {
					height: 350,
					type: "line",
					zoom: {
						enabled: false,
					},
				},
				series: [],
				colors: ["#00cfe8", "#4b4b4b"],
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "straight",
				},

				title: {
					text: `Grafica de Empates Pareto del ${2022}`,
					align: "left",
					margin: 10,
					offsetX: 0,
					offsetY: 0,
					floating: false,
					style: {
						fontSize: "16px",
						fontWeight: "bold",
						fontFamily: undefined,
						color: "#263238",
					},
				},
				grid: {
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				xaxis: {
					type: "category",
					categories: [
						"ENERO",
						"FEBRERO",
						"MARZO",
						"ABRIL",
						"MAYO",
						"JUNIO",
						"JULIO",
						"AGOSTO",
						"SETIEMBRE",
						"OCTUBRE",
						"NOVIEMBRE",
						"DICIEMBRE",
					],
				},
				yaxis: [
					{
						seriesName: "Income",
						opposite: true,
						axisTicks: {
							show: true,
						},
						axisBorder: {
							show: true,
							color: "#00E396",
						},
						labels: {
							style: {
								colors: "#00E396",
							},
						},
						title: {
							text: "Empates",
							style: {
								color: "#00E396",
							},
						},
						labels: {
							show: true,
							align: "right",
							minWidth: 0,
							maxWidth: 100,
							style: {
								colors: [],
								fontSize: "12px",
								fontFamily: "Helvetica, Arial, sans-serif",
								fontWeight: 400,
								cssClass: "apexcharts-yaxis-label",
							},
							offsetX: 0,
							offsetY: 0,
							rotate: 0,
							formatter: (value) => {
								return value == "0" || value == 0 ? 0 : Math.round(value);
							},
						},
					},
					{
						axisTicks: {
							show: true,
						},
						axisBorder: {
							show: true,
							color: "#008FFB",
						},
						labels: {
							style: {
								colors: "#008FFB",
							},
						},
						title: {
							text: "Empates acumulados",
							style: {
								color: "#008FFB",
							},
						},
						tooltip: {
							enabled: true,
						},
						labels: {
							show: true,
							align: "right",
							minWidth: 0,
							maxWidth: 100,
							style: {
								colors: [],
								fontSize: "12px",
								fontFamily: "Helvetica, Arial, sans-serif",
								fontWeight: 400,
								cssClass: "apexcharts-yaxis-label",
							},
							offsetX: 0,
							offsetY: 0,
							rotate: 0,
							formatter: (value) => {
								return value == "0" || value == 0 ? 0 : Math.round(value * 100, -2) + "%";
							},
						},
					},
				],
			},
			charts: null,
		};
	},
	async mounted() {
		await this.init();

		this.charts = new ApexCharts(document.querySelector("#chart-pareto-empates"), this.chartOptionsParetoEmpates);
		this.charts.render();

		this.initChartParetoEmpates();
	},
	methods: {
		async init() {
			const { data } = await lineGraphTournaments({
				year: this.selectYear,
			});
			this.data = data;
		},

		initChartParetoEmpates() {
			let dat = [];
			dat[0] = {
				name: "EMPATES",
				type: "column",
				data: this.data.map((item) => item.empates),
			};
			dat[1] = {
				name: "EMPATES ACUMULADOS",
				type: "line",
				data: this.data.map((item) => item.empates_acumulado_percent),
			};
			this.charts.updateSeries(dat);
		},
	},
	watch: {
		async selectYear(val) {
			await this.init();
			this.initChartParetoEmpates();
		},
	},
};
</script>
