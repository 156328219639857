<template>
	<b-modal
		centered
		no-close-on-backdrop
		size="xl"
		:title="`Detalles de cintillos en pata de la postura ${info.code}`"
		v-model="isActive"
		@hidden="handleHidden"
	>
		<b-overlay :show="isLoading">
			<ValidationObserver ref="form">
				<b-table-simple sticky-header="80vh">
					<b-thead class="text-center text-nowrap">
						<b-tr>
							<b-th>Lote</b-th>
							<b-th>Cantidad</b-th>
							<b-th>Estado</b-th>
							<b-th>Razon</b-th>
							<b-th>Observacion</b-th>
							<b-th>Creado por</b-th>
							<b-th style="width: 20rem">Evidencias</b-th>
						</b-tr>
					</b-thead>
					<b-tbody class="text-center">
						<b-tr v-for="(row, idx) in rows" :key="idx">
							<b-td>{{ row.lote_code }}</b-td>
							<b-td>
								<vue-number-input
									center
									vertical
									controls
									:min="1"
									:max="row.cintillos_restantes_max"
									v-model="row.quantity"
									:disabled="row.lote_status_id == 3"
								/>
							</b-td>
							<b-td>
								<div class="d-flex align-items-center gap-x-2">
									<v-select
										label="text"
										placeholder="---"
										style="width: 8rem"
										v-model="row.type_output_id"
										:options="[
											{ text: 'Muerto', value: 4 },
											{ text: 'Depurado', value: 5 },
										]"
										:clearable="false"
										:reduce="(opt) => opt.value"
										:disabled="row.lote_status_id == 3"
										appendToBody
										:calculatePosition="positionDropdown"
									/>
									<b-button
										class="btn-icon p-0"
										size="sm"
										variant="primary"
										v-b-tooltip
										title="Revivir"
										:disabled="row.lote_status_id == 3"
										@click="reviveChick(row)"
									>
										<v-icon name="fa-cross" scale="1.2" />
									</b-button>
								</div>
							</b-td>
							<b-td>
								<ValidationProvider
									:rules="row.type_output_id == 4 ? 'required' : ''"
									v-slot="{ errors }"
								>
									<v-select
										label="reason"
										style="min-width: 12rem"
										:class="{ 'border-danger rounded': !!errors[0] }"
										placeholder="---"
										v-model="row.reason_id"
										:options="reasonOpts"
										:clearable="false"
										:reduce="(option) => option.id"
										:disabled="row.type_output_id == 5 || row.lote_status_id == 3"
										appendToBody
										:calculatePosition="positionDropdown"
									>
										<template v-slot:option="option">
											<div class="d-flex justify-content-between">
												<span :class="{ 'pl-2': !!option.parent_id }">
													{{ option.reason }}
												</span>
												<span v-if="option.parent_id">
													Sub-motivo de {{ option.parent_reason }}
												</span>
											</div>
										</template>
									</v-select>
								</ValidationProvider>
							</b-td>
							<b-td>
								<b-form-textarea
									placeholder="---"
									v-model="row.observation"
									:disabled="row.type_output_id == 4 || row.lote_status_id == 3"
								/>
							</b-td>
							<b-td>
								<span>{{ row.created_by }}</span>
								<br />
								<small>{{ row.created_at | myGlobalDayShort }}</small>
							</b-td>
							<b-td>
								<!-- {{ row.evids }} -->
								<ShowAndUploadImg v-model="row.evids" :readonly="row.lote_status_id == 3" />
							</b-td>
						</b-tr>
						<b-tr>
							<b-td class="text-center" colspan="7" v-if="rows.length == 0">
								No hay registros para mostrar
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</ValidationObserver>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="primary" @click="updateAll">Guardar</b-button>
		</template>
	</b-modal>
</template>

<script>
import { getBase64 } from "@/helpers/functions"
import deadsService from "@/services/deads.service"
import reasonsService from "@/services/reasons.service"

import ShowAndUploadImg from "./ShowAndUploadImg.vue"

export default {
	components: { ShowAndUploadImg },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		rows: [],
		reasonOpts: [],
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.rows = []
			this.$emit("closing")
		},
		async getRows() {
			this.isLoading = true
			const { data } = await deadsService.getPosturaDetails({
				postura_id: this.info.id,
			})
			this.rows = data.map((row) => {
				const maxQty = Number(row.cintillos_restantes) + row.quantity
				return {
					...row,
					_maxQty: maxQty,
				}
			})
			this.getOpts()
			this.isLoading = false
		},
		async getOpts() {
			this.isLoading = true
			const reasons = await reasonsService.getByModule()
			this.reasonOpts = reasons
			this.isLoading = false
		},
		async reviveChick(row) {
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se revivira al pollo del lote ${row.lote_code}`,
			})
			if (!isConfirmed) return
			this.isLoading = true
			await deadsService.resurectPata({ egg_lote: row.egg_lote_output_id })
			this.isLoading = false
			this.getRows()
			this.$emit("refresh")
		},
		async updateAll() {
			if (!(await this.$refs.form.validate())) return
			this.isLoading = true
			const cintillos = []
			for (let i = 0; i < this.rows.length; i++) {
				const row = this.rows[i]

				// images
				let images = []
				for (let idx = 0; idx < row.evids.length; idx++) {
					const img = row.evids[idx]
					let base = undefined
					if (img.id == null) {
						base = await getBase64(img.file)
					}
					images.push({
						id: img.id,
						base,
						delete: img.delete === true,
					})
				}

				const reason_id = row.type_output_id == 4 ? row.reason_id : undefined
				const observation = row.type_output_id == 5 ? row.observation : undefined
				//
				cintillos.push({
					qty: row.quantity,
					egg_lote_output_id: row.egg_lote_output_id,
					cintillo_pata_id: row.cintillos.id,
					reason_id,
					status_id: row.type_output_id,
					observation,
					images,
				})
			}
			await deadsService.updatePosturaCintillos({ cintillos })
			this.isLoading = false
			this.getRows()
		},
		positionDropdown(dropdownList, component, { width, top, left }) {
			dropdownList.style.zIndex = 9999
			dropdownList.style.maxHeight = "20rem"
			dropdownList.style.top = top
			dropdownList.style.left = left
			dropdownList.style.width = width
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getRows()
			}
		},
	},
}
</script>
