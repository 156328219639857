var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"centered":"","no-close-on-backdrop":"","hide-footer":"","size":"xl","title":_vm.title},on:{"hidden":_vm.handleHidden},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('b-table-simple',{attrs:{"sticky-header":"80vh"}},[_c('b-thead',{staticClass:"text-center"},[_c('b-tr',[_c('b-th',{staticClass:"bg-transparent border-0",attrs:{"colspan":"4"}}),_c('b-th',{staticClass:"bg-primary text-white",attrs:{"colspan":"2"}},[_vm._v("Pollos")])],1),_c('b-tr',[_c('b-th',[_vm._v("Postura")]),_c('b-th',[_vm._v("Cintillos")]),_c('b-th',[_vm._v("Madrilla")]),_c('b-th',[_vm._v("Padrillo")]),_c('b-th',[_vm._v("Vivos")]),_c('b-th',[_vm._v("A matar")])],1)],1),_c('b-tbody',{staticClass:"text-center"},_vm._l((_vm.rows),function(row,idx){return _c('b-tr',{key:idx},[_c('b-td',[_vm._v(" "+_vm._s(row.code)+" ")]),_c('b-td',[(row.cintillos && row.cintillos.id)?_c('CombChickenlegsCintillos',{attrs:{"postura":{
								chickenleg_wich_leg: row.cintillos.wich_leg,
								chickenleg_qty_cintillos: row.cintillos.qty_cintillos,
								cintillo_color: row.cintillos.hex_1,
								cintillo_description: row.cintillos.description_1,
								second_color_color: row.cintillos.hex_2,
								second_color_description: row.cintillos.description_2,
								total_minus_infertile_eggs: 1,
							},"chickenleg_wich_leg":1,"is_in_posturas":""}}):_vm._e(),(row.cintillos && row.cintillos.id)?_c('CombChickenlegsCintillos',{attrs:{"postura":{
								chickenleg_wich_leg: row.cintillos.wich_leg,
								chickenleg_qty_cintillos: row.cintillos.qty_cintillos,
								cintillo_color: row.cintillos.hex_1,
								cintillo_description: row.cintillos.description_1,
								second_color_color: row.cintillos.hex_2,
								second_color_description: row.cintillos.description_2,
								total_minus_infertile_eggs: 1,
							},"is_in_posturas":"","chickenleg_wich_leg":2}}):_vm._e()],1),_c('b-td',[_c('SpecimenPlate',{attrs:{"specimen":row.madrilla}})],1),_c('b-td',[_c('SpecimenPlate',{attrs:{"specimen":row.padrillo}})],1),_c('b-td',[_c('b-button',{attrs:{"variant":"primary","disabled":row.alreadySelected == row.qty},on:{"click":function($event){return _vm.selectPostura(row)}}},[_vm._v(" "+_vm._s(row.qty)+" ")])],1),_c('b-td',[_c('span',{class:{ 'text-danger font-weight-bolder': !!row.alreadySelected }},[_vm._v(" "+_vm._s(row.alreadySelected || "---")+" ")])])],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }