<template>
	<b-modal
		centered
		no-close-on-backdrop
		size="lg"
		title="REGISTRAR MUERTOS"
		v-model="isActive"
		@hidden="handleHidden"
	>
		<b-overlay :show="isLoading">
			<b-card class="p-1" no-body>
				<b-row class="mb-2">
					<b-col class="d-flex flex-column flex-md-row justify-content-center align-items-center gap-x-2">
						<h4>VER HISTORIAL :</h4>
						<b-button-group>
							<b-button @click="showList(1)" variant="gradient-primary">Cintillos en patas</b-button>
							<b-button @click="showList(2)" variant="gradient-primary">Cintillos en ala</b-button>
							<b-button @click="showList(3)" variant="gradient-primary">Ejemplares</b-button>
						</b-button-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col class="d-flex flex-column flex-md-row justify-content-center align-items-center gap-x-2">
						<h4>CREAR REGISTROS :</h4>
						<b-tabs lazy v-model="tab">
							<b-tab title="Cintillos en pata" />
							<b-tab title="Cintillos en ala" />
							<b-tab title="Ejemplares" />
						</b-tabs>
					</b-col>
				</b-row>
			</b-card>
			<component v-bind:is="curForm" :saveTrigger="saveTrigger" @loading="($event) => (isLoading = $event)" />
		</b-overlay>
		<template #modal-footer>
			<b-button variant="success" @click="useTrigger" :disabled="isLoading">Guardar</b-button>
		</template>
		<ListPataModal :show="listPatas.show" @closing="listPatas.show = false" />
		<ListAlaModal :show="listAlas.show" @closing="listAlas.show = false" />
		<ListSpecimenModal :show="listSpecimens.show" @closing="listSpecimens.show = false" />
	</b-modal>
</template>

<script>
import InsertDeadPata from "./InsertDeadPata.vue"
import InsertDeadAla from "./InsertDeadAla.vue"
import InsertDeadSpecimen from "./InsertDeadSpecimen.vue"

import ListPataModal from "./ListPataModal.vue"
import ListAlaModal from "./ListAlaModal.vue"
import ListSpecimenModal from "./ListSpecimenModal.vue"

export default {
	components: { ListPataModal, ListAlaModal, ListSpecimenModal, InsertDeadPata, InsertDeadAla, InsertDeadSpecimen },
	props: {
		show: Boolean,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		tab: 0,
		saveTrigger: Symbol(),
		listPatas: { show: false },
		listAlas: { show: false },
		listSpecimens: { show: false },
	}),
	computed: {
		curForm() {
			switch (this.tab) {
				case 0:
					return "InsertDeadPata"
				case 1:
					return "InsertDeadAla"
				case 2:
					return "InsertDeadSpecimen"
				default:
					return ""
			}
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.tab = 0
			this.$emit("closing")
		},
		showList(type) {
			switch (type) {
				case 1:
					this.listPatas.show = !this.listPatas.show
					break
				case 2:
					this.listAlas.show = !this.listAlas.show
					break
				case 3:
					this.listSpecimens.show = !this.listSpecimens.show
					break
			}
		},
		useTrigger() {
			this.saveTrigger = Symbol()
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
			}
		},
	},
}
</script>
