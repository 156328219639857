export default [
    {
        title: 'Cintillos',
        icon: 'GitBranchIcon',
        // tag: '2',
        // tagVariant: 'light-warning',
        children: [
            {
                title: 'Colores',
                route: 'brain-colores-sr',
                requiresAdmin: true,
                requiresInvitado: true,
                requiresEncastador: false
            },
            {
                title: 'Patas',
                route: 'brain-patas-sr',
                requiresAdmin: true,
                requiresInvitado: true,
                requiresEncastador: false
            }
        ],
        requiresAdmin: true,
          requiresInvitado: true
    },
];
