<template>
	<div>
		<b-modal
			ref="modal-spences-by-petty-cash"
			@hidden="$emit('close')"
			:title="getTitle"
			cancel-title="Cancelar"
			scrollable
			size="xmd"
		>
			<div>
				<div>
					<b-row>
						<b-col sm="12" md="12" lg="4">
							<b-form-group>
								<label for="petty_cash_id">
									Caja chica
									<span class="text-danger">({{ pettyCash.length }})</span>
								</label>
								<v-select
									input-id="petty_cash_id"
									:clearable="false"
									v-model="pettyCashId"
									label="code"
									:options="pettyCash"
									:reduce="(cc) => cc.id"
									@input="changePettyCash"
								>
									<template v-slot:option="option">
										<div class="d-flex justify-content-between">
											<span :class="{ 'text-danger': option.balance <= option.balance_min }">
												{{ option.code }}
											</span>
											<span v-if="option.balance <= option.balance_min" class="mr-2 text-danger">
												( {{ option.balance | currency }} )
											</span>
										</div>
									</template>
									<template #selected-option="{ balance, code, balance_min }">
										<div class="d-flex justify-content-between">
											<span :class="{ 'text-danger': balance <= balance_min }">
												{{ code }}
											</span>
											<!-- <span v-if="balance <= balance_min" class="mr-2 text-danger">
												( {{ balance }} )
											</span> -->
										</div>
									</template>
								</v-select>
							</b-form-group>
						</b-col>
						<b-col md="6" lg="4">
							<b-form-group>
								<label>Fecha</label>
								<div
									v-if="date != null"
									class="d-flex align-items-center border rounded pl-1"
									style="height: 35px"
								>
									{{ date | myGlobalDayShort }}
								</div>
								<div v-else class="d-flex align-items-center border rounded pl-1" style="height: 35px">
									-
								</div>
							</b-form-group>
						</b-col>

						<b-col md="6" lg="4">
							<b-form-group>
								<label>Responsable</label>
								<div class="d-flex align-items-center border rounded pl-1" style="height: 35px">
									{{ responsible }}
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6" lg="4">
							<b-form-group>
								<label class="text-success">Deposito</label>
								<div
									class="d-flex align-items-center border-success rounded pl-1 text-success"
									style="height: 35px"
								>
									{{ getAmount | currency }}
								</div>
							</b-form-group>
						</b-col>

						<b-col md="6" lg="4">
							<b-form-group>
								<label class="text-danger">Gastos</label>
								<div
									class="d-flex align-items-center border-danger rounded pl-1 text-danger"
									style="height: 35px"
								>
									{{ getExpenses | currency }}
								</div>
							</b-form-group>
						</b-col>

						<b-col md="6" lg="4">
							<b-form-group>
								<label class="text-primary">Saldo</label>
								<div
									class="d-flex align-items-center border-primary rounded pl-1 text-primary"
									style="height: 35px"
								>
									{{ (getAmount - getExpenses) | currency }}
								</div>
							</b-form-group>
						</b-col>
					</b-row>
					<div v-if="pettyCashId != null" class="d-flex justify-content-end align-items-center p-1">
						<b-button
							class="btn-sm ml-1 d-flex align-items-center"
							variant="warning"
							@click="addCashInput('output')"
						>
							Agregar gasto
						</b-button>
					</div>
				</div>
				<b-table-simple responsive sticky-header="50vh" no-border-collapse>
					<b-thead class="">
						<b-th>Descripcion</b-th>
						<b-th>Sustento</b-th>
						<b-th class="text-center">Monto</b-th>
						<b-th>Observacion</b-th>
						<b-th class="text-center">Creado por</b-th>
						<b-th class="text-center">Acciones</b-th>
					</b-thead>
					<b-tbody>
						<template v-for="transac in transactions">
							<b-tr>
								<b-td>
									{{ transac.description ? transac.description : "-" }}
								</b-td>
								<b-td>
									<div v-if="transac.url != null" class="d-flex">
										<b-avatar
											class="text-dark w-100"
											icon="image"
											square
											rounded="lg"
											variant="light"
											size="4rem"
											:src="transac.url"
										/>
										<div class="d-flex flex-column justify-content-center" style="margin-left: 5px">
											<a ref="downloadLink" :href="transac.url" download target="_blank">
												<feather-icon
													icon="DownloadIcon"
													class="text-danger cursor-pointer"
													size="17"
												/>
											</a>
										</div>
									</div>
								</b-td>
								<b-td>
									<div class="d-flex justify-content-center">
										<span class="text-danger">{{ transac.amount | currency }}</span>
									</div>
								</b-td>
								<b-td>
									{{ transac.observation ? transac.observation : "-" }}
								</b-td>
								<b-td>
									<div class="d-flex flex-column align-items-center justify-content-center">
										<span>{{ transac.created_by }}</span>
										<span style="font-size: 13px">
											<feather-icon icon="CalendarIcon" class="text-success" />
											{{ transac.created_at | myGlobalDayWithHour }}
										</span>
									</div>
								</b-td>
								<b-td>
									<div class="d-flex align-items-center justify-content-center">
										<b-button
											variant="danger"
											class="btn-icon btn-sm"
											@click="deleteTransaction(transac)"
										>
											<feather-icon icon="Trash2Icon" />
										</b-button>
									</div>
								</b-td>
							</b-tr>
						</template>
					</b-tbody>
				</b-table-simple>
			</div>
			<template #modal-footer>
				<b-button variant="danger" @click="$emit('close')">Cerrar</b-button>
			</template>
		</b-modal>

		<CashInputModal
			v-if="cashInputModal"
			:pettyCash="modal.pettyCash"
			:typeTransaction="typeTransaction"
			@close="cashInputModal = false"
			@registered="resetSearch(), (cashInputModal = false)"
		/>
	</div>
</template>

<script>
import Ripple from "vue-ripple-directive"
import modalMixin from "@/mixins/modal.js"
import PettyCashService from "@/views/amg/management/views/petty-cash/services/petty-cash.service.js"
import CashInputModal from "@/views/amg/management/views/petty-cash/components/CashInputModal.vue"

export default {
	props: {},
	directives: { Ripple },
	components: { CashInputModal },
	mixins: [modalMixin],
	data() {
		return {
			pettyCash: [],
			transactions: [],
			inputs: [],
			pettyCashId: null,
			code: null,
			date: null,
			responsible: null,
			cashInputModal: false,
			modal: {
				pettyCash: {},
			},
		}
	},
	computed: {
		getTitle() {
			return "Caja chica"
		},
		getAmount() {
			let amount = 0
			this.inputs.map((i) => {
				amount += i.amount
			})
			return amount
		},
		getExpenses() {
			let expenses = 0
			this.transactions.map((t) => {
				expenses += t.amount
			})
			return expenses
		},
	},
	async mounted() {
		this.toggleModal("modal-spences-by-petty-cash")
	},
	async created() {
		await this.getMyPettyCash()
	},
	methods: {
		async getMyPettyCash() {
			this.isPreloading()
			try {
				const params = {
					isAdmin: this.isAdmin ? 1 : 0,
				}
				const { data } = await PettyCashService.getPettyCashByUserId(params)
				this.pettyCash = data

				if (this.pettyCash.length) {
					this.pettyCashId = this.pettyCash[0].id
					await this.changePettyCash(false)
				}
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		async resetSearch() {
			await this.changePettyCash()
		},

		async changePettyCash(isPreload = true) {
			if (isPreload) {
				this.isPreloading()
			}
			try {
				const { data } = await PettyCashService.getDataPettyCashById(this.pettyCashId)
				this.date = data.date
				this.responsible = data.responsible
				this.code = data.code
				const transactions = data.transactions
				this.inputs = transactions.filter((t) => t.type == "input")
				this.transactions = transactions.filter((t) => t.type == "output")
			} catch (error) {
				console.log(error)
			} finally {
				if (isPreload) {
					this.isPreloading(false)
				}
			}
		},

		async deleteTransaction(transac) {
			const confirm = await this.showConfirmSwal({
				text: "Eliminar transaccion",
			})
			if (!confirm.isConfirmed) return
			this.isPreloading()
			try {
				const deleted = await PettyCashService.deleteTransaction(transac.id)
				this.showSuccessToast("Transaccion eliminado correctamente")
				this.resetSearch()
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		addCashInput(type = "input") {
			this.typeTransaction = type
			this.cashInputModal = true
			this.modal.pettyCash = {
				id: this.pettyCashId,
				code: this.code,
				date: this.date,
				amount: this.getAmount,
				expenses: this.getExpenses,
				responsible: this.responsible,
			}
		},
	},
}
</script>
