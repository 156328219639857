<template>
	<div class="Sau">
		<div class="Sau__box Sau__box--dashed" v-if="!readonly">
			<span class="Sau__indication">Click aqui para subir imagenes</span>
			<input class="Sau__input" multiple type="file" accept="image/*" ref="fileInput" @input="handleUpload" />
		</div>
		<div class="Sau__box" v-for="image in availableImages" :key="image.idx">
			<template></template>
			<img class="Sau__img" :src="image.url" @click="displayImage(image.idx)" />
			<span class="Sau__delete" @click="handleDelete(image.idx)" v-if="!readonly">
				<feather-icon icon="XIcon" />
			</span>
		</div>
		<custom-light-box :items="lightBox.item" :index="lightBox.index" @close="lightBox.index = null" />
	</div>
</template>

<script>
export default {
	props: {
		// Array<{id?:number,url:string,file?:File,delete?:boolean}>
		value: Array,
		readonly: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		files: null,
		lightBox: {
			item: [],
			index: null,
		},
	}),
	computed: {
		availableImages() {
			// mantiene el index del array original dentro del objeto
			return this.value
				.map((img, idx) => ({ ...img, idx }))
				.filter((img) => img.delete === undefined || img.delete === false)
		},
	},
	methods: {
		handleUpload() {
			const input = this.$refs.fileInput
			const fileUrls = []
			input.files.forEach((file) => {
				fileUrls.push(URL.createObjectURL(file))
			})
			const newFiles = fileUrls.map((url, idx) => ({
				id: null,
				url,
				file: input.files[idx],
				delete: false,
			}))
			this.sendModel([...this.value, ...newFiles])
		},
		handleDelete(idx) {
			const copyImages = this.value.map((img) => img)
			copyImages[idx].delete = true
			// quita imagen que se cargo y se borro
			if (copyImages[idx].id === null && copyImages[idx].delete === true) {
				copyImages.splice(idx, 1)
			}
			this.sendModel(copyImages)
		},
		displayImage(idx) {
			this.lightBox.item = [this.value[idx].url]
			this.lightBox.index = 0
		},
		sendModel(val) {
			this.$emit("input", val)
		},
	},
}
</script>

<style lang="scss" scoped>
.Sau {
	display: flex;
	gap: 1rem;
	max-width: 16rem;
	padding-top: 1rem;
	overflow-x: auto;

	&__box {
		flex-shrink: 0;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 6rem;
		height: 7rem;
		border-radius: 0.5rem;
		border: 2px solid var(--primary);

		&--dashed {
			border-style: dashed;
			border-color: var(--secondary);
		}
	}

	&__indication {
		text-align: center;
		font-size: 0.9rem;
	}

	&__input {
		position: absolute;
		opacity: 0;
		inset: 0;
		border: 2px solid pink;
		cursor: pointer;
	}

	&__img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
		cursor: pointer;
	}

	&__delete {
		position: absolute;
		top: -0.5rem;
		right: -0.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 1.5rem;
		height: 1.5rem;
		background: var(--danger);
		color: var(--white);
		border-radius: 50%;
		cursor: pointer;
	}
}
</style>
