export default [
  {
    title: 'Contactos',
    icon: 'UsersIcon',
    route: 'brain-contact-list',
    requiresAdmin: true,
    requiresInvitado: true,
    requiresEncastador: false
    // tag: '2',
    // tagVariant: 'light-warning',

  },
];
