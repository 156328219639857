export default [
  {
    title: "Motivos",
    icon: "HelpCircleIcon",
    route: "brain-reasons-list",
    requiresAdmin: true,
    requiresEncastador: false,
    requiresInvitado: true
  }
];
