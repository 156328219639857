let calendar = []
let calendarWeek = []
let arrayCalendar = []
let monthtCurrent = null
let yearCurrent = null
let arrayDays = [
	{
		name: "Lunes",
		id: 0,
	},
	{
		name: "Martes",
		id: 1,
	},
	{
		name: "Miercoles",
		id: 2,
	},
	{
		name: "Jueves",
		id: 3,
	},
	{
		name: "Viernes",
		id: 4,
	},
	{
		name: "Sabado",
		id: 5,
	},
	{
		name: "Domingo",
		id: 6,
	},
]
let arrayMonths = [
	{
		name: "Enero",
		id: 1,
	},
	{
		name: "Febrero",
		id: 2,
	},
	{
		name: "Marzo",
		id: 3,
	},
	{
		name: "Abril",
		id: 4,
	},
	{
		name: "Mayo",
		id: 5,
	},
	{
		name: "Junio",
		id: 6,
	},
	{
		name: "Julio",
		id: 7,
	},
	{
		name: "Agosto",
		id: 8,
	},
	{
		name: "Setiembre",
		id: 9,
	},
	{
		name: "Octubre",
		id: 10,
	},
	{
		name: "Noviembre",
		id: 11,
	},
	{
		name: "Diciembre",
		id: 12,
	},
]
let arrayDay = []

let selectDay = {}
let dateNow = new Date()
let dayIn = null
let dayFn = null
let dayNow = new Date()
import moment from "moment"

export const initCalendarData = (date) => {
	return initCalendar(date)
}

const initCalendar = (date) => {
	//cal = [];
	let cal = []
	let calendario = []
	//calendar = [];
	const dateNow = date
	const dayIdNow = dateNow.getDay()
	const dayNow = dateNow.getDate()
	const monthNow = dateNow.getMonth()
	monthtCurrent = monthNow + 1
	const yearNow = dateNow.getFullYear()
	yearCurrent = yearNow
	const totalDaysNow = new Date(yearNow, monthNow + 1, 0).getDate()

	const monthPrevious = dateNow.getMonth()
	const yearPrevious = dateNow.getMonth() + 1 === 1 ? dateNow.getFullYear() - 1 : dateNow.getFullYear()
	const totalDaysPnullrevious = new Date(yearPrevious, monthPrevious, 0).getDate()
	const initIn = new Date(yearNow, monthNow, 0).getDate() - new Date(yearNow, monthNow, 1).getDay() + 1
	const offMonthPrevious = new Date(yearNow, monthNow, 0).getDate()
	// previous month
	for (let i = initIn; i <= offMonthPrevious; i += 1) {
		let month = monthNow - 1 === 0 || monthNow - 1 === -1 ? 12 : monthNow
		month = month < 10 ? `0${month}` : month
		cal.push({
			month: monthNow - 1 === 0 || monthNow - 1 === -1 ? 12 : monthNow,
			monthName: arrayMonths.find((item) => item.id === (monthNow === 0 ? 12 : monthNow)),
			year: yearPrevious,
			day: i,
			monthSelect: false,
			data: [],
			date: `${yearPrevious}-${month}-${i < 10 ? `0${i}` : i}`,
		})
	}
	for (let i = 1; i <= totalDaysNow; i += 1) {

		let month = monthNow + 1
		month = month < 10 ? `0${month}` : month
		cal.push({
			monthName: arrayMonths.find((item) => item.id === monthNow + 1),
			month: monthNow + 1,
			year: yearNow,
			day: i,
			monthSelect: true,
			data: [],
			date: `${yearNow}-${month}-${i < 10 ? `0${i}` : i}`,
		})
	}
	//next month
	let len = 42 - cal.length
	let camtSemanas = 6
	if(len >= 7){
		len = 35 - cal.length
		camtSemanas = 5
	}
	
	for (let j = 0; j < len; j += 1) {
		
		let month = monthNow + 2
		month = month < 10 ? `0${month}` : month
		cal.push({
			monthName: arrayMonths.find((item) => item.id === (monthNow + 2 === 13 ? 1 : monthNow + 2)),
			month: monthNow + 2,
			year: monthNow + 2 === 13 ? yearNow + 1 : yearNow,
			day: j + 1,
			monthSelect: false,
			data: [],
			date: `${monthNow + 2 === 13 ? yearNow + 1 : yearNow}-${month}-${j + 1 < 10 ? `0${j + 1}` : j + 1}`,
		})
	}

	// Day inicial y final del mes
	dayIn = {
		day: cal[0].day < 10 ? `0${cal[0].day}` : cal[0].day,
		month: cal[0].month < 10 ? `0${cal[0].month}` : cal[0].month,
		year: cal[0].year,
	}
	dayFn = {
		day: cal[34].day < 10 ? `0${cal[34].day}` : cal[34].day,
		month: cal[34].month < 10 ? `0${cal[34].month}` : cal[34].month,
		year: cal[34].year,
	}
	arrayCalendar = cal

	// Formatear la fecha como "dd/mm/yyyy"

	const dateIn = `${dayIn.year}-${dayIn.month}-${dayIn.day}`
	const dateFn = `${dayFn.year}-${dayFn.month}-${dayFn.day}`
	// TOMAR LOS DAYIN Y DAYFN PARA EXPORTAR A LA API
	let semana = 1
	let day = 0
	for (semana; semana <= camtSemanas; semana += 1) {
		const sem = []
		for (let item = 1; item <= 7; item += 1) {
			cal[day].idArray = day
			sem.push(arrayCalendar[day])
			day += 1
		}
		calendario.push(sem)
	}
	calendar = calendario
	// const dateIn = moment(new Date(yearNow, monthNow, 0)).format("YYYY-MM-DD")
	// const dateFn = moment(dateIn).add(34, "days").format("YYYY-MM-DD")
	return { calendar, dateIn, dateFn, yearNow, monthNow: arrayMonths[monthNow]  }
}

export const initCalendarDates = (dateIn, dateFn) => {
	let calendar = []
	let days = moment(dateFn).diff(moment(dateIn), "days", false)
	let week_num = Math.ceil((days + 1) / 7)
	days = Math.ceil((days + 1) / 7) * 7 - 1
	for (let i = 0; i <= days; i++) {
		calendar.push({
			month: moment(dateIn).format("MM"),
			day: moment(dateIn).format("DD"),
			year: moment(dateIn).format("YYYY"),
			day_text: arrayDays[new Date(dateIn).getDay()],
			date_num: dateIn,
			data: null,
			feature_days: moment(dateFn).diff(moment(dateIn), "days", false) < 0 ? false : true,
		})
		dateIn = moment(dateIn).add(1, "days").format("YYYY-MM-DD")
	}

	return { calendar, week_num }
}
