<template>
	<div class="network ml-75 mt-0">
		<FeatherIcon @click="active = !active" icon="GlobeIcon" size="20" class="cursor-pointer icon text-warning" />
		<div v-if="active" class="container-iframe ratio ratio-4-3" :class="{ active }">
			<iframe
				src="https://fast.com"
				width="100%"
				style="overflow: hidden; border: 0; text-align: center"
				height="100%"
				allowfullscreen="allowfullscreen"
				loading="lazy"
				referrerpolicy="no-referrer-when-downgrade"
				class="mapa-url mt-0"
			></iframe>
		</div>
	</div>
</template>

<script>
export default {
	name: "NetworkSpeed",
	components: {},
	data: () => ({
		onLine: null,
		download: 0,
		upload: 0,
		clickIcon: false,
		url: null,
		file: null,
		active: false,
	}),
	methods: {
		removeNetwork() {
			this.active = !this.active;
		},
	},
};
</script>

<style lang="scss">
.container-refresh {
	display: flex;
	align-items: center;
}

.container-speed {
	display: flex;
	align-items: center;

	.container {
		display: flex;
		flex-direction: column;
		align-items: center;

		.down,
		.up {
			display: flex !important;
			align-items: center;

			p {
				text-align: center;
				width: 130px;

				font-size: 12px;
				margin-bottom: 0;
				margin-left: 0.3rem;
				font-weight: bold;

				span {
					color: #9193a8;
				}
			}
		}

		.number {
			margin-top: 0.2rem;
			font-size: 20px;
			margin-bottom: 0;
		}

		.active-load {
			font-size: 14px;
			animation: loadText 2s linear infinite;
			font-weight: bold;
		}
	}
}

@keyframes loadingv2 {
	0% {
		transform: rotate(0);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes loadText {
	0% {
		color: #9193a8;
	}

	// 25% {
	//   transform: rotate(180deg);
	// }
	// 50% {
	//   transform: rotate(360deg);
	// }
	// 75% {
	//   transform: rotate(180deg);
	// }
	25% {
		color: #7367f0;
	}

	50% {
		color: #9193a8;
	}

	100% {
		color: #7367f0;
	}
}

.icon-active {
	animation: loadingv2 linear infinite;
}

.iframe-smart {
	width: 400px;
	height: 200px;
}

.network {
	position: relative;
	// margin-right: 1rem;
	// width: 70px;
	@media screen and (min-width: 500px) {
		// width: 300px;
	}
	@media screen and (min-width: 900px) {
		// width: 400px;
	}
	.container-iframe {
		height: 0;
		width: 90vw;
		position: absolute;
		top: 200%;
		right: -168%;
		overflow: hidden;
		transition: 0.5s all ease-in-out;
		border-radius: 8px;
		max-width: 450px;
		iframe {
			zoom: 1.2;
		}
		@media screen and (min-width: 500px) {
			right: 300%;
			left: -20%;
		}

		@media screen and (min-width: 800px) {
			left: 0;
		}
		.mapa-url {
			padding: 0.1rem;
			overflow: hidden;
			box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
		}
	}

	.active {
		height: 18rem;
	}
}
</style>
