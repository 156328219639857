<template>
	<h2>Hola</h2>
</template>

<script>
export default {
	name: "AttendanceCalendar",
	data() {
		return {

			calendar: [],
			calendarWeek: [],
			arrayCalendar: [],
			height: screen.width < 600 ? 50 : 170,
			open: screen.width >= 600,
			monthtCurrent: null,
			yearCurrent: null,
			arrayDays: [
				{
					name: "Domingo",
					id: 0,
				},
				{
					name: "Lunes",
					id: 1,
				},
				{
					name: "Martes",
					id: 2,
				},
				{
					name: "Miercoles",
					id: 3,
				},
				{
					name: "Jueves",
					id: 4,
				},
				{
					name: "Viernes",
					id: 5,
				},
				{
					name: "Sabado",
					id: 6,
				},
			],
			arrayMonths: [
				{
					name: "Enero",
					id: 1,
				},
				{
					name: "Febrero",
					id: 2,
				},
				{
					name: "Marzo",
					id: 3,
				},
				{
					name: "Abril",
					id: 4,
				},
				{
					name: "Mayo",
					id: 5,
				},
				{
					name: "Junio",
					id: 6,
				},
				{
					name: "Julio",
					id: 7,
				},
				{
					name: "Agosto",
					id: 8,
				},
				{
					name: "Setiembre",
					id: 9,
				},
				{
					name: "Octubre",
					id: 10,
				},
				{
					name: "Noviembre",
					id: 11,
				},
				{
					name: "Diciembre",
					id: 12,
				},
			],
			arrayDay: [],

			openTabCalendar: false,
			tabCalendar: 1,
			widthScreen: screen.width,
			selectDay: {},
			calendarSelectDay: null,
			openModalTask: false,
			taskDayCalendar: null,
			dateNow: new Date(),
			tasks: [],
			idWeek: 0,
			idWeekNow: 0,
			dayIn: null,
			dayFn: null,
			arrayTasksNow: null,
			dayNow: new Date(),
			updateMonthDay: false,
			datesTournaments: [],
		};
	},
	async created() {
		await this.initCalendar(new Date());
	},
	methods: {
		async initCalendar(date) {
			//cal = [];
			let cal = [];
			let calendario = [];
			//this.calendar = [];
			const dateNow = date;
			const dayIdNow = dateNow.getDay();
			const dayNow = dateNow.getDate();
			const monthNow = dateNow.getMonth();
			this.monthtCurrent = monthNow + 1;
			//console.log('date: ', dateNow, monthNow)
			const yearNow = dateNow.getFullYear();
			this.yearCurrent = yearNow;
			const totalDaysNow = new Date(yearNow, monthNow + 1, 0).getDate();

			const monthPrevious = dateNow.getMonth();
			const yearPrevious = dateNow.getMonth() + 1 === 1 ? dateNow.getFullYear() - 1 : dateNow.getFullYear();
			const totalDaysPnullrevious = new Date(yearPrevious, monthPrevious, 0).getDate();
			const initIn = new Date(yearNow, monthNow, 0).getDate() - new Date(yearNow, monthNow, 1).getDay() + 1;
			const offMonthPrevious = new Date(yearNow, monthNow, 0).getDate();
			// previous month
			for (let i = initIn; i <= offMonthPrevious; i += 1) {
				cal.push({
					month: monthNow - 1 === 0 || monthNow - 1 === -1 ? 12 : monthNow,
					monthName: this.arrayMonths.find((item) => item.id === (monthNow === 0 ? 12 : monthNow)),
					year: yearPrevious,
					day: i,
					monthSelect: false,
				});
			}
			// now month
			for (let i = 1; i <= totalDaysNow; i += 1) {
				cal.push({
					monthName: this.arrayMonths.find((item) => item.id === monthNow + 1),
					month: monthNow + 1,
					year: yearNow,
					day: i,
					monthSelect: true,
				});
			}
			//next month
			const len = 35 - cal.length;
			for (let j = 0; j < len; j += 1) {
				cal.push({
					monthName: this.arrayMonths.find((item) => item.id === (monthNow + 2 === 13 ? 1 : monthNow + 2)),
					month: monthNow + 2,
					year: monthNow + 2 === 13 ? yearNow + 1 : yearNow,
					day: j + 1,
					monthSelect: false,
				});
			}

			// Day inicial y final del mes
			this.dayIn = {
				day: cal[0].day < 10 ? `0${cal[0].day}` : cal[0].day,
				month: cal[0].month < 10 ? `0${cal[0].month}` : cal[0].month,
				year: cal[0].year,
			};
			this.dayFn = {
				day: cal[34].day < 10 ? `0${cal[34].day}` : cal[34].day,
				month: cal[34].month < 10 ? `0${cal[34].month}` : cal[34].month,
				year: cal[34].year,
			};

			// const data = await TournamentService.getCalendarTournament({
			// 	dateIn: `${this.dayIn.year}-${this.dayIn.month}-${this.dayIn.day}`,
			// 	dateFn: `${this.dayFn.year}-${this.dayFn.month}-${this.dayFn.day}`,
			// });
			// const dataFuture = await TournamentService.calendarFutureDates({
			//     dateIn: `${this.dayIn.year}-${this.dayIn.month}-${this.dayIn.day}`,
			// 	dateFn: `${this.dayFn.year}-${this.dayFn.month}-${this.dayFn.day}`,
			// });

			let arrayFuture = [];
			// data.forEach((item) => {
			// 	dataFuture.forEach((task) => {
			// 		if (
			// 			item.stage_name == task.stage_name &&
			// 			item.id_tournament == task.id_tournament &&
			// 			item.date == task.date &&
			// 			task.future
			// 		) {
			// 			arrayFuture.push(task);
			// 		}
			// 	});
			// });

			// Filtrar repetidos
			// const idsNoPermitidos = arrayFuture.map((doc) => doc.tournament_stage_date_id);
			// const datosFiltrados = dataFuture.filter((doc) => !idsNoPermitidos.includes(doc.tournament_stage_date_id));

			// this.tasks = [...data, ...datosFiltrados];

			this.tasks = this.tasks.map((item) => {
				return {
					...item,
					colors: JSON.parse(item.colors),
					createdBy: JSON.parse(item.createdBy),
					fronts: item.fronts ? JSON.parse(item.fronts) : [],
					participants: item.contacts ? JSON.parse(item.contacts) : [],
					future: item.future ? item.future : false,
				};
			});

			this.tasks = this.tasks.map((item) => {
				const colors = {
					color: item.colors.color.replace("1)", "1)"),
					color1: item.colors.color1.replace("1)", "0.6)"),
				};
				return {
					...item,
					colors,
				};
			});

			this.datesTournaments = this.tasks;

			// LLenar tareas al calendario
			// cal = cal.map((item) => {
			// 	// console.log(new Date(item.year, item.month, item.day))
			// 	const dateComponent = new Date(item.year, item.month - 1, item.day);
			// 	item.data = this.tasks.filter((date) => {
			// 		const fecha = this.getDateByString(date.date);
			// 		if (
			// 			dateComponent.getDate() === fecha.getDate() &&
			// 			dateComponent.getMonth() === fecha.getMonth() &&
			// 			dateComponent.getFullYear() === fecha.getFullYear()
			// 		) {
			// 			return date;
			// 		}
			// 	});
			// 	return item;
			// });
			this.arrayCalendar = cal;
			let semana = 1;
			let day = 0;
			for (semana; semana <= 5; semana += 1) {
				const sem = [];
				for (let item = 1; item <= 7; item += 1) {
					cal[day].idArray = day;
					sem.push(this.arrayCalendar[day]);
					day += 1;
				}
				calendario.push(sem);
			}
			this.calendar = calendario;

			//console.log('log1: ', this.arrayCalendar)
			//console.log('log2: ', this.calendar)
			// Fechas de inicio y fin del calendario en pantalla
		},
	},
};
</script>
