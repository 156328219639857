<template>
	<b-modal
		ref="modal-cash-input"
		@hidden="$emit('close')"
		:title="getTitle"
		cancel-title="Cancelar"
		scrollable
		size="lg"
	>
		<div>
			<ValidationObserver ref="form-add-cash">
				<b-row>
					<b-col md="12" lg="6">
						<b-form-group>
							<label>Caja chica</label>
							<div
								class="d-flex align-items-center rounded pl-1"
								style="height: 35px; border: 1px solid rgba(34, 41, 47, 0.2)"
							>
								{{ pettyCash.code }}
							</div>
						</b-form-group>
					</b-col>

					<b-col md="12" lg="6">
						<b-form-group>
							<label>Responsable</label>
							<div
								class="d-flex align-items-center rounded pl-1"
								style="height: 35px; border: 1px solid rgba(34, 41, 47, 0.2)"
							>
								{{ pettyCash.responsible }}
							</div>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col md="6" v-if="typeTransaction == 'output'">
						<b-form-group label="Sustento">
							<b-avatar
								class="clickable text-dark w-100"
								style="border: 2px dashed var(--dark)"
								ref="previewEl"
								icon="image"
								square
								rounded="lg"
								variant="light"
								:src="form.photo"
								:size="heightImage"
								@click="$refs.fileInput.click()"
							/>
							<div class="d-flex justify-content-end" style="margin-top: 5px">
								<span>
									<feather-icon
										size="22"
										icon="XOctagonIcon"
										class="cursor-pointer text-danger mr-50"
										@click="deletePhoto()"
									/>
									<feather-icon
										size="22"
										icon="UploadIcon"
										class="cursor-pointer text-primary"
										@click="$refs.fileInput.click()"
									/>
								</span>
							</div>
							<input
								ref="fileInput"
								type="file"
								accept=".jpeg,.jpg,.png,.heif,.heic"
								@input="pickFile"
								class="d-none"
							/>
						</b-form-group>
					</b-col>
					<b-col md="6">
						<b-row>
							<b-col md="12">
								<ValidationProvider v-slot="{ errors }" rules="required">
									<b-form-group :state="errors[0] ? false : null">
										<label for="amount">
											Monto
											<span class="text-danger">(*)</span>
										</label>
										<money
											id="amount"
											v-bind="money"
											v-model="form.amount"
											class="form-control"
											:class="errors[0] && save ? 'border-danger' : ''"
										/>
									</b-form-group>
								</ValidationProvider>
							</b-col>
							<b-col md="12" v-if="typeTransaction == 'output'">
								<ValidationProvider
									v-slot="{ errors }"
									:rules="`${typeTransaction == 'input' ? '' : 'required'}`"
								>
									<b-form-group>
										<label for="description">
											Descripcion
											<span v-if="typeTransaction != 'input'" class="text-danger">(*)</span>
										</label>
										<b-form-input
											id="description"
											:state="errors[0] ? false : null"
											type="text"
											v-model="form.description"
										/>
									</b-form-group>
								</ValidationProvider>
							</b-col>
							<b-col md="12" v-if="typeTransaction == 'output'">
								<b-form-group label="Observacion" label-for="observation">
									<b-form-textarea placeholder="" v-model="form.observation" />
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</ValidationObserver>
		</div>
		<template #modal-footer>
			<b-button variant="danger" @click="$emit('close')">Cancelar</b-button>
			<b-button variant="primary" @click="registerCash">Guardar</b-button>
		</template>
	</b-modal>
</template>

<script>
import Ripple from "vue-ripple-directive"
import { Money } from "v-money"
import modalMixin from "@/mixins/modal.js"
import { ValidationProvider } from "vee-validate"
import heic2any from "heic2any"
import PettyCashService from "@/views/amg/management/views/petty-cash/services/petty-cash.service.js"

export default {
	props: {
		pettyCash: {
			required: true,
		},
		typeTransaction: {
			default: "input",
		},
	},
	directives: { Ripple },
	components: { Money },
	mixins: [modalMixin],
	data() {
		return {
			money: {
				decimal: ",",
				thousand: ".",
				prefix: "S/ ",
				suffix: "",
				precision: 2,
				min: 0.0,
				masked: false,
			},
			form: {
				id: null,
				type: null,
				amount: 0,
				description: null,
				observation: null,
				photo: null,
				file: null,
			},
		}
	},
	async mounted() {
		this.toggleModal("modal-cash-input")
	},
	created() {},
	computed: {
		getTitle() {
			if (this.typeTransaction == "input") {
				return "Agregar deposito"
			}
			return "Registrar gasto"
		},
		heightImage() {
			return ["md", "lg", "xl"].includes(this.currentBreakPoint) ? "15rem" : "18rem"
		},
	},
	methods: {
		async registerCash() {
			const validate = await this.$refs["form-add-cash"].validate()
			if (!validate) return
			if (!this.form.amount > 0) {
				this.showSuccessToast(
					"Monto debe ser mayor a 0",
					"warning",
					"top-right",
					"Advertencia",
					"AlertTriangleIcon"
				)
				return
			}
			if (
				this.typeTransaction == "output" &&
				this.form.amount > this.pettyCash.amount - this.pettyCash.expenses
			) {
				this.showSuccessToast(
					"No cuenta con balance suficiente para este gasto",
					"warning",
					"top-right",
					"Advertencia",
					"AlertTriangleIcon"
				)
				return
			}

			const confirm = await this.showConfirmSwal({
				text: this.typeTransaction == "input" ? "Agregar dinero" : "Registrar gasto",
			})
			if (!confirm.isConfirmed) return

			try {
				this.isPreloading()
				this.form.id = this.pettyCash.id
				this.form.type = this.typeTransaction
				const image = this.form.file
				const formData = new FormData()
				formData.append("id", this.pettyCash.id)
				formData.append("file", image)
				formData.append("type", this.form.type)
				formData.append("amount", this.form.amount)
				formData.append("description", this.form.description)
				formData.append("observation", this.form.observation)

				const registered = await PettyCashService.addCash(formData)
				this.showSuccessToast("Dinero agregado correctamente")
				this.$emit("registered")
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		deletePhoto() {
			this.form.photo = null
			this.form.file = null
		},

		pickFile() {
			let input = this.$refs.fileInput
			let file = input.files
			const typeOfFile = file[0] && file[0].name.split(".")[file[0].name.split(".").length - 1]
			if (typeOfFile === "heic" || typeOfFile === "heif") {
				this.isPreloading()
				this.showToast(
					"warning",
					"top-right",
					"Cargando imagen, espere un momento",
					"CheckIcon",
					"El formato HEIF o HEIC no es soportado, por ello lo puede tardar un momento."
				)
				heic2any({ blob: file[0], toType: "image/jpg", quality: 1 }).then((newImage) => {
					const url = URL.createObjectURL(newImage)
					let newFile = new File([newImage], "heic" + ".jpg", {
						type: "image/jpeg",
						lastModified: new Date().getTime(),
					})
					this.form.file = newFile
					this.form.photo = url
					this.isPreloading(false)
				})
			} else {
				this.form.file = file[0]
				if (file && file[0]) {
					let reader = new FileReader()
					reader.onload = (e) => {
						this.form.photo = e.target.result
					}
					reader.readAsDataURL(file[0])
					this.form.file = file[0]
				}
			}
		},
	},
}
</script>
