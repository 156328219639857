<template>
	<b-card class="mb-1 card-sticky-global">
		<b-row>
			<b-col
				cols="12"
				md="3"
				:class="{
					'mb-1': currentBreakPoint === 'sm' || currentBreakPoint === 'xs',
				}"
			>
				<b-button
					v-if="buttonSave"
					block
					variant="info"
					@click="$emit('save')"
					:disabled="!selectedSpecimens.length"
				>
					<feather-icon icon="SaveIcon" class="mr-50" />
					<span class="align-middle">Guardar</span></b-button
				>
			</b-col>
			<b-col  cols="12" offset-md="4" offset-lg="5" md="3">
				<SelectSpecimens
				v-if="isInventory"
					:selectedSpecimens="selectedSpecimens"
					@selected="selected"
					cleanSelected
					statusList="1,9,10,12,13,7,8"
					categories="1,2,3,4"
				/>
			</b-col>
			<b-col
				:class="{
					'mt-2': currentBreakPoint === 'md',
				}"
				cols="12"
				md="2"
				lg="1"
				class="mt-1 p-0 text-center"
				><span class="h4" v-if="selectedSpecimens.length"
					><b>Total: {{ selectedSpecimens.length }}</b></span
				>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
import SelectSpecimens from "./SelectSpecimens.vue";
export default {
	name: "HeadersMassivelyChanges",
	components: {
		SelectSpecimens,
	},
	props: {
		selectedSpecimens: {
			type: Array,
			default: () => [],
		},
		buttonSave: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {};
	},
	computed: {
		isInventory() {
			if ("inventory-specimens-by-id-sr" == this.$route.name) return false;
			return true;
		},
	},
	methods: {
		selected(selected) {
			this.$emit("selected", selected);
		},
	},
};
</script>

<style lang="scss" scoped>
.card-sticky-global {
	position: sticky;
	top: -2rem;
	z-index: 999;
	// background: red;
}
</style>
