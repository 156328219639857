export default [
  {
    title: "Encaste Configuración",
    icon: "HelpCircleIcon",
    route: "encaste-settings",
    requiresAdmin: true,
    requiresEncastador: false,
    requiresInvitado: false,
  }
];
