<template>
	<div>
		<feather-icon @click="modalResultsMonthNow = true" icon="BarChartIcon" color="#ff9f43" size="22" />
		<b-modal
			v-model="modalResultsMonthNow"
			:title="`Grafico de resultados`"
			size="xl"
			body-class="p-2"
			hide-footer
			scrollable
			title-class="h3"
		>
			<GraphTournament />
		</b-modal>
	</div>
</template>

<script>
import GraphTournament from "../reports/results/tournaments/GraphTournament.vue";

export default {
	name: "GraficoResultsTournament",
	components: {
		GraphTournament,
	},
	data() {
		return {
			month: null,
			modalResultsMonthNow: false,
		};
	},
	mounted() {},
	methods: {},
};
</script>
