export default [
    {
        title: 'Clientes',
        icon: 'UsersIcon',
        route: 'brain-clients-list',
        requiresAdmin: true,
                requiresInvitado: true,
                requiresEncastador: false
        // tag: '2',
        // tagVariant: 'light-warning',
        
    },
];
