<template>
	<div class="Opt">
		<img
			class="Opt__image"
			:src="specimen.thumb || '/rooster/default2.webp'"
			onerror="this.src='/rooster/default2.webp'"
			@click="specimen.thumb && showImage()"
		/>
		<div class="Opt__container">
			<div class="Opt__plate" @click="toSpecimenPage">
				<v-icon name="gi-rooster" scale="1.2" />
				{{ specimen.plate || "- -" }}
			</div>
			<div class="Opt__alias">{{ specimen.alias || "---" }}</div>
			<input class="Opt__check" type="checkbox" v-model="checkedValue" @change="updateModel" />
		</div>

		<SlidersImageVideo
			:url="imgDisplay.url"
			:type="imgDisplay.type"
			:text="imgDisplay.text"
			:text_url="imgDisplay.text_url"
			:open="imgDisplay.open"
			v-if="imgDisplay.open"
			@refreshSlider="hideImage"
			:idSpecimen="specimen.id"
		/>
	</div>
</template>

<script>
import SlidersImageVideo from "@/views/amg/header-bookmarks/SlidersImageVideo.vue"

export default {
	components: { SlidersImageVideo },
	props: {
		specimen: Object,
		value: Boolean,
		control: Boolean,
	},
	data: () => ({
		imgDisplay: { url: null, type: "image", text: null, text_url: null, open: false },
		checkedValue: false,
	}),
	created() {
		this.checkedValue = this.value
	},
	methods: {
		updateModel() {
			this.$emit("input", this.checkedValue)
		},
		showImage() {
			this.imgDisplay = {
				open: true,
				text_url: `detalle/${this.specimen.id}`,
				type: "image",
				text: `${this.specimen.plate} ${this.specimen.alias ? "-" : ""} ${
					this.specimen.alias ? this.specimen.alias : ""
				}`,
				url: this.specimen.image ? this.specimen.image : "/rooster/rooster.jpg",
			}
		},
		hideImage() {
			this.imgDisplay = { url: null, type: "image", text: null, text_url: null, open: false }
		},
		toSpecimenPage() {
			if (!this.specimen) return
			window.open(`/ejemplares/detalle/${this.specimen.id}`, "_blank")
		},
	},
}
</script>

<style scoped>
.Opt {
	display: block;
	padding: 1rem 2rem;
	border-radius: 6px;
	box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
}

.Opt__image {
	display: block;
	width: 100%;
	min-height: 10rem;
	margin: auto;
	margin-bottom: 1rem;
	border-radius: 6px;
}

.Opt__container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 0.5rem;
	height: 6rem;
	margin: 0 auto;
}

.Opt__plate {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	margin: 0 auto;
	padding: 0.2rem;
	color: var(--primary);
	border: 1px solid var(--primary);
	border-radius: 0.5rem;
	font-size: 1.2rem;
	font-weight: 500;
	cursor: pointer;
}

.Opt__alias {
	text-align: center;
	text-transform: uppercase;
}

.Opt__check {
	display: block;
	height: 1.2rem;
	accent-color: var(--primary);
}
</style>
