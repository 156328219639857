export default [
    {
        title: 'Enfermedades',
        icon: 'PlusSquareIcon',
        route: 'brain-diseases-list',
        requiresAdmin: true,
		requiresInvitado: true,
		requiresEncastador: false
        // tag: '2',
        // tagVariant: 'light-warning',
    },
];
