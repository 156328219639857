<template>
	<div>
		<ValidationObserver ref="form">
			<b-form-group label="Accion">
				<ValidationProvider rules="required" v-slot="{ errors }">
					<b-form-radio-group
						v-model="action"
						:options="[
							{ text: 'Matar', value: 1 },
							{ text: 'Depurar', value: 2 },
						]"
					/>
					<small class="text-danger" v-if="errors.length > 0">{{ errors[0] }}</small>
				</ValidationProvider>
			</b-form-group>
			<b-table-simple class="mt-2 table-bordered" small sticky-header="80vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th style="width: auto">Lote</b-th>
						<b-th style="width: auto">Postura</b-th>
						<b-th style="width: auto">Cantidad</b-th>
						<b-th style="width: 20rem">Observacion</b-th>
						<b-th style="width: auto">Evidencia</b-th>
						<b-th style="width: auto">Acciones</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(row, idx) in rows" :key="idx">
						<b-td>
							<ValidationProvider rules="required" v-slot="{ errors }">
 								<v-select
									label="text"
									style="width: 10rem"
									:class="{ 'border-danger rounded': !!errors[0] }"
									:clearable="false"
									:options="loteOpts"
									:reduce="(opt) => opt.value"
									v-model="row.lote"
									appendToBody
									:calculatePosition="positionDropdown"
									@input="handleLoteSelected($event, idx)"
								/>
							</ValidationProvider>
						</b-td>
						<b-td>{{ row.posturaCode || "---" }}</b-td>
						<b-td>
							<vue-number-input
								class="d-inline-block"
								style="width: 8rem"
								center
								vertical
								controls
								:min="1"
								:max="row.maxQuantity"
								:disabled="!row.postura"
								v-model="row.quantity"
							/>
						</b-td>
						<b-td>
							<ValidationProvider rules="required" v-slot="{ errors }" v-if="action == 1">
								<v-select
									label="reason"
									style="min-width: 12rem"
									:class="{ 'border-danger rounded': !!errors[0] }"
									:options="reasonOpts"
									:clearable="false"
									:reduce="(option) => option.id"
									appendToBody
									:calculatePosition="positionDropdown"
									v-model="row.observacion"
								>
									<template v-slot:option="option">
										<div class="d-flex justify-content-between">
											<span :class="{ 'pl-2': !!option.parent_id }">
												{{ option.reason }}
											</span>
											<span v-if="option.parent_id">
												Sub-motivo de {{ option.parent_reason }}
											</span>
										</div>
									</template>
								</v-select>
							</ValidationProvider>
							<b-form-textarea no-resize v-model="row.comentario" v-else-if="action == 2" />
						</b-td>
						<b-td>
							<b-button
								class="btn-icon"
								variant="flat-secondary"
								@click="row.useEvidence = true"
								v-if="!row.useEvidence"
							>
								<feather-icon icon="PlusIcon" size="20" />
							</b-button>
							<div
								class="d-flex justify-content-center align-items-center gap-x-2"
								style="min-width: 12rem"
								v-else
							>
								<ShowAndUploadImg v-model="row.evidence" />
								<b-button class="btn-icon" variant="flat-danger" @click="hideEvidence(idx)">
									<feather-icon icon="TrashIcon" size="20" @click="row.useEvidence = true" />
								</b-button>
							</div>
						</b-td>
						<b-td>
							<b-button
								class="btn-icon"
								size="sm"
								variant="danger"
								@click="deleteRow(idx)"
								:disabled="rows.length < 2"
							>
								<feather-icon icon="TrashIcon" />
							</b-button>
						</b-td>
					</b-tr>
					<b-tr v-if="action == null">
						<b-td class="text-center" colspan="5">Seleccione una accion para continuar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
			<div class="d-flex justify-content-end">
				<b-button class="btn-icon" size="sm" variant="outline-success" @click="addRow">
					<feather-icon icon="PlusIcon" size="20" />
				</b-button>
			</div>
		</ValidationObserver>
		<OptsPosturasModal
			:show="optsPosturas.show"
			:info="optsPosturas.info"
			@closing="handlePosturaClosed"
			@choosed="handlePosturaChoosed"
		/>
	</div>
</template>

<script>
import { getBase64 } from "@/helpers/functions"
import deadsService from "@/services/deads.service"
import reasonsService from "@/services/reasons.service"

import OptsPosturasModal from "./OptsPosturasModal.vue"
import ShowAndUploadImg from "./ShowAndUploadImg.vue"

export default {
	components: { OptsPosturasModal, ShowAndUploadImg },
	props: {
		saveTrigger: Symbol,
	},
	data: () => ({
		action: 1,
		rows: [
			{
				lote: null,
				eggLote: null,
				quantity: null,
				maxQuantity: null,
				postura: null,
				cintilloId: null,
				observacion: null,
				comentario: null,
				useEvidence: false,
				evidence: [],
			},
		],
		loteOpts: [],
		reasonOpts: [],
		optsPosturas: { show: false, info: {}, selecting: null },
	}),
	methods: {
		resetAll() {
			this.action = 1
			this.rows = [
				{
					lote: null,
					eggLote: null,
					quantity: null,
					maxQuantity: null,
					postura: null,
					cintilloId: null,
					observacion: null,
					comentario: null,
					useEvidence: false,
					evidence: [],
				},
			]
			this.optsPosturas = { show: false, info: {}, selecting: null }
			this.$refs.form.reset()
		},
		async getOpts() {
			this.$emit("loading", true)
			const { data: pataLotes } = await deadsService.getLotes()
			this.loteOpts = pataLotes.map((opt) => ({
				text: opt.code,
				value: opt.lote_id,
			}))

			const reasons = await reasonsService.getByModule()
			this.reasonOpts = reasons

			this.$emit("loading", false)
		},
		addRow() {
			const row = {
				lote: null,
				eggLote: null,
				quantity: null,
				maxQuantity: null,
				postura: null,
				cintilloId: null,
				observacion: null,
				comentario: null,
				useEvidence: false,
				evidence: [],
			}
			this.rows.push(row)
		},
		deleteRow(idx) {
			this.rows.splice(idx, 1)
		},
		handleLoteSelected(e, idx) {
			const lote = this.loteOpts.find((opt) => opt.value == e)
			const rowsQuantity = this.rows
				.filter((row) => !!row.postura)
				.map((row) => ({
					postura: row.postura,
					quantity: row.quantity,
					eggLote: row.eggLote,
				}))
			this.optsPosturas.info = { lote, rowsQuantity }
			this.rows[idx].postura = null
			this.optsPosturas.selecting = idx
			this.optsPosturas.show = !this.optsPosturas.show
		},
		handlePosturaChoosed(postura) {
			if (this.checkEggLoteDuplicated(postura)) return
			this.rows[this.optsPosturas.selecting].postura = postura.id
			this.rows[this.optsPosturas.selecting].posturaCode = postura.code
			this.rows[this.optsPosturas.selecting].maxQuantity = postura.max
			this.rows[this.optsPosturas.selecting].quantity = 1
			this.rows[this.optsPosturas.selecting].cintilloId = postura.cintillo
			// lote es remplazado por egg_lote_id interno de la postura
			this.rows[this.optsPosturas.selecting].eggLote = postura.lote
		},
		handlePosturaClosed() {
			// vereifica si la row fue borrada
			if (this.optsPosturas.selecting === null) {
				this.optsPosturas.show = false
				return
			}

			if (!this.rows[this.optsPosturas.selecting].postura) {
				this.rows[this.optsPosturas.selecting].lote = null
				this.rows[this.optsPosturas.selecting].eggLote = null
				this.rows[this.optsPosturas.selecting].maxQuantity = null
				this.rows[this.optsPosturas.selecting].quantity = null
				this.rows[this.optsPosturas.selecting].posturaCode = null
			}
			this.optsPosturas.selecting = null
			this.optsPosturas.show = false
		},
		checkEggLoteDuplicated(postura) {
			// revisa si el egglote ya existe en alguna row y entonces solo incrementa el count
			const match = this.rows.find((row) => row.eggLote == postura.lote)
			if (match != undefined) {
				match.quantity += 1
				this.rows.splice(this.optsPosturas.selecting, 1)
				this.showToast(
					"success",
					"top-right",
					"Muertos",
					"CheckIcon",
					"La postura elegida ya habia sido seleccionada, se aumentara el contador en el registro existente"
				)
				// cuando selecting es null antes de llegar a handlePosturaClosed, la row fue borrada
				this.optsPosturas.selecting = null
				return true
			}
			return false
		},
		positionDropdown(dropdownList, component, { width, top, left }) {
			dropdownList.style.zIndex = 9999
			dropdownList.style.maxHeight = "20rem"
			dropdownList.style.top = top
			dropdownList.style.left = left
			dropdownList.style.width = width
		},
		async hideEvidence(idx) {
			const row = this.rows[idx]
			if (row.evidence.length != 0) {
				const { isConfirmed } = await this.showConfirmSwal({
					text: `Se borraran las imagenes de evidencia seleccionadas`,
				})
				if (!isConfirmed) return
				row.evidence = []
			}
			row.useEvidence = false
		},
		async save() {
			if (!(await this.$refs.form.validate())) return
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se registrara ${this.rows.length} cintillo(s) en pata como ${
					this.action == 1 ? "MUERTO" : "DEPURADO"
				}`,
			})
			if (!isConfirmed) return
			this.$emit("loading", true)
			const cintillos = []
			for (let i = 0; i < this.rows.length; i++) {
				const row = this.rows[i]

				// images
				let images = []
				for (let idx = 0; idx < row.evidence.length; idx++) {
					const img = row.evidence[idx]
					let base = undefined
					if (img.id == null) {
						base = await getBase64(img.file)
					}
					images.push({ base })
				}
				//
				cintillos.push({
					qty: row.quantity,
					egg_lote_id: row.eggLote,
					cintillo_pata_id: row.cintilloId,
					reason_id: this.action == 1 ? row.observacion : undefined,
					status_id: this.action == 1 ? 4 : 5,
					observation: this.action == 2 ? row.comentario : undefined,
					images,
				})
			}
			await deadsService.insertDeadPata({ cintillos })
			this.showToast("success", "top-right", "Muertos", "CheckIcon", "Guardado con exito")
			this.resetAll()
			this.$emit("loading", false)
		},
	},
	mounted() {
		this.getOpts()
	},
	watch: {
		saveTrigger(val) {
			this.save()
		},
	},
}
</script>
