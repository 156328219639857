<template>
    <layout-brain>
        <router-view />

        <app-customizer v-if="showCustomizer" slot="customizer" />

        <template slot="sliders-modal">
            <images-sliders-total />
        </template>

        <template slot="loading-sliders-upload">
            <loading-in-the-background/>
        </template>
        
    </layout-brain>
</template>

<script>
import LayoutBrain from "@core/layouts/layout-brain/LayoutBrain.vue";
import AppCustomizer from "@core/layouts/components/app-customizer/AppCustomizer.vue";
import { $themeConfig } from "@themeConfig";
import ImagesSlidersTotal from "../../views/amg/header-bookmarks/ImagesSlidersTotal.vue";
import LoadingInTheBackground from '../../views/amg/header-bookmarks/LoadingInTheBackground'

export default {
    components: {
        AppCustomizer,
        LayoutBrain,
        ImagesSlidersTotal,
        LoadingInTheBackground
    },
    data() {
        return {
            showCustomizer: $themeConfig.layout.customizer
        };
    },
    mounted() {
		this.loadSeaons();
	},
    
};
</script>
