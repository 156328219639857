<template>
    <div class="container-bookmarks">
        <div class="item-bookmarks" @click="openModal = true">
            <feather-icon icon="ImageIcon" color="#ff9f43" size="18" />
            <p class="mb-0 ml-1">Agregar imagen al gallo</p>
        </div>
        <b-modal
            title="Agregar image"
            v-model="openModal"
        >
            <h1>Agregar</h1>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "AddImageChick",
    data() {
        return {
            openModal: false
        };
    }
};
</script>

