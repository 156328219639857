<template>
	<b-modal
		ref="modal-massive-weight"
		:title="isTraining ? 'Enviar ejemplares a Pre-listos' : 'Agregar pesos a ejemplares'"
		no-close-on-backdrop
		centered
		v-model="isActive"
		@hidden="$emit('closing')"
		size="xlg"
		hide-footer
	>
		<div>
			<!-- <WeightsMassively />1 -->

			<b-card class="" v-if="!isTraining">
				<b-row>
					<b-col sm="12" md="12" lg="4">
						<div>
							<label for="">Ejemplar:</label>
							<SelectSpecimens
								:selectedSpecimens="S_MASSIVELY_WEIGHTS"
								@selected="selected"
								cleanSelected
								statusList="1,9,10,13,6"
								categories="1,2,3,4"
								:onlySearch="true"
							/>
						</div>
					</b-col>
					<b-col sm="10" md="8" lg="7">
						<GlobalChangesWeight :typeWeightOptions="S_TYPE_WEIGHT_OPTIONS" />
					</b-col>
					<b-col sm="2" md="1" lg="1" class="align-items-center d-flex justify-content-center">
						<feather-icon
							size="25"
							icon="Trash2Icon"
							class="text-danger cursor-pointer"
							@click="
								M_CLEAN_MASSIVELY_WEIGHTS()
								M_CLEAN_ARRAY_IMAGES()
							"
						/>
					</b-col>
				</b-row>
			</b-card>

			<transition name="fade" mode="out-in">
				<b-card v-if="S_MASSIVELY_WEIGHTS.length" class="p-1">
					<div>
						<ValidationObserver ref="form">
							<b-row
								:class="{
									'mb-2': !(index === S_MASSIVELY_WEIGHTS.length - 1),
								}"
								v-for="(specimen, index) in S_MASSIVELY_WEIGHTS"
								:key="index"
								class="rounded"
								style="
									-webkit-box-shadow: 2px 6px 19px -6px rgba(0, 0, 0, 0.31);
									-moz-box-shadow: 2px 6px 19px -6px rgba(0, 0, 0, 0.31);
									box-shadow: 2px 6px 19px -6px rgba(0, 0, 0, 0.31);
									padding-top: 10px;
									padding-bottom: 10px;
									position: relative;
								"
							>
								<div style="position: absolute; top: 10px; left: 10px">
									<b-badge variant="light-dark" style="font-size: 12px"># {{ index + 1 }}</b-badge>
								</div>
								<b-col
									sm="12"
									md="12"
									lg="4"
									class="align-items-center d-flex py-1 justify-content-center"
								>
									<div
										class="p-1 rounded"
										style="
											-webkit-box-shadow: 2px 6px 19px -6px rgba(0, 0, 0, 0.31);
											-moz-box-shadow: 2px 6px 19px -6px rgba(0, 0, 0, 0.31);
											box-shadow: 2px 6px 19px -6px rgba(0, 0, 0, 0.31);
										"
									>
										<SpecimenPlate
											:specimen="{
												id: specimen.id,
												plate: specimen.plate,
												alias: specimen.alias,
												thumb: specimen.thumb,
											}"
										/>
									</div>
								</b-col>
								<b-col
									sm="12"
									:md="(isTraining && S_MASSIVELY_WEIGHTS.length > 1) || !isTraining ? 11 : 12"
									:lg="(isTraining && S_MASSIVELY_WEIGHTS.length > 1) || !isTraining ? 7 : 8"
									class=""
								>
									<div class="">
										<AddSpecimenWeightBody
											:specimenWeight="specimen"
											:typeWeightOptions="S_TYPE_WEIGHT_OPTIONS"
											:isTraining="isTraining"
										/>
									</div>
								</b-col>
								<b-col
									v-if="!isTraining || (isTraining && S_MASSIVELY_WEIGHTS.length > 1)"
									md="1"
									lg="1"
									class="align-items-center d-flex justify-content-center"
									:class="{ 'pb-50': !isTraining, 'pt-2': isTraining }"
								>
									<feather-icon
										size="20"
										icon="Trash2Icon"
										class="text-danger cursor-pointer"
										@click="
											M_REMOVE_SPECIMEN_WEIGHT(specimen.specimen_id),
												removeSpecimenTraining(specimen.specimen_id)
										"
									/>
								</b-col>
							</b-row>
						</ValidationObserver>
					</div>
				</b-card>
				<b-card v-else>
					<div class="d-flex justify-content-center align-items-center w-100" style="height: 100px">
						<h4>No se agregaron ejemplares</h4>
					</div>
				</b-card>
			</transition>
			<div class="d-flex justify-content-end" v-if="S_MASSIVELY_WEIGHTS.length">
				<b-button variant="primary" @click="saveWeightsMassively">Guardar</b-button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import modalMixin from "@/mixins/modal"
import SelectSpecimens from "@/views/amg/specimens/components/SelectSpecimens.vue"
import SpecimenDetails from "@/views/amg/specimens/components/SpecimenDetails.vue"
import AddSpecimenWeightBody from "@/views/amg/specimens/specimens-weight/AddSpecimenWeightBody.vue"
import GlobalChangesWeight from "@/views/amg/specimens/specimens-weight/GlobalChangesWeight.vue"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import WeightsMassively from "@/views/amg/specimens/specimens-weight/WeightsMassively.vue"

export default {
	components: { SelectSpecimens, SpecimenDetails, AddSpecimenWeightBody, GlobalChangesWeight, SpecimenPlate },
	mixins: [modalMixin],
	props: {
		show: Boolean,
		isTraining: { type: Boolean, required: false, default: false },
		selecteds: { type: Array, required: false },
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			selectedSpecimens: [],
		}
	},
	async created() {
		this.M_SET_IS_TRAINING(false)
		if (this.isTraining) {
			this.M_SET_IS_TRAINING(true)
			this.selecteds.map((it) => {
				this.M_ADD_MASSIVELY_WEIGHTS({
					training_id: it.training_id,
					specimen_id: it.specimen.id,
					plate: it.specimen.plate,
					alias: it.specimen.alias,
					status_id: it.specimen.status_id,
					category_id: it.specimen.category_id,
					inside: "SI",
					dob: it.specimen.dob,
					observations: null,
					status_description: it.specimen.status,
					category_description: null,
					photo: it.specimen.image,
					thumb: it.specimen.thumb,
				})
			})
		}
	},

	computed: {
		...mapState("weightSpecimen", ["S_MASSIVELY_WEIGHTS", "S_TYPE_WEIGHT_OPTIONS", "S_IS_TRAINING"]),
	},
	methods: {
		...mapActions("weightSpecimen", ["A_SAVE_MASSIVELY_WEIGHTS", "A_GET_TYPE_WEIGHT_OPTIONS"]),
		...mapMutations("weightSpecimen", [
			"M_ADD_MASSIVELY_WEIGHTS",
			"M_CLEAN_MASSIVELY_WEIGHTS",
			"M_REMOVE_SPECIMEN_WEIGHT",
			"M_SET_IS_TRAINING",
		]),
		...mapMutations("customLightBox", ["M_CLEAN_ARRAY_IMAGES"]),
		async saveWeightsMassively() {
			const validate = await this.$refs.form.validate()
			if (validate) {
				const confirm = await this.showConfirmSwal()
				if (confirm.value) {
					this.isPreloading()
					await this.A_SAVE_MASSIVELY_WEIGHTS()
					this.showSuccessToast("Los pesos se actualizaron con exito")
					this.$emit("hidden")
					this.isPreloading(false)
				}
			}
		},
		selected(selected) {
			this.M_ADD_MASSIVELY_WEIGHTS(selected)
		},
		async saveWeightsMassively() {
			const validate = await this.$refs.form.validate()
			if (validate) {
				const counter = this.S_MASSIVELY_WEIGHTS.length
				const confirm = await this.showConfirmSwal({
					text: `Se registrara el peso a ${
						counter > 1
							? "los " + counter + " ejemplares seleccionados"
							: counter + " ejemplar seleccionado"
					}${this.isTraining ? " (Pasaran a Pre-listo)." : "."}`,
				})
				if (confirm.value) {
					this.isPreloading()
					await this.A_SAVE_MASSIVELY_WEIGHTS()
					this.showSuccessToast("Los pesos se actualizaron con exito")
					this.M_CLEAN_ARRAY_IMAGES()
					this.$emit("closing")
					this.isPreloading(false)
					if (this.isTraining) this.$emit("refresh")
				}
			}
		},
		removeSpecimenTraining(specimen_id) {
			this.$emit("cleanCheck", specimen_id)
		},
	},

	async mounted() {
		this.toggleModal("modal-massive-weight")
		if (!this.S_TYPE_WEIGHT_OPTIONS.length) {
			await this.A_GET_TYPE_WEIGHT_OPTIONS()
		}
	},
	destroyed() {
		this.M_CLEAN_MASSIVELY_WEIGHTS()
		this.M_CLEAN_ARRAY_IMAGES()
	},
}
</script>

<style>
.label-filter {
	font-size: 0.8rem;
}
</style>
