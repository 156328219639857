import { render, staticRenderFns } from "./RecommendedRejectedModal.vue?vue&type=template&id=651e2ac3&scoped=true"
import script from "./RecommendedRejectedModal.vue?vue&type=script&lang=js"
export * from "./RecommendedRejectedModal.vue?vue&type=script&lang=js"
import style0 from "./RecommendedRejectedModal.vue?vue&type=style&index=0&id=651e2ac3&prod&lang=css"
import style1 from "./RecommendedRejectedModal.vue?vue&type=style&index=1&id=651e2ac3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "651e2ac3",
  null
  
)

export default component.exports