<template>
	<div
		v-if="openModal"
		style="z-index: 9999; background-color: rgba(30, 30, 30, 0.9)"
		class="container-sliders"
		:class="[openModal ? '' : 'none']"
	>
		<div class="container-images modal-container" v-if="imageSelect">
			<img v-if="imageSelect.type == 'image'" :src="imageSelect.url" alt="" />

			<template v-else>
				<div v-if="!loadingVideo" class="w-100 content-video" style="position: relative">
					<VideoCloudflare
						v-if="openModal"
						:uid="imageSelect.url"
						:text_download="slugify(text.title || 'text')"
					/>
				</div>
			</template>
			<div class="content-file">
				<CargandoAnimation></CargandoAnimation>
			</div>
			<div class="container-link">
				<a v-if="text.url" :href="text.url" target="_blank">
					<feather-icon icon="LinkIcon" size="24" />
					{{ text.title }}
				</a>
				<p v-else class="text-primary font-weight-bolder" style="font-size: 24px">{{ text.title }}</p>
			</div>

			<div class="container-btn">
				<feather-icon @click="cerrarContainer" icon="XIcon" size="40" />
			</div>
			<div class="btns-container" v-if="images.length > 1 || imagesVideoteca.length > 1">
				<feather-icon icon="ChevronLeftIcon" size="40" @click="changeImage(indexImage - 1)" />
				<feather-icon icon="ChevronRightIcon" size="40" @click="changeImage(indexImage + 1)" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import CargandoAnimation from "./CargandoAnimation.vue"
import VideoCloudflare from "../tournaments/VideoCloudflare.vue"

export default {
	name: "ImagesSlidersTotal",
	components: { CargandoAnimation, VideoCloudflare },
	data() {
		return {
			imageSelect: null,
			loadingVideo: false,
		}
	},
	computed: {
		...mapState("header_book", [
			"images",
			"imagesVideoteca",
			"openModal",
			"indexImage",
			"text",
			"videoteca",
			"toggleOpenGallery",
			"id_specimen",
		]),
	},
	mounted() {
		this.initChange()
	},
	methods: {
		...mapActions("header_book", ["setImages", "toggleModal", "setIndexImage", "setTextImage"]),
		cerrarContainer() {
			this.toggleModal()
			this.imageSelect = null
			this.setIndexImage(null)
		},
		changeImage(id) {
			if (this.openModal) {
				this.loadingVideo = true
				if (id == -1) {
					this.setIndexImage(this.images.length - 1)
				} else if (id == this.images.length) {
					this.setIndexImage(0)
				} else {
					this.setIndexImage(id)
				}
			}
		},
		initChange() {
			if ((this.openModal && !this.videoteca) || this.toggleOpenGallery) {
				this.imageSelect = this.images[this.indexImage]
				// console.log("SEL: ", this.imageSelect, this.indexImage)
				if (this.id_specimen) {
					this.imageSelect &&
						this.setTextImage({
							title: `${this.imageSelect.text || this.imageSelect.name}`,
							url: `/ejemplares/detalle/${this.imageSelect.id_specimens}`,
						})
				} else {
					this.imageSelect &&
						this.setTextImage({
							title: `${this.imageSelect.text || this.imageSelect.name}`,
							url: this.imageSelect.specimen
								? `/ejemplares/detalle/${this.imageSelect.specimen.id}`
								: null,
						})
				}
				// console.log("ZZZ: ", this.imageSelect);

				if (this.images[this.indexImage + 1]?.type == "video") {
					this.isPreloading(true)
					setTimeout(() => {
						this.loadingVideo = false
					}, 300)
				}
			} else if (this.openModal && this.videoteca) {
				// console.log('object', this.indexImage, this.imagesVideoteca[this.indexImage]);
				this.imageSelect = this.imagesVideoteca[this.indexImage]
				// console.log("SD", this.imageSelect)
				// console.log("img", this.imageSelect, this.imagesVideoteca, this.indexImage);
				this.imageSelect &&
					this.setTextImage({
						// title:
						// 	this.imageSelect.label ||
						// 	`${this.imageSelect.local.plate} ${
						// 		this.imageSelect.local.alias ? `(${this.imageSelect.local.alias})` : ""
						// 	} vs ${this.imageSelect.rival.plate || this.imageSelect.rival.alias}`,
						title: this.text.title || this.imageSelect.name,
						url: `/ejemplares/detalle/${this.imageSelect.id_specimens || this.imageSelect.id_specimens}`,
					})
				this.isPreloading(true)
				setTimeout(() => {
					this.loadingVideo = false
				}, 300)
			}
			this.isPreloading(false)
		},
	},
	watch: {
		indexImage() {
			this.initChange()
		},
	},
}
</script>

<style lang="scss">
.container-sliders {
	width: 100%;
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	.container-images {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		img {
			object-fit: contain;
			z-index: 500;
			height: 90% !important;
			width: 90% !important;
			z-index: 500;
			@media screen and (min-width: 600px) {
				height: 50% !important;
				width: 50% !important;
			}
			@media screen and (min-width: 1000px) {
				height: 700px !important;
				width: 700px !important;
			}
		}
		video {
			object-fit: contain;
			z-index: 500;
		}
		.content-video {
			height: 190px !important;
			width: 320px !important;
			z-index: 500;
			// background: orange;
			@media screen and (min-width: 400px) {
				height: 180px !important;
				width: 420px !important;
			}
			@media screen and (min-width: 500px) {
				height: 320px !important;
				width: 520px !important;
			}
			@media screen and (min-width: 600px) {
				height: 400px !important;
				width: 620px !important;
			}
			@media screen and (min-width: 800px) {
				height: 480px !important;
				width: 820px !important;
			}
			@media screen and (min-width: 1000px) {
				height: 500px !important;
				width: 1000px !important;
			}

			@media screen and (min-width: 1800px) {
				height: 560px !important;
				width: 1200px !important;
			}
			// iframe {
			// 	height: 100%;
			// 	width: 100%;
			// }
		}
		.container-link {
			position: absolute;
			bottom: 3rem;
			left: 50%;
			margin-top: 0.5rem;
			transform: translateX(-50%);
			a {
				width: 600px;
				display: flex;
				align-items: center;
				justify-content: center;
				svg {
					margin-right: 1rem;
					margin-bottom: 0.3rem;
				}
				font-size: 16px;
				font-weight: bold;
				@media screen and (min-width: 600px) {
					font-size: 20px;
				}
			}
			z-index: 500;
		}
		.container-btn {
			position: absolute;
			right: 40px;
			top: 40px;
			width: 100%;
			display: flex;
			justify-content: flex-end;
			cursor: pointer;
			z-index: 500;
		}
		.btns-container {
			padding: 0 1rem;
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			display: flex;
			justify-content: space-between;
			transform: translateY(-50%);
			cursor: pointer;
			z-index: 500;
			@media screen and (min-width: 600px) {
				padding: 0 2rem;
			}
		}
		.content-file {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			p {
				margin-bottom: 0;
				font-size: 40px;
				font-weight: bold;
				color: #fff;
			}
		}
	}
}
.modal-container {
	overflow-y: none !important;

	height: auto !important;
}
.none {
	display: none;
}
stream {
	&:first-child {
		// background-color: red !important;
	}
}
</style>
