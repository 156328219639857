<template>
  <div>
    <p>
      <span :class="{ 'muda-gray': specimen.status_id === 9 }">
        <b-avatar
          size="40"
          button
          :src="specimen.thumb"
          :text="avatarText(specimen.alias)"
          @click="M_SET_INDEX_IMAGES(specimen)"
          :disabled="!specimen.thumb"
        />
      </span>
      <span class="text-primary ml-1 font-weight-bolder font-medium-4">{{
        specimen.plate
      }}</span>
      <span class="font-medium-4" v-if="specimen.alias">
        / {{ specimen.alias }}
      </span>
      <span class="font-small-1"> ({{ specimen.category_description }})</span>
    </p>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { avatarText } from "@core/utils/filter";
export default {
  setup() {
    return {
      avatarText,
    };
  },
  props: {
    specimen: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapMutations("customLightBox", ["M_SET_INDEX_IMAGES"]),
  },
};
</script>

<style lang="scss">
.muda-gray {
  filter: grayscale(2) !important;
}
</style>