<template>
	<div class="mt-1 container-charts">
		<div id="chart" class="chart"></div>
	</div>
</template>

<script>
import ApexCharts from "apexcharts";
import { lineGraphTournaments } from "@/services/reports.service";

export default {
	name: "GraphTournamentTotal",
	components: {
		apexchart: ApexCharts,
	},
	props: {
		selectYear: {
			type: Number,
		},
	},
	data() {
		return {
			data: [],
			chartOptions: {
				chart: {
					height: 350,
					type: "line",
					zoom: {
						enabled: false,
					},
				},
				series: [],
				colors: ["#4b4b4b", "#28c76f", "#00cfe8", "#ea5455"],
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "straight",
				},

				title: {
					text: `Grafica de Torneos del ${2022}`,
					align: "left",
					margin: 10,
					offsetX: 0,
					offsetY: 0,
					floating: false,
					style: {
						fontSize: "16px",
						fontWeight: "bold",
						fontFamily: undefined,
						color: "#263238",
					},
				},
				grid: {
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				xaxis: {
					type: "category",
					categories: [
						"ENERO",
						"FEBRERO",
						"MARZO",
						"ABRIL",
						"MAYO",
						"JUNIO",
						"JULIO",
						"AGOSTO",
						"SETIEMBRE",
						"OCTUBRE",
						"NOVIEMBRE",
						"DICIEMBRE",
					],
				},
			},
			charts: null,
		};
	},
	async mounted() {
		await this.init();
		this.initChart();
	},
	methods: {
		async init() {
			const { data } = await lineGraphTournaments({
				year: this.selectYear,
			});
			this.data = data;
		},
		initChart() {
			// const child = document.querySelector("#chart");
			// console.log('child', child);
			// child.removeChild(child.childNodes[0]);
			this.chartOptions.series[0] = { name: "TOTAL", data: this.data.map((item) => item.total) };
			this.chartOptions.series[1] = { name: "TRIUNFOS", data: this.data.map((item) => item.triunfos) };
			this.chartOptions.series[2] = { name: "EMPATES", data: this.data.map((item) => item.empates) };
			this.chartOptions.series[3] = { name: "DERROTAS", data: this.data.map((item) => item.derrotas) };
			this.charts = new ApexCharts(document.querySelector("#chart"), this.chartOptions);
			// this.chart = new ApexCharts(el, options);
			this.charts.render();
		},
	},
	watch: {
		async selectYear(val) {
			await this.init();
				let dat = [];
				dat[0] = { name: "TOTAL", data: this.data.map((item) => item.total) };
				dat[1] = { name: "TRIUNFOS", data: this.data.map((item) => item.triunfos) };
				dat[2] = { name: "EMPATES", data: this.data.map((item) => item.empates) };
				dat[3] = { name: "DERROTAS", data: this.data.map((item) => item.derrotas) };
				this.charts.updateSeries(dat);
		},
	},
};
</script>

<style lang="scss">
	.container-charts{
		// min-width: 280px;
		// overflow-x: scroll !important;
		// overflow-y: hidden;
		.chart{
			// width: 500px;
			// background: red;
			// overflow: scroll;
		}
		.apexcharts-theme-light{
			// width: 400px;
		}
	}

	// .container-charts::-webkit-scrollbar {
	// 	height: 2px !important;
	// 	background: #d5d1fb !important;
	// 	width: 2px !important;
	// }

	// .container-charts::-webkit-scrollbar-thumb {
	// 	background: #7367f0 !important;
	// 	border-radius: 6px !important;
	// }

</style>
