<template>
	<b-row class="w-full" :class="{'pt-2':isTraining}" >
		<b-col cols="12" lg="6" md="6">
			<b-form-group label="Peso KG">
				<ValidationProvider rules="required" v-slot="{ errors }">
					<b-form-input
						v-model="specimenWeight.kg_weight"
						@input="convertKilosToPounds"
						placeholder="0"
						@keypress="onlyForKilos"
						:state="errors[0] ? false : null"
						class="text-center"
					/>
				</ValidationProvider>
			</b-form-group>
		</b-col>
		<b-col cols="12" lg="6" md="6">
			<b-form-group label="Peso Libras">
				<b-form-input v-model="specimenWeight.pounds_weight" disabled class="text-center" />
			</b-form-group>
		</b-col>
		<b-col cols="12" lg="6" md="6" v-if="!isTraining">
			<b-form-group label="Tipo">
				<template #label>
					<div class="d-flex justify-content-between align-items-center">
						<span>Tipo</span>
						<span class="clickable" @click="addTypeWeight = true">
							<feather-icon icon="PlusIcon" />
						</span>
					</div>
				</template>
				<ValidationProvider rules="required" v-slot="{ errors }">
					<b-form-select
						v-model="specimenWeight.type_weight"
						:options="typeWeightOptions"
						value-field="id"
						:state="errors[0] ? false : null"
						text-field="description"
						class="text-center"
					/>
				</ValidationProvider>
			</b-form-group>
		</b-col>

		<b-col cols="12" lg="6" md="6" v-if="!isTraining">
			<b-form-group label="Fecha">
				<flat-pickr
					id="dob"
					v-model="specimenWeight.date_weight"
					placeholder="Elige una Fecha"
					class="form-control bg-transparent"
					:config="config"
				/>
			</b-form-group>
		</b-col>
		<ModalAddTypeWeight v-if="addTypeWeight" @hidden="addTypeWeight = false" />
	</b-row>
</template>

<script>
import flatPickr from "vue-flatpickr-component"
import ModalAddTypeWeight from "./ModalAddTypeWeight.vue"

import { Spanish } from "flatpickr/dist/l10n/es.js"
export default {
	name: "AddSpecimenWightBody",
	components: {
		ModalAddTypeWeight,
		flatPickr,
	},
	props: {
		specimenWeight: Object,
		typeWeightOptions: Array,
		isTraining: { type: Boolean, required: false, default: false },
	},
	mounted() {},
	data() {
		return {
			config: {
				altInput: true,
				altFormat: "F j, Y",
				dateFormat: "Y-m-d",
				locale: Spanish,
			},
			addTypeWeight: false,
		}
	},
	methods: {
		convertKilosToPounds() {
			this.specimenWeight.pounds_weight = (this.specimenWeight.kg_weight * 2.20462).toFixed(3)
		},
		onlyForKilos(event) {
			let keyCode = event.keyCode ? event.keyCode : event.which
			// only allow number and one dot
			if (
				(keyCode < 48 || keyCode > 57) &&
				(keyCode !== 46 || this.specimenWeight.kg_weight.indexOf(".") != -1)
			) {
				// 46 is dot
				event.preventDefault()
			}
			// restrict to 2 decimal places
			if (
				this.specimenWeight.kg_weight != null &&
				this.specimenWeight.kg_weight.indexOf(".") > -1 &&
				this.specimenWeight.kg_weight.split(".")[1].length > 2
			) {
				event.preventDefault()
			}
		},
	},
}
</script>

<style></style>
