<template>
  <div>
    <HeaderMassivelyChanges
      :selectedSpecimens="S_MASSIVELY_WEIGHTS"
      @save="saveWeightsMassively"
      @selected="selected"
    />
    <transition name="fade" mode="out-in">
      <b-card v-if="!S_MASSIVELY_WEIGHTS.length"
        ><h3>Aun no se seleccionan Ejemplares!!!</h3>
      </b-card>
    </transition>

    <transition name="fade" mode="out-in">
      <b-card v-if="S_MASSIVELY_WEIGHTS.length" class="p-1">
        <b-row class="mb-1">
          <b-col offset-md="3" cols="12" md="8">
            <GlobalChangesWeight :typeWeightOptions="S_TYPE_WEIGHT_OPTIONS" />
          </b-col>
          <b-col
            cols="12"
            md="1"
            class="align-items-center d-flex justify-content-center"
          >
            <feather-icon
              size="25"
              icon="Trash2Icon"
              class="text-danger cursor-pointer"
              @click="
                M_CLEAN_MASSIVELY_WEIGHTS();
                M_CLEAN_ARRAY_IMAGES();
              "
          /></b-col>
        </b-row>

        <ValidationObserver ref="form">
          <b-row
            class="border-3 border rounded pt-75"
            :class="{
              'mb-3': !(index === S_MASSIVELY_WEIGHTS.length - 1),
            }"
            v-for="(specimen, index) in S_MASSIVELY_WEIGHTS"
            :key="index"
          >
            <b-col cols="12" md="3" class="align-items-center d-flex pl-3">
              <SpecimenDetails :specimen="specimen" />
            </b-col>
            <b-col cols="12" md="8">
              <AddSpecimenWeightBody
                :specimenWeight="specimen"
                :typeWeightOptions="S_TYPE_WEIGHT_OPTIONS"
              />
            </b-col>
            <b-col
              cols="12"
              md="1"
              class="align-items-center d-flex justify-content-center pb-50"
            >
              <feather-icon
                size="20"
                icon="Trash2Icon"
                class="text-danger cursor-pointer"
                @click="M_REMOVE_SPECIMEN_WEIGHT(specimen.specimen_id)"
            /></b-col>
          </b-row>
        </ValidationObserver>
      </b-card>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import HeaderMassivelyChanges from "../components/HeaderMassivelyChanges.vue";
import SpecimenDetails from "../components/SpecimenDetails.vue";
import AddSpecimenWeightBody from "../specimens-weight/AddSpecimenWeightBody.vue";
import GlobalChangesWeight from "../specimens-weight/GlobalChangesWeight.vue";
export default {
  name: "WeightsMassively",
  components: {
    HeaderMassivelyChanges,
    AddSpecimenWeightBody,
    SpecimenDetails,
    GlobalChangesWeight,
  },
  async mounted() {
    this.isPreloading();
    if (!this.S_TYPE_WEIGHT_OPTIONS.length) {
      await this.A_GET_TYPE_WEIGHT_OPTIONS();
    }
    this.isPreloading(false);
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("weightSpecimen", [
      "S_MASSIVELY_WEIGHTS",
      "S_TYPE_WEIGHT_OPTIONS",
    ]),
  },
  methods: {
    ...mapActions("weightSpecimen", [
      "A_SAVE_MASSIVELY_WEIGHTS",
      "A_GET_TYPE_WEIGHT_OPTIONS",
    ]),
    ...mapMutations("weightSpecimen", [
      "M_ADD_MASSIVELY_WEIGHTS",
      "M_CLEAN_MASSIVELY_WEIGHTS",
      "M_REMOVE_SPECIMEN_WEIGHT",
    ]),
    ...mapMutations("customLightBox", ["M_CLEAN_ARRAY_IMAGES"]),
    async saveWeightsMassively() {
      const validate = await this.$refs.form.validate();
      if (validate) {
        const confirm = await this.showConfirmSwal();
        if (confirm.value) {
          this.isPreloading();
          await this.A_SAVE_MASSIVELY_WEIGHTS();
          this.showSuccessToast("Los pesos se actualizaron con exito");
          this.$emit("hidden");
          this.isPreloading(false);
        }
      }
    },
    selected(selected) {
      this.M_ADD_MASSIVELY_WEIGHTS(selected);
    },
  },
  destroyed() {
    this.M_CLEAN_MASSIVELY_WEIGHTS();
    this.M_CLEAN_ARRAY_IMAGES();
  },
};
</script>

<style>
</style>