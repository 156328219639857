<template>
	<div class="" :style="{minHeight: '500px'}">
		<!-- <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart> -->
		
        <b-row >
          <b-col md="6" lg="2" class="text-center mb-1">
            <v-select
                v-model="selectYear"
                :options="arrayYears"
                :clearable="false"
                label="name"
                :reduce="(option) => option.id"
                transition=""
                placeholder="Elija el año"
                class="select-year-style"
              />
          </b-col>
        </b-row>
   

        <b-tabs content-class="mt-3" v-model="indexTab">
			<b-tab title="General">
				<div>
					<GraphTournamentTotal v-if="indexTab == 0" :selectYear="selectYear"/>
				</div>
			</b-tab>
			<b-tab title="Acumulado Triunfos">
				<div>
					<GraphTournamentTriunfos v-if="indexTab == 1"  :selectYear="selectYear"/>
				</div>
			</b-tab>
			<b-tab title="Acumulado Derrotas">
				<div>
					<GraphTournamentDerrotas v-if="indexTab == 2" :selectYear="selectYear"/>
				</div>
			</b-tab>
			<b-tab title="Acumulado Empates">
				<div>
					<GraphTournamentEmpates v-if="indexTab == 3" :selectYear="selectYear"/>
				</div>
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import ApexCharts from "apexcharts";
import GraphTournamentTotal from "./GraphTournamentTotal.vue";
import GraphTournamentTriunfos from "./GraphTournamentTriunfos.vue";
import GraphTournamentDerrotas from "./GraphTournamentDerrotas.vue";
import GraphTournamentEmpates from "./GraphTournamentEmpates.vue";

export default {
	name: "GraphTournament",
	components: {
		apexchart: ApexCharts,
		GraphTournamentTotal,
		GraphTournamentTriunfos,
		GraphTournamentDerrotas,
		GraphTournamentEmpates,
	},
	data() {
		return {
			indexTab: 0,
			arrayYears: [],
			selectYear: new Date().getFullYear(),
		};
	},
	async mounted() {
		// await this.init();
		this.initYearsSelect();
	},
	methods: {
		initYearsSelect() {
			let date = new Date();
			let year = date.getFullYear();
			let yearNow = year;
			let arrayYears = [];
			for (let i = year; i >= year - 3; i--) {
				arrayYears.push({
					name: i,
					id: i,
					selected: yearNow == i ? true : false,
				});
			}
			this.arrayYears = arrayYears;
			this.selectYear = yearNow;
		},
	},
	watch: {},
};
</script>
