<template>
	<b-modal
		ref="incidences-modal"
		:title="'Incidencias'"
		centered
		v-model="isActive"
		@hidden="handleHidden"
		size="xmd"
		hide-footer
	>
		<b-overlay :show="isLoading">
			<b-row
				class="mb-1 d-flex align-items-center px-1"
				:class="{ 'justify-content-end': !isAdmin, 'justify-content-between': isAdmin }"
			>
				<b-tabs v-model="indexTab" v-if="isAdmin">
					<b-tab :disabled="false">
						<template #title>Mis incidencias</template>
					</b-tab>
					<b-tab :disabled="false">
						<template #title>
							Otros usuarios
							<b-badge variant="primary" style="margin-left: 0.5rem" v-if="G_INCIDENCES_COUNTER > 0">
								<span class="text-white">{{ G_INCIDENCES_COUNTER }}</span>
							</b-badge>
						</template>
					</b-tab>
				</b-tabs>

				<b-button variant="primary" @click="showRegister = true">Registrar incidencia</b-button>
			</b-row>

			<div v-if="indexTab == 0">
				<TableIncidence :refreshCount="refreshCount" />
			</div>
			<div v-else>
				<b-table-simple responsive sticky-header="60vh" no-border-collapse>
					<b-thead class="sticky-header">
						<b-th class="text-center">
							<span style="position: relative; top: 0.55rem">Usuario</span>
						</b-th>
						<b-th class="text-center">
							<span style="position: relative; top: 0.55rem">Última incidencia</span>
						</b-th>
						<b-th class="text-center">Nuevas incidencias</b-th>
						<b-th class="text-center">
							<span style="position: relative; top: 0.55rem">Accion</span>
						</b-th>
					</b-thead>
					<b-tbody>
						<template v-for="(incidence, index) in incidences" v-if="incidences.length > 0">
							<b-tr :key="index">
								<b-td class="text-center">
									<span>{{ incidence.name }}</span>
								</b-td>

								<b-td class="text-center p-25">
									<div
										class="d-flex justify-content-center align-items-center gap-x-2"
										style="
											width: 550px;
											margin-left: 1rem;
											border-left: 1px solid #d3d2d0;
											border-right: 1px solid #d3d2d0;
										"
									>
										<div style="padding-left: 1rem">
											<SpecimenPlate :specimen="incidence.last_incidence.specimen" />
										</div>
										<div class="d-flex flex-column align-items-start justify-content-center w-100">
											<div class="d-flex gap-x-1">
												<span
													style="width: 90px; font-size: 13px; font-weight: bold"
													class="text-left"
												>
													Fecha
												</span>
												:
												<span>
													{{ incidence.last_incidence.date | myGlobalDateTime }}
												</span>
											</div>
											<div class="d-flex gap-x-1">
												<span
													style="width: 90px; font-size: 13px; font-weight: bold"
													class="text-left"
												>
													Descripcion
												</span>
												:
												<span class="text-left">
													{{
														`${resolveLongText(
															incidence.last_incidence.description
														).toUpperCase()}`
													}}
												</span>
											</div>
										</div>
									</div>
								</b-td>

								<b-td class="text-center p-25">
									<span :class="{ 'text-danger': incidence.counter_not_seen > 0 }">
										{{ incidence.counter_not_seen }}
									</span>
								</b-td>

								<b-td class="text-center">
									<div class="d-flex justify-content-center">
										<b-button
											variant="primary"
											style="width: 140px; white-space: nowrap"
											@click="
												infoDetail = incidence
												showDetail = true
											"
										>
											<feather-icon icon="EyeIcon" class="mr-50" />
											<span>Ver detalle</span>
										</b-button>
									</div>
								</b-td>
							</b-tr>
						</template>
						<b-tr v-if="incidences.length == 0">
							<b-td colspan="10" class="text-center" style="height: 80px">
								No hay registros para mostrar
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</b-overlay>

		<RegisterIncidenceModal v-if="showRegister" @closing="showRegister = false" @refresh="refreshCount += 1" />

		<IncidenceDetailModal
			v-if="showDetail"
			:info="infoDetail"
			@closing="
				showDetail = false
				getUsersWithIncidences()
			"
		/>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal"
import RegisterIncidenceModal from "@/views/amg/header-bookmarks/incidences/RegisterIncidenceModal.vue"
import IncidencesService from "@/services/incidences.service"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import ViewImage from "@/components/commons/ViewImage.vue"
import IncidenceDetailModal from "@/views/amg/header-bookmarks/incidences/IncidenceDetailModal.vue"
import TableIncidence from "@/views/amg/header-bookmarks/incidences/TableIncidence.vue"
import { mapGetters, mapActions } from "vuex"

export default {
	components: { RegisterIncidenceModal, SpecimenPlate, ViewImage, IncidenceDetailModal, TableIncidence },
	mixins: [modalMixin],
	props: {
		show: Boolean,
		info: Object,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			indexTab: 0,
			showRegister: false,
			showDetail: false,
			infoDetail: null,
			incidences: [],
			perPageOptions: [10, 25, 50, 100],
			pagination: {
				total: 0,
				curPage: 1,
				perPage: 25,
				from: 0,
				to: 0,
			},
			refreshCount: null,
		}
	},
	async created() {
		await this.A_GET_INCIDENCES_COUNTER()
	},
	computed: {
		...mapGetters({
			G_INCIDENCES_COUNTER: "commons/G_GET_INCIDENCES_COUNTER",
		}),
	},
	methods: {
		...mapActions({
			A_GET_INCIDENCES_COUNTER: "commons/A_GET_INCIDENCES_COUNTER",
		}),
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},
		resolveLongText(text) {
			if (text.length > (this.indexTab == 0 ? 40 : 25))
				return `${text.substring(0, this.indexTab == 0 ? 40 : 25)} ....`
			return text
		},

		async getUsersWithIncidences() {
			this.incidences = []
			this.isPreloading()
			try {
				const { data } = await IncidencesService.getUsersWithIncidences()
				this.incidences = data
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
	watch: {
		indexTab: async function (val, oldVal) {
			if (val != null) {
				await this.getUsersWithIncidences()
			}
		},
	},
	async mounted() {
		this.toggleModal("incidences-modal")
	},
}
</script>

<style lang="scss" scoped>
.img-specimen {
	width: 50px;
	height: 50px;
	// margin-right: 1rem;
	// border-radius: 8px;
	// box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	// padding: 0.4rem;
	object-fit: cover;
	&:hover {
		background-color: rgba(0, 0, 0, 0.7);
	}
	cursor: pointer;
	@media screen and (min-width: 700px) {
		width: 70px;
		height: 70px;
		// background: red;
	}
}
</style>
