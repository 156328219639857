<template>
  <b-row>
    <b-col cols="12" md="6" >
      <b-form-group label="Tipo">
        <template #label>
          <div class="d-flex justify-content-between align-items-center">
            <span>Tipo</span>
            <span class="clickable" @click="addTypeWeight = true">
              <feather-icon icon="PlusIcon" />
            </span>
          </div>
        </template>
        <b-form-select
          v-model="globalTypeWeight"
          :options="typeWeightOptions"
          value-field="id"
          text-field="description"
          class="text-center"
          @change="changeGlobalWeight('type_weight', globalTypeWeight)"
        />
      </b-form-group>
    </b-col>

    <b-col cols="12" md="6">
      <b-form-group label="Fecha">
        <flat-pickr
          id="dob"
          v-model="globalDateWeight"
          placeholder="Elige una Fecha"
          class="form-control bg-transparent"
          :config="config"
          @input="changeGlobalWeight('date_weight', globalDateWeight)"
        />
      </b-form-group>
    </b-col>
    <ModalAddTypeWeight v-if="addTypeWeight" @hidden="addTypeWeight = false" />
  </b-row>
</template>

<script>
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import ModalAddTypeWeight from "./ModalAddTypeWeight.vue";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { mapMutations } from "vuex";
export default {
  name: "GlobalChangesWeight",
  components: {
    ModalAddTypeWeight,
    flatPickr,
  },
  props: {
    typeWeightOptions: Array,
  },
  mounted() {},
  data() {
    return {
      config: {
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        locale: Spanish,
      },
      addTypeWeight: false,
      globalTypeWeight: null,
      globalDateWeight: moment().format("YYYY-MM-DD"),
    };
  },
  methods: {
    ...mapMutations("weightSpecimen", ["M_GLOBAL_CHANGES_WEIGHT"]),
    changeGlobalWeight(key, value) {
      this.M_GLOBAL_CHANGES_WEIGHT({
        key,
        value,
      });
    },
  },
};
</script>

<style>
</style>
