<template>
	<b-modal ref="modal-tracking-status" centered hide-footer :title="getTitle" size="lg" @hidden="handleHidden">
		<div>
			<p class="font-weight-bold">Tracking de Etapas</p>
			<b-table-simple responsive sticky-header="50vh" no-border-collapse>
				<b-thead class="">
					<b-th class="text-center">Estado</b-th>
					<b-th class="text-center">Nombre</b-th>
					<b-th class="text-center">Fecha</b-th>
				</b-thead>
				<b-tbody>
					<template v-for="(data, index) in statuses">
						<b-tr :key="index">
							<b-td class="text-center">
								<b-badge style="text-transform: uppercase" :variant="resolveVariant(data.status)">
									{{ data.status }}
								</b-badge>
							</b-td>
							<b-td class="text-center">
								<span>{{ data.user_name }}</span>
							</b-td>
							<b-td class="text-center">
								<span>{{ data.created_at | myGlobalDayShort }}</span>
							</b-td>
						</b-tr>
					</template>
				</b-tbody>
			</b-table-simple>
			<p class="text-center" v-if="statuses.length === 0">No hay registros para mostrar</p>
		</div>
	</b-modal>
</template>

<script>
import RecommendedSpecimenService from "@/services/preparacion/events/recommendedSpecimen.service.js"
import modalMixin from "@/mixins/modal"
import moment from "moment"

export default {
	components: {},
	mixins: [modalMixin],
	props: {
		show: Boolean,
		info: Object,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			statuses: [],
		}
	},
	async created() {
		await this.getTracking()
	},
	computed: {
		getTitle() {
			return `${this.info.tournament.name} | ${moment(this.info.date).format("MM/DD/YYY")}`
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},

		async getTracking() {
			this.isPreloading()
			try {
				const { data } = await RecommendedSpecimenService.getTrackingStatus({ id: this.info.id })
				this.statuses = data.data
			} catch (error) {
				console.log("error: ", error)
			} finally {
				this.isPreloading(false)
			}
		},

		resolveVariant(tracking) {
			if (tracking) {
				if (tracking === "en proceso") {
					return "light-dark"
				} else if (tracking === "enviado") {
					return "light-warning"
				} else if (tracking === "retornado") {
					return "light-danger"
				} else {
					return "light-primary"
				}
			} else {
				return "light-dark"
			}
		},
	},
	async mounted() {
		this.toggleModal("modal-tracking-status")
	},
}
</script>
