<template>
	<div class="NotesList">
		<b-overlay :show="isLoading" :variant="skin">
			<div class="NotesList__ctitle">
				<span class="NotesList__title">Mis Notas ({{ notes.length }})</span>

				<div class="d-flex">
					<button class="NotesList__maximize" @click="heightContainer = !heightContainer">
						<feather-icon :icon="!heightContainer ? 'SquareIcon' : 'MinusIcon'" size="16" />
					</button>
					<button class="NotesList__close" @click="showNotesList = false">
						<feather-icon icon="XIcon" size="16" />
					</button>
				</div>
			</div>
			<p v-if="notes.length < 1">Aun no hay notas por mostrar</p>
			<div class="NotesList__list" :class="[heightContainer ? '' : 'active']">
				<div
					class="Item"
					v-for="note in notes"
					:style="{ 'border-left-color': note.color_light }"
					:key="note.updated_at"
				>
					<div>
						<span class="Item__title">{{ note.title }}</span
						><br />
						<span class="Item__date"
							><FeatherIcon icon="Edit2Icon" size="12" class="mb-25" />
							{{ formatDate(note.updated_at) }}</span
						>
					</div>
					<div class="Item__actions">
						<button class="Item__action Item__action--watch" @click="watchNote(note.id)">
							<feather-icon icon="EyeIcon" size="16" />
						</button>
						<button class="Item__action" @click="deleteNote(note.id)">
							<feather-icon icon="TrashIcon" size="16" />
						</button>
					</div>
				</div>
			</div>

			<button class="NotesList__create" @click="createNote">
				<FeatherIcon icon="PlusIcon" size="18" class="mr-25" />
				AGREGAR
			</button>
		</b-overlay>
	</div>
</template>

<script>
import { ref, watch } from "@vue/composition-api";
import moment from "moment";

import useAppConfig from "@core/app-config/useAppConfig";
import stickyNotesService from "@/services/header/sticky-notes.service.js";
import useStickyNotes from "@/views/amg/header-bookmarks/sticky-notes/useStickyNotes";

export default {
	setup() {
		const { showNotesList, activeNote, noteCreatedTrigger } = useStickyNotes();

		// overlay
		const { skin } = useAppConfig();
		let isLoading = ref(false);

		let heightContainer = ref(true);

		// load notes
		let notes = ref([]);
		async function getNotes() {
			isLoading.value = true;
			let data = await stickyNotesService.getNotes();
			notes.value = data.data;
			isLoading.value = false;
			console.log('data', data);
		}
		getNotes();

		function formatDate(date) {
			return moment(date).format("MM/DD/YY");
		}

		function watchNote(id) {
			if (activeNote.show) {
				// reassign to prevent asyncrony bugs
				activeNote.id = null;
				activeNote.show = false;
				setTimeout(() => {
					activeNote.id = id;
					activeNote.show = true;
				}, 10);
				return;
			}
			activeNote.id = id;
			activeNote.show = true;
		}

		function createNote() {
			if (activeNote.show) return;
			activeNote.id = null;
			activeNote.show = true;
		}

		async function deleteNote(id) {
			const { isConfirmed } = await this.showConfirmSwal();
			// this.showConfirmSwal({ text: "Se borrara la nota" });
			if (!isConfirmed) return 0;
			let body = {
				id: id,
			};
			isLoading.value = true;
			await stickyNotesService.deleteNote(body);
			getNotes();
		}

		// reload on new
		watch(noteCreatedTrigger, (cur, old) => {
			getNotes();
		});

		return {
			showNotesList,
			activeNote,
			isLoading,
			skin,
			notes,
			createNote,
			watchNote,
			deleteNote,
			getNotes,
			formatDate,
			heightContainer,
		};
	},
};
</script>

<style lang="scss" scoped>
.NotesList {
	position: fixed;
	right: 1rem;
	bottom: 1rem;
	min-width: 15rem;
	padding: 1rem;
	background: var(--darken);
	border-radius: 0.5rem;
	box-shadow: 0 0 1rem rgba($color: #000000, $alpha: 0.5);
	z-index: 9999;

	@media screen and (max-width: 768px) {
		right: 2rem;
		bottom: 2rem;
	}

	&__create {
		// display: block;
		width: 100%;
		padding: 0.5rem;
		background: var(--primary);
		color: var(--light);
		border: none;
		border-radius: 0.5rem;
		font-size: 1rem;
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__ctitle {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1rem;
	}

	&__title {
		display: block;
		font-size: 18px;
		font-weight: 600;
		// margin-bottom: 1rem;
	}

	&__close {
		top: -0.5rem;
		right: -0.5rem;
		height: 2rem;
		width: 2rem;
		background: var(--danger);
		color: var(--light);
		border: none;
		border-radius: 0.5rem;
		font-weight: 600;
		margin-left: 0.3rem;

		display: flex;
		align-items: center;
		justify-content: center;
	}
	&__maximize {
		top: -0.5rem;
		right: -0.5rem;
		height: 2rem;
		width: 2rem;
		background: var(--secondary);
		color: var(--light);
		border: none;
		border-radius: 0.5rem;
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__list {
		max-height: 72vh;
		margin-bottom: 1rem;
		overflow-y: auto;
		overflow-x: hidden;
		transition: 0.3s all ease-in-out;
		@media screen and (min-width: 700px) {
			max-height: 72vh;
		}

		&::-webkit-scrollbar {
			width: 4px;
			background: var(--gray);
		}

		&::-webkit-scrollbar-thumb {
			background: var(--primary);
			border-radius: 0.5rem;
		}
	}
	.active {
		max-height: 0;
	}
}

.Item {
	display: flex;
	gap: 3rem;
	justify-content: space-between;
	margin-bottom: 1rem;
	padding: 1rem;
	background: var(--darkest);
	color: var(--dark);
	border-radius: 0.5rem;
	border-left: 0.3rem solid #000;
	&:last-child {
		margin-bottom: 0;
	}

	&__title {
		display: inline-block;
		max-width: 8rem;
		color: var(--dark);
		font-size: 1rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: 500;
		// background: green;
	}

	&__date {
		display: inline-block;
		margin-top: 0.5rem;
		color: var(--secondary);
		font-size: 0.9rem;
	}

	&__actions {
		display: flex;
		gap: 0.5rem;
		align-items: center;
		justify-content: center;
	}

	&__action {
		height: 2rem;
		width: 2rem;
		background: var(--danger);
		color: var(--light);
		border: none;
		border-radius: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;

		&--watch {
			background: var(--primary);
		}
	}
}

.dark-layout {
	.Note {
		color: var(--light);
	}
	.Item {
		&__title {
			color: var(--light);
		}
	}
}
</style>
