<template>
	<div class="grid-reports-tournament" :class="[!month ? 'mb-8' : '']">
		<div class="container-table-result-tournament">
			<div class="mb-2 container-header">
				<div class="container-body-header">
					<div class="container-select-month">
						<div class="d-flex align-items-center">
							<p
								class="page-btn"
								:class="[pageActive == 1 ? 'disabled' : null]"
								@click="changePage(pageIn - 1)"
							>
								<feather-icon icon="ChevronLeftIcon" size="20" />
							</p>
							<div class="container-btns">
								<p
									@click="changePage(num)"
									class="page-btn"
									:class="[pageActive == num ? 'active' : null]"
									v-for="(num, index) in pages"
									:key="index"
								>
									{{ num }}
								</p>
							</div>
							<p
								class="page-btn"
								:class="[pageActive == totalPages ? 'disabled' : null]"
								@click="changePage(pageIn + 1)"
							>
								<feather-icon icon="ChevronRightIcon" size="20" />
							</p>
						</div>
						<div v-if="!plate" class="align-items-center container-select-date">
							<v-select
								v-model="selectMonth"
								:options="arrayMonths"
								:clearable="false"
								label="name"
								:reduce="(option) => option.id"
								transition=""
								placeholder="Elija el mes"
								@input="refreshCalendar()"
							/>
							<v-select
								v-model="selectYear"
								:options="arrayYears"
								:clearable="false"
								label="name"
								:reduce="(option) => option.id"
								transition=""
								placeholder="Elija el año"
								class="select-year-style"
								@input="refreshCalendar()"
							/>
							<!-- <div class="container-picker-date">
               <b-form-datepicker id="example-datepicker" v-model="value" class="mb-2"></b-form-datepicker>
              </div>
              <flat-pickr v-model="rangeDate" class="form-control" :config="{ mode: 'range' }"
                placeholder="Seleccionar fechas" /> -->

							<div class="d-flex align-items-center justify-content-center">
								<feather-icon
									class="icon"
									icon="RefreshCwIcon"
									@click="refreshCalendar()"
									:class="[clickIcon ? 'icon-active' : '']"
								/>
							</div>
							<!-- <div class="d-flex align-items-center justify-content-center">
                <feather-icon class="icon icon-trans" icon="TrashIcon" @click="resetFilters()" />
              </div> -->
						</div>
					</div>

					<div class="container-search-header-tournament">
						<input
							v-if="!plate"
							type="text"
							class="form-control"
							placeholder="Buscar placa"
							v-model="campo"
							v-on:keyup.enter="keyEnter"
						/>
						<button
							v-if="!month"
							@click="sidebarFilters = !sidebarFilters"
							class="btn btn-outline-primary btn-buscar-placa"
						>
							<feather-icon icon="FilterIcon" />
						</button>
					</div>
				</div>
			</div>

			<div class="m-auto card content-table">
				<div class="container-table-reports header-table" :class="[!resultsNow ? 'header-sticky' : '']">
					<div class="header-table-icon p-1">
						<p>#</p>
					</div>
					<div class="header-table-icon p-1">
						<p>Ejemplar</p>
					</div>

					<div class="header-table-icon pt-1 pb-1">
						<p>Fecha</p>
					</div>

					<div class="header-table-icon pt-1 pb-1">
						<p>Resultado</p>
					</div>

					<div class="header-table-icon pt-1 pb-1">
						<p>Rival</p>
					</div>

					<div class="header-table-icon pt-1 pb-1">
						<p>Torneo</p>
					</div>

					<div class="header-table-icon pt-1 pb-1">
						<p>Video</p>
					</div>

					<!-- <div class="header-table-icon pt-1 pb-1">
            <p>Opciones</p>
          </div> -->
				</div>

				<template v-if="data.length > 0 && loadingData == false">
					<div
						class="container-table-reports body-table"
						v-for="(item, index) in data"
						:key="index"
						:class="[
							item.total_effect >= 50 && item.results.total_effect != 'Sin jugar' ? 'table-1' : '',
							item.total_effect >= 25 && item.total_effect < 50
								? 'table-2' && item.results.total_effect != 'Sin jugar'
								: '',
							item.total_effect < 25 && item.results.total_effect != 'Sin jugar' ? 'table-3' : '',
						]"
					>
						<div class="d-flex align-items-center justify-content-center body-index pt-1 pb-1">
							<p class="number-report">{{ index + 1 }}</p>
						</div>

						<div class="d-flex flex-column align-items-center justify-content-center body-cell pt-1 pb-1">
							<SpecimenPlate
								:specimen="{
									plate: item.padrillo.plate,
									alias: item.padrillo.alias,
									id: item.padrillo.id,
									thumb: item.padrillo.preload,
									image: item.padrillo.image,
								}"
							/>
						</div>

						<div class="d-flex flex-column align-items-center justify-content-center body-cell pt-1 pb-1">
							<p class="number-report">
								{{ item.date | myGlobalDayShort }}
							</p>
						</div>

						<div class="d-flex flex-column align-items-center justify-content-center body-cell pt-1 pb-1">
							<p
								class="number-report"
								:class="[
									item.result == 'DERROTA'
										? 'danger-result'
										: item.result == 'TRIUNFO'
										? 'success-result'
										: 'info-result',
								]"
							>
								{{ item.result }}
							</p>
						</div>

						<div class="d-flex flex-column align-items-center justify-content-center body-cell p-1">
							<p class="number-report">
								{{ item.rival }}
							</p>
						</div>

						<div class="d-flex flex-column align-items-center justify-content-center body-cell p-1">
							<p class="number-report">
								{{ item.tournament.name }}
							</p>
							<feather-icon
								@click="linkTournament(item.tournament.id)"
								icon="ExternalLinkIcon"
								size="20"
								class="cursor-pointer text-primary"
							/>
						</div>

						<div class="d-flex flex-column align-items-center justify-content-center body-cell pt-1 pb-1">
							<img
								v-if="item.video.thumb && item.video.status == 2"
								:src="item.video.thumb"
								class="image-thumb"
								:alt="item.padrillo.alias"
								@click="
									selectFile(
										item.video.url,
										'video',
										`${item.padrillo.alias == null ? '' : `${item.padrillo.alias} -`} (${
											item.padrillo.plate && item.padrillo.plate
										}) ${item.rival != 'Sin rival' ? `VS ${item.rival}` : ''}`,
										`/ejemplares/detalle/${item.padrillo.id}`
									)
								"
							/>
							<p v-else-if="item.video.thumb && item.video.status == 1" class="mb-0">Procesando video</p>
							<div v-else>
								<p class="mb-0">Sin video</p>
								<!-- <feather-icon
                  @click="selectBattle(item)"
                  icon="PlusIcon"
                  size="16"
                  class="cursor-pointer text-primary"
                /> -->

								<span
									v-if="item.video.status == '2' && item.video.url"
									@click="viewSpecimen(item, index)"
									style="cursor: pointer"
								>
									<b-avatar
										:src="item.video.thumb"
										rounded
										size="80"
										icon="film"
										badge
										badge-variant="danger"
									>
										<template #badge>{{ item.video.duration }}</template>
									</b-avatar>
								</span>
								<div v-else-if="!item.video.url">
									<feather-icon
										@click="selectBattle(item)"
										class="icon cursor-pointer"
										icon="PlusIcon"
										size="20"
									/>
								</div>
								<p v-else class="mb-0">Procesando video</p>
							</div>
						</div>

						<!-- <div
              class="d-flex align-items-center justify-content-center body-cell pt-1 pb-1"
            >
              <feather-icon
                class="cursor-pointer icon-eye"
                icon="EyeIcon"
                color="#28A745"
                :size="width < 600 ? '16' : '20'"
                @click="selectPostura(item, 2)"
              />
              <smart-icon
                @click="
                  toggleFavorite(item.padrillo.id, item.madrilla.id, index)
                "
                v-if="item.favorite"
                color="#ff9f43"
                class="cursor-pointer"
                icon="Star1Icon"
                :size="width < 600 ? '18' : '24'"
              />
              <feather-icon
                @click="
                  toggleFavorite(item.padrillo.id, item.madrilla.id, index)
                "
                v-else
                class="icon text-warning cursor-pointer"
                icon="StarIcon"
                :size="width < 600 ? '16' : '20'"
              />
            </div> -->
					</div>
				</template>

				<template v-else-if="loadingData">
					<div class="data-null">
						<div class="loadingio-spinner-reload-r3yh8h7kig">
							<div class="ldio-m8h18h0i76f">
								<div>
									<div></div>
									<div></div>
									<div></div>
								</div>
							</div>
						</div>
						<p>Cargando...</p>
					</div>
				</template>

				<template v-else>
					<div class="data-null">
						<p>Sin datos encontrados</p>
					</div>
				</template>
			</div>
		</div>

		<!-- <GraphTournament/> -->

		<div class="container-results-total" :class="[resultsNow ? 'now-results-top' : '']">
			<div class="result-data-total" :class="[resultsNow ? 'not-sticky' : '']">
				<b-card border-variant="primary">
					<div v-if="counters" class="container-grid-table-result">
						<div class="grid-table-result">
							<p class="font-weight-bolder">RESUMEN</p>
							<p class="font-weight-bolder">
								{{ arrayMonths.find((item) => item.id == selectMonth).name }}
							</p>
							<p class="font-weight-bolder">2022</p>
						</div>
						<div class="grid-table-result">
							<p class="font-weight-bolder">JUGADAS</p>
							<p class="font-weight-bolder">{{ counters ? counters[0].total : 0 }}</p>
							<p class="font-weight-bolder">{{ counters ? counters[1].total : 0 }}</p>
						</div>
						<div class="grid-table-result">
							<p class="font-weight-bolder">TRIUNFOS</p>
							<p class="text-success">{{ counters ? counters[0].triunfos : 0 }}</p>
							<p class="text-success">{{ counters ? counters[1].triunfos : 0 }}</p>
						</div>
						<div class="grid-table-result">
							<p class="font-weight-bolder">EMPATES</p>
							<p class="text-info">{{ counters ? counters[0].empates : 0 }}</p>
							<p class="text-info">{{ counters ? counters[1].empates : 0 }}</p>
						</div>
						<div class="grid-table-result">
							<p class="font-weight-bolder">DERROTAS</p>
							<p class="text-danger">{{ counters ? counters[0].derrotas : 0 }}</p>
							<p class="text-danger">{{ counters ? counters[1].derrotas : 0 }}</p>
						</div>
						<div class="grid-table-result">
							<p class="font-weight-bolder">EFECTIVIDAD</p>
							<p>{{ counters ? counters[0].triunfos_percent : "-" }}%</p>
							<p>{{ counters[1].triunfos_percent }}%</p>
						</div>
					</div>
				</b-card>

				<p class="item-result font-small-1 mb-0">* Efectividad: Triunfos / Jugadas</p>
			</div>
		</div>

		<b-sidebar v-model="sidebarFilters">
			<div class="container-sidebar">
				<h2 class="mb-2">Filtros de reporte de torneos</h2>
				<div class="form-control-input">
					<p class="mb-0">Fecha inicial:</p>
					<b-form-datepicker
						placeholder="Fecha inicial"
						:date-format-options="{
							year: 'numeric',
							month: 'numeric',
							day: 'numeric',
						}"
						v-model="date1In"
					/>
					<!-- <flat-pickr v-model="rangeDate" class="form-control" :config="{ mode: 'range' }"
            placeholder="Seleccionar fe chas" style="width: 100% !important;" /> -->
				</div>
				<div class="form-control-input">
					<p class="mb-0">Fecha final:</p>
					<b-form-datepicker
						placeholder="Fecha final"
						:date-format-options="{
							year: 'numeric',
							month: 'numeric',
							day: 'numeric',
						}"
						v-model="date2In"
					/>
				</div>
				<div class="form-control-input">
					<p class="mb-0">Torneo:</p>
					<v-select
						v-model="tournamentIn"
						:options="tournaments"
						:clearable="false"
						label="name"
						:reduce="(option) => option.id"
						transition=""
						placeholder="Elija el torneo"
					/>
				</div>
				<div class="form-control-input">
					<p class="mb-0">Resultado:</p>
					<v-select
						v-model="resultIn"
						:options="resultsArray"
						:clearable="false"
						label="name"
						:reduce="(option) => option.id"
						transition=""
						placeholder="Elija el resultado"
					/>
				</div>
				<div class="form-control-input">
					<p class="mb-0">Origen de ejemplar:</p>
					<v-select
						v-model="originIn"
						:options="originArray"
						:clearable="false"
						label="name"
						:reduce="(option) => option.id"
						transition=""
						placeholder="Elija el origen"
					/>
				</div>

				<b-form-group label="Rival" label-for="ejemplar">
					<v-select
						v-model="participant"
						:options="participantArray"
						:clearable="false"
						label="label"
						:reduce="(option) => option.value"
						transition=""
						placeholder="Elija el rival"
						@search="searchParticipants"
					>
						<template slot="no-options"> Sin ejemplares encontrados </template>

						<template slot="option" slot-scope="option">
							<div class="d-center">
								{{ option.label }}
							</div>
						</template>

						<template slot="selected-option" slot-scope="option">
							<div class="selected d-center">
								{{ option.label }}
							</div>
						</template>
					</v-select>
				</b-form-group>

				<button @click="keyEnter" class="btn btn-primary btn-buscar-pl mt-2">Filtrar datos</button>
				<button @click="resetFilters()" class="btn btn-outline-info btn-buscar-pl mt-2 ml-1">
					Reset filtros
				</button>
			</div>
		</b-sidebar>

		<SlidersImageVideo
			v-if="dataSelectVideo.open"
			:open="dataSelectVideo.open"
			:url="dataSelectVideo.url"
			:text="dataSelectVideo.text"
			:text_url="dataSelectVideo.text_url"
			:type="dataSelectVideo.type"
			@refreshSlider="refreshSlider"
		/>

		<b-modal v-if="selectUploadVideo" title="Cargar video" v-model="modalUploadVideo" hide-footer>
      <UploadVideoBattle v-if="selectUploadVideo" @resetTable="refresh" :item="selectUploadVideo" />
		</b-modal>
	</div>
</template>

<script>
import SlidersImageVideo from "@/views/amg/header-bookmarks/SlidersImageVideo.vue";
// import UploadVideoBattle from "./UploadVideoBattle.vue";
// import UploadVideoBattle from "./UploadVideoBattle.vue";
import UploadVideoBattle from "@/views/amg/videolibrary/UploadVideoBattle.vue";
// import participantService from "@/views/brain/participants/participants.service";
import participantService from "@/views/brain/tournament/views/participants/participants.service";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";
import { getReportsByTournament, getTournaments } from "@/services/reports.service";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import FlatPickr from "vue-flatpickr-component";
import router from "@/router";
import GraphTournament from "./GraphTournament.vue";

export default {
	name: "ReportsTournamentsComponent",
	props: {
		resultsNow: {
			type: Boolean,
			default: false,
		},
		month: {
			type: String,
		},
		dayIn: {
			type: String,
		},
		dayFn: {
			type: String,
		},
		plate: {
			type: String,
		},
	},
	setup() {
		return {
			avatarText,
		};
	},
	components: {
		vSelect,
		SlidersImageVideo,
		UploadVideoBattle,
		FlatPickr,
		SpecimenPlate,
		GraphTournament,
	},
	data() {
		return {
			data: [],
			width: this.$store.state["app"].windowWidth,
			campo: null,
			pages: [],
			pageActive: 1,
			totalPages: null,
			loadingData: false,
			date1In: null,
			date2In: null,
			tournamentIn: null,
			resultIn: null,
			originIn: null,
			pageIn: 1,
			perPageIn: 50,
			result: null,
			resultFilter: null,
			tournaments: [
				{
					id: null,
					name: "TODOS",
				},
			],
			resultsArray: [
				{
					id: null,
					name: "TODOS",
				},
				{
					id: "TRIUNFO",
					name: "TRIUNFO",
				},
				{
					id: "EMPATE",
					name: "EMPATE",
				},
				{
					id: "DERROTA",
					name: "DERROTA",
				},
			],
			originArray: [
				{
					id: null,
					name: "TODOS",
				},
				{
					id: 1,
					name: "CAMADA",
				},
				{
					id: 2,
					name: "COMPRADO",
				},
			],
			participant: null,
			participantArray: [],
			sidebarFilters: false,
			dataSelectVideo: {
				url: null,
				type: null,
				text: null,
				text_url: null,
				open: false,
			},
			modalUploadVideo: false,
			selectUploadVideo: null,
			arrayMonths: [
				{
					name: "Enero",
					id: 1,
				},
				{
					name: "Febrero",
					id: 2,
				},
				{
					name: "Marzo",
					id: 3,
				},
				{
					name: "Abril",
					id: 4,
				},
				{
					name: "Mayo",
					id: 5,
				},
				{
					name: "Junio",
					id: 6,
				},
				{
					name: "Julio",
					id: 7,
				},
				{
					name: "Agosto",
					id: 8,
				},
				{
					name: "Setiembre",
					id: 9,
				},
				{
					name: "Octubre",
					id: 10,
				},
				{
					name: "Noviembre",
					id: 11,
				},
				{
					name: "Diciembre",
					id: 12,
				},
			],
			arrayYears: [],
			selectMonth: new Date().getMonth() + 1,
			selectYear: null,
			clickIcon: false,
			rangeDate: null,
			dateYearIn: new Date().getFullYear(),
			counters: [
				{
					derrotas: "1",
					empates: "0",
					total: 1,
					triunfos: "0",
					triunfos_percent: "0.00",
				},
				{
					derrotas: "1",
					empates: "0",
					total: 1,
					triunfos: "0",
					triunfos_percent: "0.00",
				},
			],
			dashboardSpecimen: false,
		};
	},
	async mounted() {
		if ((router.currentRoute.name == "detalle-ejemplar" && !this.resultsNow) || this.plate) {
			this.dashboardSpecimen = true;
		}
		this.initYearsSelect();
		await this.getTournamentsTotal();

		// console.log("Participantes", resp);
		if (this.$route.query.torneo) {
			this.tournamentIn = parseInt(this.$route.query.torneo);
		}
		// if (this.$route.query.date1 && this.$route.query.date1) {
		//   this.date1In = this.$route.query.date1;
		//   this.date2In = this.$route.query.date2;
		// }
		// if (this.$route.query.month) {
		//   this.resultsNow = this.$route.query.month;
		// }
		if (this.dayIn && this.dayFn) {
			this.date1In = this.dayIn;
			this.date2In = this.dayFn;
		}

		if (this.plate) {
			this.campo = this.plate;
		}

		// console.log("torneos: ", this.tournaments);
		await this.refreshCalendar();

		// console.log("Params: ", this.$route.query);
	},
	methods: {
		async init() {
			const dateArray = this.rangeDate && this.rangeDate.split(" to ");
			this.loadingData = true;
			const {
				data: {
					paginator: { data, last_page, current_page },
					counters,
				},
			} = await getReportsByTournament({
				date1In: (dateArray && dateArray[0]) || this.date1In,
				date2In: (dateArray && dateArray[1]) || this.date2In,
				tournamentIn: this.tournamentIn,
				resultIn: this.resultIn,
				originIn: this.originIn,
				pageIn: this.pageIn,
				perPageIn: this.perPageIn,
				plateIn: this.campo,
				participantIn: this.participant,
				dateYearIn: this.dateYearIn.toString(),
				dashboardSpecimen: this.dashboardSpecimen,
			});
			//resultFilter
			this.data = data;
			this.counters = counters;

			this.pages = this.createPages(last_page);
			this.pageActive = current_page;
			this.totalPages = last_page;
			this.loadingData = false;
		},
		createPages(num) {
			let pages = [];
			for (let i = 1; i <= num; i++) {
				pages.push(i);
			}
			return pages;
		},
		changePage(page) {
			if (page >= 1 && page <= this.totalPages) {
				this.pageIn = page;
				this.init();
			}
		},
		keyEnter() {
			this.pageIn = 1;
			this.init();
			this.sidebarFilters = false;
		},
		async getTournamentsTotal() {
			const { data } = await getTournaments();
			this.tournaments = [...this.tournaments, ...data];
		},
		linkSpecimen(id) {
			window.open(`/ejemplares/detalle/${id}`, "_blank");
		},
		linkTournament(id) {
			window.open(`/torneos/detalle/${id}`, "_blank");
		},
		selectFile(url, type, text, text_url) {
			console.log("image: ", url);
			this.dataSelectVideo.url = url;
			this.dataSelectVideo.type = type;
			this.dataSelectVideo.text = text;
			this.dataSelectVideo.text_url = text_url;
			this.dataSelectVideo.open = true;
		},
		refreshSlider() {
			this.dataSelectVideo.open = false;
		},
		selectBattle(item) {
			this.modalUploadVideo = true;
			console.log("id: ", item);
			this.selectUploadVideo = item;
      // this.selectUploadVideo
		},
		async refresh() {
			this.modalUploadVideo = false;
			await this.init();
		},
		async resetFilters() {
			this.date1In = null;
			this.date2In = null;
			this.tournamentIn = null;
			this.resultIn = null;
			this.originIn = null;
			this.selectMonth = new Date().getMonth() + 1;
			this.selectYear = new Date().getFullYear();
			this.participant = null;
			await this.init();
		},
		async refreshCalendar() {
			this.clickIcon = true;
			if (this.selectMonth && this.selectYear) {
				const date1 = `${this.selectYear}-${
					this.selectMonth < 10 ? `0${this.selectMonth}` : this.selectMonth
				}-01`;
				const totalDaysNow = new Date(this.selectYear, this.selectMonth, 0).getDate();
				const date2 = `${this.selectYear}-${
					this.selectMonth < 10 ? `0${this.selectMonth}` : this.selectMonth
				}-${totalDaysNow}`;
				this.date1In = date1;
				this.date2In = date2;
				this.init();
			} else {
				this.showToast(
					"warning",
					"top-right",
					"Buscador por mes",
					"CheckIcon",
					"Para buscar por mes y año, seleccione un mes y un año"
				);
			}
			// await this.init();
			setTimeout(() => {
				this.clickIcon = false;
			}, 1000);
		},
		initYearsSelect() {
			let date = new Date();
			let year = date.getFullYear();
			let yearNow = year;
			let arrayYears = [];
			for (let i = year; i >= year - 5; i--) {
				arrayYears.push({
					name: i,
					id: i,
					selected: yearNow == i ? true : false,
				});
			}
			this.arrayYears = arrayYears;
			this.selectYear = yearNow;
		},
		async searchParticipants(search, loading) {
			clearTimeout(this.debounce);
			this.debounce = setTimeout(async () => {
				loading(true);
				if (search.length > 1) {
					const {
						data: { data },
					} = await participantService.getContactsToStatus({
						pageIn: 1,
						perPageIn: 50,
						search: search,
						status: 0,
					});
					// console.log("DATA: ", data);
					this.participantArray = data.map((item) => {
						return {
							label: item.participant_name,
							value: item.id,
						};
					});
				}
				loading(false);
			}, 300);
		},
	},
};
</script>

<style scoped lang="scss">
.container-header {
	background: #fff;
	padding: 1rem;
	border-radius: 10px;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
	transition: 0.1s all ease-in-out;

	@media screen and (min-width: 700px) {
		padding: 1rem;
	}

	.page-btn {
		height: 32px;
		width: 32px;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.3s all ease-in-out;
		border-radius: 32px;
		cursor: pointer;
		background: #f3f2f7;
	}

	.container-btns {
		margin: 0 0.4rem;
		width: auto;
		background: #f3f2f7;
		height: 32px;
		border-radius: 16px;
		display: flex;
		align-items: center;
		transition: 0.1s all ease-in-out;

		.active {
			background: #7367f0;
			color: #fff;
		}
	}

	.disabled {
		color: #d5d5d5;
	}
}

.dark-layout {
	.container-header {
		background: #283046;

		.page-btn {
			background: #242b3d;
		}

		.container-btns {
			background: #242b3d;

			.active {
				background: #7367f0;
				color: #fff;
			}
		}
	}
}

.content-table {
	min-width: 100%;
	background: #fff;

	border-radius: 10px;
	overflow-x: scroll;

	// overflow-y: scroll;
	// height: 43.5vh;
	@media screen and (min-width: 1000px) {
		overflow-x: visible;
		// overflow-y: visible;
	}

	.container-table-reports {
		min-width: 1200px !important;
		// padding: 0.5rem 1rem;
		display: grid;
		grid-template-columns: 1fr 2fr 1.5fr 1.5fr 1.5fr 2fr 2fr !important;
		text-align: center;

		@media screen and (min-width: 600px) {
			// padding: 1rem 2rem;
		}

		p {
			margin: 0;
			font-size: 12px;
			transition: 0.1s all ease-in-out;

			@media screen and (min-width: 600px) {
				font-size: 14px;
			}
		}

		.icon {
			//color: red;
			margin: 0 0.2rem;
			cursor: pointer;
		}
	}

	.header-table {
		transition: 0.1s all ease-in-out;
		background: #f3f2f7;
		text-align: center;

		p {
			font-weight: 600;
		}
	}

	.body-table {
		border-bottom: 0.5px solid #e6e6e6;
		transition: 0.1s all ease-in-out;

		&:last-child {
			border-bottom: none;
		}

		.body-index {
			//   min-width: 30.88px;
		}

		.body-cell {
			//   min-width: 123.52px;
			p {
				word-wrap: break-word;
			}

			.badge {
				width: 80%;

				@media screen and (min-width: 600px) {
					width: 50% !important;
				}
			}
		}
	}
}

.content-table::-webkit-scrollbar {
	width: 4px !important;
	background: #d5d1fb !important;
}

.content-table::-webkit-scrollbar-thumb {
	background: #7367f0 !important;
	border-radius: 6px !important;
}

.content-table::-webkit-scrollbar {
	width: 4px !important;
	background: #d5d1fb !important;
}

.content-table::-webkit-scrollbar-thumb {
	background: #7367f0 !important;
	border-radius: 6px !important;
}

.dark-layout {
	.content-table {
		background: #283046;

		.header-table {
			background: #343d55;
		}

		.body-table {
			border-bottom-color: #3b4253;
		}
	}
}

.btn-agregar-organizer {
	width: 180px;
}

.circle {
	height: 6px;
	width: 6px;
	border-radius: 50%;
	margin-right: 4px;
}

.container-body-header {
	display: flex;
	flex-direction: column;

	@media screen and (min-width: 1000px) {
		flex-direction: row;
		justify-content: space-between;
	}
	.container-select-month {
		display: flex;
		flex-direction: column;
		@media screen and (min-width: 900px) {
			flex-direction: row;
			margin-bottom: 1rem;
		}
		@media screen and (min-width: 1000px) {
			margin-bottom: 0;
		}

		.container-select-date {
			margin-left: 0 !important;
			margin-top: 1rem;
			@media screen and (min-width: 900px) {
				margin-top: 0;
				margin-bottom: 0;
				margin-left: 1rem !important;
			}
		}
	}
}

.font-other-name {
	font-size: 12px;
	font-weight: bold;
}

.header-table-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	p {
	}

	img {
		height: 24px;
		width: 24px;
		object-fit: cover;
	}
}

.btn-buscar-placa {
	// width: 220px !important;
	margin-left: 1rem;
}

.btn-buscar-pl {
	background: #fff;
}

.container-padrillo {
	display: flex;
	align-items: center;
	flex: 1;
	width: 100%;

	// padding: 0 1rem;
	.padrillo {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		// justify-content: center;
		margin-right: 0.5rem;

		.alias {
		}

		.plate {
			background: #fff;
			border-radius: 8px;
			display: flex;
			align-items: center;
			transition: 0.2s all ease-in-out;
			padding: 0.05rem 0.2rem !important;
			box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

			.icon {
				color: #9c97da;
				font-weight: bold;
			}

			.text-plate {
				color: #9c97da;
				font-weight: bold;
			}

			&:hover {
				transform: scale(1.05);
			}
		}
	}
}

.number-report {
	// font-weight: bold;
	transition: 0.2s all ease-in-out;
	// cursor: pointer;
	// &:hover {
	//   color: #9c97da;
	// }
}

.icon-eye {
	margin-right: 0.5rem;
}

.table-1 {
	background: #ddfcdd;
}

.table-2 {
	background: #bbf4fe;
}

.table-3 {
	background: #fecccb;
}

.dark-layout {
	.table-1 {
		background: #9bb09b;
	}

	.table-2 {
		background: #83abb2;
	}

	.table-3 {
		background: #b28f8e;
	}

	.number-report {
		color: #fff !important;
	}
}

.modal-df {
	padding: 1rem !important;
}

.data-null {
	text-align: center;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	p {
		font-size: 18px;
		font-weight: bold;
		margin: 2rem 0;
		padding-left: 1rem;
	}
}

@keyframes ldio-m8h18h0i76f {
	0% {
		transform: rotate(0);
	}

	100% {
		transform: rotate(360deg);
	}
}

.ldio-m8h18h0i76f > div {
	animation: ldio-m8h18h0i76f 1s infinite linear;
	transform-origin: 50px 50px;
}

.ldio-m8h18h0i76f > div div {
	position: absolute;
}

.ldio-m8h18h0i76f > div div:nth-child(1),
.ldio-m8h18h0i76f > div div:nth-child(2) {
	width: 84px;
	height: 84px;
	border: 14px solid;
	border-radius: 50%;
	border-color: transparent #7367f0 #7367f0 #7367f0;
	box-sizing: border-box;
	position: absolute;
	transform: rotate(45deg);
	transform-origin: 50px 50px;
}

.ldio-m8h18h0i76f > div div:nth-child(1) {
	transform: rotate(45deg) translate(8px, 8px);
}

.ldio-m8h18h0i76f > div div:nth-child(2) {
	transform: rotate(0deg) translate(8px, 8px);
}

.ldio-m8h18h0i76f > div div:nth-child(3) {
	width: 0;
	height: 0;
	border: 12px solid;
	border-color: transparent transparent transparent #7367f0;
	transform: translate(50px, 2px);
}

.loadingio-spinner-reload-r3yh8h7kig {
	width: 50px;
	height: 50px;
	display: inline-block;
	overflow: hidden;
	background: #fff;
}

.ldio-m8h18h0i76f {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(0.5);
	backface-visibility: hidden;
	transform-origin: 0 0;
	/* see note above */
}

.ldio-m8h18h0i76f div {
	box-sizing: content-box;
}

.header-sidebar {
	display: flex;
	align-items: center;
	justify-content: space-between;

	&-search {
		display: flex;
		align-items: center;
	}

	.title-sidebar {
		margin: 0;
		font-size: 15px;
		font-weight: bold;
	}

	margin-bottom: 1rem;

	.icon {
		transition: 0.2s all ease-in-out;
		cursor: pointer;

		&:hover {
			transform: scale(1.05);
		}
	}
}

.content-table {
	.header-sticky {
		@media screen and (min-width: 1000px) {
			top: calc(62.4px + 1.3rem);
			// top: 200px;
			// top: 50%;
			position: sticky;
			// z-index: 999;
			// border-bottom: 1px solid #aba3f5;
			box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
		}
	}

	.header-sticky-mobile {
		position: fixed;
	}
}

.search-plate {
	display: flex;
	align-items: center;
	margin-left: 1rem;

	.form-control {
		height: 2rem;
	}

	.icon {
		margin-left: 0.5rem;
	}
}

.page-btn {
	height: 32px;
	width: 32px;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.3s all ease-in-out;
	border-radius: 32px;
	cursor: pointer;
	background: #f3f2f7;
}

.container-btns {
	margin: 0 0.4rem;
	width: auto;
	background: #f3f2f7;
	height: 32px;
	border-radius: 16px;
	display: flex;
	align-items: center;
	transition: 0.1s all ease-in-out;

	.active {
		background: #7367f0;
		color: #fff;
	}
}

.disabled {
	color: #d5d5d5;
}

.container-search-header {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	@media screen and (min-width: 600px) {
		flex-direction: row;
	}

	@media screen and (min-width: 800px) {
		input {
			width: 360px;
		}

		.btn {
			max-width: 200px !important;
		}
	}

	.btn {
		width: 100% !important;
		margin-left: 0;
		margin-top: 0.5rem;

		@media screen and (min-width: 600px) {
			margin-top: 0;
			margin-left: 0.5rem;
		}
	}
}

.dark-layout {
	.table-1 {
		background: #9bb09b;
	}

	.table-2 {
		background: #83abb2;
	}

	.table-3 {
		background: #b28f8e;
	}

	.number-report {
		color: #fff !important;
	}
}

.dark-layout {
	.padrillo {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-right: 0.5rem;

		.alias {
			color: #fff;
		}

		.plate {
			.icon {
			}

			.text-plate {
			}
		}
	}
}

.padrillo-flex {
	width: 100%;
	justify-content: space-between;
}

.header-hijos {
	grid-column: span 3;

	.container-hijos-title {
	}

	.container-hijos-header {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
	}
}

.childs-specimen {
	background: #d6f8fe !important;
}

.grid-table-sons-by-posturas {
	display: grid;
	grid-template-columns: 1fr 4fr 3fr 2fr !important;

	.container-padrillo {
		padding: 0 !important;
	}
}

.sticky-plate-specimen {
	position: sticky;
	top: calc(62.4px + 1.3rem);
	z-index: 1000;
	bottom: 0;
}

.dark-layout {
	.padrillo {
		display: flex;
		align-items: flex-start !important;
		justify-content: flex-start !important;
	}
}

.dark-layout {
	.sons-dark {
		color: #6e6b7b !important;
	}
}

.info-icon {
	margin-left: 0.4rem;
}

.success-icon {
	animation: pulse1 1s infinite;
	cursor: pointer;
	margin-left: 0.2rem;
}

.danger-icon {
	cursor: pointer;
	animation: pulse2 1s infinite;
	margin-left: 0.2rem;
}

@keyframes pulse1 {
	0% {
		color: #80e12f;
	}

	50% {
		color: #73cb2a;
	}

	100% {
		color: #80e12f;
	}
}

@keyframes pulse2 {
	0% {
		color: #f50d0d;
	}

	50% {
		color: #dd0c0c;
	}

	100% {
		color: #f50d0d;
	}
}

.sticky-plate-specimen {
	position: sticky;
	top: calc(62.4px + 1.3rem);
	z-index: 1000;
	bottom: 0;
	box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	border: 2px solid #7366ef !important;
}

.content-table {
	width: 280px;

	// overflow-x: scroll;
	// overflow-y: scroll;
	// height: 300px;
	@media screen and (min-width: 1000px) {
		// overflow-x: inherit;
	}

	.container-table-reports {
		min-width: 1000px !important;
		display: grid;
		grid-template-columns: 0.5fr 3fr 2fr 2fr 2fr 3fr 1.2fr 2fr;

		// grid-template-columns: 1fr 4fr 2fr 2fr 2fr 3fr 1.2fr 1.2fr;
		@media screen and (min-width: 1000px) {
			// overflow-x: visible;
			min-width: 100% !important;
			grid-template-columns: 1fr 3fr 2fr 2fr 2fr 3fr 2fr 2fr;
		}
	}
}

.content-table::-webkit-scrollbar {
	// width: 2px !important;
	background: #d5d1fb !important;
	height: 4px;
}

.content-table::-webkit-scrollbar-thumb {
	background: #7367f0 !important;
	border-radius: 2px !important;
}

.image-thumb {
	height: 50px;
	width: 50px;
	object-fit: cover;
	cursor: pointer;
	padding: 0.2rem;
	border-radius: 2px;
	box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	transition: 0.4s all ease-in-out;

	&:hover {
		box-shadow: 0 4px 6px -1px #7367ef, 0 2px 4px -2px #7367ef;
	}
}

.grid-reports-tournament {
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	// padding-bottom: 4rem;
	// @media screen and (min-width: 1000px) {
	//   grid-template-columns: 3fr 1fr;
	// }
	min-height: 50vh;

	@media screen and (min-width: 1200px) {
		grid-template-columns: 7fr 2fr;
	}

	.container-table-result-tournament {
		order: 2;

		@media screen and (min-width: 900px) {
			order: 1;
		}
	}
}

.container-results-total {
	order: 1 !important;
	.result-data-total {
		@media screen and (min-width: 1000px) {
			top: calc(62.4px + 1.3rem);
			// top: 200px;
			// top: 50%;
			position: sticky;
			// z-index: 999;
			// border-bottom: 1px solid #aba3f5;
			//   box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
			// box-shadow: none !important;
			// background: red;

			.item-result {
				span {
					font-weight: bold;
				}

				margin-bottom: 0.5rem;
			}
		}
	}
}

.now-results-top {
	.result-data-total {
		top: 0 !important;
		// background: #0992f2;
	}
}

.container-search-header-tournament {
	// margin-left: 1rem;
	display: flex;
	align-items: flex-end;
	// justify-content: flex-end;
	flex-direction: column;
	// z-index: 1200;
	margin-top: 1rem;

	@media screen and (min-width: 800px) {
		flex-direction: row;
		align-items: center;
	}
	@media screen and (min-width: 900px) {
		margin-top: 0;
	}

	.container-text {
		display: flex;
		align-items: center;

		h4 {
			margin-bottom: 0;
			width: 100%;
			margin-left: 0.2rem;
			font-size: 12px;

			@media screen and (min-width: 800px) {
				font-size: 14px;
			}

			@media screen and (min-width: 1000px) {
				font-size: 18px;
			}
		}
	}
}

input {
	// width: auto !important;
	// max-width: 300px !important;
}

.container-search-header input {
	width: 100% !important;
	max-width: 300px !important;
}

.btn-buscar-placa {
	margin-left: 1rem !important;
	width: auto !important;
	margin-top: 1rem;

	@media screen and (min-width: 800px) {
		margin-top: 0;
	}
}

.container-sidebar {
	padding: 0 1rem 1rem 1rem;

	.form-control-input {
		margin-bottom: 0.5rem;

		p {
			font-size: 12px;
		}
	}
}

.danger-result {
	background: #f50d0d;
	color: #fff;
	padding: 0.1rem 0.5rem;
	border-radius: 6px;
	font-size: 12px !important;
}

.success-result {
	background: #82e12f;
	color: #fff;
	padding: 0.1rem 0.5rem;
	border-radius: 6px;
	font-size: 12px !important;
}

.info-result {
	background: #0992f2;
	color: #fff;
	padding: 0.1rem 0.5rem;
	border-radius: 6px;
	font-size: 12px !important;
}

.content-table .container-table-reports {
	// min-width: auto !important;
}

.text-month {
	margin-right: 0.5rem;
}

.container-select-date {
	display: grid !important;
	grid-template-columns: 5fr 1fr 1fr;
	width: 280px;
	gap: 0.5rem;

	@media screen and (min-width: 400px) {
		width: 320px;
	}

	.select-year-style {
		width: 100px;
	}

	.icon {
		cursor: pointer;
		color: #7367ef;

		// transition: 0.5s all ease-in-out;
		&:hover {
			// transform: scale(1.05);
		}
	}

	@keyframes girar {
		0% {
			transform: rotate(0);
		}

		// 25% {
		//   transform: rotate(180deg);
		// }
		// 50% {
		//   transform: rotate(360deg);
		// }
		// 75% {
		//   transform: rotate(180deg);
		// }
		100% {
			transform: rotate(360deg);
		}
	}

	.icon-active {
		transform: rotate(360deg);
		animation: girar 1s linear infinite;
	}
}

.icon-trans:hover {
	transform: scale(1.05);
}

.container-picker-date {
	z-index: 1000;
}

.not-sticky {
	position: relative !important;
	// background: #242b3d;
}

.container-grid-table-result {
	margin-bottom: 1rem;

	.grid-table-result {
		border-bottom: 0.5px solid #dadada;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;

		p {
			margin-bottom: 0;
			text-align: center;
			margin: 0.6rem 0 0.4rem 0;
		}
	}
}

@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
