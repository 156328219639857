<template>
	<div>
		<b-modal
			ref="modal-finalize-recommended"
			@hidden="$emit('close')"
			:title="`${getTitle}`"
			cancel-title="Cancelar"
			scrollable
			size="md"
		>
			<div>
				<b-row>
					<b-col sm="12">
						<b-form-group>
							<label for="date">Observacion:</label>
							<b-form-textarea
								v-model="observation"
								placeholder="Ingrese una observacion..."
								rows="3"
								max-rows="6"
							></b-form-textarea>
						</b-form-group>
					</b-col>
				</b-row>
			</div>
			<template #modal-footer>
				<!-- <b-button variant="danger" @click="$emit('close')">Cancelar</b-button> -->
				<b-button variant="primary" @click="finalizeRecommended">Guardar</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import Ripple from "vue-ripple-directive"
import { Money } from "v-money"
import modalMixin from "@/mixins/modal.js"
import vSelect from "vue-select"
import RecommendedSpecimenService from "@/services/preparacion/events/recommendedSpecimen.service.js"
import moment from "moment"

export default {
	props: {
		info: {
			required: true,
		},
		status: String,
		tournament: Object,
		finalizeStatus: String,
		specimens: {
			required: false,
			type: Array,
		},
	},
	directives: { Ripple },
	components: { Money, vSelect },
	mixins: [modalMixin],
	data() {
		return {
			observation: null,
		}
	},
	async mounted() {
		this.toggleModal("modal-finalize-recommended")
	},
	computed: {
		getTitle() {
			switch (this.finalizeStatus) {
				case "finalizado":
					return "Finalizar recomendacion"
				case "enviado":
					return "Enviar recomendacion"
				default:
					return "Retornar recomendacion"
			}
		},
	},
	async created() {
		try {
		} catch (error) {}
	},
	methods: {
		async finalizeRecommended() {
			if (this.finalizeStatus == "enviado") {
				await this.save("send")
				return
			}
			let plates = ""
			let platesRec = ""
			this.tournament.specimens.map((item) => {
				if (item.newStatus == "rechazado") platesRec = `${platesRec} ${item.specimen.plate},`
				if (item.newStatus == "aprobado") plates = `${plates} ${item.specimen.plate},`
			})

			let retApr = `Placas Aprob: ${plates}`
			let retRec = `Placas a cambiar: ${platesRec}`
			const confirm = await this.showConfirmSwal({
				html: `<p style="margin:0 0 8px">Se ${
					this.finalizeStatus === "finalizado"
						? "finalizara"
						: this.finalizeStatus == "retornado"
						? "retornara"
						: "enviara"
				} la recomendacion del torneo ${this.tournament.tournament.name} | ${moment(
					this.tournament.date
				).format("MM/DD/YYYY")} </p> `,
			})
			if (!confirm.isConfirmed) return
			this.isPreloading()

			try {
				let recs = []
				if (["finalizado", "retornado"].includes(this.finalizeStatus)) {
					recs = this.tournament.specimens.map((item) => {
						return {
							id: item.id,
							specimen_id: item.specimen.id,
							status: item.newStatus,
							restricted: item.restricted,
						}
					})
				}

				const finalized = await RecommendedSpecimenService.finalizeRecommended(
					this.tournament.id,
					this.observation,
					this.finalizeStatus,
					recs
				)
				this.showSuccessToast(finalized.data.message)
				this.$emit("refresh")
				this.$emit("close")
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		async save(action) {
			let newsLength = this.specimens.length
			let newsReturned = this.specimens.filter((sp) => !sp.id || (sp.id && sp.status == "guardado")).length
			console.log(newsReturned)

			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se ${action === "save" ? "guardaran" : "enviaran"} ${
					newsReturned > 0 ? newsReturned : newsLength
				} nuevos ejemplares`,
			})
			if (!isConfirmed) return
			this.isPreloading()

			try {
				await RecommendedSpecimenService.storeSpecimens({
					body: {
						event_id: this.tournament.id,
						specimens: this.specimens.map((specimen) => {
							return {
								id: specimen.id,
								specimen_id: specimen.specimen.id,
								role: specimen.role,
								new_status: specimen.newStatus,
							}
						}),
						action: action,
						observation: this.observation,
						role: this.isAdmin ? "admin" : "preparador",
					},
				})

				this.$emit("refresh")
				this.$emit("close")
			} catch (error) {
				console.error(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
}
</script>
