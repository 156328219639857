<template>
	<div>
		<ValidationObserver ref="form">
			<b-row class="mb-2 gap-y-2">
				<b-col cols="12" xl="6">
					<h2 class="text-primary font-weight-bolder mb-2">{{ title }}</h2>
					<div class="pr-1" style="overflow: auto; max-height: 60vh">
						<b-form-group label="1. Categoría" label-class="font-weight-bold text-secondary"
							label-size="lg">
							<div class="circle-container">
								<div class="circle-item" v-for="(item, key) in showOptionsCategory" :key="key"
									@click="selectedLocalSpecimen == 'specimen' && changeCategory(item.item)">
									<div class="circle" :class="[
										uploadVideo.category_id == item.item ? 'active' : '',
										selectedLocalSpecimen != 'specimen' && item.name != 'TOPE'
											? 'disabled'
											: '',
									]"></div>
									<p class="mb-0">{{ item.name }}</p>
								</div>
							</div>
						</b-form-group>
						<!--  -->
						<hr />
						<b-form-group v-if="uploadVideo.category_id == 1 && uploadVideo.type_id" label="2. Tipo"
							label-class="font-weight-bold text-secondary" label-size="lg">
							<div class="circle-container">
								<div class="circle-item" v-for="(item, key) in optionsType" :key="key"
									@click="changeType(item)">
									<div class="circle" :class="uploadVideo.type_id == item.item ? 'active' : ''"></div>
									<p class="mb-0">{{ item.name }}</p>
								</div>
							</div>
						</b-form-group>
						<b-form-group v-else-if="uploadVideo.category_id == 2" label="2. Tipo"
							label-class="font-weight-bold text-secondary" label-size="lg">
							<div class="circle-container">
								<div class="circle-item">
									<div class="circle active bg-primary-light"></div>
									<p class="mb-0">EXTERNO</p>
								</div>
							</div>
						</b-form-group>
						<!--  -->
						<hr />
						<b-form-group label="3. Fecha" label-class="font-weight-bold text-secondary" label-size="lg">
							<validation-provider rules="required" v-slot="{ errors }">
								<flat-pickr v-model="uploadVideo.date" class="form-control bg-transparent"
									placeholder="Elige una fecha" :class="errors[0] ? 'error-input' : 'mb-05'" />

								<span style="color: red">{{ errors[0] }}</span>
							</validation-provider>
						</b-form-group>
						<!--  -->
						<hr />

						<!-- si data no es null, se esta editando el video -->
						<b-form-group label="4. Local" label-class="font-weight-bold text-secondary" label-size="lg">
							<b-form-radio-group :disabled="data != null" v-model="selectedLocalSpecimen"
								:options="showSpecimenOptions" @change="clearOptions('local')" />
						</b-form-group>
						<validation-provider rules="required" v-slot="{ errors }">
							<!--  -->
							<v-select :disabled="specimen_to_update_id ? data.category != 'tope' : false"
								:readonly="specimen_to_update_id"
								@input="M_SET_INPUT({ data: $event, input: selectedLocalSpecimen + '_id' })"
								:value="uploadVideo[`${selectedLocalSpecimen}_id`]" :options="optionsSpecimen"
								:clearable="false" label="label" :reduce="(option) => option.value" transition=""
								:placeholder="`Buscar por ${selectedLocalSpecimen == 'specimen' ? 'placa' : 'cintillo ala'
									}`" :class="errors[0] ? 'error-input' : ''" @search="(search, loading) =>
										A_SEARCH_EJEMPLAR({
											search,
											loading,
											type: selectedLocalSpecimen,
											side: 'local',
										})
									" appendToBody :calculatePosition="positionDropdown">
								<template slot="no-options">
									Sin
									{{ selectedLocalSpecimen == "specimen" ? "placas" : "cintillo alas" }}
									encontradas
								</template>

								<template slot="option" slot-scope="option">
									<div class="d-center">
										{{ option.label }}
									</div>
								</template>
								<!-- selectRivalModuleSpecimen -->
								<template slot="selected-option" slot-scope="option">
									<div class="selected d-center">
										{{ option.label }}
									</div>
								</template>
							</v-select>
							<span style="color: red">{{ errors[0] }}</span>
						</validation-provider>
						<b-form-group class="mt-1" v-if="selectedLocalSpecimen == 'specimen' && !data">
							<template #label>
								Calificacion
								<b-form-checkbox class="d-inline ml-05" switch v-model="ratingLocalActive" />
							</template>
							<div class="d-flex mb-1" v-if="ratingLocalActive">
								<b-row class="d-flex gap-x-2 w-full mb-1">
									<b-col cols="8" class="pl-3">
										<veeno pipsy :connect="[true, false]" :handles="[0]" :range="{ min: 0, max: 5 }"
											:step="0.25" v-model="ratingLocal" />
									</b-col>
									<b-col class="text-center text-nowrap font-weight-bolder h4">
										{{ ratingLocal }} / 5
									</b-col>
								</b-row>
							</div>
						</b-form-group>

						<!--  -->
						<hr />
						<b-form-group v-if="topeInterno">
							<validation-provider rules="required" v-slot="{ errors }">
								<b-form-group label="5. Rival" label-class="font-weight-bold text-secondary"
									label-size="lg" v-slot="{ ariaDescribedbyVisit }">
									<b-form-radio-group id="radio-group-2" v-model="selectedVisitSpecimen"
										:aria-describedby="ariaDescribedbyVisit" :options="optionsVisitSpecimens"
										@change="clearOptions('visit')" name="radio-options2" />
								</b-form-group>
								<v-select
									@input="M_SET_INPUT({ data: $event, input: selectedVisitSpecimen + '_two_id' })"
									:value="uploadVideo[`${selectedVisitSpecimen}_two_id`]"
									:options="optionsSpecimenRival" :clearable="false" label="label"
									:reduce="(option) => option.value" transition="" :placeholder="`Buscar por ${selectedVisitSpecimen == 'specimen' ? 'placa' : 'cintillo ala'
										}`" @search="(search, loading) =>
											A_SEARCH_EJEMPLAR({
												search,
												loading,
												type: selectedVisitSpecimen,
												side: 'visit',
											})
										" :class="errors[0] ? 'error-input' : ''" appendToBody :calculatePosition="positionDropdown">
									<template slot="no-options">
										Sin
										{{ selectedVisitSpecimen == "specimen" ? "placas" : "cintillo alas" }}
										encontradas
									</template>

									<template slot="option" slot-scope="option">
										<div class="d-center">
											{{ option.label }}
										</div>
									</template>

									<template slot="selected-option" slot-scope="option">
										<div class="selected d-center">
											{{ option.label }}
										</div>
									</template>
								</v-select>
								<span style="color: red">{{ errors[0] }}</span>
							</validation-provider>
							<b-form-group class="mt-1"
								v-if="selectedVisitSpecimen == 'specimen' && !specimen_to_update_id">
								<template #label>
									Calificacion
									<b-form-checkbox class="d-inline ml-05" switch v-model="ratingRivalActive" />
								</template>
								<!--  -->
								<div class="d-flex mb-1" v-if="ratingRivalActive">
									<b-row class="d-flex gap-x-2 w-full mb-1">
										<b-col cols="8" class="pl-3">
											<veeno pipsy :connect="[true, false]" :handles="[0]"
												:range="{ min: 0, max: 5 }" :step="0.25" v-model="ratingRival" />
										</b-col>
										<b-col class="text-center text-nowrap font-weight-bolder h4">
											{{ ratingRival }} / 5
										</b-col>
									</b-row>
								</div>
							</b-form-group>
						</b-form-group>
						<!--  -->
						<!-- <hr v-if="topeInterno" /> -->
						<validation-provider v-if="
							uploadVideo.category_id == 2 ||
							(uploadVideo.category_id == 1 && uploadVideo.type_id == 2)
						" rules="required" v-slot="{ errors }">
							<b-form-group label="5. Rival" label-class="font-weight-bold text-secondary"
								label-size="lg">
								<v-select @input="M_SET_INPUT({ data: $event, input: 'rival_id' })"
									:value="uploadVideo.rival_id" :clearable="false" label="name"
									:options="optionsRival" :reduce="(option) => option.id"
									:class="errors[0] ? 'error-input' : 'mb-1'" appendToBody
									:calculatePosition="positionDropdown" />
								<span style="color: red">{{ errors[0] }}</span>
							</b-form-group>
						</validation-provider>
						<!--  -->
						<hr v-if="uploadVideo.category_id == 2" />
						<validation-provider v-if="uploadVideo.category_id == 2" rules="required" v-slot="{ errors }">
							<b-form-group label="6. Resultado" label-class="font-weight-bold text-secondary"
								label-size="lg">
								<v-select
									:disabled="specimen_to_update_id && uploadVideo.category_id == 3 ? true : false"
									:readonly="specimen_to_update_id && uploadVideo.category_id == 3"
									@input="M_SET_INPUT({ data: $event, input: 'result_id' })"
									:value="uploadVideo.result_id" :clearable="false" label="name"
									v-model="uploadVideo.result_id" :options="optionsResultado"
									:reduce="(option) => option.item" :class="errors[0] ? 'error-input' : ''"
									appendToBody :calculatePosition="positionDropdown" />
								<span style="color: red">{{ errors[0] }}</span>
							</b-form-group>
						</validation-provider>
					</div>
				</b-col>
				<b-col cols="12" xl="6" class="d-flex flex-column">
					<h2 class="text-right text-primary mb-2">
						<b-button class="btn-icon" variant="primary" @click="$emit('closing')">
							<feather-icon icon="XIcon" />
						</b-button>
					</h2>
					<div class="grid-video" style="flex: 1">
						<div v-if="!uploadVideo.uid" class="container-video" style="height: 100%" @click="fileChange"
							:class="[errorVideo ? 'container-video-error' : '']">
							<img class="image-mov"
								v-if="file && ['mp4', 'avi', 'mkv', 'flv', 'mov', 'MOV'].includes(file.extension)"
								src="https://cliply.co/wp-content/uploads/2019/07/371907120_YOUTUBE_ICON_TRANSPARENT_400.gif"
								alt="" />
							<video class="" v-else :src="file ? file.base : null" autoplay muted></video>
							<div class="skeletor-video-content">
								<div class="d-flex flex-column gap-y-1 align-items-center" v-if="file.name">
									<span class="h6 p-1 rounded bg-white text-primary">
										Seleccionado: {{ file.name }}
									</span>
									<b-button class="font-weight-bolder" variant="primary">Cambiar archivo</b-button>
								</div>
								<div class="d-flex flex-column gap-y-1 align-items-center" v-else>
									<feather-icon class="text-primary mb-1" icon="UploadIcon" size="80" />
									<span class="h2 text-primary">Subir Video</span>
									<b-button class="font-weight-bolder" variant="primary">Elegir archivo</b-button>
								</div>
							</div>
							<input ref="fileInput" type="file" @input="fileChange" class="d-none"
								accept=".mp4,.avi,.mkv,.flv,.mov,.MOV" />
							<p v-if="errorVideo" style="color: red">El video debe ser obligatorio</p>
						</div>

						<div v-else class="mt-1 w-100 content-video" style="position: relative; height: 100%">
							<iframe :src="`https://iframe.videodelivery.net/${uploadVideo.uid}?autoplay=true`" autoplay
								style="border: none; position: absolute; top: 0; height: 100%; width: 100%"
								allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
								allowfullscreen="true" id="stream-player"></iframe>

							<!-- <VideoCloudflare v-if="uploadVideo.uid" :uid="uploadVideo.uid" /> -->

							<div class="icon-delete-video">
								<feather-icon @click="
									M_SET_INPUT({ data: uploadVideo.uid, input: 'oldUid' })
								M_SET_INPUT({ data: null, input: 'uid' })
								M_SET_INPUT({ data: null, input: 'thumbnail' })
									" icon="TrashIcon" size="14" color="red" />
							</div>
						</div>
					</div>
				</b-col>
			</b-row>
		</ValidationObserver>
		<b-row v-if="videoCounterUpload > 0">
			<b-col>
				<p class="mb-0">Cargando: {{ ((videoCounterUploadCheck / videoCounterUpload) * 100).toFixed(2) }}%</p>
				<div class="barra">
					<div class="back" :style="{ width: `${(videoCounterUploadCheck / videoCounterUpload) * 100}%` }">
					</div>
				</div>
			</b-col>
		</b-row>
		<div class="d-flex justify-content-end">
			<b-button :disabled="uploading || disabledBtn" variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1"
				:block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="saveVideo">
				{{ data ? "Actualizar" : "Subir" }}
			</b-button>
			<b-button :disabled="uploading" v-if="!data" variant="outline-secondary" type="reset"
				:block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="resetForm">
				Resetear
			</b-button>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex"
import vSelect from "vue-select"
import { mask } from "vue-the-mask"
import Ripple from "vue-ripple-directive"
import preparacionSettingService from "@/services/preparacion/settings.service.js"
import VideoCloudflare from "../tournaments/VideoCloudflare.vue"
import veeno from "veeno"
import "nouislider/distribute/nouislider.min.css"

export default {
	name: "UploadSpecimenVideo",
	directives: { mask, Ripple },
	components: {
		vSelect,
		VideoCloudflare,
		veeno,
	},
	props: {
		reset: {
			default: false,
		},
		specimen_to_update_id: {
			type: Number,
			default: 0,
		},
		data: {
			type: Object,
			default: null,
		},
		title: {
			type: String,
			default: "Agregar Video",
		},
	},
	async mounted() {
		this.isPreloading()
		this.getOpts()

		if (this.data) {
			//
			const { category, local, rival, date, video, fr_id, type } = this.data

			this.selectedLocalSpecimen = local.type == "specimen" ? "specimen" : "chick"
			this.A_SEARCH_EJEMPLAR({
				search: local.plate,
				loading: () => 0,
				type: this.selectedLocalSpecimen,
				side: "local",
			})

			this.M_SET_INPUT({ data: local.id, input: local.type == "specimen" ? "specimen_id" : "chick_id" })

			if (type != "externo") {
				if (category !== "torneo") this.selectedVisitSpecimen = rival.type == "specimen" ? "specimen" : "chick"
			} else {
				this.A_GET_RIVALS()
			}

			this.M_SET_INPUT({
				data:
					this.data.category == "tope"
						? 1
						: this.data.category == "pelea"
							? 2
							: this.data.category == "torneo"
								? 3
								: null,
				input: "category_id",
			})
			if (this.data.result) {
				this.M_SET_INPUT({
					data:
						this.data.result.id == 1
							? 1
							: this.data.result.id == 2
								? 2
								: this.data.result.id == 3
									? 3
									: null,
					input: "result_id",
				})
			}

			this.M_SET_INPUT({
				data: this.data.type == "interno" ? 1 : 2,
				input: "type_id",
			})

			// LLAMAR EL RIVAL SEGUUN SU TYPE Y NAME

			if (rival && rival.type !== null) {
				let rival_id = rival.type
				switch (rival.type) {
					case "specimen": // specimen
						rival_id += "_two"
						await this.A_GET_PLATE_SPECIMEN_BY_ID({ specimen_id: rival.id })
						break
					case "chick": // chick
						await this.A_GET_CORR_CHICK_BY_ID({ chick_id: rival.id })
						rival_id += "_two"
						break
					case "other": // rival
						await this.A_GET_RIVALS()
						break
					case "3": // participant
						// this.A_GET_TOURNAMENTS_BY_SPECIMEN({ specimen_id: entidad.id });
						break

					default:
						break
				}
				rival_id += "_id"
				// console.log("rival rival", rival.id, rival_id);
				this.M_SET_INPUT({ data: rival.id, input: rival_id })

				if (type == "externo") {
					this.M_SET_INPUT({ data: rival.id, input: "rival_id" })
				}
			}

			// this.M_SET_INPUT({ data: this.data.tss_id, input: "tournament_stage_specimen_id" });

			this.M_SET_INPUT({ data: date, input: "date" })

			this.M_SET_INPUT({ data: video.url, input: "uid" })

			this.M_SET_INPUT({ data: video.thumb, input: "thumbnail" })

			this.M_SET_INPUT({ data: fr_id, input: "id_fight_results" })
		} else {
			this.has_rivals = true
			await this.A_GET_RIVALS()
		}
		this.M_SET_INPUT({
			data: this.currentUser.user_id,
			input: "user_id",
		})

		this.isPreloading(false)
	},
	destroyed() {
		this.M_RESET_FORM_VIDEO({ category_idIn: 1, type_idIn: 1 })
	},
	computed: {
		...mapState("header_book", ["urlBackendNode", "id_specimen"]),
		...mapState("video", [
			"optionsType",
			"optionsResultado",
			"optionsSpecimen",
			"optionsRival",
			"optionsParticipant",
			"arrayAmarradores",
			"arrayCareadores",
			"dataFile",
			"uploadVideo",
			"arrayGallos",
			"optionsSpecimenRival",
			"file",
			"arrayTournaments",
			"arrayFightResults",
			"uploading",
		]),
		...mapGetters("video", ["optionsCategory"]),
		showOptionsCategory() {
			let is_specimen = true
			return this.optionsCategory(is_specimen)
		},
		layout() {
			return this.$store.getters["appConfig/layout"]
		},
		isPrestado() {
			return this.specimenObject.origin == 5
		},
		topeInterno() {
			return this.uploadVideo.category_id == 1 && this.uploadVideo.type_id == 1
		},
		showSpecimenOptions() {
			if (this.uploadVideo.category_id == 2) {
				// SI ES TORNEO
				return this.specimenOptions.filter((s) => s.value == "specimen")
			}
			return this.specimenOptions
		},
	},
	data() {
		return {
			errorVideo: false,
			videoNoRequired: true,
			barraLoader: 0,
			selectedLocalSpecimen: "specimen",
			selectedVisitSpecimen: "specimen",
			specimenOptions: [
				{ value: "specimen", text: "Ejemplar" },
				{ value: "chick", text: "Pollo" },
			],
			optionsVisitSpecimens: [
				{ value: "specimen", text: "Ejemplar" },
				{ value: "chick", text: "Pollo" },
			],
			has_rivals: false,
			ratingLocal: 0,
			ratingLocalActive: false,
			qualityLocal: null,
			defectLocal: null,
			ratingRival: 0,
			ratingRivalActive: false,
			qualityRival: null,
			defectRival: null,
			qualityOpts: [],
			defectOpts: [],
			disabledBtn: false,
			videoCounterUpload: 0,
			videoCounterUploadCheck: 0,
		}
	},
	methods: {
		...mapMutations("video", [
			"M_SET_INPUT",
			"M_RESET_FORM_VIDEO",
			"M_DATA_FILE",
			"M_SET_TOURNAMENT_SPECIMEN_ARRAY",
			"M_SET_VIDEO_BY_ID",
			"M_SET_SPECIMENS",
			"M_SET_SPECIMENS_RIVAL",
		]),
		...mapActions("video", [
			"A_GET_RIVALS",
			"A_GET_ARRAY_AMARRADOR",
			"A_GET_ARRAY_CAREADOR",
			"A_SEARCH_EJEMPLAR",
			"A_DATE_RESULT_NOW",
			"A_SAVE_VIDEO",
			"A_UPLOAD_VIDEO_NODE",
			"A_SAVE_VIDEO_TOURNAMENT",
			"A_GET_PLATE_SPECIMEN_BY_ID",
			"A_UPDATE_VIDEO_TOPES",
			"A_UPDATE_VIDEO_TOURNAMENT",
			"A_GET_SPECIMENS_RIVAL",
			"A_GET_CORR_CHICK_BY_ID",
			"A_GET_TOURNAMENTS",
			"A_GET_FIGHT_RESULTS_BY_TOURNAMENT_ID",
		]),
		...mapActions({
			A_GET_DAYS_WITHOUT_TOPES_COUNTER: "commons/A_GET_DAYS_WITHOUT_TOPES_COUNTER",
		}),
		selectResultTournament(opt) {
			this.M_SET_INPUT({ data: opt, input: "participant_id" })
			// this.M_SET_INPUT({ data: option.result_id, input: 'result_id' });
		},
		fileChange() {
			this.$refs.fileInput.click()
			let input = this.$refs.fileInput
			let file = input.files
			const typeOfFile = file[0] && file[0].name.split(".")[file[0].name.split(".").length - 1]
			this.setVideo(file, "video")
		},
		setVideo(file, type) {
			const typeOfFile = file[0] && file[0].name.split(".")[file[0].name.split(".").length - 1]
			this.errorVideo = false
			if (file && file[0]) {
				this.M_DATA_FILE({
					name: file[0].name,
					data: file[0],
					type,
					base: null,
					progress: 0,
					uploadFinished: false,
					extension: typeOfFile,
				})
			}
		},
		async saveVideo() {
			const validForm = await this.$refs.form.validate()
			this.M_SET_INPUT({ data: this.currentUser.user_id, input: "user_id" })

			if (this.videoNoRequired && (this.file.data || this.uploadVideo.uid)) {
				this.errorVideo = false
			} else {
				this.errorVideo = true
			}

			if (validForm && !this.errorVideo) {
				const videoForm = new FormData()
				if (this.uploadVideo.oldUid || this.file.data) {
					let lengthVideo = 0
					let offset = 0
					let chunkSize = 10000000
					if (this.file.data) {
						while (offset < this.file.data.size) {
							offset += chunkSize
							lengthVideo++
						}
						this.videoCounterUpload = this.videoCounterUpload + lengthVideo
					}

					this.disabledBtn = true
					const response = await this.uploadVideoLaravel(this.file.data, this.configReload)

					if (response.statusResponse == 500) {
						this.showToast(
							"danger",
							"top-right",
							"ERROR AL SUBIR VIDEO",
							"AlertCircleIcon",
							"ALGO SALIO MAL, VUELVA A INTENTAR POR FAVOR"
						)
						this.videoCounterUpload = 0
						this.videoCounterUploadCheck = 0
						this.disabledBtn = false
						this.$refs.form.reset()
						return
					}

					let url = response.url

					let fileName = response.fileName

					videoForm.set("name", fileName)
					videoForm.append("path_s3", url)
				}

				// this.uploadVideo.category_id     				&& videoForm.append("category_id", this.uploadVideo.category_id);
				// this.uploadVideo.type_id         				&& videoForm.append("type_id", this.uploadVideo.type_id);
				// this.uploadVideo.specimen_id     				&& videoForm.append("specimen_id", this.uploadVideo.specimen_id);
				// this.uploadVideo.specimen_two_id 				&& videoForm.append("specimen_two_id", this.uploadVideo.specimen_two_id);
				// this.uploadVideo.chick_id 						&& videoForm.append("chick_id", this.uploadVideo.chick_id);
				// this.uploadVideo.chick_two_id 					&& videoForm.append("chick_two_id", this.uploadVideo.chick_two_id);
				// this.uploadVideo.rival_id 						&& videoForm.append("rival_id", this.uploadVideo.rival_id);
				// this.uploadVideo.participant_id 				    && videoForm.append("participant_id", this.uploadVideo.participant_id);
				// this.uploadVideo.result_id 						&& videoForm.append("result_id", this.uploadVideo.result_id);
				// this.uploadVideo.tournament_stage_specimen_id 	&& videoForm.append("tournament_stage_specimen_id", this.uploadVideo.tournament_stage_specimen_id);
				// this.uploadVideo.order 	      					&& videoForm.append("order", this.uploadVideo.order);
				// this.uploadVideo.event_date   					&& videoForm.append("event_date", this.uploadVideo.event_date);
				// this.uploadVideo.amarrador_id 					&& videoForm.append("amarrador_id", this.uploadVideo.amarrador_id);
				// this.uploadVideo.careador_id 					&& videoForm.append("careador_id", this.uploadVideo.careador_id);
				// this.uploadVideo.description 					&& videoForm.append("description", this.uploadVideo.description);
				// this.uploadVideo.user_id 						&& videoForm.append("user_id", this.uploadVideo.user_id);
				// this.uploadVideo.id 	 							&& videoForm.append("id", this.uploadVideo.id);
				// this.uploadVideo.tournament_id    				&& videoForm.append("tournament_id", this.uploadVideo.tournament_id);
				// this.uploadVideo.id_fight_results 				&& videoForm.append("id_fight_results", this.uploadVideo.id_fight_results);
				// this.uploadVideo.uid 			  				&& videoForm.append("uid", this.uploadVideo.uid);

				for (const [keys, values] of Object.entries(this.uploadVideo)) {
					if (values !== null) {
						videoForm.append(`${keys}`, values)
					}
				}
				if (this.ratingLocalActive) {
					videoForm.append("rating_local", this.ratingLocal)
					videoForm.set("qualities", this.qualityLocal)
					videoForm.set("defects", this.defectLocal)
				}
				if (this.ratingRivalActive) {
					videoForm.append("rating_rival", this.ratingRival)
					videoForm.set("qualities_rival", this.qualityRival)
					videoForm.set("defects_rival", this.defectRival)
				}

				const config = {
					onUploadProgress: (progressEvent) => {
						const { loaded, total } = progressEvent
						let percent = Math.floor((loaded * 100) / total)
						this.barraLoader = percent
					},
				}
				this.isPreloading()
				if (this.file.data || this.uploadVideo.uid) {
					if (this.data && this.data.id) {
						if ([1, 2].includes(this.uploadVideo.category_id)) {
							// Actualizar tope o pelea
							// Validar si existe el archivo
							if (this.uploadVideo.uid) {
								// Actualizando sin el archivo
								const {
									data: { fight_results: data },
								} = await this.A_UPDATE_VIDEO_TOPES({
									videoForm: this.uploadVideo,
									config,
									id: this.data.id,
								})
								// this.M_SET_VIDEO_BY_ID({ id: data.id, data });
								this.M_SET_INPUT({ data: this.uploadVideo.uid, input: "uid" })
								this.M_SET_INPUT({
									data: this.uploadVideo.thumbnail,
									input: "thumbnail",
								})
							} else if (videoForm.get("file") == "null") {
								this.M_SET_INPUT({ data: null, input: "thumbnail" })
								this.M_SET_INPUT({ data: null, input: "uid" })
							} else {
								// Actualizando con el archivo
								const {
									data: { fight_results: data },
								} = await this.A_UPDATE_VIDEO_TOPES({ videoForm, config, id: this.data.id })
								// this.M_SET_VIDEO_BY_ID({ id: data.id, data });
								this.M_SET_INPUT({
									data: this.uploadVideo.thumbnail ? this.uploadVideo.thumbnail : null,
									input: "thumbnail",
								})
								this.M_SET_INPUT({
									data: this.uploadVideo.uid ? this.uploadVideo.uid : null,
									input: "uid",
								})
							}

							this.M_RESET_FORM_VIDEO({
								category_idIn: 1,
								type_idIn: 1,
							})
							this.isPreloading(false)
						} else if (this.uploadVideo.category_id == 3) {
							const {
								data: { fight_results: data },
							} = await this.A_UPDATE_VIDEO_TOURNAMENT({ videoForm, config })
							this.isPreloading(false)
						}
					} else {
						if ([1, 2].includes(this.uploadVideo.category_id)) {
							await this.A_SAVE_VIDEO({ videoForm, config })
						} else if (this.uploadVideo.category_id == 3) {
							await this.A_SAVE_VIDEO_TOURNAMENT({ videoForm, config })
						}
						this.showToast(
							"success",
							"top-right",
							"Video",
							"CheckIcon",
							"El video se ha guardado correctamente, se está procesando su información."
						)
						this.isPreloading(false)
					}
					this.$emit("resetTable")
				} else {
					if (!this.uploadVideo.id) {
						await this.A_SAVE_VIDEO_TOURNAMENT(videoForm)
					}
					this.M_RESET_FORM_VIDEO({
						category_idIn: 1,
						type_idIn: 1,
					})
					this.$emit("resetTable")
					this.isPreloading(false)
				}
			} else {
				this.errorVideo = true
			}

			await this.A_GET_DAYS_WITHOUT_TOPES_COUNTER()

			this.isPreloading(false)
			this.disabledBtn = false
			// setTimeout(() => {
			// 	this.clearOptions();
			// }, 2000);
		},
		resetForm() {
			this.M_RESET_FORM_VIDEO({ category_idIn: 1, type_idIn: 1 })
			//
			this.ratingLocal = 0
			this.ratingLocalActive = false
			this.qualityLocal = null
			this.defectLocal = null
			this.ratingRival = 0
			this.ratingRivalActive = false
			this.qualityRival = null
			this.defectRival = null
		},
		changeCategory(item) {
			if (!this.data && !this.tournament_stage_specimen_id) {
				this.M_RESET_FORM_VIDEO({
					category_idIn: item,
					type_idIn: item == 1 ? 1 : item == 2 ? 2 : null,
				})
			}
			if (item > 1) {
				this.selectedLocalSpecimen = "specimen"
				this.selectedVisitSpecimen = "specimen"
			}
			this.errorVideo = false
		},
		changeType(item) {
			this.M_SET_INPUT({ data: item.item, input: "type_id" })
			item.item == 1
				? this.M_SET_INPUT({ data: null, input: "rival_id" })
				: this.M_SET_INPUT({ data: null, input: "specimen_two_id" })
			if (!this.has_rivals) {
				this.A_GET_RIVALS()
			}
		},
		clearOptions(type = null) {
			switch (type) {
				case "local":
					this.M_SET_SPECIMENS([])
					this.ratingLocalActive = false
					this.ratingLocal = 0
					this.qualityLocal = null
					this.defectLocal = null
					break
				case "visit":
					this.M_SET_SPECIMENS_RIVAL([])
					this.ratingRivalActive = false
					this.ratingRival = 0
					this.qualityRival = null
					this.defectRival = null
					break

				default:
					this.M_SET_SPECIMENS([])
					this.M_SET_SPECIMENS_RIVAL([])
					break
			}
		},
		async getOpts() {
			this.isLoading = true
			const responses = [
				preparacionSettingService.getQualities({ status: 1 }),
				preparacionSettingService.getDefects({ status: 1 }),
			]
			const data = await Promise.all(responses)
			this.qualityOpts = data[0].data.map((opt) => ({ value: opt.id, text: opt.name }))
			this.defectOpts = data[1].data.map((opt) => ({ value: opt.id, text: opt.name }))
			this.isLoading = false
		},
		configReload() {
			this.videoCounterUploadCheck++
		},
		positionDropdown(dropdownList, component, { width, top, left }) {
			dropdownList.style.zIndex = 9999
			dropdownList.style.maxHeight = "12rem"
			dropdownList.style.top = top
			dropdownList.style.left = left
			dropdownList.style.width = width
		},
	},
}
</script>

<style lang="scss" scoped>
.container-video {
	height: 186px;
	width: 100%;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);

	// background: red;
	video {
		// background: gold;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		border-radius: 4px;
		padding: 4px;
		// background: red;
	}

	position: relative;
	cursor: pointer;

	.skeletor-video-content {
		border-radius: 0.5rem;
		border: 2px dashed var(--primary);
		padding: 4px;
		// background: rgba(30, 30, 30, 0.5);
		background: #8095ff54;
		position: absolute;
		top: 4px;
		left: 4px;
		right: 4px;
		bottom: 4px;
		display: flex;
		align-items: center;
		justify-content: center;

		p {
			color: var(--primary);
			margin-bottom: 0;
			font-size: 20px;
			font-weight: bolder;
		}
	}
}

.container-form12 {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 2fr;

	gap: 1rem;

	position: relative;

	@media screen and (min-width: 1000px) {
		gap: 1rem;
		grid-template-areas: 1fr 1fr 1fr;
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (min-width: 1400px) {
		gap: 2rem;
		grid-template-areas: 1fr 1fr 1fr;
		grid-template-columns: 1fr 1fr 1fr;
	}

	.grid-video {
		@media screen and (min-width: 600px) {
			grid-row: span 3 / span 3;
		}
	}

	.grid-description {
		@media screen and (min-width: 900px) {
			grid-column: span 2 / span 2;
			grid-column-start: 3;
		}
	}

	.skeletor-form-disabled {
		position: absolute;
		width: 100%;
		height: 100%;
		background: transparent;
		z-index: 100;
	}
}

.pro {
	grid-column: span 4 / span 4;
	background: red;
	margin-bottom: 2rem;
	border-radius: 10px;
	overflow: hidden;

	.container-pro {
		background: burlywood;
		position: relative;
		width: 100%;
		height: 20px;

		.bar {
			transition: 0.5s all ease-in-out;
			width: 0%;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			background: #7367f0;
			display: flex;
			align-items: center;
			justify-content: center;

			p {
				color: #fff;
				margin: 0;
			}
		}
	}
}

.image-mov {
	width: 100% !important;
	height: 100% !important;
	object-fit: contain !important;
	object-position: center;
}

.container-video-error {
	border: 2px solid red;
	border-radius: 6px;
}

.error-input {
	border: 1.2px solid red;
	border-radius: 6px;
}

.circle-container {
	display: flex;
	align-items: center;
	margin-top: 1rem;

	.circle-item {
		margin-right: 1rem;
		display: flex;
		align-items: center;

		cursor: pointer;

		.circle {
			height: 16px;
			width: 16px;
			border-radius: 50%;
			border: 2px solid #7367f0;
			transition: 0.3s all ease-in-out;
			margin-right: 0.3rem;
			background: #fff;
			margin-top: 0 !important;
		}

		p {
			font-size: 12px;

			@media screen and (min-width: 900px) {
				font-size: 14px;
			}
		}

		.active {
			background: #7367f0;
			padding: 0.2rem;
		}
	}
}

.container-select-rival-torneo {
	display: flex;
	align-items: flex-start;

	.container-text {
		display: flex;
		flex-direction: column;

		span {
			font-weight: bold;
		}
	}
}

.circle {
	height: 10px;
	width: 10px;
	background: #7367f0;
	border-radius: 50%;
	margin-right: 0.25rem;
	margin-top: 0.5rem;
}

.circle1 {
	background: #7fe132;
}

.circle2 {
	background: #f50d0d;
}

.circle3 {
	background: #efefef;
}

.container-total-form {
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;

	@media screen and (min-width: 1000px) {
		gap: 2rem;
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (min-width: 1300px) {
		gap: 2rem;
		grid-template-columns: 3fr 1fr;
	}
}

.grid-col-2 {
	grid-column: span 2 / span 2;
}

.barra {
	// background: #f50d0d;
	border-radius: 8px;
	position: relative;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
	height: 24px;
	margin-bottom: 2rem;
	overflow: hidden;

	.back {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		height: 100%;
		// width: 40%;
		background: #7367f0;
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		transition: 0.3 all ease-in-out;
	}
}

.disabled {
	background: #dddddd !important;
	border-color: #dddddd !important;
}

.icon-delete-video {
	position: absolute;
	top: 0;
	right: 0;
}
</style>

<style>
[dir] .noUi-connect {
	background: #7367f0;
}
</style>
