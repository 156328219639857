<template>
	<b-modal
		centered
		no-close-on-backdrop
		hide-footer
		size="xlg"
		title="Muertos / Cintillos en ala"
		v-model="isActive"
		@hidden="handleHidden"
	>
		<b-overlay :show="isLoading">
			<b-row class="mb-1">
				<b-col cols="12" md="6" class="d-flex align-items-center mb-1 mb-md-0">
					<span>
						Mostrando {{ pagination.from }} a {{ pagination.to }} de {{ pagination.total }} registros
					</span>
				</b-col>
				<b-col cols="12" md="6" class="d-flex justify-content-md-end">
					<b-pagination
						class="m-0"
						pills
						hide-goto-end-buttons
						hide-ellipsis
						v-model="pagination.page"
						:total-rows="pagination.total"
						:per-page="pagination.perPage"
						@input="getRows"
					/>
				</b-col>
			</b-row>
			<b-row class="mb-2">
				<b-col cols="12" md="6" class="d-flex align-items-center gap-x-2 mb-1 mb-md-0">
					<span>Mostrar</span>
					<b-form-select
						style="width: 5rem"
						:options="[20, 50, 100, 200]"
						v-model="pagination.perPage"
						@input="getRows"
					/>
					<span>registros</span>
					<b-button class="btn-icon" size="sm" variant="flat-secondary" @click="resetRows">
						<feather-icon icon="RefreshCcwIcon" />
					</b-button>
				</b-col>
				<b-col cols="12" md="6">
					<div class="d-flex align-items-center gap-x-2">
						<b-form-group label="Buscar por">
							<b-radio-group
								:options="[
									{ text: 'Padrillo/Madrilla/Postura', value: 1 },
									{ text: 'Cintillo', value: 2 },
								]"
								v-model="typeSearch"
							/>
						</b-form-group>
						<b-input-group>
							<b-form-input
								placeholder="Placa, Alias, Postura..."
								v-model="search"
								@keypress.enter="getRows"
							/>
							<b-input-group-append>
								<b-button class="btn-icon" variant="primary" @click="getRows">
									<feather-icon icon="SearchIcon" />
								</b-button>
							</b-input-group-append>
						</b-input-group>
					</div>
				</b-col>
			</b-row>
			<b-table-simple sticky-header="70vh">
				<b-thead class="text-center text-nowrap">
					<b-tr>
						<b-th>
							<span @click="changeOrder(1)">
								Padrillo
								<template v-if="orderBy == 1">
									<feather-icon
										:class="[orderBy != 1 && 'text-muted', 'ml-05']"
										icon="ChevronUpIcon"
										v-if="order == 'asc'"
									/>
									<feather-icon
										:class="[orderBy != 1 && 'text-muted', 'ml-05']"
										icon="ChevronDownIcon"
										v-if="order == 'desc'"
									/>
								</template>
								<feather-icon class="text-muted ml-05" icon="MinusIcon" v-else />
							</span>
						</b-th>
						<b-th>
							<span @click="changeOrder(2)">
								Madrilla
								<template v-if="orderBy == 2">
									<feather-icon
										:class="[orderBy != 2 && 'text-muted', 'ml-05']"
										icon="ChevronUpIcon"
										v-if="order == 'asc'"
									/>
									<feather-icon
										:class="[orderBy != 2 && 'text-muted', 'ml-05']"
										icon="ChevronDownIcon"
										v-if="order == 'desc'"
									/>
								</template>
								<feather-icon class="text-muted ml-05" icon="MinusIcon" v-else />
							</span>
						</b-th>
						<b-th>
							<span @click="changeOrder(3)">
								Postura
								<template v-if="orderBy == 3">
									<feather-icon
										:class="[orderBy != 3 && 'text-muted', 'ml-05']"
										icon="ChevronUpIcon"
										v-if="order == 'asc'"
									/>
									<feather-icon
										:class="[orderBy != 3 && 'text-muted', 'ml-05']"
										icon="ChevronDownIcon"
										v-if="order == 'desc'"
									/>
								</template>
								<feather-icon class="text-muted ml-05" icon="MinusIcon" v-else />
							</span>
						</b-th>
						<b-th>
							<span @click="changeOrder(4)">
								Cantidad
								<template v-if="orderBy == 4">
									<feather-icon
										:class="[orderBy != 4 && 'text-muted', 'ml-05']"
										icon="ChevronUpIcon"
										v-if="order == 'asc'"
									/>
									<feather-icon
										:class="[orderBy != 4 && 'text-muted', 'ml-05']"
										icon="ChevronDownIcon"
										v-if="order == 'desc'"
									/>
								</template>
								<feather-icon class="text-muted ml-05" icon="MinusIcon" v-else />
							</span>
						</b-th>
						<b-th>Acciones</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(row, idx) in rows" :key="idx">
						<b-td>
							<SpecimenPlate :specimen="row.padrillo" />
						</b-td>
						<b-td>
							<SpecimenPlate :specimen="row.madrilla" />
						</b-td>
						<b-td>
							{{ row.code }}
						</b-td>
						<b-td>
							{{ row.quantity }}
						</b-td>
						<b-td>
							<b-button
								class="btn-icon"
								variant="primary"
								@click=";(editPostura.info = row), (editPostura.show = !editPostura.show)"
							>
								<feather-icon icon="EyeIcon" />
							</b-button>
						</b-td>
					</b-tr>
					<b-tr>
						<b-td class="text-center" colspan="5" v-if="rows.length == 0">
							No hay registros para mostrar
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
		<EditAlaModal
			:show="editPostura.show"
			:info="editPostura.info"
			@closing="editPostura.show = false"
			@refresh="getRows"
		/>
	</b-modal>
</template>

<script>
import deadsService from "@/services/deads.service"

import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import EditAlaModal from "./EditAlaModal.vue"

export default {
	components: { SpecimenPlate, EditAlaModal },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		pagination: {
			page: 1,
			perPage: 20,
			total: 0,
			from: 0,
			to: 0,
		},
		orderBy: 1,
		order: "asc",
		search: null,
		typeSearch: 1,
		rows: [],
		//
		editPostura: { show: false, info: {} },
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.pagination = {
				page: 1,
				perPage: 20,
				total: 0,
				from: 0,
				to: 0,
			}
			this.orderBy = 1
			this.order = "asc"
			this.search = null
			this.rows = []
			this.$emit("closing")
		},
		changeOrder(col) {
			if (col == this.orderBy) {
				if (this.order == "asc") this.order = "desc"
				else this.order = "asc"
				this.getRows()
				return
			}
			this.orderBy = col
			this.order = "asc"
			this.getRows()
		},
		async getRows() {
			this.isLoading = true
			const orderToStr = new Map()
			orderToStr.set(1, "alias_padrillo")
			orderToStr.set(2, "plate_madrilla")
			orderToStr.set(3, "code")
			orderToStr.set(4, "quantity")
			const { data } = await deadsService.indexAlas({
				page: this.pagination.page,
				perpage: this.pagination.perPage,
				orderBy: this.order,
				order: orderToStr.get(this.orderBy),
				search: this.search,
				type_search: this.typeSearch,
			})
			this.pagination.total = data.total
			this.pagination.from = data.from
			this.pagination.to = data.to
			if (this.pagination.page > data.last_page) this.pagination.page = data.last_page
			this.rows = data.data
			this.isLoading = false
		},
		resetRows() {
			this.pagination = {
				page: 1,
				perPage: 20,
				total: 0,
				from: 0,
				to: 0,
			}
			this.orderBy = 1
			this.order = "asc"
			this.search = null
			this.getRows()
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getRows()
			}
		},
	},
}
</script>
