<template>
	<div>
		<ValidationObserver ref="form">
			<b-table-simple class="mt-2 table-bordered" small sticky-header="80vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th>Ejemplar</b-th>
						<b-th>Razon</b-th>
						<b-th>Fecha</b-th>
						<b-th>Evidencia</b-th>
						<b-th>Acciones</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(row, idx) in rows" :key="idx">
						<b-td>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									label="query"
									style="min-width: 15rem"
									:class="{ 'border-danger rounded': !!errors[0] }"
									:clearable="false"
									:options="specimenOpts"
									:reduce="specimenReducer"
									:selectable="checkOptionSelectable"
									:getOptionKey="(opt) => `${opt.id}-${opt.chick_id}`"
									v-model="row.specimen"
									appendToBody
									:calculatePosition="positionDropdown"
									@search="fetchOpts"
									@option:selecting="handleSpecimenSelected"
								>
									<template #option="opt">
										<span>{{ opt.alias || "---" }} / ({{ opt.plate }})</span>
									</template>

									<template #selected-option="opt">
										<span>{{ opt.alias || "---" }} / ({{ opt.plate }})</span>
									</template>
								</v-select>
							</ValidationProvider>
						</b-td>
						<b-td>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									label="reason"
									style="min-width: 15rem"
									:class="{ 'border-danger rounded': !!errors[0] }"
									:options="reasonOpts"
									:clearable="false"
									:reduce="(option) => option.id"
									v-model="row.reason"
									appendToBody
									:calculatePosition="positionDropdown"
								>
									<template v-slot:option="option">
										<div class="d-flex justify-content-between">
											<span :class="{ 'pl-2': !!option.parent_id }">
												{{ option.reason }}
											</span>
											<span v-if="option.parent_id">
												Sub-motivo de {{ option.parent_reason }}
											</span>
										</div>
									</template>
								</v-select>
							</ValidationProvider>
						</b-td>
						<b-td>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<flat-pickr
									class="form-control bg-transparent"
									:class="{ 'border-danger is-invalid': !!errors[0] }"
									style="min-width: 10rem"
									v-model="row.date"
								/>
								<small class="text-danger" v-if="errors.length != 0">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-td>
						<b-td>
							<b-button
								class="btn-icon"
								variant="flat-secondary"
								@click="row.useEvidence = true"
								v-if="!row.useEvidence"
							>
								<feather-icon icon="PlusIcon" size="20" />
							</b-button>
							<div
								class="d-flex justify-content-center align-items-center gap-x-2"
								style="min-width: 10rem"
								v-else
							>
								<ShowAndUploadImg v-model="row.evidence" />
								<b-button class="btn-icon" variant="flat-danger" @click="hideEvidence(idx)">
									<feather-icon icon="TrashIcon" size="20" @click="row.useEvidence = true" />
								</b-button>
							</div>
						</b-td>
						<b-td>
							<b-button
								class="btn-icon"
								size="sm"
								variant="danger"
								@click="deleteRow(idx)"
								:disabled="rows.length < 2"
							>
								<feather-icon icon="TrashIcon" />
							</b-button>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
			<div class="d-flex justify-content-end">
				<b-button class="btn-icon" size="sm" variant="outline-success" @click="addRow">
					<feather-icon icon="PlusIcon" size="20" />
				</b-button>
			</div>
		</ValidationObserver>
	</div>
</template>

<script>
import _ from "lodash"
import moment from "moment"
import { getBase64 } from "@/helpers/functions"
import deadsService from "@/services/deads.service"
import reasonsService from "@/services/reasons.service"

import ShowAndUploadImg from "./ShowAndUploadImg.vue"

export default {
	components: { ShowAndUploadImg },
	props: {
		saveTrigger: Symbol,
	},
	data: () => ({
		rows: [],
		//
		specimenOpts: [],
		selectedSpecimens: [],
		reasonOpts: [],
		//
	}),
	methods: {
		async getOpts() {
			this.$emit("loading", true)
			const reasons = await reasonsService.getByModule()
			this.reasonOpts = reasons

			this.$emit("loading", false)
		},
		resetAll() {
			this.rows = []
			this.specimenOpts = []
			this.selectedSpecimens = []
			this.$refs.form.reset()
		},
		addRow() {
			this.rows.push({ specimen: null, reason: null, date: null, useEvidence: false, evidence: [] })
		},
		deleteRow(idx) {
			this.rows.splice(idx, 1)
		},
		positionDropdown(dropdownList, component, { width, top, left }) {
			dropdownList.style.zIndex = 9999
			dropdownList.style.maxHeight = "20rem"
			dropdownList.style.top = top
			dropdownList.style.left = left
			dropdownList.style.width = width
		},
		// search
		fetchOpts(search, loading) {
			this.findSpecimens(search, loading, this.setOpts)
		},
		findSpecimens: _.debounce(async (search, loading, setter) => {
			loading(true)
			const { data } = await deadsService.getSpecimens({ search })
			// para que el search del select tenga un campo para buscar por placa y alias a la vez
			const fixedData = data.map((opt) => ({ ...opt, query: opt.plate + "," + opt.alias }))
			setter(fixedData)
			loading(false)
		}, 500),
		setOpts(data) {
			const existingIds = this.selectedSpecimens.map((opt) => opt.id)
			const withoutDuplicateds = data.filter((opt) => !existingIds.includes(opt.id))
			this.specimenOpts = [...withoutDuplicateds, ...this.selectedSpecimens]
		},
		handleSpecimenSelected(specimen) {
			if (specimen.id === 0) {
				// el ejemplar esta en 'Nuevos Ejemplares'
				// specimen.id = specimen.chick_id
				this.selectedSpecimens.push(specimen)
				return
			}
			const existingIds = this.selectedSpecimens.map((opt) => opt.id)
			if (existingIds.includes(specimen.id)) return
			this.selectedSpecimens.push(specimen)
		},
		async hideEvidence(idx) {
			const row = this.rows[idx]
			if (row.evidence.length != 0) {
				const { isConfirmed } = await this.showConfirmSwal({
					text: `Se borraran las imagenes de evidencia seleccionadas`,
				})
				if (!isConfirmed) return
				row.evidence = []
			}
			row.useEvidence = false
		},
		async save() {
			if (!(await this.$refs.form.validate())) return
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se registrara ${this.rows.length} ejemplar(es) como MUERTO`,
			})
			if (!isConfirmed) return
			this.$emit("loading", true)
			const specimens = []
			for (let i = 0; i < this.rows.length; i++) {
				const row = this.rows[i]
				const fullSpecimen = this.selectedSpecimens.find(
					(s) => s.id == row.specimen || s.chick_id == row.specimen
				)
				if (!fullSpecimen) throw Error("Ejemplar no encontrado en opciones")

				// images
				let images = []
				for (let idx = 0; idx < row.evidence.length; idx++) {
					const img = row.evidence[idx]
					let base = undefined
					if (img.id == null) {
						base = await getBase64(img.file)
					}
					images.push({ base })
				}
				//
				specimens.push({
					id: fullSpecimen.id,
					reason_id: row.reason,
					date_dead: moment(row.date).format("YYYY-MM-DD"),
					images,
					chick_id: fullSpecimen.chick_id,
				})
			}
			await deadsService.insertDeadSpecimens({ specimens })
			this.showToast("success", "top-right", "Muertos", "CheckIcon", "Guardado con exito")
			this.resetAll()
			this.$emit("loading", false)
		},
		checkOptionSelectable(opt) {
			// 1286
			if (opt.id === 0) {
				// el ejemplar esta en 'Nuevos Ejemplares'
				const existingIds = this.rows.map((row) => row.specimen)
				if (existingIds.includes(opt.chick_id)) return false
				return true
			}
			const existingIds = this.rows.map((row) => row.specimen)
			if (existingIds.includes(opt.id)) return false
			return true
		},
		specimenReducer(opt) {
			if (opt.id === 0 && opt.chick_id != null) {
				// cuando es una opcion de 'Nuevos Ejemplares'
				return opt.chick_id
			}
			return opt.id
		},
	},
	created() {
		this.getOpts()
		this.addRow()
	},
	watch: {
		saveTrigger(val) {
			this.save()
		},
	},
}
</script>
