<template>
	<b-modal
		ref="incidents-detail-modal"
		:title="getTitle"
		centered
		v-model="isActive"
		@hidden="handleHidden"
		size="xmd"
		hide-footer
	>
		<div>
			<TableIncidence :info="info" :origin="'by_user'" />
		</div>

		<RegisterIncidenceModal
			v-if="showRegister"
			:info="infoDetail"
			@closing="showRegister = false"
			@refresh="getIncidences"
			@refresh-viewed="getIncidences()"
		/>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal"
import RegisterIncidenceModal from "@/views/amg/header-bookmarks/incidences/RegisterIncidenceModal.vue"
import IncidencesService from "@/services/incidences.service"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import TableIncidence from "@/views/amg/header-bookmarks/incidences/TableIncidence.vue"
import ViewImage from "@/components/commons/ViewImage.vue"
import { mapActions } from "vuex"

export default {
	components: { RegisterIncidenceModal, SpecimenPlate, ViewImage, TableIncidence },
	mixins: [modalMixin],
	props: {
		show: Boolean,
		info: Object,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			indexTab: 0,
			showRegister: false,
			openDetail: false,
			infoDetail: null,
			incidences: [],
			perPageOptions: [10, 25, 50, 100],
			pagination: {
				total: 0,
				curPage: 1,
				perPage: 25,
				from: 0,
				to: 0,
			},
		}
	},
	async created() {
		await this.getIncidences()
		await this.markViewed()
		await this.A_GET_INCIDENCES_COUNTER()
	},
	computed: {
		getTitle() {
			return `Incidencias de usuario: ${this.info.name}`
		},
	},
	methods: {
		...mapActions({
			A_GET_INCIDENCES_COUNTER: "commons/A_GET_INCIDENCES_COUNTER",
		}),
		async markViewed() {
			console.log("actualizando...")
			try {
				const updated = await IncidencesService.markViewed(this.info.id)
			} catch (error) {
				console.log("error:", error.response)
			}
		},
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},
		resolveLongText(text) {
			if (text.length > 25) return `${text.substring(0, 25)} ....`
			return text
		},
		async refresh() {
			await this.getIncidences()
		},
		async getIncidences() {
			this.incidences = []
			this.isPreloading()
			try {
				const { data } = await IncidencesService.getIncidences({
					is_admin: this.isAdmin ? 1 : 0,
					user_id: this.info.id,
					page: this.pagination.curPage,
					perpage: this.pagination.perPage,
				})
				this.incidences = data.data
				this.pagination.total = data.total
				this.pagination.from = data.from
				this.pagination.to = data.to
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
	watch: {
		"pagination.curPage": async function () {
			await this.getIncidences()
		},
		"pagination.perPage": async function () {
			await this.getIncidences()
		},
	},
	async mounted() {
		this.toggleModal("incidents-detail-modal")
	},
}
</script>

<style scoped></style>
<style lang="scss" scoped>
.img-specimen {
	width: 50px;
	height: 50px;
	// margin-right: 1rem;
	// border-radius: 8px;
	// box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	// padding: 0.4rem;
	object-fit: cover;
	&:hover {
		background-color: rgba(0, 0, 0, 0.7);
	}
	cursor: pointer;
	@media screen and (min-width: 700px) {
		width: 70px;
		height: 70px;
		// background: red;
	}
}
.is-seen {
	background-color: rgba(185, 230, 165, 0.3);
}
</style>
