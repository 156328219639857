<template>
	<b-navbar-nav class="nav" v-if="!isInvitado">
		<div class="d-flex justify-content-start align-items-center container-book">
			<ItemDropdownImage />

			<NetworkSpeed />

			<RegisterAttendance v-if="!isAdmin" />

			<!-- <HeaderBookmarksDeads /> -->
			<!-- Tasks Button -->
			<b-nav-item
				class="position-relative"
				@click="showDeadsModal = !showDeadsModal"
				v-b-tooltip.hover.bottom="'Registrar Muertos'"
			>
				<v-icon name="fa-skull-crossbones" scale="1.2" class="p-0 cursor-pointer text-primary" />
			</b-nav-item>
			<b-nav-item class="position-relative" @click="showTasks = !showTasks" v-b-tooltip.hover.bottom="'Tareas'">
				<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512">
					<g>
						<path
							d="M437.852 43.198C429.36 34.688 418.066 30 406.05 30h-19.945v20c0 22.056-17.924 40-39.956 40h-179.8c-22.032 0-39.956-17.944-39.956-40V30h-20.011c-24.758 0-44.922 20.164-44.95 44.95l-.432 392c-.014 12.029 4.656 23.341 13.147 31.852C82.64 507.313 93.934 512 105.95 512h299.667c24.758 0 44.923-20.164 44.95-44.95l.433-392c.014-12.029-4.656-23.341-13.148-31.852zM261.094 180h106.453c8.275 0 14.983 6.716 14.983 15s-6.708 15-14.983 15H261.094c-8.275 0-14.983-6.716-14.983-15s6.708-15 14.983-15zm0 100h106.453c8.275 0 14.983 6.716 14.983 15s-6.708 15-14.983 15H261.094c-8.275 0-14.983-6.716-14.983-15s6.708-15 14.983-15zm0 100h106.881c8.275 0 14.983 6.716 14.983 15s-6.708 15-14.983 15H261.094c-8.275 0-14.983-6.716-14.983-15s6.708-15 14.983-15zM133.431 180.592c5.852-5.858 15.338-5.858 21.19 0l8.147 8.157 34.554-34.592c5.852-5.857 15.338-5.859 21.189 0 5.851 5.857 5.851 15.355 0 21.213l-45.149 45.198a14.972 14.972 0 0 1-21.19 0l-18.742-18.764c-5.851-5.857-5.851-15.355.001-21.212zm0 107.637c5.852-5.858 15.338-5.858 21.19 0l8.147 8.156 34.554-34.592c5.851-5.857 15.338-5.857 21.189 0s5.851 15.355 0 21.213l-45.149 45.199a14.974 14.974 0 0 1-21.19 0l-18.742-18.764c-5.851-5.857-5.851-15.354.001-21.212zm0 100c5.852-5.858 15.338-5.858 21.19 0l8.147 8.156 34.554-34.592c5.851-5.857 15.338-5.857 21.189 0s5.851 15.355 0 21.213l-45.149 45.199a14.974 14.974 0 0 1-21.19 0l-18.742-18.764c-5.851-5.857-5.851-15.354.001-21.212z"
							fill="var(--primary)"
						></path>
						<path
							d="M156.361 50c0 5.523 4.472 10 9.989 10h179.8c5.517 0 9.989-4.477 9.989-10V10c0-5.523-4.472-10-9.989-10h-179.8c-5.517 0-9.989 4.477-9.989 10z"
							fill="var(--primary)"
						></path>
					</g>
				</svg>

				<span class="tasks-counter" v-if="tasksCounters && tasksCounters.pending">
					{{ tasksCounters && tasksCounters.pending }}
				</span>
			</b-nav-item>
			<b-nav-item :to="{ name: 'tournaments-calendar' }">
				<feather-icon v-b-tooltip.hover title="Calendario de torneos" icon="CalendarIcon" size="22" />
				<span class="tasks-counter" v-if="counterCalendar">
					{{ counterCalendar }}
				</span>
			</b-nav-item>
			<!-- <b-nav-item
				class="position-relative"
				@click="showDecrestModal = !showDecrestModal"
				v-b-tooltip.hover.bottom="'Descrestar'"
			>
				<feather-icon class="text-primary" icon="ScissorsIcon" size="22" />
				<span v-if="decrestAvailableCounter != 0" class="tasks-counter">
					{{ G_COUNTER_DECREST }}
				</span>
			</b-nav-item> -->

			<b-nav-item class="position-relative" @click="viewPettyCash" v-b-tooltip.hover.bottom="'Gastos'">
				<feather-icon class="text-success" icon="DollarSignIcon" size="22" />
				<span v-if="lowBalanceCounter > 0" class="tasks-counter">
					{{ lowBalanceCounter }}
				</span>
			</b-nav-item>

			<b-nav-item
				class="position-relative"
				@click="showRecomendations = true"
				v-b-tooltip.hover.bottom="'Gallos recomendados'"
				v-if="isAdmin || isPreparador"
			>
				<v-icon
					name="gi-boxing-glove"
					scale="1.3"
					class="p-0 cursor-pointer text-primary"
					style="transform: rotate(270deg)"
				/>
				<span v-if="G_COUNTER_RECOMMENDED > 0" class="tasks-counter">
					{{ G_COUNTER_RECOMMENDED }}
				</span>
			</b-nav-item>

			<b-nav-item
				class="position-relative"
				v-b-tooltip.hover.bottom="'Ejemplares sin topar'"
				@click="showWithout = true"
			>
				<img src="/training/icon.svg" alt="" class="" style="width: 24px; margin-right: 0.5rem" />
				<span v-if="totalCountDaysWithoutTopes > 0" class="tasks-counter">
					{{ totalCountDaysWithoutTopes }}
				</span>
			</b-nav-item>

			<b-nav-item
				class="position-relative"
				@click="showWeight = true"
				v-b-tooltip.hover.bottom="'Agregar pesos'"
				v-if="isAdmin || isPreparador"
			>
				<v-icon name="fa-weight" scale="1.3" class="p-0 cursor-pointer text-primary" />
				<!-- <span class="tasks-counter">12</span> -->
			</b-nav-item>

			<b-nav-item @click="showIncidences = true" v-b-tooltip.hover.bottom="'Incidencias'">
				<feather-icon class="text-primary" icon="AlertOctagonIcon" size="22" />
				<span v-if="G_INCIDENCES_COUNTER > 0 && isAdmin" class="tasks-counter">{{ G_INCIDENCES_COUNTER }}</span>
			</b-nav-item>
		</div>

		<NotesList v-if="showNotesList" />
		<DeadsModal :show="showDeadsModal" @closing="showDeadsModal = false" />
		<DecrestModal :show="showDecrestModal" @closing="showDecrestModal = false" @refresh="A_GET_NOTIFICATIONS()" />

		<SpencesByPettyCashModal v-if="showSpences" @close="showSpences = false" />
		<RecomendationsModal v-if="showRecomendations" @closing="showRecomendations = false" :isGeneral="true" />
		<DaysWithoutTopesModal v-if="showWithout" @closing="showWithout = false" />
		<MassiveWeightModal v-if="showWeight" @closing="showWeight = false" />
		<IncidencesModal v-if="showIncidences" @closing="showIncidences = false" />
	</b-navbar-nav>
</template>

<script>
import { BNavbarNav, BNavItem, BTooltip, BNavItemDropdown, BFormInput, BDropdownItem } from "bootstrap-vue"
import VuePerfectScrollbar from "vue-perfect-scrollbar"
import useAutoSuggest from "@core/components/app-auto-suggest/useAutoSuggest"
import { ref, watch } from "@vue/composition-api"
import router from "@/router"
import store from "@/store"
import searchAndBookmarkData from "../search-and-bookmark-data"
import ItemDropdownImage from "@/views/amg/header-bookmarks/ItemDropdownImage.vue"
import NetworkSpeed from "@/views/amg/NetworkSpeed.vue"
import RegisterAttendance from "@/views/amg/header-bookmarks/attendance/RegisterAttendance.vue"
import NotesList from "@/views/amg/header-bookmarks/sticky-notes/NotesList.vue"
import DeadsModal from "@/views/amg/deads/v2/DeadsModal.vue"
import DecrestModal from "@/views/amg/header-bookmarks/decrest/DecrestModal.vue"
import SpencesByPettyCashModal from "@/views/amg/management/views/petty-cash/components/SpencesByPettyCashModal.vue"
import { mapGetters, mapActions } from "vuex"
import useStickyNotes from "@/views/amg/header-bookmarks/sticky-notes/useStickyNotes"
import useTasks from "@/views/amg/header-bookmarks/tasks/useTasks.js"
import useCalendar from "@/views/amg/tournaments/v2/components/calendar/useCalendar.js"
import tournamentService from "@/services/tournament/default.service"
import decrestService from "@/services/header/decrest.service"
import PettyCashService from "@/views/amg/management/views/petty-cash/services/petty-cash.service.js"
import RecomendationsModal from "@/views/amg/preparacion/components/recommendedSpecimens/RecomendationsModal.vue"
import DaysWithoutTopesModal from "@/views/amg/preparacion/components/DaysWithoutTopesModal.vue"
import MassiveWeightModal from "@/views/amg/preparacion/components/MassiveWeightModal.vue"
import SpecimenService from "@/services/specimens.service.js"
import IncidencesModal from "@/views/amg/header-bookmarks/incidences/IncidencesModal.vue"

export default {
	name: "Bookmarks",
	components: {
		BNavbarNav,
		BNavItem,
		BTooltip,
		BNavItemDropdown,
		BFormInput,
		VuePerfectScrollbar,
		BDropdownItem,
		ItemDropdownImage,
		NetworkSpeed,
		RegisterAttendance,
		NotesList,
		DeadsModal,
		DecrestModal,
		SpencesByPettyCashModal,
		RecomendationsModal,
		DaysWithoutTopesModal,
		MassiveWeightModal,
		IncidencesModal,
	},
	computed: {
		...mapGetters({
			G_COUNTER_DECREST: "commons/G_GET_DECREST_COUNTER",
			G_COUNTER_RECOMMENDED: "commons/G_GET_RECOMMENDED_COUNTER",
			G_DAYS_WITHOUT_TOPES_COUNTER: "commons/G_GET_DAYS_WITHOUT_TOPES_COUNTER",
			G_INCIDENCES_COUNTER: "commons/G_GET_INCIDENCES_COUNTER",
		}),
		totalCountDaysWithoutTopes() {
			return this.G_DAYS_WITHOUT_TOPES_COUNTER.reduce((sum, item) => sum + item.counter, 0)
		},
	},
	methods: {
		...mapActions({
			A_GET_NOTIFICATIONS: "commons/A_GET_DECREST_COUNTER",
			A_GET_RECOMMENDED_COUNTER: "commons/A_GET_RECOMMENDED_COUNTER",
			A_GET_DAYS_WITHOUT_TOPES_COUNTER: "commons/A_GET_DAYS_WITHOUT_TOPES_COUNTER",
			A_GET_INCIDENCES_COUNTER: "commons/A_GET_INCIDENCES_COUNTER",
		}),
		viewPettyCash() {
			if (!this.isAdmin) {
				this.showSpences = true
			} else {
				if (this.$route.name !== "management-petty-cash") {
					this.$router.push({
						name: "management-petty-cash",
					})
				}
			}
		},
		async getLowBalanceCounter() {
			if (this.isAdmin) {
				const { data } = await PettyCashService.getLowBalanceCounter()
				this.lowBalanceCounter = data.balance_cont
			} else {
				const { data } = await PettyCashService.getCounterPettyCash()
				this.lowBalanceCounter = data.petty_cash_cont
			}
		},
	},
	async created() {
		await this.A_GET_NOTIFICATIONS()
		await this.A_GET_RECOMMENDED_COUNTER(this.isAdmin ? "admin" : "preparador")
		await this.A_GET_NOTIFICATIONS()
		await this.A_GET_DAYS_WITHOUT_TOPES_COUNTER()
		await this.A_GET_INCIDENCES_COUNTER()
	},
	data() {
		return {
			showSpences: false,
			showRecomendations: false,
			showWithout: false,
			showWeight: false,
			showIncidences: false,
			lowBalanceCounter: 0,
			recommendedCounter: 0,
		}
	},
	setup() {
		const searchAndBookmarkDataPages = ref(searchAndBookmarkData.pages)
		const bookmarks = ref(searchAndBookmarkData.pages.data.filter((page) => page.isBookmarked))
		const currentSelected = ref(-1)

		const perfectScrollbarSettings = {
			maxScrollbarLength: 60,
		}
		const counterCalendar = ref(0)

		const { searchQuery, resetsearchQuery, filteredData } = useAutoSuggest({
			data: { pages: searchAndBookmarkDataPages.value },
			searchLimit: 6,
		})

		watch(searchQuery, (val) => {
			store.commit("app/TOGGLE_OVERLAY", Boolean(val))
		})

		watch(filteredData, (val) => {
			currentSelected.value = val.pages && !val.pages.length ? -1 : 0
		})

		const suggestionSelected = () => {
			const suggestion = filteredData.value.pages[currentSelected.value]
			router.push(suggestion.route).catch(() => {})
			resetsearchQuery()
		}

		const toggleBookmarked = (item) => {
			// Find Index of item/page in bookmarks' array
			const pageIndexInBookmarks = bookmarks.value.findIndex((i) => i.route === item.route)

			// If index is > -1 => Item is bookmarked => Remove item from bookmarks array using index
			// Else => Item is not bookmarked => Add item to bookmarks' array
			if (pageIndexInBookmarks > -1) {
				bookmarks.value[pageIndexInBookmarks].isBookmarked = false
				bookmarks.value.splice(pageIndexInBookmarks, 1)
			} else {
				bookmarks.value.push(item)
				bookmarks.value[bookmarks.value.length - 1].isBookmarked = true
			}
		}

		const { showNotesList } = useStickyNotes()
		// Tasks
		const { showTasks, tasksCounters, counterTrigger } = useTasks()
		counterTrigger.value = Symbol()

		watch(
			() => store.state.auth.currentUser.id || null,
			() => {
				counterTrigger.value = Symbol()
			}
		)

		const { counterTrigger: calendarCounterTrigger } = useCalendar()
		watch(
			() => calendarCounterTrigger.value,
			() => {
				getCounterCalendar()
			}
		)

		const getCounterCalendar = async () => {
			const {
				data: { counter },
			} = await tournamentService.counterCalendar()

			counterCalendar.value = counter
		}

		getCounterCalendar()

		const showDeadsModal = ref(false)

		const showDecrestModal = ref(false)
		const decrestAvailableCounter = ref(0)
		async function getDecrestCounter() {
			const { data } = await decrestService.getDecrestedCounters()
			decrestAvailableCounter.value = data.find((c) => c.id == 1).counter
		}
		getDecrestCounter()

		return {
			bookmarks,
			perfectScrollbarSettings,
			currentSelected,
			suggestionSelected,
			toggleBookmarked,

			// AutoSuggest
			searchQuery,
			resetsearchQuery,
			filteredData,
			showNotesList,
			showTasks,
			tasksCounters,
			counterCalendar,
			showDeadsModal,
			showDecrestModal,
			decrestAvailableCounter,
		}
	},
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

.nav {
	// background: red;
	display: flex;
	justify-content: space-between;
	.container-nvs {
		display: flex;
		align-items: center;
	}
}
ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
p {
	margin: 0;
}

.nav-bookmar-content-overlay {
	position: fixed;
	opacity: 0;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	-webkit-transition: all 0.7s;
	transition: all 0.7s;
	z-index: -1;

	&:not(.show) {
		pointer-events: none;
	}

	&.show {
		cursor: pointer;
		z-index: 10;
		opacity: 1;
	}
}

.nav-item {
	.dropdown-menu {
		background: red;
		.show {
			background: orange;
			padding: 0 !important;
		}
	}
}
.item-bookmarks {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;
	padding: 8px;
	p {
		transition: 0.1s all ease-in-out;
	}
	&:hover {
		p {
			color: #7367f0;
			font-weight: bold;
		}
	}
}

.tasks-counter {
	position: absolute;
	top: -0.5rem;
	left: 1.4rem;
	height: 1.2rem;
	min-width: 1.2rem;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px;
	background: var(--danger);
	border-radius: 0.2rem;
	font-weight: bolder;
	font-size: 12px;
}

// .container-book {
// 	overflow-x: auto;
// 	max-width: 57vw;
// 	padding: 5px;

// 	@media screen and (min-width: 768px) {
// 		overflow: none;
// 		max-width: unset;
// 		padding: unset;
// 	}
// }

// .container-book::-webkit-scrollbar {
// 	display: none;
// }
</style>
