<template>
	<div>
		<b-modal
			ref="modal-specimens-recommended"
			centered
			no-close-on-backdrop
			:title="getTitle"
			size="xsmd"
			@hidden="handleHidden"
			:hide-footer="
				info.indexTab == 1 ||
				(tracking && ['finalizado', 'enviado'].includes(tracking.last_tracking.status)) ||
				(tracking && tracking.last_tracking.status === 'proceso_admin' && isPreparador) ||
				(tracking && tracking.last_tracking.status === 'enviado_admin' && isAdmin)
			"
		>
			<div class="d-flex justify-content-end align-item-center">
				<span class="font-weight-bold d-flex" style="gap: 0.7rem">
					Estado de recomendacion:
					<div class="d-flex flex-column align-item-center justify-content-center">
						<b-badge :variant="`light-${resolveVariant(tracking)}`">
							{{
								tracking
									? getTrackingStatus(tracking.last_tracking.status).toLocaleUpperCase()
									: "pendiente".toLocaleUpperCase()
							}}
						</b-badge>
						<span v-if="tracking" class="text-center" :class="`text-${resolveVariant(tracking)}`">
							{{ tracking.last_tracking.date | myGlobalDayShort }}
						</span>
					</div>
				</span>
			</div>
			<div>
				<p
					v-if="
						info.indexTab == 0 &&
						(!tracking || (tracking && !['finalizado', 'enviado'].includes(tracking.last_tracking.status)))
					"
				>
					Recomendar gallos
				</p>
				<b-input-group
					v-if="
						info.indexTab == 0 &&
						(!tracking || (tracking && !['finalizado', 'enviado'].includes(tracking.last_tracking.status)))
					"
				>
					<b-form-input placeholder="Ingrese placa o alias" v-model="search" @keyup.enter="searchSpecimen" />
					<b-input-group-append>
						<b-button variant="outline-primary" @click="searchSpecimen">
							<feather-icon icon="SearchIcon" />
						</b-button>
					</b-input-group-append>
				</b-input-group>
				<template v-if="!!options.length">
					<h4 class="my-1">Seleccionar</h4>
					<b-row>
						<b-col class="mb-1" cols="6" v-for="(specimen, idx) in options" :key="specimen.id">
							<div class="position-relative clickable hoverTrigger">
								<SpecimenPlate :specimen="specimen.specimen" />

								<div class="deleteOverlay deleteOverlaySuccess" @click="selectOpt(specimen)">
									<feather-icon icon="PlusIcon" size="25" />
								</div>
							</div>
						</b-col>
					</b-row>
				</template>

				<hr class="border-dark" />

				<div class="d-flex justify-content-between align-item-center">
					<h4 class="mb-1">Ejemplares</h4>
					<div>
						<span v-if="countRejected.length > 0">
							Inactivos:
							<b-badge @click="recRejected = true" variant="danger" class="cursor-pointer">
								{{ countRejected.length }}
							</b-badge>
						</span>
						<span class="px-2" v-if="countRejected.length > 0">|</span>
						<span class="font-weight-bold">
							Gallos requeridos:
							<b-badge variant="light-dark">{{ info.programming.required }}</b-badge>
						</span>
					</div>
				</div>
				<b-row class="cards-container">
					<template v-for="(specimen, idx) in specimens" v-if="specimens.length > 0">
						<b-col class="mb-1" :key="idx">
							<div class="position-relative clickable hoverTrigger card">
								<div class="card-badges">
									<div class="position-relative">
										<feather-icon
											@click="openIncidenceDetail(specimen)"
											v-b-tooltip.hover="'Ver incidencias'"
											class="text-primary"
											:class="{ 'icon-disabled': specimen.incidence_counter === 0 }"
											icon="AlertOctagonIcon"
											size="19"
											style="cursor: pointer"
										/>
										<span v-if="specimen.incidence_counter > 0" class="incidences-counter">
											{{ specimen.incidence_counter }}
										</span>
									</div>

									<b-badge v-if="specimen.created_by" variant="light-warning" class="rol-label">
										<feather-icon icon="UserIcon" size="25" />
										<span style="text-transform: capitalize">
											{{ specimen.created_by.name }}
										</span>
									</b-badge>
									<b-badge
										v-if="specimen.id && specimen.status !== 'guardado'"
										:variant="resolveTitleTooltip(specimen).variant"
										class="status-label"
										v-b-tooltip.hover
										:title="resolveTitleTooltip(specimen).title"
									>
										<div class="d-flex flex-column align-items-center justify-content-center">
											<feather-icon
												:icon="resolveTitleTooltip(specimen).icon"
												size="22"
												class="text-center"
											/>
										</div>
									</b-badge>
								</div>
								<SpecimenPlate :specimen="specimen.specimen" />

								<div
									style="display: flex; align-items: end; justify-content: space-between; width: 100%"
								>
									<div>
										<b-badge
											variant="light-info"
											class="text-primary font-weight-bold text-uppercase"
											v-if="specimen.id"
										>
											GUARDADO
										</b-badge>
										<b-badge
											variant="light-danger"
											class="text-danger font-weight-bold text-uppercase"
											v-else
										>
											SIN GUARDAR
										</b-badge>
									</div>
									<div class="card-buttons">
										<div
											class="deleteOverlay"
											@click="removeFromCreation(idx)"
											v-b-tooltip.hover="`Remover ejemplar`"
											v-if="isViewButtonDelete(specimen)"
										>
											<feather-icon icon="Trash2Icon" size="25" />
										</div>

										<div class="">
											<feather-icon
												v-if="
													(specimen.newStatus == specimen.status &&
														['aprobado'].includes(specimen.newStatus) &&
														tracking &&
														['enviado_admin', 'en proceso', 'retornado'].includes(
															tracking.last_tracking.status
														) &&
														isPreparador) ||
													(tracking &&
														['aprobado'].includes(specimen.newStatus) &&
														tracking.last_tracking.status === 'enviado' &&
														isAdmin)
												"
												icon="XCircleIcon"
												size="20"
												v-b-tooltip.hover
												title="Inactivar"
												class="text-danger cursor-pointer"
												@click="changeStatusRecommended(specimen, 'rechazado')"
											/>

											<feather-icon
												v-if="
													(specimen.newStatus != specimen.status &&
														tracking &&
														['enviado_admin', 'en proceso'].includes(
															tracking.last_tracking.status
														) &&
														isPreparador) ||
													(tracking && tracking.last_tracking.status === 'enviado' && isAdmin)
												"
												icon="CornerUpLeftIcon"
												size="20"
												class="text-warning cursor-pointer"
												v-b-tooltip.hover
												title="Regresar"
												@click="specimen.newStatus = specimen.status"
											/>
										</div>
									</div>
								</div>

								<template v-if="specimen.nursing_status_id">
									<b-badge class="text-uppercase mt-25" variant="secondary">En enfermeria</b-badge>
								</template>
							</div>
						</b-col>
					</template>
				</b-row>
				<b-card class="w-full text-center" v-if="specimens.length === 0">No se agregaron ejemplares</b-card>

				<b-card class="observation-container" v-if="observations.length">
					<p style="font-weight: 600; margin-bottom: 0; padding-bottom: 10px">Observaciones:</p>
					<div class="observation-cards">
						<div class="observation-card" v-for="observation in observations">
							<b-badge
								:variant="`light-${resolveVariantObservation(observation)}`"
								style="text-transform: capitalize"
							>
								{{ getTrackingStatus(observation.status).toLocaleUpperCase() }}
							</b-badge>
							<div class="observation-card-text">
								<p>{{ observation.observation }}</p>
								<span>{{ observation.name }} - {{ observation.created_at | myGlobalDateTime }}</span>
							</div>
						</div>
					</div>
				</b-card>
			</div>
			<template #modal-footer>
				<b-button variant="success" @click="save('save')">Guardar</b-button>
				<b-button variant="primary" @click="save('send')">Enviar</b-button>
			</template>
		</b-modal>

		<FinalizeRecommended
			v-if="modalFinalize"
			:show="modalFinalize"
			:info="{}"
			:status="''"
			:finalizeStatus="finalizeStatus"
			:tournament="tournament"
			@close="modalFinalize = false"
			@refresh="refreshModal"
			:specimens="specimens"
		/>

		<RecommendedRejectedModal
			v-if="recRejected"
			:show="recRejected"
			:info="info"
			:tracking="tracking"
			@closing="recRejected = false"
			@refresh="refreshModal"
		/>

		<SpecimenIncidencesModal
			v-if="incidenceModal.show"
			:show="incidenceModal.show"
			:info="incidenceModal.info"
			@closing="incidenceModal.show = false"
		/>
	</div>
</template>

<script>
import modalMixin from "@/mixins/modal"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import RecommendedSpecimenService from "@/services/preparacion/events/recommendedSpecimen.service.js"
import RecommendedRejectedModal from "@/views/amg/preparacion/components/recommendedSpecimens/RecommendedRejectedModal.vue"
import FinalizeRecommended from "@/views/amg/preparacion/components/recommendedSpecimens/FinalizeRecommendedModal.vue"
import moment from "moment"
import { mapActions } from "vuex"
import specimens from "@/store/modules/specimens"
import SpecimenIncidencesModal from "@/views/amg/header-bookmarks/incidences/SpecimenIncidencesModal.vue"

export default {
	components: { SpecimenPlate, FinalizeRecommended, RecommendedRejectedModal, SpecimenIncidencesModal },
	mixins: [modalMixin],
	props: {
		show: Boolean,
		info: Object,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			title: null,
			search: "",
			options: [],
			specimens: [],
			tracking: null,
			tournament: null,
			modalFinalize: false,
			finalizeStatus: "",
			observations: [],
			countRejected: [],
			recRejected: false,
			incidenceModal: {
				show: false,
				info: {},
			},
		}
	},
	async created() {
		await this.getRegisteredSpecimens()
		await this.updateToViewed()
		await this.A_GET_RECOMMENDED_COUNTER(this.isAdmin ? "admin" : "preparador")
	},
	computed: {
		getTitle() {
			return `${this.info.tournament.name} | ${moment(this.info.date).format("MM/DD/YYYY")} | ${
				this.info.stage.name
			}`
		},
	},
	methods: {
		async refreshModal() {
			this.$emit("refresh")
			await this.A_GET_RECOMMENDED_COUNTER("preparador")
			this.getRegisteredSpecimens()
		},
		...mapActions({
			A_GET_RECOMMENDED_COUNTER: "commons/A_GET_RECOMMENDED_COUNTER",
		}),
		openIncidenceDetail(data) {
			console.log(data)

			this.incidenceModal.info = data.specimen
			this.incidenceModal.show = true
		},
		resolveTitleTooltip(rec) {
			if (rec.newStatus === "rechazado")
				return {
					title: `Inactivo`,
					icon: "XIcon",
					variant: "danger",
				}
			if (["aprobado", "APROBADO"].includes(rec.newStatus))
				return {
					title: `Aprobado el ${moment(rec.updated_at).format("MM/DD/YYYY")}`,
					icon: "CheckIcon",
					variant: "success",
				}
			if (["pendiente", "guardado"].includes(rec.newStatus))
				return { title: "Pendiente", icon: "ClockIcon", variant: "dark" }
			return { title: "pendiente", icon: "ClockIcon", variant: "dark" }
		},
		resolveVariantObservation(tracking) {
			if (tracking) {
				if (tracking.status == "en proceso") {
					return "dark"
				} else if (tracking.status == "enviado") {
					return "warning"
				} else if (tracking.status == "retornado") {
					return "danger"
				} else {
					return "primary"
				}
			} else {
				return "dark"
			}
		},
		isViewButtonDelete(item) {
			if (
				(this.isAdmin && !item.id) ||
				(this.isAdmin && item.id && item.status === "guardado" && item.role === "admin")
			)
				return true
			if (
				(this.isPreparador && !item.id) ||
				(this.isPreparador &&
					(!this.tracking ||
						(this.tracking &&
							["enviado_admin", "en proceso"].includes(this.tracking.last_tracking.status))) &&
					item.id &&
					item.status === "guardado" &&
					item.role === "preparador")
			)
				return true
			return false
		},
		isViewButtonLock(item) {
			if (!this.isAdmin && item.role === "admin") return true
			if (!this.isPreparador && item.role === "preparador") return true
			return false
		},
		resolveVariant(tracking) {
			if (tracking) {
				if (tracking.last_tracking.status == "en proceso") {
					return "dark"
				} else if (tracking.last_tracking.status == "enviado") {
					return "warning"
				} else if (tracking.last_tracking.status == "retornado") {
					return "danger"
				} else {
					return "primary"
				}
			} else {
				return "dark"
			}
		},
		getTrackingStatus(tracking) {
			if (tracking) {
				if (tracking === "enviado_admin") {
					return "enviado_dueño"
				} else if (tracking === "proceso_admin") {
					return "proceso_dueño"
				} else {
					return tracking
				}
			}
		},

		async updateToViewed() {
			this.isPreloading()
			try {
				const response = await RecommendedSpecimenService.updateToViewed({
					event_id: this.info.id,
					role: this.isAdmin ? "admin" : "preparador",
				})
			} catch (error) {
				console.log("error: ", error)
			} finally {
				this.isPreloading(false)
			}
		},
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
			this.$emit("refresh")
		},
		isInactiveCurrentEvent(specimen_id) {
			const isExist = this.countRejected.find((cre) => cre.specimen.id == specimen_id)
			return isExist ? true : false
		},
		async searchSpecimen() {
			this.options = []
			if (!this.search || this.search.length < 2) {
				this.showToast(
					"warning",
					"top-right",
					"Advertencia",
					"AlertTriangleIcon",
					"La busqueda debe ser de al menos 2 caracteres"
				)
				return
			}

			this.isPreloading()
			try {
				const { data } = await RecommendedSpecimenService.getSearchRecommendedSpecimen({ search: this.search })
				this.search = null
				let opts = data.data.map((opt) => ({
					...opt,
					additional_plate: opt.additional_plate == opt.plate ? undefined : opt.additional_plate,
				}))
				if (opts.length == 0) {
					// sin resultados
					this.showToast(
						"warning",
						"top-right",
						"Advertencia",
						"AlertTriangleIcon",
						"No se encontraron resultados para la busqueda"
					)
					// this.creationModal.loading = false
					this.isPreloading(false)
					return
				}
				if (opts.length == 1) {
					if (opts[0].events && opts[0].events.length > 0) {
						const specimenActive = opts[0].events.find((event) => event.active_fronts > 0)
						if (specimenActive) {
							this.showToast(
								"warning",
								"top-right",
								"Advertencia",
								"AlertTriangleIcon",
								`Ejemplar ${
									opts[0].specimen.alias || opts[0].specimen.plate
								} esta registrado en la fecha ${moment(opts[0].events[0].te_date).format(
									"MM/DD/YYYY"
								)} del torneo ${opts[0].events[0].to_name}`
							)
							// this.creationModal.loading = false
							this.isPreloading(false)
							return
						}
					}
					if (opts[0].recommended && opts[0].recommended.length > 0) {
						this.showToast(
							"warning",
							"top-right",
							"Advertencia",
							"AlertTriangleIcon",
							`Ejemplar ${
								opts[0].specimen.alias || opts[0].specimen.plate
							} esta recomendado en la fecha ${moment(opts[0].recommended[0].te_date).format(
								"MM/DD/YYYY"
							)} del torneo ${opts[0].recommended[0].to_name}`
						)
						// this.creationModal.loading = false
						this.isPreloading(false)
						return
					}
					// un resultado
					if (this.hasRepeated(opts[0], this.specimens)) {
						this.showToast(
							"danger",
							"top-right",
							"Advertencia",
							"AlertTriangleIcon",
							`Ejemplar ${opts[0].specimen.alias || opts[0].specimen.plate} ya se encuentra agregado`
						)
						// this.creationModal.loading = false
						this.isPreloading(false)
						return
					}
					if (this.isInactiveCurrentEvent(opts[0].specimen.id)) {
						this.showToast(
							"warning",
							"top-right",
							"Advertencia",
							"AlertTriangleIcon",
							`Ejemplar ${
								opts[0].specimen.alias || opts[0].specimen.plate
							} ya esta registrado en la fecha actual como inactivo, debe volver a activarlo.`
						)
						this.isPreloading(false)
						return
					}
					const role = this.isAdmin ? "admin" : this.isPreparador ? "preparador" : "other"
					this.specimens.push({ ...opts[0], role, status: "pendiente", newStatus: "pendiente" })
					this.showToast(
						"success",
						"top-right",
						"Exito",
						"CheckIcon",
						`Ejemplar ${opts[0].specimen.alias || opts[0].specimen.plate} encontrado`
					)
					// this.creationModal.loading = false
					this.isPreloading(false)
					return
				}
				this.options = opts
				// this.creationModal.loading = false
			} catch (error) {
				console.log("error: ", error)
			} finally {
				this.isPreloading(false)
			}
		},
		selectOpt(specimen) {
			// check duplicated
			if (this.specimens.some((s) => s.specimen.id === specimen.specimen.id)) {
				this.showToast(
					"warning",
					"top-right",
					"Advertencia",
					"AlertTriangleIcon",
					`El ejemplar ${
						specimen.specimen.alias || specimen.specimen.plate
					} ya existe en la lista de ejemplares asignados`
				)
				return
			}
			if (specimen.events && specimen.events.length > 0) {
				const specimenActive = specimen.events.find((event) => event.active_fronts > 0)
				if (specimenActive) {
					this.showToast(
						"warning",
						"top-right",
						"Advertencia",
						"AlertTriangleIcon",
						`Ejemplar ${
							specimen.specimen.alias || specimen.specimen.plate
						} esta registrado en la fecha ${moment(specimenActive.te_date).format(
							"MM/DD/YYYY"
						)} del torneo ${specimenActive.to_name}`
					)
					this.loading = false
					return
				}
			}
			if (specimen.recommended && specimen.recommended.length > 0) {
				this.showToast(
					"warning",
					"top-right",
					"Advertencia",
					"AlertTriangleIcon",
					`Ejemplar ${
						specimen.specimen.alias || specimen.specimen.plate
					} esta recomendado en la fecha ${moment(specimen.recommended[0].te_date).format(
						"MM/DD/YYYY"
					)} del torneo ${specimen.recommended[0].to_name}`
				)
				this.loading = false
				return
			}
			if (this.isInactiveCurrentEvent(specimen.specimen.id)) {
				this.showToast(
					"warning",
					"top-right",
					"Advertencia",
					"AlertTriangleIcon",
					`Ejemplar ${
						specimen.specimen.alias || specimen.specimen.plate
					} ya esta registrado en la fecha actual como inactivo, debe volver a activarlo.`
				)
				this.isPreloading(false)
				return
			}
			const role = this.isAdmin ? "admin" : this.isPreparador ? "preparador" : "other"
			this.specimens.push({
				id: null,
				specimen: { ...specimen.specimen },
				delete: false,
				role,
				status: "pendiente",
				newStatus: "pendiente",
				incidence_counter: specimen.incidence_counter,
			})
			this.options = []
		},
		async removeFromCreation(idx) {
			const specimen = this.specimens[idx]

			if (specimen.id) {
				const { isConfirmed } = await this.showConfirmSwal({
					title: `Seguro de eliminar el ejemplar ${
						specimen.specimen.alias || specimen.specimen.plate
					}? (fue previamente guardado)`,
				})
				if (!isConfirmed) return
				await RecommendedSpecimenService.deleteRecommendedSpecimen({
					id: specimen.id,
				})
				this.$emit("refresh")
			}

			this.specimens[idx].delete = true
			this.specimens.splice(idx, 1)
		},
		async getRegisteredSpecimens() {
			this.isPreloading()

			try {
				const { data } = await RecommendedSpecimenService.getSpecimensToEvent({
					id: this.info.id,
					role: "preparador",
				})
				this.specimens = data.data.specimens.map((it) => ({ ...it, newStatus: it.status }))
				this.tracking = data.data.tracking
				this.observations = data.data.observations
				this.countRejected = data.data.count_rejected
			} catch (error) {
				console.log("error: ", error)
			} finally {
				this.isPreloading(false)
			}
		},
		hasRepeated(specimen, arr) {
			return arr.find((el) => el.specimen.id == specimen.specimen.id) != undefined
		},
		async save(action) {
			let newsLength = this.specimens.length
			let newsReturned = this.specimens.filter((sp) => !sp.id || (sp.id && sp.status == "guardado")).length

			// if (newsLength < 1) {
			// 	this.showToast(
			// 		"warning",
			// 		"top-right",
			// 		"Advertencia",
			// 		"AlertTriangleIcon",
			// 		`No hay nuevos ejemplares para ${action === "save" ? "guardar" : "enviar"}`
			// 	)
			// 	return
			// }

			// if (newsReturned < 1 && this.tracking && this.tracking.last_tracking.status === "retornado") {
			// 	this.showToast(
			// 		"warning",
			// 		"top-right",
			// 		"Advertencia",
			// 		"AlertTriangleIcon",
			// 		`No hay nuevos ejemplares para ${action === "save" ? "guardar" : "enviar"}`
			// 	)
			// 	return
			// }

			if (action == "send") {
				this.tournament = this.info
				this.modalFinalize = true
				this.finalizeStatus = "enviado"
				return
			}
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se ${action === "save" ? "guardaran" : "enviaran"} ${
					newsReturned > 0 ? newsReturned : newsLength
				} nuevos ejemplares`,
			})
			if (!isConfirmed) return
			this.isPreloading()

			try {
				await RecommendedSpecimenService.storeSpecimens({
					body: {
						event_id: this.info.id,
						specimens: this.specimens.map((specimen) => {
							return {
								id: specimen.id,
								specimen_id: specimen.specimen.id,
								role: specimen.role,
								new_status: specimen.newStatus,
							}
						}),
						action: action,
						role: this.isAdmin ? "admin" : "preparador",
					},
				})
				this.$emit("refresh")
				await this.A_GET_RECOMMENDED_COUNTER(this.isAdmin ? "admin" : "preparador")
				this.getRegisteredSpecimens()
				this.isActive = false
			} catch (error) {
				console.error(error)
			} finally {
				this.isPreloading(false)
			}
		},
		async changeStatusRecommended(rec, status) {
			rec.newStatus = status
		},
	},

	async mounted() {
		this.toggleModal("modal-specimens-recommended")
	},
}
</script>

<style lang="scss" scoped>
.status-label {
	// position: absolute;
	text-transform: uppercase;
}

.rol-label {
	// position: absolute;
	width: fit-content;
	padding: 0.3rem;
	display: flex;
	align-items: center;
	gap: 0.25rem;
}

.deleteOverlay {
	// position: absolute;
	// right: 1rem;
	// bottom: 1rem;
	width: 2.2rem;
	height: 2.2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.2rem;
	color: black;
	transition: 0.2s all ease-in-out;
}

.deleteOverlay.lock {
	cursor: default !important;
}

.cards-container {
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));

	@media screen and (min-width: 768px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	@media screen and (min-width: 1368px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

.card-badges {
	display: flex;
	align-items: start;
	justify-content: space-between;
	width: 100%;
}

.card-buttons {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 0.3rem;
}

.hoverTrigger {
	padding: 0.6rem;
	// height: 110px;
	display: flex;
	align-items: center;
	justify-content: center;
	// border: 2px solid peru;
}

.hoverTrigger.card {
	flex-direction: column;
	gap: 0.3rem;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	max-width: 300px;
	margin-left: auto;
	margin-right: auto;

	@media screen and (min-width: 768px) {
		max-width: unset;
		margin: unset;
	}
}

.hoverTrigger .deleteOverlay {
	opacity: 0.8;
	background: rgba(255, 0, 0, 0.2);
	border-radius: 4px;
}

.hoverTrigger .deleteOverlaySuccess {
	opacity: 0.8;
	background: rgba(0, 255, 0, 0.2);
	border-radius: 4px;
}

.hoverTrigger .deleteOverlayEdit {
	right: 3rem;
	opacity: 0.8;
	background: rgba(255, 255, 0, 0.2);
	border-radius: 4px;
}

.title-register-ejemplares {
	font-size: 14px !important;
}

.incidences-counter {
	position: absolute;
	top: -0.4rem;
	left: 1rem;
	height: 1.2rem;
	min-width: 1.2rem;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px;
	background: var(--primary);
	border-radius: 0.2rem;
	font-weight: bolder;
	font-size: 12px;
}

.icon-disabled {
	pointer-events: none;
	opacity: 0.5;
}
</style>
