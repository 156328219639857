<template>
  <div class="d-flex justify-content-between align-items-center w-100">
    <!-- <span>
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://amgsoft.us"
        target="_blank"
        >AMG</b-link
      >
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span> -->

    <!-- <span
      >Software Team &amp; Made with
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span> -->
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
};
</script>
