export default [
    {
        title: 'Participantes',
        icon: 'UserCheckIcon',
        route: 'brain-participants-list',
        requiresAdmin: true,
                requiresInvitado: true,
                requiresEncastador: false
        // tag: '2',
        // tagVariant: 'light-warning',
        
    },
];
