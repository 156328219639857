<template>
	<div class="container-picture">
		<div class="picture">
			<canvas id="canvas"></canvas>
		</div>
		<div class="container-btns d-none d-md-flex">
			<div class="refresh" @click="$emit('refresh')">
				<FeatherIcon class="" icon="RefreshCcwIcon" color="#000" size="24" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "PictureAttendance",
	components: {},
	data: function () {
		return {};
	},
	methods: {},
};
</script>

<style scoped lang="scss">
.container-picture {
	width: 214px;
	height: 214px;
	display: flex;
	justify-content: center;
	margin-bottom: 4rem;
	position: relative;
}
.picture {
	width: 214px;
	height: 214px;
	object-fit: cover;
	/* border: 1px solid orange; */
	text-align: center;
	display: flex;
	justify-content: center;
	border-radius: 12px;

	box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.25);
	canvas {
		border-radius: 12px;
	}
}
.container-btns {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	transform: translateY(-10px);
	/* background: red; */
	display: flex;
	justify-content: center;
	cursor: pointer;
	.refresh {
		background: #f5f5f5;
		height: 40px;
		width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
	}
}
</style>
