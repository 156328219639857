<template>
    <div class="container-notifications">
        <feather-icon
            icon="BellIcon"
            color="#ff9f43"
            size="22"
            @click="active = !active"
            v-b-tooltip.hover
            title="Notificaciones"
        />
        <span
            v-if="
                data.filter(item => item.status == 1).length === 0
                    ? false
                    : true
            "
            class="span"
            @click="active = !active"
            >{{ data.filter(item => item.status == 1).length }}</span
        >
        <div
            v-show="active"
            class="grid-notifications"
            :class="[active ? 'active' : null]"
        >
            <div
                @click="updateNotification(item.id)"
                class="item-notify"
                :class="[item.status == 1 ? 'inactive' : 'active']"
                v-for="(item, key) in data"
                :key="key"
            >
                <img :src="item.thumb" alt="" />
                <div class="container-text">
                    <span class="span-duration">{{ item.duration }}</span>
                    <p>{{ item.title }}</p>
                    <span>{{ item.description }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { getNotificationsVideo, updateStatusByID } from "./service.header-book";

export default {
    name: "NotificationsVideo",
    data() {
        return {
            numberNotifications: 0,
            data: [],
            active: false,
            count: 0
        };
    },
    computed: {
        ...mapState("auth", ["currentUser"])
    },
    async mounted() {
        // $request->userId,
        // $request->status
        await this.init();
    },
    methods: {
        async init() {
            const resp = await getNotificationsVideo({
                userId: this.currentUser.user_id
            });
            this.numberNotifications = resp.length;
            this.data = resp;
            console.log("DATA: ", this.data);
        },
        async updateNotification(id) {
            await updateStatusByID({
                id
            });
            this.data = this.data.map(item => {
                if (item.id == id) {
                    return {
                        ...item,
                        status: 2
                    };
                } else {
                    return item;
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
.container-notifications {
    position: relative;

    .span {
        position: absolute;
        right: -6px;
        top: -5px;
        height: 16px;
        width: 16px;
        background: rgb(240, 46, 94);
        border-radius: 100%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 10px;
    }
    .grid-notifications {
        
        position: absolute;
        top: 36px;
        left: 0;
        width: 360px;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%),
            0 2px 3px -1px rgb(0 0 0 / 10%);

        min-height: 0;
        opacity: 0;
        overflow: hidden;

        .item-notify {
            border-radius: 4px;
            padding: 0.5rem .5rem;
            display: grid;
            grid-template-columns: 2fr 8fr;
            border-bottom: 1px solid #7367f0;
            transition: .3s all ease-in-out;
            &:last-child{
                border-bottom: none;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 3px;
            }
            .container-text {
                // background: red;
                text-align: right;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                margin-left: 4px;
                position: relative;
                p {
                    margin-bottom: 0;
                    transition: .3s all ease-in-out;
                }
                span {
                    font-size: 12px;
                    font-weight: bold;
                    transition: .3s all ease-in-out;
                }
                .span-duration {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: rgb(240, 46, 94);
                    color: #fff;
                    font-size: 10px;
                    border-radius: 4px;
                    padding: 0.1rem 0.3rem;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        .inactive {
            background: #bab5f8;
            .container-text {
                p {
                    margin-bottom: 0;
                    color: #fff;
                }
                span {
                    color: #fff;
                }
                .span-duration {
                    background: rgb(240, 46, 94);
                    color: #fff;
                }
            }
        }
        .active {
            
        }
    }
    .grid-notifications::-webkit-scrollbar {
        width: 5px;
        background: #d5d1fb;
    }
    .grid-notifications::-webkit-scrollbar-thumb {
        background: #7367f0;
        border-radius: 10px;
    }
    .active {
        height: auto;
        opacity: 1;
        overflow: auto;
        max-height: 500px;
    }
}
</style>
