import axios from "@/axios"

const url = "/api/management/petty-cash";

class PettyCashService {
    async getPettyCash(params){
        try {
            const data = await axios.get(`${url}/get-petty-cash`,{params});
            return data
        } catch (error) {
            throw error
        }

    }

    async getTracking(pettyCashId){
        try {
            const data =  await axios.get(url+`/get-tracking-petty-cash/${pettyCashId}`)
            return data
        } catch (error) {
            throw error
        }
    }

    async getResponsibles(){
        try {
            const data = await axios.get(url+`/get-users-actives`);
            return data
        } catch (error) {
            throw error
        }
    }

    async registerPettyCash(body){
        try{
            const data = await axios.post(url+`/register-petty-cash`,body)
            return data
        }catch(error){
            throw error
        }
    }

    async addCash(body){
        try {
            const data = await axios.post(url+'/add-cash',body)
            return data
        } catch (error) {
            throw error
        }
    }

    async deleteTransaction(id){
        try {
            const data = await axios.delete(url+`/delete-transaction/${id}`)
            return data
        } catch (error) {
            throw error
        }
    }

    async deletePettyCash(id){
        try {
            const data = await axios.delete(url+`/delete-petty-cash/${id}`)
            return data
        } catch (error) {
            throw error
        }
    }

    async closePettyCash(id,body){
        try {
            const data = await axios.put(url+`/close-petty-cash/${id}`,body)
            return data
        } catch (error) {
            throw error
        }
    }

    async getCashBalances(params){
        try {
            const data = await axios.get(url+`/get-cash-balances`,{params})
            return data
        } catch (error) {
            throw error
        }
    }

    async getPettyCashByUserId(params){
        try {
            const data = await axios.get(url+`/get-petty-cash-by-user-id`,{params})
            return data
        } catch (error) {
            throw error
        }
    }

    async getDataPettyCashById(id){
        try {
            const data = await axios.get(url+`/get-data-petty-cash-by-id/${id}`)
            return data
        } catch (error) {
            throw error
        }
    }

    async getDescriptionsOfTransactions(){
        try {
            const data = await axios.get(url+`/get-descriptions-of-transactions`)
            return data
        } catch (error) {
            throw error
        }
    }

    async getSettingBalance(){
        try {
            const data = await axios.get(url+`/get-setting-balance`)
            return data
        } catch (error) {
            throw error
        }
    }

    async updateSettingBalance(body){
        try {
            const data = await axios.put(url+`/update-setting-balance/${body.id}`,body)
            return data
        } catch (error) {
            throw error
        }
    }

    async getLowBalanceCounter(){
        try {
            const data = await axios.get(url+`/get-low-balance-counter`)
            return data
        } catch (error) {
            throw error
        }
    }

    async getCategories(){
        try {
            const data = await axios.get(url+`/get-categories`)
            return data
        } catch (error) {
            throw error
        }
    }

    async registerCategory(body){
        try {
            const data = await axios.post(url+`/register-category`,body)
            return data
        } catch (error) {
            throw error
        }
    }

    async registerDescription(body){
        try {
            const data = await axios.post(url+`/register-description`,body)
            return data
        } catch (error) {
            throw error
        }
    }

    async getCounterPettyCash(){
        try {
            const data = await axios.get(url+`/counter-petty-cash`)
            return data
        } catch (error) {
            throw error
        }
    }
}

export default new PettyCashService()