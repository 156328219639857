<template>
	<div class="camera">
		<video autoplay playsinline muted class="feed"></video>
	</div>
</template>

<script>
export default {
	name: "CameraAttendance",
	components: {},
	data() {
		return {
			devicesArray: [],
			selectedDevice: null,
		};
	},
	async mounted() {
		await this.getDevices();
		this.init();
	},
	methods: {
		init() {
			// console.log("init", navigator.mediaDevices);
			if ("mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices) {
				let constraints = {
					audio: false,
					video: {
						width: {
							min: 700,
							ideal: 700,
							max: 700,
						},
						height: {
							min: 700,
							ideal: 700,
							max: 700,
						},
						facingMode: "user",
						deviceId: "Zz1HQOhwLWCGZitfQs+VlDqylWVhFzcI/b83Ln174KA=",
					},
				};
				navigator.mediaDevices
					.getUserMedia(constraints)
					.then((stream) => {
						const videoPlayer = document.querySelector("video");
						videoPlayer.srcObject = stream;
						this.$emit("setStream", stream);
						videoPlayer.play();
					})
					.catch((err) => {
						console.log(err);
					});
			} else {
				let jr = "not devices";
			}
		},
		async getDevices() {
			const resp = await navigator.mediaDevices.enumerateDevices();
			this.devicesArray = resp.filter((it) => it.kind == "videoinput");
		},
	},
	watch: {
		selectedDevice() {
			// console.log(this.selectedDevice);
			this.init(this.selectedDevice);
		},
	},
};
</script>

<style scoped>
.camera {
	width: 100%;
	/* height: 100vh; */
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* margin-top: 2rem; */
	box-sizing: border-box;
	/* background: #171717; */
}
.camera .feed {
	background: #171717;
	width: 100%;
	height: 100%;
	box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.25);
	transform: rotateY(180deg);
	border-radius: 12px;
	overflow: hidden;
}
.snap {
	display: flex;
	width: 75px;
	height: 75px;
	background: orange;
	color: #fff;
	font-weight: bolder;
	border-radius: 50%;
	border: none;
	justify-content: center;
	align-items: center;
	margin-top: 2rem;
	cursor: pointer;
	transition: 0.4s all ease-in-out;
	box-shadow: 4px 4px 12px 0px rgba(0, 0, 2, 0.45);
}
.snap:hover {
	background: orangered;
}
</style>
