<template>
	<b-modal ref="modal-specimen-admin" centered hide-footer :title="getTitle" size="xmd" @hidden="handleHidden">
		<div>
			<div>
				<p v-if="info.indexTab == 0 && tracking && ['enviado'].includes(tracking.last_tracking.status)">
					Recomendar gallos
				</p>
				<b-input-group
					v-if="info.indexTab == 0 && tracking && ['enviado'].includes(tracking.last_tracking.status)"
				>
					<b-form-input placeholder="Ingrese placa o alias" v-model="search" @keyup.enter="searchSpecimen" />
					<b-input-group-append>
						<b-button variant="outline-primary" @click="searchSpecimen">
							<feather-icon icon="SearchIcon" />
						</b-button>
					</b-input-group-append>
				</b-input-group>
				<template v-if="!!options.length">
					<h4 class="my-1">Seleccionar</h4>
					<b-row>
						<b-col class="mb-1" cols="6" v-for="(specimen, idx) in options" :key="specimen.id">
							<div class="position-relative clickable hoverTrigger">
								<SpecimenPlate :specimen="specimen.specimen" />

								<div class="deleteOverlay deleteOverlaySuccess" @click="selectOpt(specimen)">
									<feather-icon icon="PlusIcon" size="25" />
								</div>
							</div>
						</b-col>
					</b-row>
				</template>

				<hr class="border-dark" />
			</div>
			<div class="pb-1">
				<span v-if="countRejected.length > 0">
					Inactivos:
					<b-badge @click="recRejected = true" variant="danger" class="cursor-pointer">
						{{ countRejected.length }}
					</b-badge>
				</span>
			</div>
			<b-table-simple responsive sticky-header="50vh" no-border-collapse>
				<b-thead class="sticky-header">
					<b-th class="text-center">Ejemplar</b-th>
					<b-th class="text-center">Estado</b-th>
					<b-th class="text-center">Recomendado por</b-th>
					<b-th v-if="isFinalized && info.indexTab == 0" class="text-center">Acciones</b-th>
				</b-thead>
				<b-tbody>
					<template v-for="(rec, index) in recommendeds">
						<b-tr :key="index">
							<b-td class="text-center">
								<div style="display: flex; justify-content: center">
									<SpecimenPlate :specimen="rec.specimen" />
									<div class="position-relative">
										<feather-icon
											@click="openIncidenceDetail(rec)"
											v-b-tooltip.hover="'Ver incidencias'"
											class="text-primary"
											:class="{ 'icon-disabled': rec.incidence_counter === 0 }"
											icon="AlertOctagonIcon"
											size="19"
											style="cursor: pointer"
										/>
										<span v-if="rec.incidence_counter > 0" class="incidences-counter">
											{{ rec.incidence_counter }}
										</span>
									</div>
								</div>
							</b-td>
							<b-td class="text-center">
								<feather-icon
									v-if="rec.newStatus"
									v-b-tooltip.hover
									:title="resolveTitleTooltip(rec.newStatus).title"
									:class="`text-${resolveTitleTooltip(rec.newStatus).variant}`"
									:icon="resolveTitleTooltip(rec.newStatus).icon"
									size="22"
								/>
								<feather-icon
									v-else
									v-b-tooltip.hover
									:title="resolveTitleTooltip(rec.status).title"
									:class="`text-${resolveTitleTooltip(rec.status).variant}`"
									:icon="resolveTitleTooltip(rec.status).icon"
									size="22"
								/>
								<!-- <b-badge v-if="rec.newStatus" :variant="rec.newStatus === 'aprobado'
									? 'success'
									: rec.newStatus === 'rechazado'
										? 'danger'
										: 'light-dark'
									" class="status-label">
									{{ rec.newStatus === "rechazado" ? "CAMBIAR" : rec.newStatus.toUpperCase() }}
								</b-badge>
								<b-badge v-else :variant="rec.status === 'aprobado'
									? 'success'
									: rec.status === 'rechazado'
										? 'danger'
										: 'light-dark'
									" class="status-label">
									{{ rec.status === "rechazado" ? "CAMBIAR" : rec.status.toUpperCase() }}
								</b-badge> -->

								<!-- <p v-if="['aprobado', 'rechazado'].includes(rec.status)" class="mt-1">
									{{ rec.created_by.name }} - {{ rec.updated_at | myGlobalDayShort }}
								</p> -->
							</b-td>

							<b-td class="text-center">
								<p class="m-0">{{ rec.created_by ? rec.created_by.name : "--" }}</p>
								<p class="m-0">{{ rec.sent_at ? rec.sent_at : null | myGlobalDateTime }}</p>
							</b-td>

							<b-td
								v-if="isFinalized && info.indexTab == 0"
								class="d-flex align-items-center w-full"
								style="height: 87px"
							>
								<div
									class="d-flex justify-content-center h-100 w-100 align-items-center"
									v-if="['pendiente', 'aprobado'].includes(rec.status)"
								>
									<feather-icon
										v-if="rec.newStatus == rec.status && rec.newStatus == 'pendiente'"
										icon="CheckCircleIcon"
										size="20"
										class="text-success cursor-pointer mr-1"
										v-b-tooltip.hover
										title="Aprobar"
										@click="changeStatusRecommended(rec, 'aprobado')"
									/>
									<feather-icon
										v-if="
											rec.newStatus == rec.status &&
											['pendiente', 'aprobado'].includes(rec.newStatus)
										"
										icon="XCircleIcon"
										size="20"
										class="text-danger cursor-pointer"
										v-b-tooltip.hover
										title="Cambiar"
										@click="changeStatusRecommended(rec, 'rechazado')"
									/>
								</div>
								<div
									class="d-flex justify-content-center align-items-center"
									v-if="rec.newStatus != rec.status"
								>
									<feather-icon
										icon="CornerUpLeftIcon"
										size="20"
										class="text-warning cursor-pointer"
										v-b-tooltip.hover
										title="Regresar"
										@click="rec.newStatus = rec.status"
									/>
								</div>
								<!-- <div class="d-flex justify-content-end align-item-center p-1 w-100">
									<feather-icon
										@click="removeFromCreation(index, rec)"
										class="text-danger text-center"
										icon="Trash2Icon"
										size="20"
										v-b-tooltip.hover="`Eliminar ejemplar`"
									/>
								</div> -->
							</b-td>
						</b-tr>
					</template>
				</b-tbody>
			</b-table-simple>
			<p class="text-center" v-if="recommendeds.length === 0">No hay registros para mostrar</p>
			<b-card class="observation-container" v-if="observations.length">
				<p style="font-weight: 600; margin-bottom: 0; padding-bottom: 10px">Observaciones:</p>
				<div class="observation-cards">
					<div class="observation-card" v-for="observation in observations">
						<b-badge :variant="`light-${resolveVariant(observation)}`" style="text-transform: uppercase">
							{{ getTrackingStatus(observation.status) }}
						</b-badge>
						<div class="observation-card-text">
							<p>{{ observation.observation }}</p>
							<span>{{ observation.name }} - {{ observation.created_at | myGlobalDateTime }}</span>
						</div>
					</div>
				</div>
			</b-card>

			<div class="d-flex mt-2 justify-content-end" style="gap: 1rem" v-if="isViewButton">
				<b-button variant="outline-warning" :disabled="validateButton > 0" @click="openFinalize('retornado')">
					RETORNAR
				</b-button>
				<b-button variant="primary" :disabled="validateButton > 0" @click="openFinalize('finalizado')">
					FINALIZAR
				</b-button>
			</div>

			<FinalizeRecommended
				v-if="modalFinalize"
				:show="modalFinalize"
				:info="infoFinalize"
				:status="status"
				:finalizeStatus="finalizeStatus"
				:tournament="tournament"
				@close="modalFinalize = false"
				@refresh="getRecommendeds"
			/>

			<RecommendedRejectedModal
				v-if="recRejected"
				:show="recRejected"
				:info="info"
				:tracking="tracking"
				@closing="recRejected = false"
				@refresh="getRecommendeds"
			/>

			<SpecimenIncidencesModal
				v-if="incidenceModal.show"
				:show="incidenceModal.show"
				:info="incidenceModal.info"
				@closing="incidenceModal.show = false"
			/>
		</div>
	</b-modal>
</template>

<script>
import RecommendedSpecimenService from "@/services/preparacion/events/recommendedSpecimen.service.js"
import FinalizeRecommended from "@/views/amg/preparacion/components/recommendedSpecimens/FinalizeRecommendedModal.vue"
import RecommendedRejectedModal from "@/views/amg/preparacion/components/recommendedSpecimens/RecommendedRejectedModal.vue"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import modalMixin from "@/mixins/modal"
import moment from "moment"
import { mapActions } from "vuex"
import SpecimenIncidencesModal from "@/views/amg/header-bookmarks/incidences/SpecimenIncidencesModal.vue"

export default {
	components: {
		SpecimenPlate,
		FinalizeRecommended,
		RecommendedRejectedModal,
		SpecimenIncidencesModal,
	},
	mixins: [modalMixin],
	props: {
		show: Boolean,
		info: Object,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			modalFinalize: false,
			infoFinalize: {},
			recommendeds: [],
			status: "",
			search: "",
			options: [],
			tracking: null,
			observations: [],
			tournament: null,
			finalizeStatus: "",
			countRejected: [],
			recRejected: false,
			incidenceModal: {
				show: false,
				info: {},
			},
		}
	},
	async created() {
		await this.updateToViewed()
		await this.getRecommendeds()
		await this.A_GET_RECOMMENDED_COUNTER(this.isAdmin ? "admin" : "preparador")
	},
	computed: {
		getTitle() {
			return `Ejemplares recomendados | ${this.info.tournament.name} | ${moment(this.info.date).format(
				"MM/DD/YYYY"
			)}`
		},
		validateButton() {
			const counter = this.recommendeds.filter((re) => re.newStatus == "pendiente").length
			return counter
		},
		isViewButton() {
			if (this.tracking) {
				if (!this.tracking.last_tracking) return false
				if (this.tracking.last_tracking.status === "enviado" && this.info.indexTab === 0) {
					return true
				}
				return false
			}
			return false
		},
		isFinalized() {
			if (
				this.tracking &&
				this.tracking.last_tracking &&
				["enviado"].includes(this.tracking.last_tracking.status)
			) {
				return true
			}
			return false
		},
	},
	methods: {
		...mapActions({
			A_GET_RECOMMENDED_COUNTER: "commons/A_GET_RECOMMENDED_COUNTER",
		}),
		openIncidenceDetail(data) {
			this.incidenceModal.info = data.specimen
			this.incidenceModal.show = true
		},
		resolveTitleTooltip(status) {
			if (status === "rechazado") return { title: "Cambiar", icon: "XIcon", variant: "danger" }
			if (["aprobado", "APROBADO"].includes(status))
				return { title: "Aprobado", icon: "CheckIcon", variant: "success" }
			if (["pendiente", "guardado"].includes(status))
				return { title: "Pendiente", icon: "ClockIcon", variant: "dark" }
			return { title: "pendiente", icon: "ClockIcon", variant: "dark" }
		},
		resolveVariant(tracking) {
			if (tracking) {
				if (tracking.status == "en proceso") {
					return "dark"
				} else if (tracking.status == "enviado") {
					return "warning"
				} else if (tracking.status == "retornado") {
					return "danger"
				} else {
					return "primary"
				}
			} else {
				return "dark"
			}
		},
		getTrackingStatus(tracking) {
			if (tracking) {
				if (tracking === "enviado_admin") {
					return "enviado_dueño"
				} else if (tracking === "proceso_admin") {
					return "proceso_dueño"
				} else {
					return tracking
				}
			}
		},
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
			this.$emit("refresh")
		},
		statusMessage(status) {
			return status === "aprobado"
				? `se agregara al TORNEO: ${this.info.tournament.name}, con FECHA: ${moment(this.info.date).format(
						"MM/DD/YYYY"
				  )}.`
				: "quedara disponible para recomendar a otra fecha."
		},

		async updateToViewed() {
			this.isPreloading()
			try {
				const response = await RecommendedSpecimenService.updateToViewed({
					event_id: this.info.id,
					role: this.isAdmin ? "admin" : "preparador",
				})
			} catch (error) {
				console.log("error: ", error)
			} finally {
				this.isPreloading(false)
			}
		},
		async openFinalize(status) {
			this.tournament = { ...this.info, specimens: this.recommendeds }
			this.modalFinalize = true
			this.finalizeStatus = status
		},
		async getRecommendeds() {
			this.isPreloading()
			try {
				const { data } = await RecommendedSpecimenService.getSpecimensToEvent({
					id: this.info.id,
					role: "admin",
				})
				this.recommendeds = data.data.specimens.map((sp) => {
					return {
						...sp,
						// newStatus: ["aprobado", "rechazado"].includes(sp.status) ? sp.status : null,
						newStatus: sp.status,
					}
				})
				this.tracking = data.data.tracking
				this.observations = data.data.observations
				this.countRejected = data.data.count_rejected
			} catch (error) {
				console.log("error: ", error)
			} finally {
				this.isPreloading(false)
			}
		},

		async changeStatusRecommended(rec, status) {
			// const { isConfirmed } = await this.showConfirmSwal({
			// 	text: `Al ${status === "aprobado" ? "aprobar" : "rechazar"} la recomendacion del ejemplar ${
			// 		rec.specimen.plate
			// 	}, ${this.statusMessage(status)}`,
			// })
			// if (!isConfirmed) return
			// this.isPreloading()

			// try {
			// 	const registered = await RecommendedSpecimenService.changeStatusRecommended({
			// 		id: rec.id,
			// 		status: status,
			// 	})
			// 	this.showSuccessToast(registered.data.message)
			// 	this.getRecommendeds()
			// } catch (error) {
			// 	console.log("error: ", error)
			// } finally {
			// 	this.isPreloading(false)
			// }
			rec.newStatus = status
		},
		isInactiveCurrentEvent(specimen_id) {
			const isExist = this.countRejected.find((cre) => cre.specimen.id == specimen_id)
			return isExist ? true : false
		},
		async searchSpecimen() {
			this.options = []
			if (!this.search || this.search.length < 2) {
				this.showToast(
					"warning",
					"top-right",
					"Advertencia",
					"AlertTriangleIcon",
					"La busqueda debe ser de al menos 2 caracteres"
				)
				return
			}

			this.isPreloading()
			try {
				const { data } = await RecommendedSpecimenService.getSearchRecommendedSpecimen({ search: this.search })
				this.search = null
				let opts = data.data.map((opt) => ({
					...opt,
					additional_plate: opt.additional_plate == opt.plate ? undefined : opt.additional_plate,
				}))
				if (opts.length == 0) {
					// sin resultados
					this.showToast(
						"warning",
						"top-right",
						"Advertencia",
						"AlertTriangleIcon",
						"No se encontraron resultados para la busqueda"
					)
					// this.creationModal.loading = false
					this.isPreloading(false)
					return
				}
				if (opts.length == 1) {
					if (opts[0].events && opts[0].events.length > 0) {
						const specimenActive = opts[0].events.find((event) => event.active_fronts > 0)
						if (specimenActive) {
							this.showToast(
								"warning",
								"top-right",
								"Advertencia",
								"AlertTriangleIcon",
								`Ejemplar ${
									opts[0].specimen.alias || opts[0].specimen.plate
								} esta registrado en la fecha ${moment(opts[0].events[0].te_date).format(
									"MM-DD-YYYY"
								)} del torneo ${opts[0].events[0].to_name}`
							)
							// this.creationModal.loading = false
							this.isPreloading(false)
							return
						}
					}
					if (opts[0].recommended && opts[0].recommended.length > 0) {
						this.showToast(
							"warning",
							"top-right",
							"Advertencia",
							"AlertTriangleIcon",
							`Ejemplar ${
								opts[0].specimen.alias || opts[0].specimen.plate
							} esta recomendado en la fecha ${moment(opts[0].recommended[0].te_date).format(
								"MM-DD-YYYY"
							)} del torneo ${opts[0].recommended[0].to_name}`
						)
						// this.creationModal.loading = false
						this.isPreloading(false)
						return
					}
					// un resultado
					if (this.hasRepeated(opts[0], this.recommendeds)) {
						this.showToast(
							"danger",
							"top-right",
							"Advertencia",
							"AlertTriangleIcon",
							`Ejemplar ${opts[0].specimen.alias || opts[0].specimen.plate} ya se encuentra agregado`
						)
						// this.creationModal.loading = false
						this.isPreloading(false)
						return
					}

					if (this.isInactiveCurrentEvent(opts[0].specimen.id)) {
						this.showToast(
							"warning",
							"top-right",
							"Advertencia",
							"AlertTriangleIcon",
							`Ejemplar ${
								opts[0].specimen.alias || opts[0].specimen.plate
							} ya esta registrado en la fecha actual como inactivo, debe volver a activarlo.`
						)
						this.isPreloading(false)
						return
					}

					const role = this.isAdmin ? "admin" : this.isPreparador ? "preparador" : "other"
					this.recommendeds.unshift({
						id: null,
						...opts[0],
						status: "aprobado",
						role,
						restricted: 0,
						newStatus: "APROBADO",
					})
					this.showToast(
						"success",
						"top-right",
						"Exito",
						"CheckIcon",
						`Ejemplar ${opts[0].specimen.alias || opts[0].specimen.plate} encontrado`
					)
					// this.creationModal.loading = false
					this.isPreloading(false)
					return
				}
				this.options = opts
				// this.creationModal.loading = false
			} catch (error) {
				console.log("error: ", error)
			} finally {
				this.isPreloading(false)
			}
		},
		selectOpt(specimen) {
			// check duplicated
			if (this.recommendeds.some((s) => s.specimen.id === specimen.specimen.id)) {
				this.showToast(
					"warning",
					"top-right",
					"Advertencia",
					"AlertTriangleIcon",
					`El ejemplar ${
						specimen.specimen.alias || specimen.specimen.plate
					} ya existe en la lista de ejemplares asignados`
				)
				return
			}
			if (specimen.events && specimen.events.length > 0) {
				const specimenActive = specimen.events.find((event) => event.active_fronts > 0)
				if (specimenActive) {
					this.showToast(
						"warning",
						"top-right",
						"Advertencia",
						"AlertTriangleIcon",
						`Ejemplar ${
							specimen.specimen.alias || specimen.specimen.plate
						} esta registrado en la fecha ${moment(specimenActive.te_date).format(
							"MM-DD-YYYY"
						)} del torneo ${specimenActive.to_name}`
					)
					this.loading = false
					return
				}
			}
			if (specimen.recommended && specimen.recommended.length > 0) {
				this.showToast(
					"warning",
					"top-right",
					"Advertencia",
					"AlertTriangleIcon",
					`Ejemplar ${
						specimen.specimen.alias || specimen.specimen.plate
					} esta recomendado en la fecha ${moment(specimen.recommended[0].te_date).format(
						"MM-DD-YYYY"
					)} del torneo ${specimen.recommended[0].to_name}`
				)
				this.loading = false
				return
			}

			if (this.isInactiveCurrentEvent(specimen.specimen.id)) {
				this.showToast(
					"warning",
					"top-right",
					"Advertencia",
					"AlertTriangleIcon",
					`Ejemplar ${
						specimen.specimen.alias || specimen.specimen.plate
					} ya esta registrado en la fecha actual como inactivo, debe volver a activarlo.`
				)
				this.isPreloading(false)
				return
			}

			const role = this.isAdmin ? "admin" : this.isPreparador ? "preparador" : "other"
			this.recommendeds.unshift({
				id: null,
				specimen: { ...specimen.specimen },
				status: "aprobado",
				delete: false,
				role,
				restricted: 0,
				newStatus: "APROBADO",
			})
			this.options = []
		},
		async removeFromCreation(idx, recommended) {
			const specimen = this.recommendeds[idx]
			if (!specimen.id) {
				this.recommendeds = this.recommendeds.filter((item, index) => index != idx)
				return
			}
			if (recommended.id != null) {
				const confirm = await this.showConfirmSwal({
					html: `<p style="margin:0 0 8px">Se eliminara ejemplar recomendado con placa: <span class='font-weight-bold'> ${recommended.specimen.plate}</span>.</p> `,
				})
				if (!confirm.isConfirmed) return
				this.isPreloading()
				try {
					const { data } = await RecommendedSpecimenService.deleteRecommendedSpecimen({ id: recommended.id })
					this.showSuccessToast(data.message)
					await this.getRecommendeds()
				} catch (error) {
					console.log("error: ", error.response)
					this.showToast(
						"warning",
						"top-right",
						"Advertencia",
						"AlertTriangleIcon",
						`Ocurrio un error al intentar eliminar al ejemplar ${recommended.specimen.plate}.`
					)
					this.loading = false
				} finally {
					this.isPreloading(false)
				}
			}
		},
		hasRepeated(specimen, arr) {
			return arr.find((el) => el.specimen.id == specimen.specimen.id) != undefined
		},
	},
	async mounted() {
		this.toggleModal("modal-specimen-admin")
	},
}
</script>

<style>
.observation-cards {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding-block: 1.3rem;
	padding-inline: 1rem;
	max-height: 20vh;
	overflow-y: auto;
}

.observation-card {
	display: flex;
	align-items: start;
	gap: 1rem;
	width: fit-content;
	padding-block: 1.3rem;
	padding-inline: 1.3rem;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	border-radius: 10px;
	min-width: 150px;
}

.observation-card-text {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 0.5rem;
}

.observation-card-text p {
	margin-bottom: 0;
	width: 100%;
	word-break: break-all;
}

.observation-card-text span {
	font-weight: 500;
}
</style>

<style lang="scss" scoped>
.hoverTrigger {
	padding: 0.6rem;
	// height: 110px;
	display: flex;
	align-items: center;
	justify-content: center;
	// border: 2px solid peru;
}

.hoverTrigger.card {
	flex-direction: column;
	gap: 0.3rem;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	max-width: 300px;
	margin-left: auto;
	margin-right: auto;

	@media screen and (min-width: 768px) {
		max-width: unset;
		margin: unset;
	}
}

.hoverTrigger .deleteOverlay {
	opacity: 0.8;
	background: rgba(255, 0, 0, 0.2);
	border-radius: 4px;
}

.hoverTrigger .deleteOverlaySuccess {
	opacity: 0.8;
	background: rgba(0, 255, 0, 0.2);
	border-radius: 4px;
}

.hoverTrigger .deleteOverlayEdit {
	right: 3rem;
	opacity: 0.8;
	background: rgba(255, 255, 0, 0.2);
	border-radius: 4px;
}

.incidences-counter {
	position: absolute;
	top: -0.4rem;
	left: 1rem;
	height: 1.2rem;
	min-width: 1.2rem;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px;
	background: var(--primary);
	border-radius: 0.2rem;
	font-weight: bolder;
	font-size: 12px;
}

.icon-disabled {
	pointer-events: none;
	opacity: 0.5;
}
</style>
