<template>
	<div v-if="!load" class="swiper" :style="{ gridTemplateColumns: `repeat(${sliders.length}, 1fr) !important` }">
		<div class="swiper-wrapper">
			<div class="swiper-slide" v-for="(item, key) in sliders" :key="key">
				<template v-if="item">
					<div
						class="container-days"
						:class="[day.feature_days ? 'bg-white' : 'bg-not-select']"
						v-for="(day, index) in item"
						:key="index"
					>
						<!-- {{ sliders.length }} -->
						<div
							class="header"
							v-if="day"
							:class="[
								index == 0 ? 'header-left' : '',
								index == 6 ? 'border-right' : '',
								day.feature_days ? 'bg-header-select' : '',
							]"
						>
							<p class="mb-0 number" :class="[day.date_num == dateCurrent(new Date()) ? 'active' : '']">
								{{ day.day }}
							</p>
							<p class="text mt-25" v-if="day.day_text">{{ day.day_text.name }}</p>
							<!-- {{ index  }} {{ item.length + 1 }} -->
						</div>
						<template>
							<div v-for="(date, index_day) in items" :key="index_day" class="day">
								<div class="skeletor">
									<template v-if="day.feature_days">
										<v-icon
											name="ri-close-circle-fill"
											scale="1.2"
											class="cursor-pointer text-warning"
										/>
										<p>Ausente</p>
									</template>
									<!-- <img v-if="day.feature_days" src="/headers/calendar.svg" alt="" /> -->
								</div>
								<template v-for="(dt, index_d) in date.days">
									<template v-if="dt.date == day.date_num">
										<div class="container-text">
											<!-- <div class="ip" v-if="dt.hours[0].ip">
												<p>{{ dt.hours[0].ip }}</p>
											</div> -->
											<p
												v-b-tooltip.hover
												:title="dt.hours[0].ip"
												class="init"
												v-if="dt.hours[0]"
												@click="
													modalImage.show = true;
													modalImage.image = dt.hours[0].image;
													modalImage.ip = dt.hours[0].ip;
												"
											>
												<v-icon
													name="bi-arrow-right-circle-fill"
													scale="1.2"
													class="cursor-pointer text-success"
												/>
												<!--  -->
												<span>{{ dt.hours[0].hour }}</span>
											</p>
											<p
												v-b-tooltip.hover
												:title="dt.hours[1].ip"
												class="finished"
												v-if="dt.hours[1]"
												@click="
													modalImage.show = true;
													modalImage.image = dt.hours[1].image;
													modalImage.ip = dt.hours[1].ip;
												"
											>
												<v-icon
													name="bi-arrow-left-circle-fill"
													scale="1.2"
													class="cursor-pointer text-danger"
												/>
												<span>{{ dt.hours[1].hour }}</span>
											</p>
										</div>
									</template>
								</template>
							</div>
						</template>
					</div>
				</template>
			</div>
		</div>

		<b-modal hide-footer v-model="modalImage.show" size="xs" centered hide-header body-class="m-0 p-0">
			<!-- <h2>Hola</h2> -->
			<img class="image-attendace" :src="modalImage.image ? modalImage.image : '/rooster/rooster.jpg'" alt="" />
			<p class="ip">{{ modalImage.ip }}</p>
		</b-modal>
	</div>
</template>

<script>
import moment from "moment";
import Swiper from "swiper";

export default {
	name: "SlidersAttendance",
	props: {
		sliders: {
			type: Array,
			default: () => {},
		},
		items: {
			type: Array,
			default: () => {},
		},
		swiper: {
			type: Object,
			default: () => {},
		},
		load: {
			type: Boolean,
		},
	},
	data() {
		return {
			sw: null,
			modalImage: {
				show: false,
				image: null,
				ip: null,
			},
		};
	},
	mounted() {
		this.sw = new Swiper(".swiper", {
			speed: 400,
			spaceBetween: 100,
			slidesPerView: 1,
			spaceBetween: 10,

			pagination: {
				el: ".swiper-pagination",
				type: "bullets",
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
		});
		this.$emit("setSwiper", this.sw);
	},
	computed: {
		dateCurrent: () => (date) => {
			return moment(date).format("YYYY-MM-DD");
		},
	},
};
</script>

<style lang="scss">
.grid-3 {
	grid-template-columns: repeat(3, 1fr);
}
.image-attendace {
	width: 100%;
	height: 300px;
	object-fit: cover;
	// border-radius: 8px;
	overflow: hidden;
}
.bg-not-select {
	// background: #e7e7e7;
	.header {
		border-bottom: 1px solid rgba(217, 217, 217, 1);
		border-top: 1px solid rgba(217, 217, 217, 1);
		p {
			color: rgba(139, 139, 139, 1) !important;
		}
	}
}

.circle-attendance {
	height: 20px;
	width: 20px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.ip{
	position: absolute;
	bottom: 0;
	margin-bottom: 0;
	text-align: center;
	font-size: 16px;
	font-weight: bolder;
	padding: .4rem 0 ;
	right: 0;
	left: 0;
	width: 100%;
	color: #fff;
	background: var(--primary);
}
</style>
