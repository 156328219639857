<template>
	<b-modal
		centered
		hide-footer
		header-bg-variant="primary"
		title-class="text-white"
		size="lg"
		title="Descreste"
		v-model="isActive"
		@hidden="handleHidden"
	>
		<b-overlay :show="isLoading">
			<div class="d-flex justify-content-start mb-0">
				<b-tabs
					class="decrestTabs"
					v-model="tab"
					:small="widthScreen < 425 ? true : false"
					@input="tabChange"
					pills
				>
					<b-tab>
						<template #title>
							Disponibles
							<b-badge class="ml-05 mb-0" variant="danger" v-if="counters.available">
								{{ counters.available }}
							</b-badge>
						</template>
					</b-tab>
					<b-tab>
						<template #title>
							Realizados
							<b-badge class="ml-05" variant="danger" v-if="counters.decrested">
								{{ counters.decrested }}
							</b-badge>
						</template>
					</b-tab>
				</b-tabs>
			</div>
			<hr class="mb-1" />
			<div v-if="tab == 0">
				<div class="d-flex flex-column gap-y-2 container-modal-header">
					<b-row class="d-flex align-items-center justify-content-between gap-y-2">
						<b-col cols="12" xl="6" class="d-flex gap-x-2">
							<b-input-group v-if="filterType">
								<b-input-group-prepend>
									<b-button class="btn-icon" variant="outline-secondary" disabled>
										<feather-icon icon="SearchIcon" />
									</b-button>
								</b-input-group-prepend>
								<b-form-input
									placeholder="Buscar placa o alias..."
									v-model="filters.search"
									@keypress.enter="getAvailableSpecimens"
								/>
							</b-input-group>
							<b-button variant="primary" @click="getAvailableSpecimens">Buscar</b-button>
							<b-button class="btn-icon" variant="outline-secondary">
								<feather-icon icon="RefreshCcwIcon" @click="clearFilters(), getAvailableSpecimens()" />
							</b-button>
						</b-col>
						<b-col cols="12" xl="6" class="d-flex justify-content-md-end gap-x-2">
							<b-button
								@click="handleCleanDecrest"
								variant="outline-primary"
								:disabled="isLoading || !availablesSelected"
							>
								Limpiar
							</b-button>
							<b-button
								variant="primary"
								:disabled="isLoading || !availablesSelected"
								@click="processSelecteds"
							>
								<feather-icon icon="ScissorsIcon" style="margin-right: 0.5rem" />
								Descrestar {{ availablesSelected ? `(${availablesSelected})` : "" }}
							</b-button>
						</b-col>
					</b-row>
				</div>
				<hr />
				<b-row class="mb-2 gap-y-2 p-1" style="max-height: 60vh; overflow-y: auto; overflow-x: hidden">
					<b-col cols="12" md="6" lg="4" v-for="available in availables" :key="available.specimen.id">
						<SpecimenOpt
							:specimen="available.specimen"
							:value="available.checked"
							@input=";(available.checked = $event), handleCheckBoxEvent(available.decrest_id, available)"
						/>
					</b-col>
					<b-col cols="12" class="text-center" v-if="availables.length == 0">
						No hay ejemplares disponibles
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12" md="6" class="d-flex align-items-center pagination-label">
						<p style="margin: 0 0 0 1.2rem">
							Mostrando {{ this.from }} a {{ this.to }} de {{ filters.total }} ejemplares disponibles
						</p>
					</b-col>
					<b-col cols="12" md="6" class="d-flex justify-content-end">
						<b-pagination
							@input="getAvailableSpecimens"
							v-model="currentPage"
							:total-rows="this.filters.total"
							:per-page="30"
							first-number
							last-number
							align="right"
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
			<div v-else-if="tab == 1">
				<div>
					<b-row class="filters-content mb-2 gap-y-2">
						<b-col cols="12" lg="6" class="d-flex gap-x-2">
							<b-input-group>
								<b-input-group-prepend>
									<b-button class="btn-icon" variant="outline-secondary" disabled>
										<feather-icon icon="SearchIcon" />
									</b-button>
								</b-input-group-prepend>
								<b-form-input
									placeholder="Buscar placa o alias"
									v-model="filters.search"
									@keypress.enter="getDecrestedSpecimens"
								/>
							</b-input-group>
							<b-button variant="primary" @click="getDecrestedSpecimens">Buscar</b-button>
						</b-col>
						<b-col cols="12" lg="6" class="d-flex justify-content-lg-end gap-x-2">
							<b-button class="btn-icon" variant="outline-secondary">
								<feather-icon icon="RefreshCcwIcon" @click="clearFilters(), getDecrestedSpecimens()" />
							</b-button>
							<b-button
								class="btn-icon"
								variant="outline-primary"
								@click="refreshConditional(), (filterType = 'calendar')"
								v-if="filterType == 'search'"
								v-b-tooltip.hover.top="'Filtros'"
							>
								<feather-icon icon="FilterIcon" />
							</b-button>
							<b-button
								class="btn-icon"
								variant="outline-primary"
								@click="refreshConditional(), (filterType = 'search')"
								v-else
								v-b-tooltip.hover.top="'Cerrar filtros'"
							>
								<feather-icon icon="XCircleIcon" />
							</b-button>
						</b-col>
					</b-row>
					<b-row v-if="filterType == 'calendar'" class="d-flex justify-content-start gap-y-2">
						<b-col cols="12" md="6" lg="4">
							<b-input-group prepend="Temporada">
								<b-form-select
									text-field="season"
									value-field="id"
									:options="seasonOpts"
									placeholder="Seleccionar"
									v-model="filters.season"
									@change="seasonSelected"
								/>
							</b-input-group>
						</b-col>
						<b-col cols="12" md="6" lg="4">
							<b-input-group prepend="Desde">
								<b-form-datepicker
									placeholder="Seleccionar"
									:date-format-options="{ month: '2-digit', day: '2-digit', year: 'numeric' }"
									v-model="filters.dateFrom"
									:max="filters.dateTo"
									@input="searchInputsDate()"
								/>
							</b-input-group>
						</b-col>
						<b-col cols="12" md="6" lg="4">
							<b-input-group prepend="Hasta">
								<b-form-datepicker
									:disabled="filters.dateFrom ? false : true"
									placeholder="Seleccionar"
									:date-format-options="{ month: '2-digit', day: '2-digit', year: 'numeric' }"
									:min="filters.dateFrom"
									v-model="filters.dateTo"
									@input="searchInputsDate()"
								/>
							</b-input-group>
						</b-col>
					</b-row>
				</div>
				<hr />
				<b-table-simple class="text-center rounded" sticky-header="60vh">
					<b-thead head-variant="dark">
						<b-tr class="text-white" variant="primary">
							<b-td class="">
								<span @click="changeOrder(1)">
									Ejemplar
									<template v-if="filters.orderBy == 1">
										<feather-icon
											:class="[filters.orderBy != 1 && 'text-muted', 'ml-05']"
											icon="ChevronUpIcon"
											v-if="filters.order == 'asc'"
										/>
										<feather-icon
											:class="[filters.orderBy != 1 && 'text-muted', 'ml-05']"
											icon="ChevronDownIcon"
											v-if="filters.order == 'desc'"
										/>
									</template>
									<feather-icon class="text-muted ml-05" icon="MinusIcon" v-else />
								</span>
							</b-td>
							<b-td>
								<span @click="changeOrder(2)">
									Realizado por
									<template v-if="filters.orderBy == 2">
										<feather-icon
											:class="[filters.orderBy != 2 && 'text-muted', 'ml-05']"
											icon="ChevronUpIcon"
											v-if="filters.order == 'asc'"
										/>
										<feather-icon
											:class="[filters.orderBy != 2 && 'text-muted', 'ml-05']"
											icon="ChevronDownIcon"
											v-if="filters.order == 'desc'"
										/>
									</template>
									<feather-icon class="text-muted ml-05" icon="MinusIcon" v-else />
								</span>
							</b-td>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr v-for="(decrested, idx) in decresteds" :key="idx">
							<b-td class="border-bottom-primary border-left-primary">
								<SpecimenPlate :specimen="decrested.specimen" />
							</b-td>
							<b-td class="border-bottom-primary border-right-primary">
								<span>{{ decrested.updated_by.name }}</span>
								<br />
								<small>{{ decrested.updated_by.date | myGlobalDayShort }}</small>
							</b-td>
						</b-tr>
						<b-tr v-if="decresteds.length == 0">
							<b-td colspan="2">No hay registros para mostrar</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
				<b-row>
					<b-col cols="12" lg="6">
						<span class=" " style="margin-left: 1.2rem">
							Mostrando {{ filters.from }} a {{ filters.to }} de {{ filters.total }} registros
						</span>
					</b-col>
					<b-col cols="12" lg="6" class="d-flex justify-content-end">
						<b-pagination
							class="m-0"
							pills
							hide-goto-end-buttons
							hide-ellipsis
							v-model="filters.page"
							:total-rows="filters.total"
							:per-page="filters.perPage"
							@input="getDecrestedSpecimens()"
						/>
					</b-col>
				</b-row>
			</div>
		</b-overlay>
		<template #modal-footer></template>
	</b-modal>
</template>

<script>
import decrestService from "@/services/header/decrest.service"

import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import SpecimenOpt from "./SpecimenOpt.vue"

export default {
	components: { SpecimenPlate, SpecimenOpt },
	props: {
		show: Boolean,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		tab: 0,
		filterType: "search",
		availablesSelection: [],
		availables: [],
		decresteds: [],
		filters: {
			search: "",
			dateFrom: null,
			dateTo: null,
			orderBy: 1,
			order: "desc",
			total: 0,
			page: 1,
			from: 0,
			to: 0,
			season: null,
		},
		counters: {
			available: 0,
			decrested: 0,
		},
		from: null,
		to: null,
		currentPage: 1,
	}),
	computed: {
		availablesSelected() {
			return this.availablesSelection.length
		},
		widthScreen() {
			return this.$store.state.app.windowWidth
		},
		seasonOpts() {
			return [{ id: null, season: "---", start: null, end: null }, ...this.seasons]
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.filterType = "search"
			this.availablesSelection = []
			this.clearFilters()
			this.tab = 0
			this.from = null
			this.to = null
			this.currentPage = 1
			this.clearFilters()
			this.$emit("closing")
		},
		clearFilters() {
			this.filters = {
				search: "",
				dateFrom: this.seasons[0].start,
				dateTo: this.seasons[0].end,
				orderBy: 1,
				order: "desc",
				total: 0,
				page: 1,
				from: 0,
				to: 0,
				season: this.seasons[0].id,
			}
		},
		refreshConditional() {
			if (this.filters.search == "" && !this.filters.dateFrom && !this.filters.dateTo) {
				return
			} else {
				this.clearFilters()
				this.filters.season = this.seasonOpts[1].id
				this.seasonSelected()
			}
		},
		handleCheckBoxEvent(id, item) {
			let ExistsInArray = this.availablesSelection.findIndex((i) => i.decrest_id == id)
			if (item.checked) {
				if (ExistsInArray == -1) {
					this.availablesSelection.push(item)
				}
				return
			} else {
				if (ExistsInArray != -1) {
					this.availablesSelection.splice(ExistsInArray, 1)
				}
			}
		},
		async handleCleanDecrest() {
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se limpiaran todos los ejemplares seleccionados`,
			})
			if (!isConfirmed) return
			this.availablesSelection = []
			this.availables = []
			this.getAvailableSpecimens()
		},
		changeOrder(col) {
			if (col == this.filters.orderBy) {
				if (this.filters.order == "asc") this.filters.order = "desc"
				else this.filters.order = "asc"
				this.getDecrestedSpecimens()
				return
			}
			this.filters.orderBy = col
			this.filters.order = "asc"
			this.getDecrestedSpecimens()
		},
		tabChange(e) {
			this.clearFilters()
			if (e == 0) {
				this.filters.search = ""
				this.getAvailableSpecimens()
			} else if (e == 1) {
				this.filters.search = ""
				this.filters.season = this.seasonOpts[1].id
				this.seasonSelected()
			}
		},
		async getCounters() {
			this.isLoading = true
			const { data } = await decrestService.getDecrestedCounters()
			this.counters = {
				available: data.find((c) => c.id == 1).counter,
				decrested: data.find((c) => c.id == 2).counter,
			}
			this.isLoading = false
			this.$emit("refresh", this.counters.available)
		},
		async getAvailableSpecimens() {
			this.isLoading = true
			const { data: res } = await decrestService.getDecrested({
				status_id: 1,
				search: this.filters.search,
				page: this.currentPage,
				perpage: 30,
				orderby: "plate",
				order: "desc",
			})
			this.from = res.from
			this.to = res.to
			this.filters.total = res.total
			this.availables = res.data.map((a) => ({ ...a, checked: false }))
			this.availables.forEach((element) => {
				this.availablesSelection.forEach((item) => {
					if (element.decrest_id == item.decrest_id) {
						element.checked = true
					}
				})
			})
			this.isLoading = false

			this.getCounters()
		},
		async getDecrestedSpecimens() {
			this.isLoading = true
			const orderToStr = new Map()
			orderToStr.set(1, "plate")
			orderToStr.set(2, "updated_at")
			const { data: res } = await decrestService.getDecrested({
				status_id: 2,
				search: this.filters.search,
				page: this.filters.page,
				perpage: 20,
				orderby: orderToStr.get(this.filters.orderBy),
				order: this.filters.order,
				date_in: this.filters.dateFrom || this.seasons[0].start,
				date_fn: this.filters.dateTo || this.seasons[0].end,
			})
			this.filters.total = res.total
			this.filters.from = res.from
			this.filters.to = res.to
			this.decresteds = res.data
			this.isLoading = false
			// this.filters.season = this.seasonOpts[1].id
			// this.seasonSelected()
		},
		async processSelecteds() {
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se descrestaran ${this.availablesSelected} ejemplar(es) seleccionados`,
			})
			if (!isConfirmed) return
			this.isLoading = true
			const specimens = this.availablesSelection.filter((a) => a.checked).map((a) => a.specimen.id)
			await decrestService.setDecrested({ specimens })
			this.showToast(
				"success",
				"top-right",
				`Ejemplares Descrestados`,
				"SuccessIcon",
				`Se descrestó ${this.availablesSelected} ejemplar(es) con éxito`
			)
			this.availablesSelection = []
			this.isLoading = false
			this.getAvailableSpecimens()
		},
		searchInputsDate() {
			this.filters.season = null
			if (this.filters.dateFrom != null && this.filters.dateTo) {
				this.getDecrestedSpecimens()
			}
		},
		seasonSelected() {
			if (this.filters.season == null) {
				this.filters.dateFrom = null
				this.filters.dateTo = null
			} else {
				const season = this.seasonFromId(this.filters.season)
				this.filters.dateFrom = season.start
				this.filters.dateTo = season.end
			}
			this.getDecrestedSpecimens()
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getAvailableSpecimens()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.container-modal-header {
	@media screen and (max-width: 769px) {
		gap: 0 !important;
	}
	.paginator {
		@media screen and (max-width: 769px) {
			width: 100%;
			justify-content: center !important;
		}
	}
	.pagination-label {
		@media screen and (max-width: 769px) {
			justify-content: center !important;
			margin: 16px 0;
		}
	}
}
.decrest-label-paginator {
	@media screen and (max-width: 992px) {
		justify-content: center !important;
	}
}
.contenedor-calendar-button {
	@media screen and (max-width: 430px) {
		flex-direction: column !important;
	}
}
.filters-content {
	@media screen and (min-width: 992px) {
		margin-top: 12px;
	}
}
</style>

<style>
.decrestTabs .nav-item > a {
	padding: 0.5rem 1rem;
}
.decrestTabs ul {
	margin-bottom: 0px !important;
}
</style>
