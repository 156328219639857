<template>
	<div class="d-flex container-items-boolmarks align-items-center">
		<div
			class="ml-25 item-bookmarks cursor-pointer"
			@click.prevent="openModalGallery"
			v-b-tooltip.hover
			title="Ver galeria"
			style="margin: 0 3px"
		>
			<feather-icon icon="ImageIcon" color="#ff9f43" size="22" />
		</div>
		<!-- <div class="ml-50 item-bookmarks cursor-pointer" v-b-tooltip.hover title="Resultados del mes">
			<RedirectResultsNow />
		</div> -->
		<div class="ml-50 item-bookmarks cursor-pointer" v-b-tooltip.hover title="Notas" @click="showNotesList = true">
			<!-- <feather-icon icon="FileMinusIcon" color="#ff9f43" size="22" /> -->
			<font-awesome-icon icon="fa-regular fa-sticky-note" size="xl" class="text-warning" />
		</div>
		<div
			class="ml-75 item-bookmarks cursor-pointer eggs-found"
			v-b-tooltip.hover
			title="Huevos Encontrados"
			@click="
				SET_VALUES_EGGS_FOUND({
					eggsFound: {
						...eggsFound,
						sidebar: {
							show: true,
						},
					},
				})
			"
		>
			<font-awesome-icon icon="pl-25 fa-solid fa-egg" size="xl" class="text-primary" />
			<!-- <feather-icon icon="CircleIcon" color="#ff9f43" size="22" /> -->
			<!-- isAdmin || isSupervisor -->
			<p class="counter-eggs-found" v-if="eggsFound.counter > 0 && (isSupervisor || isAdmin)">
				{{ eggsFound.counter }}
			</p>
		</div>

		<b-modal
			title="Seleccione ejemplar"
			v-model="openM"
			@ok.prevent="saveImageById"
			@hidden="
				typeFiles = 0
				toggleModalGallery(false)
			"
			scrollable
			:hide-footer="!selectEjemplar || typeFiles === 1"
			ok-title="Guardar"
		>
			<b-form-group label="Ejemplar" label-for="ejemplar">
				<v-select
					v-model="selectEjemplar"
					:options="ejemplares"
					:clearable="false"
					label="label"
					:reduce="(option) => option.value"
					placeholder="Ingrese placa o alias"
					@search="fetchOptions"
				>
					<template slot="no-options">Sin ejemplares encontrados</template>

					<template slot="option" slot-scope="option">
						<div class="d-center">
							{{ option.label }}
						</div>
					</template>

					<template slot="selected-option" slot-scope="option">
						<div class="selected d-center">
							{{ option.label }}
						</div>
					</template>
				</v-select>
			</b-form-group>

			<div class="content-btns-select-gallery" v-if="selectEjemplar">
				<button class="btn btn-select-gallery" @click="typeFiles = 0" :class="[typeFiles == 0 ? 'active' : '']">
					<feather-icon class="icon" icon="HardDriveIcon" size="16" />
					Imagenes
					<span>· {{ totalFiles.total }}</span>
				</button>

				<button
					v-if="id_specimen && [1, 3, 5].includes(id_specimen.category)"
					class="btn btn-select-gallery"
					@click="typeFiles = 1"
					:class="[typeFiles == 1 ? 'active' : '']"
				>
					<feather-icon class="icon" icon="ImageIcon" size="16" />
					Videos
					<span>· {{ totalFiles.images }}</span>
				</button>

				<div class="d-flex flex-icon">
					<feather-icon
						class="icon"
						icon="RefreshCwIcon"
						@click="getEjemplares(id_specimen.id)"
						:class="[clickIcon ? 'icon-active' : '']"
						size="16"
					/>
				</div>
			</div>

			<template v-if="images.length > 0">
				<b-form-group :label="typeFiles == 0 ? 'Imagenes' : 'Videos'" label-for="imagenes">
					<div
						class="container-images"
						:class="[
							images.filter((item) => item.disadled == true).length > 4 ? 'grid-images' : '',
							images.filter((item) => item.disadled == true).length > 8 ? 'grid-image-max' : '',
						]"
					>
						<template v-for="(image, index) in images">
							<div class="container-image" :key="index" v-if="image.disabled">
								<img
									@click="viewSpecimen(image)"
									class="image-specimen"
									:class="[image.feature == 1 ? 'feature' : '']"
									:src="image.preload"
									:alt="image.name"
								/>
								<div class="container-icons">
									<feather-icon
										icon="CheckCircleIcon"
										size="16"
										@click="dbClickImage(image)"
										color="#7367f0 "
										class="cursor-pointer"
									/>
									<feather-icon
										icon="Trash2Icon"
										size="16"
										@click="deleteImage(image)"
										class="icon-trash cursor-pointer"
									/>
								</div>
							</div>
						</template>
					</div>
				</b-form-group>
			</template>

			<div class="w-100" v-if="selectEjemplar && typeFiles == 0">
				<p
					v-b-tooltip.hover
					title="Para seleccionar el feature debe ir a la imagen y dar click en seleccionar feature."
					class="mb-0 font-small-1 color-span"
				>
					* AL HACER CLICK EN EL ICONO
					<feather-icon icon="CheckCircleIcon" size="10" />
					SE ACTIVA COMO PRINCIPAL.
				</p>
			</div>

			<b-form-group v-if="typeFiles == 0" label="Subir imagen" label-for="upload">
				<div
					class="container-upload-image"
					v-b-tooltip.hover
					title="Click para seleccionar imagen del ejemplar."
					:class="[!pointerE ? '' : '']"
				>
					<h5 class="mb-0">Click para subir fotos del ejemplar</h5>
					<input
						ref="fileInput"
						type="file"
						accept=".jpeg,.jpg,.png,.heif,.heic"
						@input="pickFile"
						class="d-none"
					/>
					<div class="skeletor-container-image" @click="pickFile"></div>
					<div @click="pickFile" v-if="uploadImages.length" class="image-select-upload">
						<div class="hola" @mouseover="pointerE = true"></div>
						<div class="container-img-drop" v-for="(img, index) in uploadImages" :key="index">
							<img v-if="img.type == 'image' && img.extension != 'mov'" :src="img.base" alt="" />
							<img
								class="image-mov"
								v-if="img.extension == 'mov' || img.extension == 'MOV'"
								src="https://cliply.co/wp-content/uploads/2019/07/371907120_YOUTUBE_ICON_TRANSPARENT_400.gif"
								alt=""
							/>
							<video
								muted
								autoplay
								v-if="img.type == 'video' && img.extension != 'mov'"
								:src="img.base"
							></video>
							<div class="container-trash trash-upload-image">
								<feather-icon
									@mouseover="pointerE = false"
									icon="Trash2Icon"
									size="13"
									@click="deleteImageDrop(index)"
								/>
							</div>
						</div>
					</div>
				</div>
			</b-form-group>

			<div
				v-if="typeFiles == 1 && id_specimen && [1, 3, 5].includes(id_specimen.category)"
				class="d-flex align-items-center justify-content-between"
			>
				<!-- <p class="mb-0">{{ statusTope }} {{ openModalConfigTope }}</p> -->
				<p class="mb-0"></p>
				<b-button size="sm" variant="primary" type="button" @click="openModalConfigTope = true">
					Agregar video
				</b-button>
			</div>
		</b-modal>

		<b-modal hide-footer title="Agregar video" v-model="openModalConfigTope" size="xl">
			<UploadSpecimenVideo @resetTable="resetTable" reset="true" :specimen_to_update_id="selectEjemplar" />
		</b-modal>
	</div>
</template>

<script>
import { ref } from "@vue/composition-api"
import { mapActions, mapState, mapMutations } from "vuex"
import AddImageChick from "./AddImageChick.vue"
import ViewGallery from "./ViewGallery.vue"
import AddVideoChick from "./AddVideoChick.vue"
import vSelect from "vue-select"
import axios from "@/axios"
import _ from "lodash"
import UploadSpecimenVideo from "../videolibrary/UploadSpecimenVideo.vue"
import NotificationsVideo from "./NotificationsVideo.vue"
import RedirectResultsNow from "./RedirectResultsNow.vue"
import heic2any from "heic2any"
import GraficoResultsTournament from "./GraficoResultsTournament.vue"
import useStickyNotes from "@/views/amg/header-bookmarks/sticky-notes/useStickyNotes"

import {
	getSpecimensByName,
	getImagesBySpecimens,
	updateFeatureImage,
	deleteImageGallery,
	getRivals,
	getCareador,
	getAmarrador,
	deleteVideoTopeViId,
} from "./service.header-book"
import eggsFoundService from "@/services/header/eggs-found.service"

export default {
	name: "ItemDropdownImage",
	components: {
		AddImageChick,
		ViewGallery,
		AddVideoChick,
		vSelect,
		UploadSpecimenVideo,
		NotificationsVideo,
		RedirectResultsNow,
		GraficoResultsTournament,
	},
	data() {
		return {
			openM: false,
			selectEjemplar: null,
			ejemplares: [],
			name: null,
			image: {
				name: null,
				data: null,
			},
			arrayImages: [],
			index: 1,
			pointerE: true,
			totalFiles: {
				total: 0,
				images: 0,
				videos: 0,
				topes: 0,
			},
			typeFiles: 0,
			isEditing: false,
			specimen: null,
			statusTope: false,
			openModalConfigTope: false,
			topeArrayCategory: [
				{
					label: "TOPE",
					value: 1,
				},
				{
					label: "PELEA",
					value: 2,
				},
			],
			topeSelectCategory: null,
			topeArrayType: [
				{
					label: "INTERNO",
					value: 1,
				},
				{
					label: "EXTERNO",
					value: 2,
				},
			],
			topeSelectType: null,
			topeSelectRival: null,
			topeArrayRival: [
				{
					label: "Seleccionar rival",
					value: null,
				},
			],
			topeSelectRival: null,
			topeSelectEvent: null,
			topeArrayResult: [
				{
					label: "Seleccionar resultado",
					value: null,
				},
				{
					label: "TRIUNFO",
					value: 1,
				},
				{
					label: "EMPATE",
					value: 2,
				},
				{
					label: "DERROTA",
					value: 3,
				},
			],
			topeSelectResult: null,
			topeArrayAmarrador: [
				{
					label: "Seleccionar amarrador",
					value: null,
				},
			],
			topeSelectAmarrador: null,
			topeArrayCareador: [
				{
					label: "Seleccionar careador",
					value: null,
				},
			],
			topeSelectCareador: null,
			topeSelectDescription: null,
			debounce: null,
			clickIcon: false,
		}
	},
	computed: {
		...mapState("header_book", [
			"images",
			"openModal",
			"uploadImages",
			"upload",
			"toggleOpenGallery",
			"id_specimen",
			"uploadFinished",
			"urlBackendNode",
		]),
		...mapState("headers", ["eggsFound"]),
	},
	async mounted() {
		// TODO: limpiar componente
		// await this.initRivals();
		// await this.initAmarrador();
		// await this.initCareador();
		this.typeFiles = 0
		// console.log('id_specimen', this.id_specimen);
		await this.initCounterEggsFound()
	},
	methods: {
		...mapActions("header_book", [
			"setVideoteca",
			"setImages",
			"toggleModal",
			"setIndexImage",
			"setTextImage",
			"setUploadImages",
			"deleteUploadFile",
			"toggleBgUpload",
			"initToggleUpload",
			"toggleModalGallery",
			"toggleUploadGallery",
			"setIdSpecimen",
		]),
		...mapMutations("headers", ["SET_VALUES_EGGS_FOUND"]),
		async initRivals() {
			const { data } = await getRivals()
			this.topeArrayRival = [
				...this.topeArrayRival,
				...data.map((item) => {
					return {
						value: item.id,
						label: item.name,
					}
				}),
			]
		},
		async initAmarrador() {
			const { data } = await getAmarrador()
			this.topeArrayAmarrador = [
				...this.topeArrayAmarrador,
				...data.map((item) => {
					return {
						value: item.id,
						label: item.name,
					}
				}),
			]
		},
		async initCareador() {
			const { data } = await getCareador()
			this.topeArrayCareador = [
				...this.topeArrayCareador,
				...data.map((item) => {
					return {
						value: item.id,
						label: item.name,
					}
				}),
			]
		},
		openModalGallery() {
			if (this.upload) {
				this.showInfoSwal({
					title: "Informacion",
					text: "No puede abrir la galeria cuando se estan subiendo imagenes",
				})
			} else {
				this.toggleModalGallery(true)
			}
		},
		async fetchOptions(search, loading) {
			clearTimeout(this.debounce)
			this.debounce = setTimeout(async () => {
				loading(true)
				if (search.length > 1) {
					const { data } = await getSpecimensByName({
						name: search,
					})
					this.ejemplares = data.map((item) => {
						return {
							...item,
							label: `${item.plate} ${item.alias ? item.alias : ""}`,
							value: item.id,
						}
					})
				}
				loading(false)
			}, 300)
		},
		pickFile() {
			if (this.pointerE) {
				this.$refs.fileInput.click()
				let input = this.$refs.fileInput
				let file = input.files
				if (file[0]) {
					console.log("file: ", file[0].name.split(".")[file[0].name.split(".").length - 1])
				}
				const typeOfFile = file[0] && file[0].name.split(".")[file[0].name.split(".").length - 1]
				if (typeOfFile == "png" || typeOfFile == "jpg" || typeOfFile == "jpeg") {
					this.setImage(file, "image")
				} else if (typeOfFile == "heif" || typeOfFile == "heic") {
					this.isPreloading()
					this.showToast(
						"warning",
						"top-right",
						"Cargando imagen, espere un momento",
						"CheckIcon",
						"El formato HEIF o HEIC no es soportado, por ello lo puede tardar un momento."
					)
					heic2any({ blob: file[0], toType: "image/jpg", quality: 1 }).then((newImage) => {
						const url = URL.createObjectURL(newImage)
						let newFile = new File([newImage], "heic" + ".jpg", {
							type: "image/jpeg",
							lastModified: new Date().getTime(),
						})
						this.setUploadImages(
							[
								...this.uploadImages,
								{
									name: "name",
									data: newFile,
									type: "image",
									base: url,
									progress: 0,
									uploadFinished: false,
									extension: typeOfFile,
								},
							].filter((item) => item.type == "image")
						)
						this.isPreloading(false)
					})
					this.$refs.fileInput.value = null
				}
			}
		},
		setImage(file, type) {
			// console.log("file: ", file);
			const typeOfFile = file[0] && file[0].name.split(".")[file[0].name.split(".").length - 1]

			if (file && file[0]) {
				let reader = new FileReader()

				reader.onload = (e) => {
					//this.specimenObject.avatar = e.target.result;
					// console.log("data: ", e.target.result);
					let isRepeated = false
					this.uploadImages.forEach((item) => {
						if (item.base == e.target.result) {
							// console.log("Se repite");
							isRepeated = true
						}
					})
					if (!isRepeated) {
						//uploadImages
						this.setUploadImages(
							[
								...this.uploadImages,
								{
									name: file[0].name,
									data: file[0],
									type,
									base: e.target.result,
									progress: 0,
									uploadFinished: false,
									extension: typeOfFile,
								},
							].filter((item) => item.type == "image")
						)
						// console.log("Status tope: ", this.statusTope);
						if (this.statusTope) {
							this.setUploadImages([this.uploadImages[0]])
						} else {
							this.setUploadImages([...this.uploadImages])
						}

						this.$refs.fileInput.value = null
					}
				}
				reader.readAsDataURL(file[0])

				//console.log("file2 ", reader.readAsDataURL(file[0]));
				//this.$emit("input", file[0]);
				//this.specimenObject.file_name = file[0].name;
			}
		},
		async saveImageById() {
			//this.toggleBgUpload();

			if (this.uploadImages.length > 0 && this.selectEjemplar) {
				this.isPreloading()
				this.isPreloadingBg()
				let input = this.$refs.fileInput
				let file = input.files

				this.initToggleUpload(true)

				const arrayFile = this.arrayImages
				const itemsFile = this.arrayImages
				//this.isPreloading(true);

				for (let i = 0; i < this.uploadImages.length; i++) {
					const file = this.uploadImages[i]

					let formData = new FormData()

					const tope = {
						category: this.topeSelectCategory,
						type: this.topeSelectType,
						rival: this.topeSelectRival,
						event_date: this.topeSelectEvent,
						result: this.topeSelectResult,
						amarrador: this.topeSelectAmarrador,
						careador: this.topeSelectCareador,
						description: this.topeSelectDescription,
					}

					formData.append("file", file.data)
					formData.append("id_specimens", this.id_specimen.id)
					// console.log('user', this.currentUser.user_id);
					formData.append("user_id", this.currentUser.user_id)
					formData.append("tope", JSON.stringify(tope))
					formData.append("statusTope", this.statusTope)

					const config = {
						onUploadProgress: (progressEvent) => {
							const { loaded, total } = progressEvent
							let percent = Math.floor((loaded * 100) / total)
							this.setUploadImages(
								this.uploadImages.map((file) => {
									// console.log("progress: ", percent);
									return {
										...file,
										progress: percent,
										uploadFinished: percent >= 100 ? true : false,
									}
								})
							)
						},
					}
					if (!this.statusTope && this.uploadImages[i].type == "image") {
						await axios.post("/api/specimens/add-image", formData, config)
					}
				}

				if (!this.backgroundUpload) {
					this.toggleModalGallery(true)
				}

				await this.setUploadImages([])
				await this.initToggleUpload(false)
				await this.getEjemplares(this.selectEjemplar)
				await this.isPreloading(false)
				await this.toggleUploadGallery(true)
				await this.toggleBgUpload(false)
				// await this.toggleModalGallery(false)
				//toggleUploadGallery

				// console.log("ASDASD");

				this.image.name = null
				this.image.data = null
				this.arrayImages = []
				this.$refs.fileInput.value = null
				this.isPreloadingBg(false)
				this.$store.commit("header_book/TOGGLE_IS_UPDATING_FEATURE", true)
			} else {
				this.showInfoSwal({
					title: "Informacion",
					text: "¡Para poder guardar imagenes debe seleccionar un ejemplar e imágenes!",
				})
			}
		},
		async getEjemplares(id) {
			this.clickIcon = true
			const { data } = await getImagesBySpecimens({
				id,
				typeFiles: 0,
			})

			const ejemplaresData = data.map((item) => {
				return {
					...item,
					disabled: true,
					text: item.text ? item.text : this.id_specimen.tezt,
				}
			})
			// console.log('DATA:', ejemplaresData);
			// console.log("total: ", data);
			this.totalFiles.total = data.filter((item) => item.type == "image").length
			this.totalFiles.images = data.filter((item) => item.type == "video").length
			this.totalFiles.videos = null
			this.totalFiles.topes = null

			this.setImages(ejemplaresData)

			this.setImages(
				this.images.map((item) => {
					if (item.type == "image") {
						return {
							...item,
							disabled: true,
						}
					} else {
						return {
							...item,
							disabled: false,
						}
					}
				})
			)

			this.typeFiles = 0
			setTimeout(() => {
				this.clickIcon = false
			}, 1000)
		},
		async dbClickImage(local) {
			await this.updateFeature(local.id_specimens, local.id)
		},
		async updateFeature(id_specimens, id) {
			await updateFeatureImage({ id_specimens, id })
			this.$store.commit("header_book/TOGGLE_IS_UPDATING_FEATURE", true)
			this.getEjemplares(this.selectEjemplar)
		},
		viewSpecimen(item) {
			//get index of images where item id is equals images id
			let index = this.images.findIndex((x) => x.id == item.id)

			this.toggleModal()
			// this.setTextImage({
			//     title: `${this.specimen.label}`,
			//     url: `/ejemplares/detalle/${this.specimen.id}`
			// });
			this.setIndexImage(index)
		},
		async deleteImage(video) {
			const swal = await this.showConfirmSwal()
			if (swal.isConfirmed) {
				const itemsFilemage = this.images.find((item) => item.id == video.id)
				// console.log('AS: ', this.images, id_specimen, id);
				if (!video.id_specimens) {
					// console.log("ADD Eliminar");
					await deleteVideoTopeViId({
						fightResult: video.fr,
						id: video.id,
						iduser: this.currentUser.user_id,
						tournament_stage_specimen: video.tournament_stage_specimen_id,
					})
				} else {
					await deleteImageGallery({
						specimen_id: video.id_specimens,
						id: video.id,
					})
				}

				this.setImages(this.images.filter((item) => item.id != video.id))
				this.totalFiles.total = this.images.filter((item) => item.type == "image").length
				this.totalFiles.images = this.images.filter((item) => item.type == "video").length

				this.$store.commit("header_book/TOGGLE_IS_UPDATING_FEATURE", true)
			}
		},
		//toggleOpenGallery
		deleteImageDrop(i) {
			this.deleteUploadFile(i)

			//this.pointerE = true;
			this.$refs.fileInput.value = null
			if (this.arrayImages.length == 0) {
				setTimeout(() => {
					this.pointerE = true
					// console.log("zero");
				}, 500)
			}
		},
		reload() {
			// Recargar pagina TODO prueba
			location.reload()
		},
		preventNav(event) {
			if (!this.isEditing) return
			event.preventDefault()
			event.returnValue = ""
		},
		async resetTable(specimen) {
			console.log("RESET", specimen)
			const { data } = await getSpecimensByName({
				name: specimen.plate,
			})
			// console.log("DATA: ", specimen, data);
			this.ejemplares = data.map((item) => {
				return {
					...item,
					label: `${item.plate} ${item.alias ? item.alias : ""}`,
					value: item.id,
				}
			})
			this.selectEjemplar = specimen.id

			// this.getEjemplares(specimen.id);
			this.openModalConfigTope = false
		},
		async initCounterEggsFound() {
			const { data } = await eggsFoundService.getCounters()
			//Sumar huevos con el show en true
			this.SET_VALUES_EGGS_FOUND({
				eggsFound: {
					...this.eggsFound,
					counter: data.reduce(function (previousValue, currentValue) {
						return previousValue + (currentValue.show ? currentValue.count : 0)
					}, 0),
				},
			})
		},
	},
	watch: {
		toggleOpenGallery() {
			if (this.toggleOpenGallery) {
				this.openM = true
			} else {
				this.openM = false
			}
		},
		async selectEjemplar(newValue, oldValue) {
			//total
			// console.log('GET', this.selectEjemplar);
			if (newValue) {
				this.setImages([])
				this.isPreloading()
				this.setIdSpecimen(this.ejemplares.find((item) => item.id == this.selectEjemplar))
				//id_specimen
				this.pointerE = false

				if (this.id_specimen.id) {
					await this.getEjemplares(this.id_specimen.id)
					this.pointerE = true
					const [ej] = this.ejemplares.filter((item) => item.id == this.id_specimen.id)
					// console.log('DF: ', ej);
					this.specimen = ej
				}
				this.isPreloading(false)
			}
		},
		openM() {
			if (!this.openM) {
				this.selectEjemplar = null
				this.image.name = null
				this.image.data = null
				this.arrayImages - []
				this.setImages([])
			}
		},
		arrayImages() {},
		async typeFiles() {
			// console.log("this.typeFiles", this.typeFiles);
			// if (this.selectEjemplar) {
			//     await this.getEjemplares(this.selectEjemplar);
			//     this.pointerE = true;
			// }
			if (this.typeFiles == 0) {
				this.setImages(
					this.images.map((item) => {
						if (item.type == "image") {
							return {
								...item,
								disabled: true,
							}
						} else {
							return {
								...item,
								disabled: false,
							}
						}
					})
				)
			} else if (this.typeFiles == 1) {
				this.setImages(
					this.images.map((item) => {
						if (item.type == "video") {
							return {
								...item,
								disabled: true,
							}
						} else {
							return {
								...item,
								disabled: false,
							}
						}
					})
				)
			}
			// this.totalFiles.images = data.filter(item => item.type == 'image').length;
			// this.totalFiles.videos = data.filter(item => item.type_of_video == 'tope').length;
			// this.totalFiles.topes = data.filter(item => item.type_of_video == 'pelea').length;
		},
		statusTope() {
			// console.log(
			//     "Modal tope: ",
			//     this.statusTope,
			//     this.openModalConfigTope
			// );
			if (this.statusTope == "accepted") {
				this.openModalConfigTope = true
			} else {
				this.openModalConfigTope = false
			}
			if (this.statusTope) {
				this.setUploadImages(this.uploadImages.filter((item) => item.type != "image"))
			}
		},
		topeSelectCategory() {
			if (this.topeSelectCategory == 2) {
				this.topeSelectType = 2
			} else {
				this.topeSelectType = null
			}
		},
		async uploadFinished() {
			if (this.uploadFinished) {
				const { data } = await getSpecimensByName({
					name: this.id_specimen.plate,
				})
				// console.log("DATA 123: ", data);
				this.ejemplares = data.map((item) => {
					return {
						...item,
						label: `${item.plate} ${item.alias ? item.alias : ""}`,
						value: item.id,
					}
				})
				this.selectEjemplar = this.id_specimen.id
			}
		},
	},
	destroyed() {
		this.setImages([])
	},
	setup() {
		const { showNotesList } = useStickyNotes()

		let showEggsFound = ref(false)

		return {
			showNotesList,
			showEggsFound,
		}
	},
}
</script>

<style lang="scss">
.container-items-boolmarks {
	// background: red;
	z-index: 1032;
}

.container-bookmarks {
	margin-top: 0.2px;

	.item-bookmarks {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin: 0 7px !important;
		cursor: pointer;

		// padding: 8px;
		p {
			transition: 0.1s all ease-in-out;
		}

		&:hover {
			p {
				color: #7367f0;
				font-weight: bold;
			}
		}
	}
}

.container-upload-image {
	margin-top: 0.5rem;
	border: #e3e1fc 2px dashed;
	border-radius: 10px;
	padding: 7rem 3rem;
	text-align: center;
	transition: 0.1s all ease-in-out;
	cursor: pointer;
	overflow: hidden;

	&:hover {
		background: #fafafa;
	}

	position: relative;
	z-index: 100;

	.file-click {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: transparent;
		z-index: 10;
		display: none;
	}

	.text {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: transparent;
		z-index: 100;
	}

	.skeletor-container-image {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: transparent;
		z-index: 300;
		pointer-events: all !important;
	}

	.image-select-upload {
		// background: red;
		z-index: 400;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1rem;
		overflow-y: scroll;
		padding: 1rem;

		.hola {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: transparent;
		}

		.container-img-drop {
			position: relative;
			pointer-events: auto;
			box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%), 0 2px 3px -1px rgb(0 0 0 / 10%);

			border-radius: 10px;
			overflow: hidden;
			height: 185px;

			img,
			video {
				padding: 2px;
				width: 100%;
				height: 100%;
				object-fit: cover;
				box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px -1px rgb(0 0 0 / 10%);
				border: 5px transparent dashed;
				border-radius: 10px;

				//border: #7367f0 2px dashed;
			}

			transition: 0.3s all ease-in-out;

			&:hover {
				// border: 1px solid #fff;
				.container-trash {
					background: #a4a7aa;
				}
			}
		}
	}

	.image-select-upload::-webkit-scrollbar {
		width: 4px !important;
		background: #d5d1fb !important;
	}

	.image-select-upload::-webkit-scrollbar-thumb {
		background: #7367f0 !important;
		border-radius: 6px !important;
	}
}

.dark-layout {
	.container-upload-image {
		border-color: #b4b7bd;

		&:hover {
			background: #1b2330;
		}
	}
}

.container-images {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0.5rem;

	@media screen and (min-width: 600px) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	@media screen and (min-width: 1000px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	.container-image {
		position: relative;

		.image-specimen {
			cursor: pointer;
			border-radius: 10px;
			width: 100%;
			object-fit: cover;
			height: 120px;
			box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px -1px rgb(0 0 0 / 10%);
			border: 5px transparent dashed;
			transition: 0.2s all ease-in-out;

			@media screen and (min-width: 600px) {
				height: 120;
			}

			@media screen and (min-width: 900px) {
				height: 100;
			}
		}

		.feature {
			padding: 2px;
			border: 3px #7367f0 dashed;
		}

		.hover-image {
			padding: 4px;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			cursor: pointer;

			.container-text {
				z-index: 400;
				height: 100%;
				width: 100%;
				transition: 0.1s all ease-in-out;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				border-radius: 10px;
				opacity: 0;
				padding: 0 1rem;

				.text {
					padding: 0.8rem 1rem 0 1rem;
					color: #fff;
					font-size: 12px;
					font-weight: bold;
					text-align: center;
					line-height: 1;
					margin-bottom: 0.5rem;
				}
			}

			.container-type {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				transition: 0.1s all ease-in-out;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				border-radius: 10px;
				padding: 0 1rem;
			}

			&:hover {
				.container-text {
					opacity: 1;

					background-color: rgba(30, 30, 30, 0.5);
					position: relative;
				}

				.container-type {
					opacity: 0;
				}
			}
		}
	}
}

.container-trash {
	position: absolute;
	top: 3px;
	right: 5px;
	color: #fff;
	transition: 0.1s all ease-in-out;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9999;
}

.trash-upload-image {
	top: 10px;
	right: 10px;
}

.grid-images {
	height: 250px;
}

.grid-image-max {
	overflow-y: scroll;
}

.container-images::-webkit-scrollbar {
	width: 5px;
	background: #d5d1fb;
}

.container-images::-webkit-scrollbar-thumb {
	background: #7367f0;
	border-radius: 10px;
}

//container-images
.my-gallery {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;

	figure {
		img {
			cursor: pointer;
			border-radius: 10px;
			width: 100%;
			object-fit: cover;
			height: 100px;
			box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px -1px rgb(0 0 0 / 10%);
			border: 5px transparent dashed;
		}
	}
}

.content-btns-select-gallery {
	margin-bottom: 0.4rem;
	display: grid;
	grid-template-columns: 1fr 1fr;

	@media screen and (min-width: 600px) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	@media screen and (min-width: 900px) {
		grid-template-columns: 1.5fr 1.5fr 1fr 1fr;
	}

	.btn-select-gallery {
		display: flex;
		align-items: center;
		justify-content: center;
		border: 2px solid #e8e8e8;
		color: #5e5e5e;
		font-weight: bold;
		padding: 8px 6px;
		border-radius: 3px;
		transition: 0.3s all ease-in-out;

		.icon {
			margin-right: 0.3rem;
		}

		span {
			font-size: 12px;
			font-weight: 500;
			color: #9e9e9e;
			margin-left: 0.3rem;
		}
	}

	.active {
		border: 2px solid #7367f0;
		color: #7367f0;
	}
}

.image-mov {
	background: #fafafa;
}

.modal-body {
}

.modal-body::-webkit-scrollbar {
	width: 4px !important;
	background: #d5d1fb !important;
}

.modal-body::-webkit-scrollbar-thumb {
	background: #7367f0 !important;
	border-radius: 6px !important;
}

.custom-control-label {
	font-size: 12px !important;
	color: #7367f0 !important;
	cursor: pointer;
}

.text-form-data-picker {
	.form-control {
		font-size: 12px;
	}
}

.dark-layout .color-span {
	color: #b4b7bd;
}

@keyframes girar {
	0% {
		transform: rotate(0);
	}

	// 25% {
	//   transform: rotate(180deg);
	// }
	// 50% {
	//   transform: rotate(360deg);
	// }
	// 75% {
	//   transform: rotate(180deg);
	// }
	100% {
		transform: rotate(360deg);
	}
}

.icon-active {
	transform: rotate(360deg);
	animation: girar 1s linear 1;
}

.flex-icon {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-left: 0.5rem;
}

.container-icons {
	margin: 0 0.2rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	// background: #fafafa;
	margin-top: 0.4rem;

	span {
		font-weight: bold;
	}

	.icon-trash {
		color: red;
	}
}

.eggs-found {
	position: relative;
	.counter-eggs-found {
		position: absolute;
		top: -8px;
		right: -8px;
		height: 16px;
		width: 16px;
		color: #fff;
		background: red;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		margin-bottom: 0;
		font-weight: bolder;
		font-size: 12px;
	}
}
</style>
