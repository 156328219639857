<template>
	<div>
		<feather-icon
			@click="modalResultsMonthNow = true"
			icon="ActivityIcon"
			color="#ff9f43"
			size="22"
		/>
		<b-modal
			v-model="modalResultsMonthNow"
			:title="`Resultados del mes de ${month}`"
			size="xl"
			body-class="p-2"
			hide-footer
			scrollable
			title-class="h3"
		>
			<ReportTournamentsComponent
				:resultsNow="true"
				:month="month"
				:dayIn="dayIn"
				:dayFn="dayFn"
			/>
		</b-modal>
	</div>
</template>

<script>
import ReportTournamentsComponent from "@/views/amg/reports/results/tournaments/ReportTournamentsComponent.vue";

export default {
	name: "RedirectResultsNow",
	components: {
		ReportTournamentsComponent,
	},
	data() {
		return {
			dayIn: null,
			dayFn: null,
			arrayMonths: [
				{
					name: "Enero",
					id: 1,
				},
				{
					name: "Febrero",
					id: 2,
				},
				{
					name: "Marzo",
					id: 3,
				},
				{
					name: "Abril",
					id: 4,
				},
				{
					name: "Mayo",
					id: 5,
				},
				{
					name: "Junio",
					id: 6,
				},
				{
					name: "Julio",
					id: 7,
				},
				{
					name: "Agosto",
					id: 8,
				},
				{
					name: "Setiembre",
					id: 9,
				},
				{
					name: "Octubre",
					id: 10,
				},
				{
					name: "Noviembre",
					id: 11,
				},
				{
					name: "Diciembre",
					id: 12,
				},
			],
			arrayYears: [
				{
					name: "2020",
					id: 2020,
				},
				{
					name: "2021",
					id: 2021,
				},
				{
					name: "2022",
					id: 2022,
				},
			],
			month: null,
			modalResultsMonthNow: false,
		};
	},
	mounted() {
		this.initDateNow();
	},
	methods: {
		linkReportsNow() {
			this.$router.push(
				`/reportes/torneos?month=${this.month}&date1=${this.dayIn}&date2=${this.dayFn}`
			);
		},
		initDateNow() {
			const date = new Date();
			const dateNow = date;

			const monthNow = dateNow.getMonth() + 1;
			const yearNow = dateNow.getFullYear();
			const totalDaysNow = new Date(yearNow, monthNow + 1, 0).getDate();
			// console.log("dayNow: ", totalDaysNow, monthNow + 1);

			this.dayIn = `${yearNow}-${monthNow < 10 ? `0${monthNow}` : monthNow}-01`;
			this.dayFn = `${yearNow}-${monthNow < 10 ? `0${monthNow}` : monthNow}-${
				totalDaysNow - 1
			}`;
			// console.log('ASD', this.arrayMonths.filter(item => item.id == monthNow)[0].name);
			// console.log('MONTH: ', this.dayIn, this.dayFn);
			this.month = this.arrayMonths.filter(
				(item) => item.id == monthNow
			)[0].name;
		},
	},
};
</script>
