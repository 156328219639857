import axios from "@/axios";

const url = "/api/reports";

const getReportsPosturas = ({ page, perpage, search, category_id, specimen_id }) => {
	console.log("category_id", category_id);
	try {
		const resp = axios.get(`${url}/get-report-tournament-by-postura`, {
			params: {
				page,
				perpage,
				search,
				category_id,
				specimen_id
			},
		});
		return resp;
	} catch (error) {
		return error.response.data;
	}
};

const getReportsPosturasPadrillo = (body) => {
	const resp = axios.post(`/api/reports/list-posturas-padrillo`, body);
	return resp;
};

const getReportsPosturasMadrilla = (body) => {
	const resp = axios.post(`/api/reports/list-posturas-madrilla`, body);
	return resp;
};

//list-posturas-madrilla
const createFavorite = (body) => {
	const resp = axios.post(`/api/favorites/crear-favorite`, body);
	return resp;
};

const createFavoriteSpecimen = (body) => {
	const resp = axios.post(`/api/favorites/crear-favorite-specimens`, body);
	return resp;
};

const getSonsByPostura = async ({ padrillo_id, madrilla_id, gender }) => {
	try {
		const { data } = await axios.get(`/api/reports/get-details-sons-by-specimen`, {
			params: {
				padrillo_id,
				madrilla_id,
				gender,
			},
		});
		return data;
	} catch (error) {
		return error.response.data;
	}
};

const getSonsByPadrillo = (body) => {
	const resp = axios.post(`/api/reports/list-sons-by-postura-padrillo`, body);
	return resp;
};

const getSonsByMadrilla = (body) => {
	const resp = axios.post(`/api/reports/list-sons-by-postura-madrilla`, body);
	return resp;
};

const getSonsBySpecimen = ({ idSpecimen }) => {
	const resp = axios.post(`/api/reports/list-sons-by-specimen`, { idSpecimen });
	return resp;
};

const getReportsByTournament = (body) => {
	const resp = axios.post(`/api/reports/tournament/list-specimens`, body);
	return resp;
};

const getTournaments = (body) => {
	const resp = axios.post(`/api/reports/get-tournaments`, body);
	return resp;
};

const uploadVideoBattle = (body) => {
	const resp = axios.post(`/api/reports/upload-video-battle`, body);
	return resp;
};

const lineGraphTournaments = ({ year }) => {
	try {
		const resp = axios.post(`/api/reports/get-line-graph-tournaments`, { year });
		return resp;
	} catch (error) {}
};

const getDetailsTournamentsBySpecimen = async ({ padrillo_id, madrilla_id, result }) => {
	try {
		const {data} = await axios.get(`/api/reports/get-details-tournaments-by-specimen`, {
			params: {
				padrillo_id,
				madrilla_id,
				result,
			},
		});
		return data;
	} catch (error) {
		return error.response.data;
	}
};

export {
	getReportsPosturas,
	createFavorite,
	getReportsPosturasPadrillo,
	getReportsPosturasMadrilla,
	createFavoriteSpecimen,
	getSonsByPostura,
	getSonsByPadrillo,
	getSonsByMadrilla,
	getSonsBySpecimen,
	getReportsByTournament,
	getTournaments,
	uploadVideoBattle,
	lineGraphTournaments,
	getDetailsTournamentsBySpecimen
};
