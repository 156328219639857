var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"nav-item",class:{
	active: _vm.isActive,
	disabled: _vm.item.disabled,
}},[_c('b-link',_vm._b({staticClass:"d-flex align-items-center"},'b-link',_vm.linkProps,false),[(
			_vm.item.title !== 'Pollos' &&
			_vm.item.title !== 'Ejemplares' &&
			_vm.item.title !== 'Encaste'
		)?_c('feather-icon',{attrs:{"icon":_vm.item.icon || 'CircleIcon'}}):_vm._e(),_c('img',{staticClass:"mr-1",staticStyle:{"width":"22px"},attrs:{"src":require("@/assets/images/icons/pollo_b11.png"),"alt":""}}),(
			_vm.item.title == 'Pollos' &&
			(_vm.layout.skin == 'dark' || _vm.layout.skin == 'semi-dark')
		)?_c('img',{staticClass:"mr-1",staticStyle:{"width":"22px"},attrs:{"src":require("@/assets/images/icons/pollo_b11.png"),"alt":""}}):_vm._e(),(
			_vm.item.title == 'Pollos' &&
			(_vm.layout.skin == 'light' || _vm.layout.skin == 'bordered')
		)?_c('img',{staticClass:"mr-1",staticStyle:{"width":"22px","filter":"brightness(0.5)"},attrs:{"src":require("@/assets/images/icons/pollo_n11.png"),"alt":""}}):_vm._e(),(
			_vm.item.title == 'Ejemplares' &&
			(_vm.layout.skin == 'dark' || _vm.layout.skin == 'semi-dark')
		)?_c('img',{staticClass:"mr-1",staticStyle:{"width":"23px"},attrs:{"src":require("@/assets/images/icons/iconos-02.png"),"alt":""}}):_vm._e(),(
			_vm.item.title == 'Ejemplares' &&
			(_vm.layout.skin == 'light' || _vm.layout.skin == 'bordered')
		)?_c('img',{staticClass:"mr-1",staticStyle:{"width":"23px","filter":"brightness(0.5)"},attrs:{"src":require("@/assets/images/icons/iconos-05.png"),"alt":""}}):_vm._e(),(
			_vm.item.title == 'Encaste' &&
			(_vm.layout.skin == 'dark' || _vm.layout.skin == 'semi-dark')
		)?_c('img',{staticClass:"mr-1",staticStyle:{"width":"23px"},attrs:{"src":require("@/assets/images/icons/iconos-03.png"),"alt":""}}):_vm._e(),(
			_vm.item.title == 'Encaste' &&
			(_vm.layout.skin == 'light' || _vm.layout.skin == 'bordered')
		)?_c('img',{staticClass:"mr-1",staticStyle:{"width":"23px","filter":"brightness(0.5)"},attrs:{"src":require("@/assets/images/icons/iconos-06.png"),"alt":""}}):_vm._e(),_c('span',{staticClass:"menu-title text-truncate"},[_vm._v(_vm._s(_vm.t(_vm.item.title)))]),(_vm.item.tag)?_c('b-badge',{staticClass:"mr-1 ml-auto",attrs:{"pill":"","variant":_vm.item.tagVariant || 'primary'}},[_vm._v(" "+_vm._s(_vm.item.tag)+" ")]):_vm._e()],1),_vm._v(" asdaasadasd ")],1)}
var staticRenderFns = []

export { render, staticRenderFns }