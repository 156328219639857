import axios from "@/axios";
const url = "/api/attendance";

class AttendanceService {
	async index({ search, start_date, end_date, is_local }) {
		try {
			const { data } = await axios.get(`${url}`, {
				params: { start_date, end_date, search, is_local },
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}
	async attendanceCheck() {
		try {
			const { data } = await axios.get(`${url}/next-mark`, {});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}
	async store(body) {
		try {
			const { data } = await axios.post(`${url}/store`, body);
			return data;
		} catch (err) {
			return err.response.data;
		}
	}
}
export default new AttendanceService();
