<template>
	<b-modal
		ref="modal-specimen-recommended"
		:title="'Recomendaciones'"
		no-close-on-backdrop
		centered
		v-model="isActive"
		@hidden="handleHidden"
		size="xl"
		hide-footer
	>
		<b-overlay :show="isLoading">
			<b-card>
				<b-row class="mb-2">
					<b-col class="d-flex align-items-center" cols="12" md="9">
						<b-tabs v-model="indexTab" @input="inputTabs">
							<b-tab :disabled="false">
								<template #title>Fechas Proximas</template>
							</b-tab>
							<b-tab :disabled="false">
								<template #title>Fechas Pasadas</template>
							</b-tab>
						</b-tabs>
					</b-col>
					<b-col
						class="d-flex flex-column flex-xl-row align-items-xl-center justify-content-xl-end gap-x-2"
						cols="12"
						md="3"
						v-if="indexTab == 1"
					>
						<b-form-checkbox v-model="dateCheck" class="mt-md-1 d-flex align-items-center">
							<span style="white-space: nowrap; font-size: 0.9rem">
								{{ !dateCheck ? "Activar" : "Desactivar" }} filtros
							</span>
						</b-form-checkbox>
						<div class="d-flex align-items-center mt-md-1">
							<feather-icon
								icon="ChevronLeftIcon"
								size="40"
								class="text-primary cursor-pointer"
								:class="{ 'check-disabled': !dateCheck }"
								style="margin-right: 10px"
								@click="previousWeek()"
								:disabled="!dateCheck"
							/>

							<b-form-datepicker
								class="border-primary"
								style="width: 140px"
								locale="en-US"
								placeholder="Desde"
								:disabled="true"
								:date-format-options="{
									month: 'numeric',
									day: 'numeric',
									year: 'numeric',
								}"
								v-model="weekFrom"
							/>
							<b-form-datepicker
								class="border-primary ml-1"
								locale="en-US"
								style="width: 140px"
								placeholder="Hasta"
								:disabled="true"
								:date-format-options="{
									month: 'numeric',
									day: 'numeric',
									year: 'numeric',
								}"
								v-model="weekTo"
							/>

							<feather-icon
								icon="ChevronRightIcon"
								size="40"
								class="text-primary cursor-pointer"
								:class="{ 'check-disabled': !dateCheck }"
								style="margin-left: 10px"
								@click="nextWeek()"
								:disabled="!dateCheck"
							/>
						</div>
					</b-col>
				</b-row>
				<div>
					<RecommendationTable
						:isGeneral="isGeneral"
						:indexTab="indexTab"
						:start="weekFrom"
						:end="weekTo"
						:dateCheck="dateCheck"
					/>
				</div>
			</b-card>
		</b-overlay>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal"
import ViewImage from "@/components/commons/ViewImage.vue"
import SpecimensRecommendedModal from "@/views/amg/preparacion/components/recommendedSpecimens/SpecimensRecommendedModal.vue"
import AdminModal from "@/views/amg/preparacion/components/recommendedSpecimens/AdminModal.vue"
import TrackingStatusModal from "@/views/amg/preparacion/components/recommendedSpecimens/TrackingStatusModal.vue"
import RecommendationTable from "@/views/amg/preparacion/components/recommendedSpecimens/RecommendationsTable.vue"
import moment from "moment"

export default {
	components: { ViewImage, SpecimensRecommendedModal, AdminModal, TrackingStatusModal, RecommendationTable },
	mixins: [modalMixin],
	props: {
		show: Boolean,
		info: Object,
		isGeneral: Boolean,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			title: null,
			indexTab: 0,
			weekFrom: null,
			weekTo: null,
			dateCheck: false,
		}
	},
	async created() {
		this.generateWeekDates()
	},
	computed: {},
	methods: {
		inputTabs(event) {
			if (event == 0) this.generateWeekDates()
		},
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},
		previousWeek() {
			if (!this.dateCheck) return
			const newFrom = new Date(this.weekFrom)
			const newTo = new Date(this.weekTo)
			newFrom.setDate(newFrom.getDate() - 6)
			newTo.setDate(newTo.getDate() - 6)
			this.weekFrom = moment(newFrom).format("YYYY-MM-DD")
			this.weekTo = moment(newTo).format("YYYY-MM-DD")
		},
		nextWeek() {
			if (!this.dateCheck) return
			const newFrom = new Date(this.weekFrom)
			const newTo = new Date(this.weekTo)
			newFrom.setDate(newFrom.getDate() + 8)
			newTo.setDate(newTo.getDate() + 8)
			this.weekFrom = moment(newFrom).format("YYYY-MM-DD")
			this.weekTo = moment(newTo).format("YYYY-MM-DD")
		},
		async generateWeekDates() {
			const currentDate = new Date()
			const weekDays = currentDate.getDay() // Retorna un número del 0 al 6, donde 0 es domingo.

			// Calcular la diferencia entre el día actual y el lunes
			const differenceBetweenDays = weekDays === 0 ? -6 : 1 - weekDays
			const mondayDate = new Date(currentDate)
			mondayDate.setDate(currentDate.getDate() + differenceBetweenDays)

			// Calcular la diferencia entre el día actual y el domingo
			const diasDiferenciaDomingo = weekDays === 0 ? 0 : 7 - weekDays
			const sundayDate = new Date(currentDate)
			sundayDate.setDate(currentDate.getDate() + diasDiferenciaDomingo)

			this.weekFrom = moment(mondayDate).format("YYYY-MM-DD")
			this.weekTo = moment(sundayDate).format("YYYY-MM-DD")
		},
	},
	async mounted() {
		this.toggleModal("modal-specimen-recommended")
	},
}
</script>

<style scoped>
.check-disabled {
	opacity: 0.5;
	cursor: default;
}
</style>
