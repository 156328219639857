<template>
    <div>
        <div
            v-if="!hiddenContainer"
            class="loading-sliders-upload"
            :class="[backgroundUpload ? 'active' : '']"
        >
            <div class="header">
                <h6>Subiendo archivos</h6>
                <feather-icon
                    class="icon"
                    :icon="!hiddenBody ? 'ArrowDownIcon' : 'ArrowUpIcon'"
                    size="24"
                    @click="hiddenBody = !hiddenBody"
                />
            </div>
            <div
                class="body"
                :style="{ height: `${45 * uploadImages.length}px` }"
                :class="[hiddenBody ? 'body-hidden' : '']"
            >
                <div
                    v-for="(file, index) in uploadImages"
                    :key="index"
                    class="file-upload-item"
                >
                    <div
                        class="d-flex align-items-center justify-content-start"
                    >
                        <feather-icon
                            :icon="
                                file.type == 'image' ? 'ImageIcon' : 'VideoIcon'
                            "
                            size="16"
                        />
                        <p class="text-file">{{ file.name }}</p>
                    </div>
                    <div v-if="!file.uploadFinished" class="container-circle">
                        <div
                            class="circular-progress"
                            :style="{
                                background: `conic-gradient(#4d5bf9 ${file.progress *
                                    3.6}deg, #cadcff ${file.progress *
                                    3.6}deg )`
                            }"
                        >
                            <div class="value-container">
                                <p>{{ file.progress }}%</p>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="processing">
                            <p class="processing-text">Procesando</p>
                            <div class="loadingio-spinner-dual-ring-t8chy42qdg">
                                <div class="ldio-vq8nyi6cur">
                                    <div></div>
                                    <div><div></div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "LoadingInTheBackground",
    data() {
        return {
            progress: 0,
            hiddenBody: false,
            hiddenContainer: false
        };
    },
    computed: {
        ...mapState("header_book", [
            "backgroundUpload",
            "uploadImages",
            "uploadFinished"
        ])
    },
    mounted() {
        setInterval(() => {
            if (this.progress < 100) this.progress = this.progress + 1;
        }, 500);
    },
    methods: {
        ...mapActions("header_book", ["toggleBgUpload", "toggleUploadGallery"])
    },
    watch: {
        uploadFinished() {
            if (this.uploadFinished) {
                console.log("cerrando");
                setTimeout(() => {
                    this.hiddenContainer = false;
                    this.toggleUploadGallery(false);
                    // this.toggleBgUpload()
                }, 1000);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.loading-sliders-upload {
    position: fixed;
    bottom: 0;
    right: -400px;
    width: 400px;
    z-index: 200;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    transition: 0.5s all ease-in-out;
    opacity: 0;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #323232;

        padding: 0.8rem 1.5rem;
        h6 {
            color: #fff;
            font-size: 14px;
            margin-bottom: 0;
        }
        .icon {
            cursor: pointer;
        }
    }
    .body {
        background: #fff;
        // height: 500px;
        transition: 0.3s all ease-in-out;
        .file-upload-item {
            height: 45px;
            position: relative;
            padding: 0.3rem 1.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
                margin-bottom: 0;
                margin-left: 0.5rem;
                font-size: 13px;
            }
        }
    }
    .body-hidden {
        height: 0 !important;
    }
}
.active {
    opacity: 1;
    right: 0;
}
.bg-animation-in {
    animation: FromDown 2s ease-in-out 1;
}
.bg-animation-out {
    animation: FromLeft 2s ease-in-out 1;
}

.container-circle {
    height: 36px;
    width: 36px;
    // background: green;
    border-radius: 2px;

    display: grid;
}
.circular-progress {
    position: relative;
    height: 36px;
    width: 36px;
    background: black;
    border-radius: 50%;
    display: grid;
    place-items: center;
}
.circular-progress::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 74%;
    width: 74%;
    background: #fff;
    border-radius: 50%;
}
.value-container {
    position: relative;
    p {
        font-size: 9px !important;
        margin-left: 0 !important;
        color: #231c3d;
        span {
            font-size: 8px;
        }
    }
}

@keyframes FromRight {
    0% {
        transform: translateX(400px);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}
@keyframes FromLeft {
    0% {
        transform: translateX(0px);
        opacity: 1;
    }
    100% {
        transform: translateX(400px);
        opacity: 2;
    }
}
.text-file {
    white-space: nowrap;
    width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
}
.processing {
    display: flex;
    align-items: center;
    position: relative;
    // background: red;
    .processing-text{
        transition: all 0.5s all;
        font-weight: bold;
        font-size: 10px !important;
        margin-right: 12px;
        // animation: processingAnimation 2s ease-in-out infinite;
    }
}
@keyframes processingAnimation {
    0% {
        font-weight: bold;
        transform: scale(1);
    }
    25% {
        font-weight: bolder;
        transform: scale(1.05);
        color: #7367f0;
    }
    50% {
        font-weight: bold;
        transform: scale(1);
    }
    75% {
        font-weight: normal;
        transform: scale(0.95);
        color: #7367f0;
    }
    100% {
        font-weight: bold;
        transform: scale(1);
    }
}

@keyframes ldio-vq8nyi6cur {
  0% { transform: rotate(0) }
  100% { transform: rotate(360deg) }
}
.ldio-vq8nyi6cur div { box-sizing: border-box!important }
.ldio-vq8nyi6cur > div {
  position: absolute;
  width: 144px;
  height: 144px;
  top: 28px;
  left: 28px;
  border-radius: 50%;
  border: 16px solid #000;
  border-color: #7367f0 transparent #7367f0 transparent;
  animation: ldio-vq8nyi6cur 1s linear infinite;
}
.ldio-vq8nyi6cur > div:nth-child(2) { border-color: transparent }
.ldio-vq8nyi6cur > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-vq8nyi6cur > div:nth-child(2) div:before, .ldio-vq8nyi6cur > div:nth-child(2) div:after { 
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -16px;
  left: 48px;
  background: #7367f0;
  border-radius: 50%;
  box-shadow: 0 128px 0 0 #7367f0;
}
.ldio-vq8nyi6cur > div:nth-child(2) div:after { 
  left: -16px;
  top: 48px;
  box-shadow: 128px 0 0 0 #7367f0;
}
.loadingio-spinner-dual-ring-t8chy42qdg {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
  transform: scale(0.16);
  position: absolute;
  right: -105px;
  bottom: -90px;
}
.ldio-vq8nyi6cur {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-vq8nyi6cur div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>
