<template>
	<b-modal ref="modal-recommended-rejecteds" centered hide-footer :title="getTitle" size="xmd" @hidden="handleHidden">
		<div>
			<b-table-simple responsive sticky-header="50vh" no-border-collapse>
				<b-thead class="sticky-header">
					<b-th class="text-center">Ejemplar</b-th>
					<b-th class="text-center">Estado</b-th>
					<b-th class="text-center">Recomendado por</b-th>
					<b-th class="text-center">Inactivado por</b-th>
					<b-th v-if="isFinalized && info.indexTab == 0" class="text-center">Acciones</b-th>
				</b-thead>
				<b-tbody>
					<template v-for="(rec, index) in recommendeds">
						<b-tr :key="index" :class="{ 'is_error-row': rec.is_error && rec.status != rec.newStatus }">
							<b-td class="text-center">
								<SpecimenPlate :specimen="rec.specimen" />
								<!-- <b-badge variant="light-danger"> -->
								<div
									v-if="rec.is_error && rec.status != rec.newStatus"
									class="mt-1 text-danger"
									style="word-wrap: break-word; font-size: 12px"
								>
									{{ rec.message_error }}
								</div>
								<!-- </b-badge> -->
							</b-td>
							<b-td class="text-center">
								<feather-icon
									v-if="rec.newStatus"
									v-b-tooltip.hover
									:title="resolveTitleTooltip(rec.newStatus).title"
									:class="`text-${resolveTitleTooltip(rec.newStatus).variant}`"
									:icon="resolveTitleTooltip(rec.newStatus).icon"
									size="22"
								/>
								<feather-icon
									v-else
									v-b-tooltip.hover
									:title="resolveTitleTooltip(rec.status).title"
									:class="`text-${resolveTitleTooltip(rec.status).variant}`"
									:icon="resolveTitleTooltip(rec.status).icon"
									size="22"
								/>
								<!-- <br v-if="rec.is_error"> -->
							</b-td>

							<b-td class="text-center">
								<p class="m-0">{{ rec.created_by ? rec.created_by.name : "--" }}</p>
								<p class="m-0">{{ rec.sent_at ? rec.sent_at : null | myGlobalDateTime }}</p>
							</b-td>
							<b-td class="text-center">
								<p class="m-0">{{ rec.updated_by ? rec.updated_by.name : "--" }}</p>
								<p class="m-0">{{ rec.updated_by ? rec.updated_by.date : null | myGlobalDateTime }}</p>
							</b-td>
							<b-td
								v-if="isFinalized && info.indexTab == 0"
								class="d-flex align-items-center w-full"
								style="height: 87px"
							>
								<div
									class="d-flex justify-content-center h-100 w-100 align-items-center"
									v-if="['rechazado'].includes(rec.status)"
								>
									<feather-icon
										v-if="rec.newStatus == rec.status && rec.newStatus == 'rechazado'"
										icon="CheckCircleIcon"
										size="20"
										class="text-success cursor-pointer mr-1"
										v-b-tooltip.hover
										title="Aprobar"
										@click="changeStatusRecommended(rec, 'aprobado')"
									/>
								</div>
								<div
									class="d-flex justify-content-center align-items-center"
									v-if="rec.newStatus != rec.status"
								>
									<feather-icon
										icon="CornerUpLeftIcon"
										size="20"
										class="text-warning cursor-pointer"
										v-b-tooltip.hover
										title="Regresar"
										@click="rec.newStatus = rec.status"
									/>
								</div>
							</b-td>
						</b-tr>
					</template>
				</b-tbody>
			</b-table-simple>
			<p class="text-center" v-if="recommendeds.length === 0">No hay registros para mostrar</p>

			<div class="d-flex mt-2 justify-content-end" style="gap: 1rem" v-if="isViewButton">
				<b-button variant="primary" :disabled="validateButton == 0" @click="activateRec">ACTUALIZAR</b-button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import RecommendedSpecimenService from "@/services/preparacion/events/recommendedSpecimen.service.js"
import FinalizeRecommended from "@/views/amg/preparacion/components/recommendedSpecimens/FinalizeRecommendedModal.vue"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import modalMixin from "@/mixins/modal"
import moment from "moment"
import { mapActions } from "vuex"
import * as icons from "vue-feather-icons"

export default {
	components: {
		SpecimenPlate,
		FinalizeRecommended,
	},
	mixins: [modalMixin],
	props: {
		show: Boolean,
		info: Object,
		tracking: Object,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			recommendeds: [],
			observations: [],
			finalizeStatus: "",
		}
	},
	async created() {
		await this.getRecommendeds()
	},
	computed: {
		getTitle() {
			return `Ejemplares recomendados inactivos | ${this.info.tournament.name} | ${moment(this.info.date).format(
				"MM/DD/YYYY"
			)}`
		},
		validateButton() {
			const counter = this.recommendeds.filter((re) => re.status != re.newStatus).length
			return counter
		},
		isViewButton() {
			if (this.info.indexTab == 1) return false
			if (
				this.isAdmin &&
				this.tracking &&
				this.tracking.last_tracking &&
				this.tracking.last_tracking.status == "enviado"
			)
				return true

			if (
				this.isPreparador &&
				this.tracking &&
				this.tracking.last_tracking &&
				["enviado_admin", "retornado", "en proceso"].includes(this.tracking.last_tracking.status)
			)
				return true

			return false
		},
		isFinalized() {
			if (
				this.isAdmin &&
				this.tracking &&
				this.tracking.last_tracking &&
				["enviado"].includes(this.tracking.last_tracking.status)
			) {
				return true
			}
			if (
				this.isPreparador &&
				this.tracking &&
				this.tracking.last_tracking &&
				["enviado_admin", "retornado", "en proceso"].includes(this.tracking.last_tracking.status)
			) {
				return true
			}
			return false
		},
	},
	methods: {
		resolveTitleTooltip(status) {
			if (status === "rechazado") return { title: "Cambiar", icon: "XIcon", variant: "danger" }
			if (["aprobado", "APROBADO"].includes(status))
				return { title: "Aprobado", icon: "CheckIcon", variant: "success" }
			if (["pendiente", "guardado"].includes(status))
				return { title: "Pendiente", icon: "ClockIcon", variant: "dark" }
			return { title: "pendiente", icon: "ClockIcon", variant: "dark" }
		},
		resolveVariant(tracking) {
			if (tracking) {
				if (tracking.status == "en proceso") {
					return "dark"
				} else if (tracking.status == "enviado") {
					return "warning"
				} else if (tracking.status == "retornado") {
					return "danger"
				} else {
					return "primary"
				}
			} else {
				return "dark"
			}
		},
		getTrackingStatus(tracking) {
			if (tracking) {
				if (tracking === "enviado_admin") {
					return "enviado_dueño"
				} else if (tracking === "proceso_admin") {
					return "proceso_dueño"
				} else {
					return tracking
				}
			}
		},
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
			// this.$emit("refresh")
		},
		statusMessage(status) {
			return status === "aprobado"
				? `se agregara al TORNEO: ${this.info.tournament.name}, con FECHA: ${moment(this.info.date).format(
						"MM/DD/YYYY"
				  )}.`
				: "quedara disponible para recomendar a otra fecha."
		},

		async openFinalize(status) {},
		async getRecommendeds() {
			this.isPreloading()
			try {
				const { data } = await RecommendedSpecimenService.getRecommendedRejected({
					id: this.info.id,
				})
				this.recommendeds = data.data.map((sp) => {
					return {
						...sp,
						// newStatus: ["aprobado", "rechazado"].includes(sp.status) ? sp.status : null,
						message_error: "",
						is_error: false,
						newStatus: sp.status,
					}
				})
			} catch (error) {
				console.log("error: ", error)
			} finally {
				this.isPreloading(false)
			}
		},

		async changeStatusRecommended(rec, status) {
			rec.newStatus = status
		},

		async activateRec() {
			const selecteds = this.validateButton
			console.log("selecteds: ", selecteds)
			const confirm = await this.showConfirmSwal({
				html: `<p style="margin:0 0 8px">Se activaran <span class='font-weight-bold'>${
					selecteds > 1 ? selecteds + " ejemplares" : selecteds + " ejemplar"
				}</span> en la recomendacion del torneo <span class='font-weight-bold'>${
					this.info.tournament.name
				} </span> | ${moment(this.info.date).format("MM/DD/YYYY")} </p> `,
			})

			if (!confirm.isConfirmed) return
			this.isPreloading()
			try {
				const specimens = this.recommendeds.filter((re) => re.status != re.newStatus)
				const updated = await RecommendedSpecimenService.activateRecommendeds(this.info.id, specimens)
				this.showSuccessToast(updated.data.message)
				await this.getRecommendeds()
				this.$emit("refresh")
			} catch (error) {
				console.log("error: ", error)
				if (error.response) {
					const currentError = error.response
					if (currentError.status == 400) {
						const recErrors = currentError.data.data
						recErrors.map((err) => {
							this.recommendeds.map((rec) => {
								if (rec.id == err.id) {
									const is_rec = err.type_error == "recommended"
									rec.is_error = true
									rec.message_error = `Este ejemplar ya fue ${
										is_rec ? "recomendado" : "registrado"
									} en Torneo: ${err.filters[0].to_name} | Fecha: ${moment(
										err.filters[0].te_date
									).format("MM/DD/YYYY")}`
								}
							})
						})
					}
				}
				// console.log("error: ",error.response)
			} finally {
				this.isPreloading(false)
			}
		},
	},
	async mounted() {
		this.toggleModal("modal-recommended-rejecteds")
	},
}
</script>

<style>
.is_error-row {
	background-color: rgba(253, 208, 178, 0.4);
}
</style>
<style lang="scss" scoped>
.hoverTrigger {
	padding: 0.6rem;
	// height: 110px;
	display: flex;
	align-items: center;
	justify-content: center;
	// border: 2px solid peru;
}

.hoverTrigger.card {
	flex-direction: column;
	gap: 0.3rem;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	max-width: 300px;
	margin-left: auto;
	margin-right: auto;

	@media screen and (min-width: 768px) {
		max-width: unset;
		margin: unset;
	}
}

.hoverTrigger .deleteOverlay {
	opacity: 0.8;
	background: rgba(255, 0, 0, 0.2);
	border-radius: 4px;
}

.hoverTrigger .deleteOverlaySuccess {
	opacity: 0.8;
	background: rgba(0, 255, 0, 0.2);
	border-radius: 4px;
}

.hoverTrigger .deleteOverlayEdit {
	right: 3rem;
	opacity: 0.8;
	background: rgba(255, 255, 0, 0.2);
	border-radius: 4px;
}
</style>
